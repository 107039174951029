import { DeviceDetectorService } from 'ngx-device-detector';
import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { CapacitorGlobal } from '@capacitor/core';
import { get } from '@vfi-ui/util/helpers';
import { CAPACITOR_CORE_TOKEN } from '@vfi-ui/models';
@Injectable({
  providedIn: 'root',
})
export class MobileGuard {
  constructor(
    private router: Router,
    private deviceDetectorService: DeviceDetectorService,
    @Inject(CAPACITOR_CORE_TOKEN) private capacitor: CapacitorGlobal
  ) {}
  /**
   * determines if the application is run on mobile device
   *
   * @returns {Observable<boolean>}
   * @memberof AuthGuard
   */
  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    const isMobile = this.deviceDetectorService.isMobile();
    const isTablet = this.deviceDetectorService.isTablet();
    if (isMobile || isTablet || this.capacitor.isNativePlatform()) {
      const url = get(route, '_routerState.url', '/alarms');
      this.router.navigate([`/m${url}`]);
      return false;
    }
    return true;
  }
}
