<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Add Domain Value"
  [nzBodyStyle]="{ 'min-height': '200px' }"
  [nzFooter]="modalFooter"
  [nzWidth]="460"
  (nzOnCancel)="closeModal.emit()"
>
  <ng-template nzModalContent>
    <section
      aria-label="createCmmsDomainValueFields"
      class="d-flex scrollable-frame modalBodyHeight"
    >
      <div class="d-flex flex-column">
        <div class="d-flex flex-row">
          <p>
            Please enter the name (key) and the value of the domain field to add
            to the CMMS <strong>{{ selectedCmmsField.name }}</strong> field
          </p>
        </div>
        <div class="d-flex" [formGroup]="form">
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active': form?.controls?.name?.value
            }"
          >
            <input
              aria-label="cmms-field-value-name"
              [ngClass]="{
                'required-field': form.controls.name.invalid
              }"
              #displayName
              type="text"
              class="form-control"
              formControlName="name"
            />
            <label>Name</label>
          </div>
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active': form?.controls?.value?.value
            }"
          >
            <input
              aria-label="cmms-field-value-value"
              [ngClass]="{
                'required-field': form.controls.value.invalid
              }"
              #displayName
              type="text"
              class="form-control"
              formControlName="value"
            />
            <label>Value</label>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
  <ng-template #modalFooter>
    <div class="w-100 d-flex justify-content-end">
      <atom-vfi-button
        [text]="'Cancel'"
        [type]="'secondary'"
        (clicked)="handleCancel()"
      ></atom-vfi-button>
      <atom-vfi-button
        class="ml-1"
        [text]="'Add Value'"
        [type]="'primary'"
        [disabled]="form.invalid || disableAddValueButton"
        (clicked)="handleCreateCmmsDomainValue()"
      ></atom-vfi-button>
    </div>
  </ng-template>
</nz-modal>
