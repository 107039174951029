import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DesktopRedirectGuard {
  constructor(private router: Router) {}

  /**
   * navigate to alarms table by default
   *
   * @returns {Observable<boolean>}
   * @memberof AuthGuard
   */
  canActivate(): boolean {
    this.router.navigate(['/alarms/Standard Lenses/Active Alarms']);
    return false;
  }
}
