import { DeviceDetectorService } from 'ngx-device-detector';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CapacitorGlobal } from '@capacitor/core';
import { CAPACITOR_CORE_TOKEN } from '@vfi-ui/models';
@Injectable({
  providedIn: 'root',
})
export class DesktopGuard {
  constructor(
    private router: Router,
    private deviceDetectorService: DeviceDetectorService,
    @Inject(CAPACITOR_CORE_TOKEN) private capacitor: CapacitorGlobal
  ) {}
  /**
   * determines if the application is run on non mobile device
   *
   * @returns {boolean}
   * @memberof AuthGuard
   */
  canActivate(): boolean {
    const isMobile = this.deviceDetectorService.isMobile();
    const isTablet = this.deviceDetectorService.isTablet();
    if (!isMobile && !isTablet && !this.capacitor.isNativePlatform()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
