<section
  *ngIf="reportExports.length"
  class="scrollable-frame report-export-container pr-2 cursor-default mt-1"
>
  <div *ngFor="let export of reportExports; let last = last">
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="d-flex align-items-center w-95">
        <i class="fa-regular fa-file-check regular-24 mr-2 dm00"></i>
        <div class="d-flex flex-column w-85">
          <div
            class="ellipsis regular-14 dm00"
            [nzTooltipTitle]="export?.fileName"
            nz-tooltip
          >
            {{ export?.fileName }}
          </div>
          <div
            *ngIf="export?.status === reportExportStatus.Complete"
            class="ellipsis regular-10"
          >
            Completed -
            {{ export?.completedAt | dateTime: 'MM/dd/yyyy hh:mm a' }}
          </div>
          <div
            *ngIf="export?.status === reportExportStatus.Failed"
            class="ellipsis regular-10"
          >
            Export failed
          </div>
          <div
            *ngIf="export?.status === reportExportStatus.InProgress"
            class="ellipsis regular-10"
          >
            Generating report
          </div>
        </div>
      </div>
      <div class="export-icon regular-18 cursor-default">
        <i
          *ngIf="export?.status === reportExportStatus.Complete"
          class="fa-solid fa-arrow-down-to-line cursor-pointer"
          (click)="downloadExport(export?.fileUrl, export?.fileName)"
        ></i>
        <i
          *ngIf="export?.status === reportExportStatus.Failed"
          class="fa-regular fa-circle-x"
        ></i>
        <i
          *ngIf="export?.status === reportExportStatus.InProgress"
          class="fa-solid fa-spinner-third fa-spin"
        ></i>
      </div>
    </div>
    <hr *ngIf="!last" />
  </div>
</section>
<section *ngIf="!reportExports.length" class="report-export-container">
  <p>There are currently no available exports</p>
</section>
