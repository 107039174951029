import { TableColumn } from '../interfaces/alarm-instance.interface';
import {
  AssetClass,
  AssetSource,
  AssetStatus,
} from '../interfaces/assets.interface';

export const AssetStatusComparatorValues = {
  [AssetStatus.Red]: 1,
  [AssetStatus.Yellow]: 2,
  [AssetStatus.Green]: 3,
};

export const AssetStatusOptions = [
  {
    label: 'Green',
    value: AssetStatus.Green,
  },
  {
    label: 'Red',
    value: AssetStatus.Red,
  },
  {
    label: 'Yellow',
    value: AssetStatus.Yellow,
  },
];

export const AssetCriticalityOptions = [
  {
    label: 'Highest',
    value: 5,
  },
  {
    label: 'High',
    value: 4,
  },
  {
    label: 'Medium',
    value: 3,
  },
  {
    label: 'Low',
    value: 2,
  },
  {
    label: 'Lowest',
    value: 1,
  },
  {
    label: 'No Criticality',
    value: 0,
  },
];

export const AssetClassOptions = [
  {
    label: 'Equipment',
    value: AssetClass.Equipment,
  },
  {
    label: 'Space',
    value: AssetClass.Space,
  },
  {
    label: 'System',
    value: AssetClass.System,
  },
];

export const AssetSourceOptions = [
  {
    label: 'Alarm',
    value: AssetSource.Alarm,
  },
  {
    label: 'Inventory',
    value: AssetSource.Inventory,
  },
];

export const ASSET_GRID_DEFAULT_COLUMNS: TableColumn[] = [
  {
    name: '',
    value: 'status',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Name',
    value: 'name',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Type',
    value: 'type',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Class',
    value: 'class',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Location - Building',
    value: 'building',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Location - Floor',
    value: 'floor',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Location - Room',
    value: 'room',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Associated Alarms',
    value: 'associatedAlarms',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Service Failure Alarms',
    value: 'associatedServiceImpactingAlarms',
    isVisible: true,
    isEditable: false,
    subGroup: 'Asset Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Associated Open Work',
    value: 'associatedWorkTickets',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Criticality',
    value: 'criticality',
    isVisible: true,
    isEditable: true,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Space Type',
    value: 'spaceType',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Room Name',
    value: 'roomName',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Room Number',
    value: 'roomNumber',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Licensed',
    value: 'isPaid',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'VF ID',
    value: 'id',
    isVisible: false,
    isEditable: false,
    subGroup: 'Asset Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Source',
    value: 'source',
    isVisible: false,
    isEditable: false,
    subGroup: 'Asset Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Label',
    value: 'label',
    isVisible: false,
    isEditable: false,
    subGroup: 'Asset Fields',
    isAlarmAttribute: false,
  },
];

export const AssetStatusTooltipValues = {
  [AssetStatus.Red]: 'Not Covered Alarms',
  [AssetStatus.Yellow]: 'Covered Alarms',
  [AssetStatus.Green]: 'No Impacting Alarms',
};

export const ASSET_CRITICALITY_MAP = new Map<number, string>()
  .set(5, 'Highest')
  .set(4, 'High')
  .set(3, 'Medium')
  .set(2, 'Low')
  .set(1, 'Lowest')
  .set(0, 'No Criticality')
  .set(null, 'No Criticality');

export const ASSET_CRITICALITY_VALUE_MAP = new Map<string, number>()
  .set('Highest', 5)
  .set('High', 4)
  .set('Medium', 3)
  .set('Low', 2)
  .set('Lowest', 1)
  .set('No Criticality', 0);

export const EQUIPMENT_SYSTEM_CREATE_FIELDS = [
  'name',
  'classId',
  'locationEntityId',
];

export const SPACE_CREATE_FIELDS = [
  'locationEntityId',
  'roomName',
  'roomNumber',
  'spaceType',
];
