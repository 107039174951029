import { OrderDirection } from '../constants/work-ticket.constant';

export interface ResolverOrder<T, T2 = string, T3 = string> {
  field: T;
  field2?: T2;
  field3?: T3;
  direction: OrderDirection;
}

export interface ResolverMultiOrderOptions<TOrder, TWhere> {
  limit?: number;
  offset?: number;
  order?: ResolverOrder<TOrder>[];
  where?: TWhere;
  whereNot?: TWhere;
}
