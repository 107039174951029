import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MINUTE_IN_MILLISECONDS, TimeValues } from '@vfi-ui/models';
import { calculateReadableMSTime, isNull } from '@vfi-ui/util/helpers';

@Component({
  selector: 'nuclei-duration-value',
  templateUrl: './duration-value.component.html',
  styleUrls: ['./duration-value.component.scss'],
})
export class DurationValueComponent implements OnChanges {
  @Input() duration;
  @Input() color = 'dm00';
  @Input() fontSize = 14;
  timeValues: TimeValues;
  isNull = isNull;
  minuteInMs = MINUTE_IN_MILLISECONDS;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.duration) {
      this.timeValues = null;
      this.timeValues = calculateReadableMSTime(this.duration);
    }
  }
}
