import { gql } from 'apollo-angular';

export const systemSource = gql`
  query ExternalSystem($options: ExternalSystemOptions!) {
    externalSystemsAndCount(options: $options) {
      items {
        displayName
        schema
        type
        isAutomationRuleCmmsSyncEnabled
        isCloseWorkButtonEnabled
        name: id
        databaseId: name
        id
        workTicketObjectiveTemplate
        isActive
        createdAt
      }
    }
  }
`;

export const GET_INTEGRATIONS_QUERY = `
  query externalSystemsAndCount($options: ExternalSystemOptions!) {
    externalSystemsAndCount(options: $options) {
      items {
        id
        name
        type
        displayName
        direction
        connectionType
        hideConfidenceThreshold
        rescaleConfidenceThreshold
        highlightConfidenceThreshold
        levenshteinConfidenceThreshold
        genericParserAlarmCount
        lastRawEmailCsvGenerationAt
        rawEmailCsvFileName
        lastTrainingSetUploadAt
        alarmCount
        averageAccuracy
        workTicketObjectiveTemplate
        excludedAlarmTexts
        isActive
        lastModifiedAt
      }
    }
  }
`;

export const GET_ALL_INTEGRATIONS_QUERY = `
query externalSystemsAndCount($options: ExternalSystemOptions!) {
  externalSystemsAndCount(options: $options) {
      items {
        id
        displayName
        alarmThroughput
        averageProcessingDuration
        workTicketObjectiveTemplate
      }
  }
}
`;

export const GET_EXTERNAL_SYSTEM_JSON_SCHEMA_QUERY = `
query getExternalSystemJsonSchema($formType: String!) {
  getExternalSystemJsonSchema(formType: $formType)
}
`;

export const CREATE_EXTERNAL_SYSTEM_QUERY = `
  mutation createExternalSystem($data: ExternalSystemCreateInput!) {
    createExternalSystem(data: $data) {
      id
        name
        type
        displayName
    }
  }
`;

export const UPDATE_EXTERNAL_SYSTEM_QUERY = `
  mutation updateExternalSystem($id: Int!, $data: ExternalSystemUpdateInput!) {
    updateExternalSystem(id: $id, data: $data) {
      id
      name
      type
      displayName
      genericParserConfiguration {
        separators
      }
    }
  }
`;

export const EXTERNAL_SYSTEM_AND_COUNTS_QUERY = `
query externalSystemsAndCount($options: ExternalSystemOptions!) {
  externalSystemsAndCount(options: $options) {
    items {
      type
      displayName
      schema
      licenseExpiresAt
      operatingSystemType
      credentials
      hideConfidenceThreshold
      rescaleConfidenceThreshold
      highlightConfidenceThreshold
      levenshteinConfidenceThreshold
      hasProcessVariables
      direction
      connectionType
      inboundEmailAddress
      isExternalAlertsEnabled
      isAutomationRuleCmmsSyncEnabled
      externalAlertEmails
      lastAccessOnSlackThreshold
      lastAccessOnResolutionThreshold
      alarmThroughputEmailThreshold
      etlNormalizeVersion
      etlIgnorableStates
      etlTimestampOffset
      cmmsWorkTicketSyncThreshold
      isCloseWorkButtonEnabled
      workTicketObjectiveTemplate
      isCmmsPollingBackSyncEnabled
      normalizerVersions
    }
  }
}
`;

export const GENERATE_BAS_LICENSE_KEY_QUERY = `
query getLicenseKeyForBAS($id: Int!) {
  getLicenseKeyForBAS(id: $id)
}
`;

export const GENERATE_PARSER_JOB = `
  mutation createGenericParserJob($externalSystemId: Int!) {
    createGenericParserJob(externalSystemId: $externalSystemId) {
        id
        startedAt
        createdAt
        state
        endedAt
    }
  }
`;

export const GENERATE_RAW_EMAIL_CSV_QUERY = `
   mutation generateRawEmailCsv($id: Int!) {
    generateRawEmailCsv(id: $id)
  }
`;

export const GET_EXTERNAL_SYSTEMS_OPTIONS_QUERY = `
  query getExternalSystemOptions($type: ConnectionType) {
    getExternalSystemOptions(type: $type) {
      name
      schema
      connectionType
    }
  }
`;

export const ISSUE_KILL_COMMAND = `
mutation initiateExternalSystemKill($id: Int!) {
  initiateExternalSystemKill(id: $id)
}`;

export const REPARSE_ALARMS_FOR_EXTERNAL_SYSTEM = `
mutation reparseAlarmsForExternalSystem($id: Int!) {
  reparseAlarmsForExternalSystem(id: $id)
}`;

export const REPLAY_ALARMS_FOR_EXTERNAL_SYSTEM = `
mutation replayAlarms($id: Int!, $input: ExternalSystemAlarmReplayInput) {
  replayAlarms(id: $id, input: $input)
}`;

export const GET_EXTERNAL_SYSTEM_ERROR_LOGS_QUERY = gql`
  query getExternalSystemIssueLogs($options: ExternalSystemIssueLogOptions!) {
    getExternalSystemIssueLogs(options: $options) {
      externalSystemId
      issueType
      isResolved
      createdAt
      workTicketId
      resolvedAt
      dismissedAt
    }
  }
`;

export const INITIATE_EXTERNAL_SYSTEM_GET_ASSETS_COMMAND = gql`
  mutation initiateExternalSystemGetAssetsCommand(
    $id: Int!
    $commandType: ExternalSystemAssetCommandType!
  ) {
    initiateExternalSystemGetAssetsCommand(id: $id, commandType: $commandType)
  }
`;

export const GET_EXTERNAL_SYSTEM_COMMAND_TYPES_QUERY = gql`
  query getExternalSystemCommandTypes($id: Int!) {
    getExternalSystemCommandTypes(id: $id)
  }
`;

export const INITIATE_EXTERNAL_SYSTEM_HISTORICAL_COMMAND = gql`
  mutation initiateExternalSystemSync(
    $id: Int!
    $data: ExternalSystemSyncInput!
  ) {
    initiateExternalSystemSync(id: $id, data: $data)
  }
`;

export const GET_EXTERNAL_SYSTEM_COMMANDS_QUERY = gql`
  query getExternalSystemCommands($options: ExternalSystemCommandOptions!) {
    getExternalSystemCommands(options: $options) {
      id
      externalSystemId
      status
      command
      commandType
      response
      workTicketIds
      createdAt
      finishedAt
    }
    getExternalSystemCommandCount(options: $options)
  }
`;

export const RETRY_EXTERNAL_SYSTEM_COMMAND = gql`
  mutation retryExternalSystemCommand($id: String!) {
    retryExternalSystemCommand(id: $id)
  }
`;
