import {
  Directive,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[atomRateOfChange]',
})
export class RateOfChangeDirective implements OnChanges {
  @Input() change: number;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.change) {
      const color =
        this.change > 0 ? '#DE6363' : this.change < 0 ? '#5CCA9C' : '#C7CED6';
      this.el.nativeElement.style.color = color;
    }
  }
}
