import { AllAlarmFields } from './alarms.constant';

export const MAX_ASSOCIATED_ALARMS = 25;
export const CRITERIA_DAYS = [1, 5, 7, 10, 14, 30];
export const MaxWorkTicketsLimit = 30;
export const WorkTicketOpenIcon = 'fas fa-clipboard-list-check';
export const WorkTicketCloseIcon = 'far fa-clipboard-list-check';
export const WorkTicketCMMSFailIcon = 'fas fa-exclamation';
export const PreAlarmReoccurencesRange = -30;
export const PostAlarmReoccurencesRange = 30;
export const PreWorkReoccurencesRange = -90;
export const PostWorkReoccurencesRange = 90;
export const CMMSAutomaticallyClosedPlaceholder =
  'This ticket was automatically closed by ';

export const WORK_HISTORY_REPORT_BLACKLISTED_FILTERS = ['Active'];

export const allWorkTicketActivityFields = `
  id
  type
  state
  status
  externalStatus
  externalType
  assignee {
    id
    displayName
    avatarUrl
  }
  comment
  isDeleted
  lastModifiedBy {
    id
    displayName
    avatarUrl
  }
  lastModifiedAt
  createdBy {
    id
    displayName
    avatarUrl
  }
  createdAt
  cmmsSyncAttemptBy {
    id
    displayName
    avatarUrl
  }
  cmmsSyncAttemptAt
  error
`;

export const allWorkTicketFields = `
  id
  displayId
  assignee {
    id
    displayName
    avatarUrl
    firstName
    lastName
  }
  createdBy {
    id
    displayName
    avatarUrl
  }
  closedBy {
    id
    displayName
  }
  createdAt
  completedWorkDetails
  state
  status
  isResolved
  dueAt
  closedAt
  objective
  problemDetails
  externalId
  externalType
  externalStatus
  externalDetails
  isEscalated
  closestEntityImpactAt
  entities {
    id
    name
    alarmId
  }
  isExternalSyncEnabled
  isClosedExternally
  alarms {
    ${AllAlarmFields}
  }
  alarmCount
  oneHourSinceLastClosed: alarmsUnresolved(input: {hoursSinceLastClosed: 1}) {
    id
  }
  twentyFourHourSinceLastClosed: alarmsUnresolved(input: {hoursSinceLastClosed: 24}) {
    id
  }
  oneDaySinceLastClosed: alarmInstanceReoccurenceFirst(input: { daysSinceLastClosed: 1 }) {
    id
    alarmTime
  }
  alarmsUnresolvedAfterClose: alarmsUnresolved(input: {
    atCloseTime: true,
    hoursSinceLastClosed: 24
  }) {
    id
  }
  automationRule {
    id
    createdAt
    name
  }
  cmmsSyncStatus
  lastCmmsSyncAttemptAt
  lastCmmsSyncAttemptBy
  cmmsSyncFailureNotificationDismissedBy
  cmmsSyncFailureNotificationDismissedAt
  formattedExternalDetails {
    field {
      id
      name
      isReadOnly
      displayOrder
      displayTemplate
      dataType
      title
    }
    name
    value
  }
  isRelatedEntityActive
  externalSystem {
    id
    name
    displayName
    schema
    isActive
    isCloseWorkButtonEnabled
  }
  `;

export enum WorkTicketStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  Open = 'OPEN',
}
export enum WorkTicketProcessState {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export enum WorkTicketSortField {
  ID = 'ID',
  CREATED_AT = 'CREATED_AT',
  DUE_AT = 'DUE_AT',
  CLOSED_AT = 'CLOSED_AT',
  ASSOCIATED_ALARMS = 'ASSOCIATED_ALARMS',
  AGE_DAYS = 'AGE_DAYS',
  IS_RESOLVED = 'IS_RESOLVED',
  ASSIGNEE = 'ASSIGNEE',
  CLOSEST_ENTITY_IMPACT_AT = 'CLOSEST_ENTITY_IMPACT_AT',
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  asc = 'ASC',
  desc = 'DESC',
}

export const SurveyDifficulty = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

export const ExpectedBudgetTime = [
  { label: 'Not at all', value: 1 },
  { label: 'Somewhat', value: 2 },
  { label: 'Entirely', value: 3 },
];

export const WorkTicketStateValues = {
  CLOSED: 'Closed',
  OPEN: 'Open',
};

export enum WorkTicketExternalStatus {
  Open = 'NEWREQUEST',
  Closed = 'CLOSED',
  Close = 'CLOSE',
}

export enum WorkTicketExternalType {
  AWARE_MANAGER = 'AwareManager',
  MAXIMO = 'Maximo',
  MAXIMO_SERVICE_REQUEST = 'Maximo Service Request',
  FAMIS = 'FAMIS',
  MAINTENANCE_CONNECTION = 'Maintenance Connection',
  WEBTMA = 'WebTMA',
  WEBTMACLOUD = 'WEBTMACLOUD',
  TMS = 'TMS',
  SCHOOLDUDE = 'SchoolDude',
  SABRE = 'Sabre',
}

export const DefaultWorkSort = {
  name: 'Created Date',
  dbName: WorkTicketSortField.CREATED_AT,
};

export enum WorkTicketCmmsSyncStatus {
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
}
