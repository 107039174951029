import { AnalyticsGoalsFormats } from '../constants/analytics-goals.constants';
import {
  KpiDashboardMetricCategory,
  KpiDashboardMetricType,
} from '../constants/kpi-dashboard.constants';
import { AlarmInstanceCoveredSource } from './alarm-instance-report.interface';
import { AlarmTimeWindow } from './alarm-instance.interface';
import { DateType } from './core.interface';

export interface KpiResult {
  metricType: KpiDashboardMetricType;
  currentPeriod: KpiMetric;
  previousPeriod: KpiMetric;
  metricConfig: KpiDashboardMetricConfig;
  note: KpiDashboardNote;
}

export interface KpiMetric {
  numerator: number;
  denominator: number;
}

export interface KpiDashboard {
  metricType?: KpiDashboardMetricType;
  metricCategory?: KpiDashboardMetricCategory;
  dateType?: DateType;
  numerator?: number;
  denominator?: number;
  isTrending?: boolean;
  createdAt?: string;
  metadata?: object;
  metricConfig?: KpiDashboardMetricConfig;
  lastDashboard?: KpiDashboard;
}

export interface KpiDashboardMetricConfig {
  id?: string;
  metricType?: KpiDashboardMetricType;
  metricCategory?: KpiDashboardMetricCategory;
  format?: AnalyticsGoalsFormats;
  scale?: number;
  warning?: number;
  goal?: number;
}

export interface KpiDashboardWorkTimeToDispatchTeamDrilldown {
  teamId: string;
  teamName?: string;
  workTicketCount?: number;
  averageTimeToDispatch?: number;
  maxTimeToDispatch?: number;
  previousAvgTimeToDispatch?: number;
  previousAvgTimeToDispatchReadable?: string;
}

export interface KpiDashboardWorkTimeToDispatchCreatorDrilldown {
  userId: string;
  userDisplayName?: string;
  workTicketCount?: number;
  avgTimeToDispatch?: number;
  longestTimeToDispatch?: number;
  previousAvgTimeToDispatch?: number;
  previousAvgTimeToDispatchReadable?: string;
}

export interface KpiDashboardWorkCoverageAlarmDrillDown {
  priority?: number;
  alarmInstanceCount?: number;
  coveredAlarmInstanceCount?: number;
  alarmInstanceCoveredByUserCount?: number;
  alarmInstanceCoveredByAutomationCount?: number;
  workTicketCount?: number;
}

export interface KpiDashboardDuplicateWorkDrillDown {
  teamId?: string;
  teamName?: string;
  workTicketCount?: number;
  duplicateWorkTicketCount?: number;
  duplicateWorkTicketPercent?: number;
  overlappingAlarmCount?: number;
}

export interface KpiDashboardAwarenessMetricAlarmDrillDown {
  priority?: number;
  alarmInstanceCount?: number;
  dispatcherCount?: number;
  alarmInstanceDistribution?: number;
}

export interface KpiDashboardOpenWorkInactiveAlarmDrilldown {
  teamId?: string;
  teamName?: string;
  workTicketCount?: number;
  openWorkTicketCount?: number;
  openWorkCount?: number;
  alarmInactiveTimeMedian?: string;
}

export interface KpiDashboardReportWhere {
  priority?: number[];
  alarmTime?: AlarmTimeWindow;
  endTime?: AlarmTimeWindow;
  minDuration?: number;
  maxDuration?: number;
  attributes?: any[];
  externalSystemId?: number[];
  isFleeting?: boolean;
  isChattering?: boolean;
  isNuisance?: boolean;
  isCovered?: boolean;
  coveredSource?: AlarmInstanceCoveredSource;
  createdByTeam?: [string];
  assetCriticality?: number[];
}

export interface KpiDashboardNote {
  metricType?: KpiDashboardMetricType;
  notes?: string;
}
