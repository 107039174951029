import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AlarmPriorityIcon } from '@vfi-ui/models';
import { isNil } from '@vfi-ui/util/helpers';

@Component({
  selector: 'atom-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
  @Input() id: string;
  @Input() priority: number;
  @Input() status: string;
  @Input() nuisanceCount: number;
  @Input() bellIcon: string;
  @Input() priorityColor: string;
  @Input() showBackground = true;
  @Input() size = 16;
  @Input() showTooltip = false;
  iconPrefix: string;
  iconBell: string;
  iconColor: string;

  constructor() {}

  ngOnChanges(change: SimpleChanges) {
    if (
      (change.id && change.id.currentValue) ||
      (change.priority && !isNil(change.priority.currentValue))
    ) {
      this.iconPrefix = this.updatePrefix(this.status);
      this.iconBell = AlarmPriorityIcon;
      this.iconColor = this.updateColor(this.priority);
    }
  }

  /**
   * update icon prefix based on status
   *
   * @param {string} status
   * @returns {string}
   * @memberof IconComponent
   */
  updatePrefix(status: string): string {
    return status.toLowerCase() === 'inactive' ? 'fal' : 'fas';
  }

  /**
   * update icon color based on priority
   *
   * @param {number} priority
   * @returns {string}
   * @memberof IconComponent
   */
  updateColor(priority: number): string {
    return `priority-${priority}`;
  }
}
