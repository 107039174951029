import {
  EntityType,
  MultiLevelInputOption,
  MultiLevelPath,
} from '@vfi-ui/models';
import { EntityManagementDataService } from '@vfi-ui/data-access/shared';
import { BaseComponent } from '@vfi-ui/feature/core';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject, take } from 'rxjs';
import {
  debounce,
  fastParse,
  formatEntityOptionNames,
} from '@vfi-ui/util/helpers';

@Component({
  selector: 'atom-point-entity-selector',
  templateUrl: './point-entity-selector.component.html',
  styleUrls: ['./point-entity-selector.component.scss'],
})
export class PointEntitySelectorComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Output() entityChanged = new EventEmitter<MultiLevelInputOption>();
  @Output() pathChanged = new EventEmitter<MultiLevelPath>();
  @Output() newSelected = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() value: MultiLevelInputOption;
  @Input() reset: Subject<boolean>;
  @Input() type: EntityType;
  @Input() showMultiLevelSelect = false;
  @Input() disabled = false;
  @Input() baseEntity: MultiLevelInputOption;
  @Input() pointEntity: MultiLevelInputOption;
  @Input() showInput = true;
  @Input() lowestLevel: number;
  @Input() showTypeSelector = true;
  @Input() multiple = false;
  @Input() allowNew = false;
  parent: MultiLevelInputOption[] = [];
  options = [];
  loading = false;
  assetTypeCount: number;
  spaceTypeCount: number;
  offset = 0;
  limit = 50;
  optionCount: number;

  constructor(
    private entityManagement: EntityManagementDataService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.reset) {
      this.reset.pipe(take(1)).subscribe(() => {
        this.value = this.pointEntity;
      });
    }

    if (this.baseEntity) {
      this.parent = [this.baseEntity];
    }

    if (this.type) {
      this.parent = [null];
    }

    this.getEntities(null, null, this.type);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.value?.currentValue) {
      this.value = changes.value.currentValue;
    }

    if (changes?.pointEntity?.currentValue) {
      this.pointEntity = changes.pointEntity.currentValue;
    }
  }

  /**
   * get search value from form
   * @param {string} searchTerm
   * @memberof PointEntitySelectorComponent
   */
  searchEntities(searchTerm: string) {
    const debounced = debounce(this.getEntities, 1000);
    debounced.call(
      this,
      this.parent[this.parent.length - 1]?.id,
      searchTerm,
      this.type
    );
  }

  /**
   * drill down to child
   *
   * @param {MultiLevelInputOption} event
   * @memberof PointEntitySelectorComponent
   */
  drillDown(event) {
    this.loading = true;
    if (!this.parent.length) {
      this.type = event.option.name;
    }

    if (event.option.count > 0) {
      this.offset = 0;
      this.parent.push(event.option);
      this.baseEntity = event.option;
      this.getEntities(
        this.baseEntity.id as number,
        event.searchTerm,
        this.type
      );
    }
  }

  /**
   * click back to parent
   *
   * @memberof PointEntitySelectorComponent
   */
  back() {
    this.offset = 0;
    this.options = null;
    this.parent.pop();

    this.getEntities(
      this.parent[this.parent.length - 1]?.id as number,
      null,
      this.type
    );
  }

  /**
   * scroll to load more
   * @param {string} searchTerm
   * @memberof PointEntitySelectorComponent
   */
  scroll(event: { searchTerm: string }) {
    if (this.options.length < this.optionCount) {
      this.offset += this.limit;
      this.getEntities(
        this.parent[this.parent.length - 1]?.id as number,
        event.searchTerm,
        this.type
      );
    }
  }

  /**
   * get entities from api
   * @param {number} [baseEntityId]
   * @param {string} [pattern]
   * @param {string} [type]
   * @memberof PointEntitySelectorComponent
   */
  getEntities(baseEntityId?: number, pattern?: string, type?: string) {
    this.assetTypeCount = null;
    this.spaceTypeCount = null;

    if (this.parent.length && type) {
      if (!this.options) {
        this.loading = true;
      }
      this.entityManagement
        .getEntityOptions(
          baseEntityId,
          pattern,
          EntityType[type],
          this.limit,
          this.offset
        )
        .subscribe((res) => {
          this.optionCount = res?.searchEntityCount;
          const searchEntities = fastParse(res?.searchEntities);
          const formattedOptions = formatEntityOptionNames(searchEntities);
          const newOption = {
            id: 0,
            name: `Add New ${type}`,
            count: 0,
            building: '',
            class: '',
          };
          this.loading = false;
          this.options =
            this.offset === 0
              ? formattedOptions
              : [...this.options, ...formattedOptions];
          if (this.allowNew && this.options.length === this.optionCount) {
            this.options.push(newOption);
          }
          this.cd.detectChanges();
        });
    } else {
      this.options = [
        { name: 'Space', count: 1 },
        { name: 'Asset', count: 1 },
      ];

      if (!baseEntityId && pattern) {
        this.entityManagement
          .searchEntityCount(baseEntityId, pattern, EntityType.Space)
          .pipe(take(1))
          .subscribe((res) => {
            this.options[0].meta = { badge: res };
            this.cd.detectChanges();
          });
        this.entityManagement
          .searchEntityCount(baseEntityId, pattern, EntityType.Asset)
          .pipe(take(1))
          .subscribe((res) => {
            this.options[1].meta = { badge: res };
            this.cd.detectChanges();
          });
      }
    }
  }

  /**
   * emit value on change
   * @param {MultiLevelInputOption} option
   * @memberof PointEntitySelectorComponent
   */
  onValueChange(option: MultiLevelInputOption) {
    this.value = option;
    const path = { path: this.parent, value: this.value };
    this.pathChanged.emit(path);
    this.entityChanged.emit(this.value);
    this.showMultiLevelSelect = false;
    this.getEntities(null, null, this.type);
  }
}
