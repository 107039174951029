import { BaseComponent } from '@vfi-ui/feature/core';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { AngularDateFormat, CoreAlarm } from '@vfi-ui/models';

@Component({
  selector: 'nuclei-alarm-source',
  templateUrl: './alarm-source.component.html',
  styleUrls: ['./alarm-source.component.scss'],
})
export class AlarmSourceComponent extends BaseComponent {
  @Input() alarm: CoreAlarm;
  @Input() show = true;
  @Input() showDate = true;
  @Input() alarmSource: string;
  @Output() sourceSelected: EventEmitter<void> = new EventEmitter();
  angularDateFormat = AngularDateFormat;

  /**
   * Invoked when the source link is selected
   * emits an event notifying callbacks that
   * the source has been selected so that they
   * may take the appropriate action.
   *
   * @memberof AlarmSourceComponent
   */
  onSourceSelected() {
    this.sourceSelected.emit();
  }
}
