import { fastParse } from '@vfi-ui/util/helpers';
import { UntypedFormGroup } from '@angular/forms';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Users, User } from '@vfi-ui/models';

@Component({
  selector: 'atom-dropdown-menu-icon-left',
  templateUrl: './dropdown-menu-icon-left.component.html',
  styleUrls: ['./dropdown-menu-icon-left.component.scss'],
})
export class DropdownMenuIconLeftComponent implements OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() users: Users[];
  @Input() user: User;
  @Input() formName: string;
  @Input() labelName: string;
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() updateFormValue = true;
  @Input() resetOnSelect = false;
  @Input() showCurrentUser = true;
  @Output() searchValue: EventEmitter<string> = new EventEmitter();
  @Output() selectedId: EventEmitter<string> = new EventEmitter();
  focus: boolean;
  selectedUser: Users;
  selectedIds = [];
  usersList: Users[];

  constructor() {}

  ngOnChanges(change: SimpleChanges) {
    if (change.users && change.users.currentValue) {
      this.updateUsersAndForm(this.showCurrentUser, this.value);
    }
    if (change.value && change.value.currentValue) {
      if (this.users) {
        this.updateForm();
      }
    }
  }

  /**
   * update users and form
   *
   * @param {boolean} showCurrentUser
   * @param {string} value
   * @memberof DropdownMenuIconLeftComponent
   */
  updateUsersAndForm(showCurrentUser: boolean, value: string) {
    this.usersList = fastParse(this.users);
    if (!showCurrentUser) {
      this.usersList = this.removeCurrentUser(this.usersList);
    }
    if (value) {
      this.updateForm();
    }
  }

  /**
   * emits searchValue to fetch users
   *
   * @param {string} [searchValue]
   * @memberof DropdownMenuIconLeftComponent
   */
  fetchUsers(searchValue?: string) {
    if (searchValue) {
      this.searchValue.emit(searchValue);
    }
    this.focus = true;
  }

  /**
   * update form with selected value and reset focus value
   *
   * @memberof DropdownMenuIconLeftComponent
   */
  updateForm(touched?: boolean) {
    this.selectedUser = this.users.find((u) => u.id === this.value);
    if (this.updateFormValue) {
      this.updateFormValues(this.formName, this.value, touched);
    }
    if (this.selectedUser) {
      this.selectedId.emit(String(this.selectedUser.id));
    }
    this.focus = false;
    if (this.resetOnSelect) {
      this.resetValues();
    }
  }

  /**
   * remove current user from users list
   *
   * @private
   * @param {Users[]} users
   * @returns
   * @memberof DropdownMenuIconLeftComponent
   */
  private removeCurrentUser(users: Users[]) {
    return users.filter((user) => user?.id !== this.user?.id);
  }

  /**
   * update form values
   *
   * @private
   * @param {string} formName
   * @param {string} value
   * @memberof DropdownMenuIconLeftComponent
   */
  private updateFormValues(formName: string, value: string, touched?: boolean) {
    this.form.get(formName).patchValue(value);
    if (this.value) {
      this.form.get(formName).markAsDirty();
    }
    if (touched) {
      this.form.get(formName).markAsTouched();
    }
  }

  /**
   * reset select value
   *
   * @private
   * @memberof DropdownMenuIconLeftComponent
   */
  private resetValues() {
    this.value = null;
    this.selectedUser = null;
  }
}
