<div [ngClass]="{ 'cursor-not-allowed': disabled }">
  <div
    class="vfi-input"
    [ngClass]="{
      'pointer-none': readOnly || disabled,
      disabled,
    }"
  >
    <div
      *ngIf="label"
      class="vfi-label"
      [class.yellow-6]="highlightRequired && required && !readOnly"
    >
      <span>{{ label }}</span>
      <span *ngIf="required">&nbsp;(Required)</span>
    </div>
    <div
      class="input-icons position-relative"
      [ngClass]="{ subtle, success, warning, error }"
    >
      <textarea
        [ngModel]="value"
        (ngModelChange)="valueChanged.emit($event)"
        [ngClass]="{ 'disabled-input': disabled }"
        class="vfi-textarea"
        [class.readOnly]="readOnly"
        [maximumHeight]="1000"
        [minHeight]="48"
        maxlength="1000"
        [placeholder]="placeholder"
        [reset]="reset"
        atomAutoGrow
        tabindex="-1"
      ></textarea>
    </div>
    <p *ngIf="hint && !warning && !error" class="mb-0 hint-text">{{ hint }}</p>
    <p *ngIf="warning && warningMessage && !subtle" class="mb-0 hint-text">
      {{ warningMessage || hint }}
    </p>
    <p *ngIf="error && errorMessage && !subtle" class="mb-0 error-text">
      {{ errorMessage || hint }}
    </p>
  </div>
</div>
