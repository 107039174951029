import { Component, Input } from '@angular/core';
import {
  WorkTicketStatus,
  WorkTicketExternalStatus,
  WorkTicketProcessState,
  WorkTicket,
} from '@vfi-ui/models';

@Component({
  selector: 'atom-badge-work',
  templateUrl: './badge-work.component.html',
  styleUrls: ['./badge-work.component.scss'],
})
export class BadgeWorkComponent {
  @Input() ticket: WorkTicket;
  @Input() externalTicket = false;

  constructor() {}

  /**
   * get badge classes based on if ticket is external or internal
   *
   * @param {WorkTicket} ticket
   * @param {boolean} externalTicket
   * @returns
   * @memberof BadgeWorkComponent
   */
  getBadgeClasses(ticket: WorkTicket, externalTicket: boolean) {
    let classes = [];
    if (!externalTicket) {
      classes = [
        this.getStatusBackgroundColor(ticket?.state, ticket?.status),
        this.getStatusFontColor(ticket?.state, ticket?.status),
      ];
    } else {
      classes = [
        this.getExternalStatusBackgroundColor(ticket?.externalStatus),
        this.getExternalStatusFontColor(ticket?.externalStatus),
      ];
    }
    return classes;
  }

  /**
   * returns status background color based on ticket state
   *
   * @param {string} state
   * @returns
   * @memberof BadgeWorkComponent
   */
  getStatusBackgroundColor(state: string, status: string) {
    let bgColor = 'neutral-1-bg';
    if (status === WorkTicketStatus.Open) {
      bgColor = 'neutral-1-bg';
    }
    if (state === WorkTicketProcessState.Closed) {
      bgColor = 'gray-10-bg';
    }
    return bgColor;
  }

  /**
   * returns status background color based on external status
   *
   * @param {string} status
   * @returns
   * @memberof BadgeWorkComponent
   */
  getExternalStatusBackgroundColor(status: string) {
    let bgColor = 'neutral-1-bg';
    if (status === WorkTicketExternalStatus.Open) {
      bgColor = 'neutral-1-bg';
    }
    if (
      status === WorkTicketExternalStatus.Closed ||
      status === WorkTicketExternalStatus.Close
    ) {
      bgColor = 'gray-10-bg';
    }
    return bgColor;
  }

  /**
   * returns status font colot based on ticket state
   *
   * @param {string} state
   * @returns
   * @memberof BadgeWorkComponent
   */
  getStatusFontColor(state: string, status: string) {
    let fontColor = 'gray-7';
    if (status === WorkTicketStatus.Open) {
      fontColor = 'gray-7';
    }
    if (state === WorkTicketProcessState.Closed) {
      fontColor = 'neutral-1';
    }
    return fontColor;
  }

  /**
   * returns status font color based on external status
   *
   * @param {string} status
   * @returns
   * @memberof BadgeWorkComponent
   */
  getExternalStatusFontColor(status: string) {
    let fontColor = 'gray-7';
    if (status === WorkTicketExternalStatus.Open) {
      fontColor = 'gray-7';
    }
    if (
      status === WorkTicketExternalStatus.Closed ||
      status === WorkTicketExternalStatus.Close
    ) {
      fontColor = 'neutral-1';
    }
    return fontColor;
  }

  /**
   * returns text of status badge
   *
   * @param {string} state
   * @param {string} status
   * @returns
   * @memberof BadgeWorkComponent
   */
  getStatusText(state: string, status: string) {
    let text = status;
    if (status === WorkTicketStatus.Open) {
      text = status;
    }
    if (state === WorkTicketProcessState.Closed) {
      text = state;
    }
    return text;
  }
}
