import {
  WorkTicketExternalType,
  WorkTicketProcessState,
  WorkTicketStatus,
  WorkTicketExternalStatus,
} from '../constants/work-ticket.constant';
import { Users } from './user.interface';

export interface WorkTicketActivity {
  id: string;
  type: WorkTicketActivityType;
  state: WorkTicketProcessState;
  status: WorkTicketStatus;
  assignee: Users;
  comment: String;
  isDeleted: boolean;
  lastModifiedBy: Users;
  lastModifiedAt: string;
  createdBy: Users;
  createdAt: string;
  externalStatus: WorkTicketExternalStatus;
  externalType: WorkTicketExternalType;
}

export interface ActivityLog extends WorkTicketActivity {
  editMode: boolean;
  newComment: string;
}

export enum WorkTicketActivityType {
  STATE = 'STATE',
  ASSIGNEE = 'ASSIGNEE',
  COMMENT = 'COMMENT',
  REISSUE = 'REISSUE',
  EXTERNAL_STATUS = 'EXTERNAL_STATUS',
  EXTERNAL_CREATION = 'EXTERNAL_CREATION',
  EXTERNAL_CLOSURE = 'EXTERNAL_CLOSURE',
  CMMS_SYNC_FAILURE = 'CMMS_SYNC_FAILURE',
  CMMS_SYNC_SUCCESS = 'CMMS_SYNC_SUCCESS',
  DISMISS_CMMS_SYNC_FAILURE = 'DISMISS_CMMS_SYNC_FAILURE',
  RETRY_SYNC = 'RETRY_SYNC',
}
