import { AuthRoles } from '../constants/roles.constants';
import { Email, Phone } from './user.interface';

export interface SimulatorMetaContent {
  isEnabled: boolean;
  filename: string;
  lastUploadBy: SimulatorLastUploadBy;
  lastUploadAt: string;
}

export interface SimulatorLastUploadBy {
  id: string;
  uid: string;
  phone: Phone;
  email: Email;
  displayName: string;
  firstName: string;
  lastName: string;
  role: string;
  authRole: AuthRoles;
  company: string;
  avatarUrl: string;
  status: string;
  lastSignInTime: string;
  teams: any[];
}
