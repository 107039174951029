import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TABLE_SIZE_OPTIONS } from '@vfi-ui/models';
import { get } from '@vfi-ui/util/helpers';

@Component({
  selector: 'atom-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() pageLimit: number;
  @Input() totalItems: number;
  @Input() allowPageChange = false;
  @Input() disabled = false;
  @Input() pageIndex = 1;
  @Input() resetCurrentPage = false;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();
  pageOptions = TABLE_SIZE_OPTIONS;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const isTotalItemsChanged = get(changes.totalItems, 'currentValue', false);
    this.pageIndex =
      isTotalItemsChanged && !this.resetCurrentPage ? 1 : this.pageIndex;
  }
}
