export interface MultiLevelInputOption {
  id?: number | string;
  name: string;
  meta?: { [key: string]: any };
  count?: number;
}

export interface MultiLevelPath {
  path: MultiLevelInputOption[];
  value: MultiLevelInputOption;
}
