import {
  AlarmProperty,
  SelectedAlarmProperty,
} from './alarm-instance.interface';

export interface Location {
  id: string;
  name: string;
  alarmProperties: AlarmProperty[];
}

export interface SelectLocation extends Location {
  hidden?: boolean;
  selected?: boolean;
  isEditing?: boolean;
}
export interface LocationBuildingSelection {
  triggered: SelectedAlarmProperty;
  state: SelectedAlarmProperty[];
}
