<div class="multi-level-select" *ngIf="showInput">
  <button
    class="dropdown-button"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    nz-popover
    [(nzPopoverVisible)]="showMultiLevelSelect"
    [nzPopoverPlacement]="['bottom', 'top']"
    nzPopoverTrigger="click"
    [nzPopoverContent]="multiLevelDropdown"
    [nzPopoverOverlayClassName]="'multi-level-select-popover'"
    [disabled]="disabled"
    [class.igray-8-bg]="disabled"
  >
    <div class="dropdown-label-placeholder" *ngIf="!value">
      {{ placeholder }}
    </div>
    <!-- TODO: remove when new styles are implmented -->
    <div class="value w-100" *ngIf="value || pointEntity">
      <label>{{ placeholder }}</label>
      <div class="dropdown-label d-flex align-items-start">
        {{ value?.name || pointEntity?.name }}
      </div>
    </div>
    <!-- TODO: Add back when new styles are implmented -->
    <!-- <i class="fas fa-chevron-down" *ngIf="!dropdownOpen"></i>
          <i class="fas fa-chevron-up" *ngIf="dropdownOpen"></i> -->
  </button>
</div>
<div *ngIf="!showInput">
  <ng-container *ngTemplateOutlet="multiLevelDropdown"></ng-container>
</div>
<ng-template #multiLevelDropdown>
  <atom-multi-level-input
    [multiSelect]="multiple"
    [options]="options"
    [parent]="parent"
    [type]="type"
    [showTypeSelector]="showTypeSelector"
    [showDrilldown]="parent?.length !== lowestLevel"
    (searchChange)="searchEntities($event)"
    (drilldownClick)="drillDown($event)"
    (valueChange)="onValueChange($event)"
    (scrolled)="scroll($event)"
    (backClicked)="back()"
    (newSelected)="newSelected.emit(type)"
    [loading]="loading"
  ></atom-multi-level-input>
</ng-template>
