export interface SettingsMenuItems {
  id?: number;
  name: string;
  order: number;
  icon: string;
  route: string;
  routeParams: string;
  permission: string;
  permissionApp: string;
  show: boolean;
}
