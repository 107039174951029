<div class="multi-level-select">
  <button
    class="dropdown-header"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    nz-popover
    [(nzPopoverVisible)]="showSavedViews"
    [nzPopoverPlacement]="['bottomLeft']"
    nzPopoverTrigger="click"
    [nzPopoverContent]="savedViewsDropdown"
    [nzPopoverOverlayClassName]="'multi-level-select-popover'"
    [disabled]="disabled"
    [class.gray-8-bg]="disabled"
  >
    <h4 class="header-title regular-24 mr-2 mb-0 dm00">
      {{ selectedOption?.name }}
    </h4>
    <i class="fas fa-chevron-down"></i>
  </button>
</div>
<ng-template #savedViewsDropdown>
  <div class="dropdown-list" role="listbox" aria-expanded="false">
    <atom-dropdown-item
      class="default-view"
      [item]="defaultView"
      [title]="defaultView.name"
      [showCheckbox]="false"
      [selected]="defaultView.id === selectedOption?.id"
      (selectChange)="isChecked(defaultView)"
      [showRightIcon]="false"
      [showDrilldown]="false"
    ></atom-dropdown-item>

    <div class="alert" *ngIf="loading">Loading...</div>

    <div
      class="list-container"
      *ngIf="!loading && options?.length > 0"
      #listContainer
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [infiniteScrollContainer]="listContainer"
    >
      <h4 class="user-views regular-14">Saved Views</h4>
      <atom-dropdown-item
        *ngFor="let o of options"
        [item]="o"
        [title]="o.name"
        [showCheckbox]="false"
        [selected]="o.id === selectedOption?.id"
        (selectChange)="isChecked(o)"
        [customRightIcon]="o.id === selectedOption?.id ? 'fa-pen' : null"
        [alwaysShowRightIcon]="true"
        (rightIconClick)="editClicked.emit(o)"
        [showRightIcon]="false"
        [showDrilldown]="false"
        [ngClass]="{ 'cursor-not-allowed': o?.meta?.disabled }"
      ></atom-dropdown-item>
    </div>
  </div>
</ng-template>
