import { Component, Input } from '@angular/core';
import { VFI_THEME } from '@vfi-ui/models';

@Component({
  selector: 'atom-rate-of-change',
  templateUrl: './rate-of-change.component.html',
  styleUrls: ['./rate-of-change.component.scss'],
})
export class RateOfChangeComponent {
  @Input() change: number;
  @Input() inverted = false;
  @Input() theme: VFI_THEME = VFI_THEME.DARK;
  isNaN = isNaN;
  abs = Math.abs;
  themeType = VFI_THEME;

  constructor() {}
}
