import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'atom-vfi-accordion',
  templateUrl: './vfi-accordion.component.html',
  styleUrls: ['./vfi-accordion.component.scss'],
})
export class VfiAccordionComponent {
  @Input() header: TemplateRef<any>;
  @Input() body: TemplateRef<any>;

  constructor() {}
}
