import {
  CoreStateModel,
  AlarmsStateModel,
  GlobalFiltersStateModel,
} from '@vfi-ui/state';

export interface AppState {
  core: CoreStateModel;
  alarmInstances: AlarmsStateModel;
  globalFilters: GlobalFiltersStateModel;
}

export interface ReleaseVersion {
  releaseVersion: string;
  releaseDate: string;
  mobileReleaseVersion: string;
}
