import { Validators } from '@angular/forms';
import {
  AutomationRuleExcutionAction,
  AutomationRuleTrigger,
  DayOfWeek,
  GroupCriteriaClause,
  RepeatingTimePrefix,
} from './../interfaces/automation-rules.interface';
export const AUTOMATION_RULES_DRAG_GROUP = 'AUTOMATION_RULES_DRAG';
export const AutomationRuleIncompleteTooltip =
  'This rule isn’t set up yet. A trigger and action are required to activate a rule.';
export const ACTIVE_RULE_DELETE_TITLE = 'Delete active automation rule?';
export const ACTIVE_RULE_DELETE_BODY = `This rule is currently active and deleting it cannot be undone. If you want to only deactivate the rule, consider toggling off the rule instead.`;
export const INACTIVE_RULE_DELETE_TITLE = 'Delete inactive automation rule?';
export const MAX_AUTOMATION_RULES = 99;
export const AUTOMATION_ACTIONS_OPTIONS = [
  {
    value: AutomationRuleExcutionAction.CREATE_WORK,
    label: 'Create Work Ticket',
  },
  {
    value: AutomationRuleExcutionAction.SET_ALARM_END_TIME,
    label: 'Clear Alarm and Set End Time',
  },
];
export const AUTOMATION_TRIGGERS_OPTIONS = [
  {
    value: AutomationRuleTrigger.ENTER_INTAKE,
    label: 'An alarm enters Intake',
  },
  {
    value: AutomationRuleTrigger.TURNS_STATLE,
    label: 'When an alarm turns stale',
  },
];
export const AUTOMATION_MAX_HOURS = 24;
export const AUTOMATION_CREATE_WORK_ACTION_FIELDS = [
  { formName: 'problemDetails', validators: [], defaultValue: null },
  {
    formName: 'assignee',
    validators: Validators.required,
    defaultValue: null,
  },
  { formName: 'dueInDays', validators: [], defaultValue: 1 },
];
export const AUTOMATION_DELAY_DEFAULTS = {
  delay: { days: 0, hours: 0, minutes: 10 },
  schedule: null,
};
export const NUISANCE_FILTER_KEYS = [
  'minChattering',
  'maxChattering',
  'minFleeting',
  'maxFleeting',
];

export const DEFAULT_BUILDER_CRITERIA = { clause: GroupCriteriaClause.WHERE };

export const AUTOMATION_TRIGGER_CRITERIAS = [
  {
    trigger: AutomationRuleTrigger.ENTER_INTAKE,
    criteria: {
      clause: GroupCriteriaClause.WHERE,
      where: { statuses: ['Intake'] },
    },
  },
  {
    trigger: AutomationRuleTrigger.TURNS_STATLE,
    criteria: {
      clause: GroupCriteriaClause.WHERE,
      where: { isStale: true },
    },
  },
];

export const AUTOMATION_RULES_ATTRIBUTE_SYNC = ['building', 'shop'];
export const AUTOMATION_RULES_AWAREMANAGER_REQUIRED_FIELDS = [
  'building',
  'unit',
  'shop',
  'status',
  'category',
  'type',
  'priority',
  'phone',
  '',
];

export enum SCHEDULE_OPTIONS {
  Active = 'active',
  Window = 'window',
}

export const TIME_WINDOW_CATEGORIES = ['day', 'weeks', 'month', 'year'];

export const TIME_WINDOW_DAYS_OF_WEEK_CHECKBOX_NOT_CHECKED = [
  { label: 'M', value: DayOfWeek.Monday, checked: false, disabled: false },
  { label: 'T', value: DayOfWeek.Tuesday, checked: false, disabled: false },
  { label: 'W', value: DayOfWeek.Wednesday, checked: false, disabled: false },
  { label: 'T', value: DayOfWeek.Thursday, checked: false, disabled: false },
  { label: 'F', value: DayOfWeek.Friday, checked: false, disabled: false },
  { label: 'S', value: DayOfWeek.Saturday, checked: false, disabled: false },
  { label: 'S', value: DayOfWeek.Sunday, checked: false, disabled: false },
];

export const TIME_WINDOW_DAYS_OF_WEEK_CHECKBOX_DEFAULT_DATE = [
  {
    label: 'M',
    value: DayOfWeek.Monday,
    checked: new Date().getDay() === 1,
    disabled: new Date().getDay() === 1,
  },
  {
    label: 'T',
    value: DayOfWeek.Tuesday,
    checked: new Date().getDay() === 2,
    disabled: new Date().getDay() === 2,
  },
  {
    label: 'W',
    value: DayOfWeek.Wednesday,
    checked: new Date().getDay() === 3,
    disabled: new Date().getDay() === 3,
  },
  {
    label: 'T',
    value: DayOfWeek.Thursday,
    checked: new Date().getDay() === 4,
    disabled: new Date().getDay() === 4,
  },
  {
    label: 'F',
    value: DayOfWeek.Friday,
    checked: new Date().getDay() === 5,
    disabled: new Date().getDay() === 5,
  },
  {
    label: 'S',
    value: DayOfWeek.Saturday,
    checked: new Date().getDay() === 6,
    disabled: new Date().getDay() === 6,
  },
  {
    label: 'S',
    value: DayOfWeek.Sunday,
    checked: new Date().getDay() === 7,
    disabled: new Date().getDay() === 7,
  },
];

export const TIME_WINDOW_DAYS_OF_WEEK = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

export const TIME_WINDOW_REPEATING_PREFIXES = [
  RepeatingTimePrefix.First,
  RepeatingTimePrefix.Second,
  RepeatingTimePrefix.Third,
  RepeatingTimePrefix.Fourth,
  RepeatingTimePrefix.Last,
];

export const MONTHS = [
  { value: 1, label: 'january' },
  { value: 2, label: 'february' },
  { value: 3, label: 'march' },
  { value: 4, label: 'april' },
  { value: 5, label: 'may' },
  { value: 6, label: 'june' },
  { value: 7, label: 'july' },
  { value: 8, label: 'august' },
  { value: 9, label: 'september' },
  { value: 10, label: 'october' },
  { value: 11, label: 'november' },
  { value: 12, label: 'december' },
];

export enum TIME_WINDOW_SELECTOR {
  day = 31,
  weeks = 52,
  month = 12,
  year = 2,
}

export const AUTOMATION_RULES_FILTER_BLACKLIST = [
  'room_name',
  'room_number',
  'isPinned',
  'instrument_type',
];
