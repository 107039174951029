import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { CreateCustomers, Customers } from '@vfi-ui/models';
import { get, head, keys } from '@vfi-ui/util/helpers';

@Component({
  selector: 'nuclei-create-customer-model',
  templateUrl: './create-customer-model.component.html',
  styleUrls: ['./create-customer-model.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCustomerModelComponent implements OnInit, OnChanges {
  @Input() mode = 'create';
  @Input() customer: Customers;
  @Input() isVisible = false;
  @Input() disableAddButton = false;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @Output() addCustomer: EventEmitter<CreateCustomers> = new EventEmitter();
  @Output() updateCustomer: EventEmitter<CreateCustomers> = new EventEmitter();
  createCustomerForm: UntypedFormGroup;
  pilotEnabled = false;
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.createCustomerForm = this.formBuilder.group({
      name: [null, Validators.required],
      domains: null,
      pilotDateEnd: null,
      isPilot: false,
      isSSOEnabled: false,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      get(changes.isVisible, 'currentValue', false) &&
      this.mode === 'create'
    ) {
      this.createCustomerForm.reset();
    }

    if (changes?.isVisible?.currentValue && this.mode === 'edit') {
      this.populateForm(this.customer);
    }
  }

  /**
   * handle create customer event
   *
   * @memberof CreateCustomerModelComponent
   */
  createCustomer(mode: string) {
    if (mode === 'edit') {
      this.updateCustomer.emit(this.createCustomerForm.value);
    } else {
      this.addCustomer.emit(this.createCustomerForm.value);
    }
  }

  /**
   * populate form
   *
   * @param {Customers} customer
   * @memberof CreateCustomerModelComponent
   */
  populateForm(customer: Customers) {
    const objKeys = keys(this.createCustomerForm.value);
    objKeys.forEach((key) => {
      if (key === 'domains') {
        this.createCustomerForm.controls[key].setValue(head(customer[key]));
      } else {
        this.createCustomerForm.controls[key].setValue(customer[key]);
      }
      this.createCustomerForm.controls[key].updateValueAndValidity();
    });
    this.createCustomerForm.updateValueAndValidity();
  }

  /**
   * handle modal close event
   *
   * @memberof CreateCustomerModelComponent
   */
  handleCancel() {
    this.createCustomerForm.reset();
    this.closeModal.emit(false);
  }
}
