import { AuthRoles } from '../constants/roles.constants';

export interface Roles {
  role: AuthRoles;
  name: string;
  description?: string;
  customRoleId?: string;
  permissions?: unknown;
}

export interface RoleChange {
  prev: AuthRoles;
  current: AuthRoles;
}
