import { Apollo } from 'apollo-angular';
import {
  GET_MENU_ITEMS_QUERY,
  UPDATE_DEFAULT_LENS_QUERY,
} from './../queries/menu-query';
import { Injectable } from '@angular/core';
import {
  CoreMenuItems,
  SocketAction,
  Counts,
  permission,
  SETTINGS_MENU,
  LensParent,
  CoreAlarmsWhere,
  ANALYTICS_MENU,
  Customers,
  Lens,
  LenBadgeCountEvent,
  MenuLens,
  CustomerOnboardingStatus,
  AuthRoles,
  RESTRICTED_TRIAGE_LENS_CATEGORIES,
  ALL_ACCESS_TRIAGE_LENS_CATEGORIES,
  ALL_ACCESS_ALARM_LENS_CATEGORIES,
  RESTRICTED_WORK_LENS_CATEGORIES,
  ALL_ACCESS_WORK_LENS_CATEGORIES,
  LensCategory,
  ERROR_SET_DEFAULT_LENS,
  LensType,
  LENS_TYPE_URL_MAP,
  LENS_TYPE_DEFAULT_LENS,
  SavedViewsViewType,
  AnalyticsLensUrlMap,
} from '@vfi-ui/models';
import { map, catchError, switchMap } from 'rxjs/operators';
import { throwError, Observable, Subject } from 'rxjs';
import {
  fastParse,
  fetchLensTeamName,
  head,
  result,
  titleCase,
} from '@vfi-ui/util/helpers';
import { SocketsService } from './sockets.service';
import { Store } from '@ngxs/store';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  SetCurrentlySelectedLens,
  SetLens,
  SetMobileLens,
} from 'libs/state/src/lib/lens/lens.actions';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CustomersState } from 'libs/state/src/lib/customers/customers.state';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { environment } from '@vfi-ui/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  menuUpdated = new Subject<MenuLens>();
  settingsMenuItems = SETTINGS_MENU;
  analyticsMenuItems = ANALYTICS_MENU;

  constructor(
    private apollo: Apollo,
    private socketService: SocketsService,
    private store: Store,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  /**
   * fetch all lenses
   *
   * @returns {Observable<MenuLens[]>}
   * @memberof MenuService
   */
  public getLenses(): Observable<MenuLens[]> {
    const options = {
      where: {
        isActive: true,
      },
    };
    return this.apollo
      .query<{ lenses: Lens[] }>({
        fetchPolicy: 'network-only',
        query: GET_MENU_ITEMS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        map((instance) => instance.data.lenses),
        catchError((error) => throwError(error))
      );
  }

  /**
   * fetch menu items from backend
   *
   * @returns {Observable<MenuLens[]>}
   * @memberof MenuService
   */
  public getMenuItems(): Observable<MenuLens[]> {
    const options = {
      where: {
        category: ALL_ACCESS_ALARM_LENS_CATEGORIES,
        isActive: true,
      },
    };
    return this.apollo
      .query<{ lenses: Lens[] }>({
        fetchPolicy: 'network-only',
        query: GET_MENU_ITEMS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        switchMap((instance) =>
          this.store
            .dispatch(new SetLens(instance.data.lenses))
            .pipe(map(() => instance.data.lenses))
        ),
        catchError((error) => throwError(error))
      );
  }

  /**
   * fetch a custom lens
   *
   * @param {*} [where]
   * @returns
   * @memberof MenuService
   */
  public getCustomLens(
    where: CoreAlarmsWhere,
    category: string,
    isActive = true
  ) {
    let app;
    if (category === LensParent.ACTIVE_ALARMS) {
      app = 'active_alarms';
    } else if (category === LensParent.ALL_ALARMS) {
      app = 'all_alarms';
    }
    const options = {
      where: { ...where, category: app, isActive },
    };
    return this.apollo
      .query<{ lenses: CoreMenuItems[] }>({
        fetchPolicy: 'network-only',
        query: GET_MENU_ITEMS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        map((instance) => head(instance.data.lenses)),
        catchError((error) => throwError(() => error))
      );
  }

  /**
   * get work lens from constant file
   *
   * @param {string} id
   * @returns
   * @memberof MenuService
   */
  public getWorkLens(id: string) {
    return this.apollo
      .query<{ lenses: CoreMenuItems[] }>({
        fetchPolicy: 'network-only',
        query: GET_MENU_ITEMS_QUERY,
        variables: {
          options: {
            where: {
              ids: [id],
            },
          },
        },
      })
      .pipe(
        map((instance) => head(instance.data.lenses)),
        catchError((error) => throwError(error))
      );
  }

  /**
   * returns formatted reporting menu items
   *
   * @param {AuthRoles} role
   * @returns
   * @memberof MenuService
   */
  public getReportingMenuItems(role: AuthRoles) {
    const options = {
      where: {
        category:
          role === AuthRoles.occupant
            ? RESTRICTED_WORK_LENS_CATEGORIES
            : ALL_ACCESS_WORK_LENS_CATEGORIES,
        isActive: true,
      },
    };
    return this.apollo
      .query<{ lenses: MenuLens[] }>({
        fetchPolicy: 'network-only',
        query: GET_MENU_ITEMS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        switchMap((instance) =>
          this.store
            .dispatch(new SetLens(instance.data.lenses))
            .pipe(map(() => instance.data.lenses))
        ),
        catchError((error) => throwError(error))
      );
  }

  /**
   * fetch assets menu items
   *
   * @returns
   * @memberof MenuService
   */
  public getAssetMenuItems() {
    const options = {
      where: {
        category: [LensCategory.ACTIVE_ASSETS, LensCategory.ALL_ASSETS],
        isActive: true,
      },
    };
    return this.apollo
      .query<{ lenses: MenuLens[] }>({
        fetchPolicy: 'network-only',
        query: GET_MENU_ITEMS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        switchMap((instance) =>
          this.store
            .dispatch(new SetLens(instance.data.lenses))
            .pipe(map(() => instance.data.lenses))
        ),
        catchError((error) => throwError(error))
      );
  }

  /**
   * fetch mobile menu items
   *
   * @param {AuthRoles} role
   * @returns
   * @memberof MenuService
   */
  public getMobileMenuItems(role: AuthRoles) {
    const options = {
      where: {
        category:
          role === AuthRoles.occupant
            ? RESTRICTED_TRIAGE_LENS_CATEGORIES
            : ALL_ACCESS_TRIAGE_LENS_CATEGORIES,
        isActive: true,
      },
    };
    return this.apollo
      .query<{ lenses: MenuLens[] }>({
        fetchPolicy: 'network-only',
        query: GET_MENU_ITEMS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        switchMap((instance) =>
          this.store
            .dispatch(new SetMobileLens(instance.data.lenses))
            .pipe(map(() => instance.data.lenses))
        ),
        catchError((error) => throwError(error))
      );
  }

  /**
   * set default lens
   *
   * @param {string} id
   * @returns
   * @memberof MenuService
   */
  public setDefaultLens(id: string) {
    return this.apollo
      .mutate({
        mutation: UPDATE_DEFAULT_LENS_QUERY,
        variables: { id },
      })
      .pipe(
        catchError((error) => {
          this.notificationService.showError(ERROR_SET_DEFAULT_LENS);
          return throwError(error);
        })
      );
  }

  /**
   * returns formatted settings menu items
   *
   * @returns
   * @memberof MenuService
   */
  public getSettingsMenuItems(role: AuthRoles) {
    return this.formatSettingsMenuItems(role);
  }

  /**
   * returns formatted analytics menu items
   *
   * @param {AuthRoles} role
   * @param {boolean} hasWork
   * @param {SavedViewsViewType} defaultLens
   * @returns
   * @memberof MenuService
   */
  public getAnalyticsMenuItems(
    role: AuthRoles,
    hasWork: boolean,
    defaultLens: SavedViewsViewType
  ) {
    this.navigateToDefaultAnalyticsPage(defaultLens);
    return this.formatAnalyticsMenuItems(role, hasWork);
  }

  /**
   * returns formatted parser menu items
   *
   * @returns
   * @memberof MenuService
   */
  public getParserMenuItems(customers: Customers[]) {
    return this.formatParserMenuItems(customers);
  }

  /**
   * gets a stream of work counts
   *
   * @returns {Observable<Counts>}
   * @memberof MenuService
   */
  public getTriageLensCount(): Observable<Counts> {
    return this.socketService
      .onMessage<LenBadgeCountEvent>(SocketAction.LENS_BADGE_COUNT_EVENT)
      .pipe(map((event) => ({ [event.lens.id]: event.data.entity })));
  }

  /**
   * emit event when lens is created
   *
   * @memberof MenuService
   */
  public updateMenu(len?: MenuLens) {
    this.menuUpdated.next(len);
  }

  /**
   * navigate to default lens
   *
   * @param {LensType} lensType
   * @memberof MenuService
   */
  public navigateToDefaultLens(
    lensType: LensType,
    currentUrl: string
  ): Observable<boolean> {
    return this.getLenses().pipe(
      map((lenses) => {
        const firstPick = lenses.find(
          (lens) =>
            lens.type === lensType && lens.isSectionDefault && !lens.isHidden
        );
        const backUpLenses = lenses.find(
          (lens) =>
            lens.name === LENS_TYPE_DEFAULT_LENS.get(lensType) && !lens.isHidden
        );
        const defaultLens = firstPick || backUpLenses;

        let url = LENS_TYPE_URL_MAP.get(lensType);
        if (defaultLens) {
          const idCandidate = +currentUrl?.replace(/.*\//, '');
          url += `/${fetchLensTeamName(defaultLens)}/${defaultLens.name}`;
          if (Number.isInteger(idCandidate)) {
            url += `/${idCandidate}`;
          }
          this.store.dispatch(new SetCurrentlySelectedLens(defaultLens.id));
        } else {
          url += '/no-data';
        }

        // WARN: if this logic fails we can infinite loop loading
        if (encodeURI(url) !== currentUrl) {
          this.router.navigate([url]);
          return false;
        }
        return true;
      })
    );
  }

  /**
   * navigate to default analytics landing page
   *
   * @private
   * @param {SavedViewsViewType} defaultLens
   * @memberof MenuService
   */
  private navigateToDefaultAnalyticsPage(defaultLens: SavedViewsViewType) {
    const page = AnalyticsLensUrlMap.get(defaultLens);
    this.router.navigate([`analytics/${page}`]);
  }

  /**
   * formats settings menu items
   *
   * @private
   * @param {role} AuthRoles
   * @returns
   * @memberof MenuService
   */
  private formatSettingsMenuItems(role: AuthRoles) {
    const settingsMenuItems = fastParse(this.settingsMenuItems);
    settingsMenuItems.forEach((item) => {
      item.show = result(
        permission,
        `[${role}][${item.permissionApp}][${item.permission}]`,
        false
      );
      if (item.name === 'My Profile' && role === AuthRoles.admin) {
        item.name = `My Profile (${titleCase(role)})`;
      }
    });
    return settingsMenuItems;
  }

  /**
   * formats analytics menu items
   *
   * @private
   * @param {AuthRoles} role
   * @param {boolean} hasWork
   * @returns
   * @memberof MenuService
   */
  private formatAnalyticsMenuItems(role: AuthRoles, hasWork: boolean) {
    const kpiEnabled = this.store.selectSnapshot(CustomersState.isKpiEnabled);
    const analyticsMenuItems = fastParse(this.analyticsMenuItems);

    analyticsMenuItems.forEach((item) => {
      item.show = result(
        permission,
        `[${role}][${item.permissionApp}][${item.permission}]`,
        false
      );
      if (item?.isWork) {
        item.show = hasWork;
      }
      if (item.name === 'KPI Dashboard') {
        item.show = kpiEnabled;
      }

      // Temp remove Looker Work History report on prod
      if (item.name === 'Work History' && environment.env === 'prod') {
        item.show = false;
      }
      if (
        item.name === 'Work Activity (Last 14 Days)' &&
        environment.env === 'prod'
      ) {
        item.header = 'Work';
      }
    });
    return analyticsMenuItems;
  }

  /**
   * formats parser menu items
   *
   * @private
   * @param {Customers[]} customers
   * @returns
   * @memberof CoreMenuItems[]
   */
  private formatParserMenuItems(customers: Customers[]) {
    return customers.map(
      ({ id, name, onboardingStatus, isPilot }) =>
        ({
          id,
          name:
            onboardingStatus === CustomerOnboardingStatus.PENDING
              ? `${name} (${CustomerOnboardingStatus.PENDING.toLocaleLowerCase()})`
              : name,
          route: `${id}`,
          isPilot,
        }) as CoreMenuItems
    );
  }
}
