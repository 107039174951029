import {
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { keys } from '@vfi-ui/util/helpers';

export const AtLeastOneRequired =
  (validator: ValidatorFn) =>
  (group: UntypedFormGroup): ValidationErrors | null => {
    const hasAtLeastOne =
      group &&
      group.controls &&
      keys(group.controls).some((k) => !validator(group.controls[k]));

    return hasAtLeastOne ? null : { atLeastOne: true };
  };
