import {
  RETRY_WORK_TICKET_CMMS_SYNC,
  DISMISS_WORK_TICKET_CMMS_SYNC,
  FETCH_EXTERNAL_CMMS_FIELDS_QUERY,
  FETCH_EXTERNAL_CMMS_FIELDS_OPTIONS_QUERY,
  TRANSLATE_CMMS_VALUE_QUERY,
  UPDATE_WORK_TICKET_QUERY,
  CHANGE_WORK_TICKET_STATE_QUERY,
  GET_WORK_HISTORY_QUERY,
  GET_EXISTING_WORK_TICKETS_QUERY,
  GET_WORK_TICKET_IDS_QUERY,
  GET_FAST_WORK_TICKETS_QUERY,
  GET_FAST_WORK_TICKETS_WITH_COUNTS_QUERY,
} from './../queries/work-ticket.query';
/* eslint-disable @typescript-eslint/naming-convention */
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import {
  CriterionSelection,
  MAX_TABLE_LIMIT,
  OrderDirection,
  WorkTicket,
  WorkTicketOptions,
  WorkTicketProcessState,
  WorkTicketsAndCountQuery,
  WorkTicketSortField,
  WorkTicketSurvey,
  ERROR_GET_WORK_TICKET,
  ERROR_UPDATE_WORK_TICKET,
  ERROR_CHANGE_WORK_TICKET_STATE,
  WorkTicketDetailsUpdateInput,
  ActivityLog,
  WorkTicketsActivityAndCountQuery,
  WorkTicketActivity,
  WorkTicketStatistics,
  PreAlarmReoccurencesRange,
  PostAlarmReoccurencesRange,
  PreWorkReoccurencesRange,
  PostWorkReoccurencesRange,
  WorkTicketsHistoryAndCountQuery,
  CoreAlarm,
  WorkTicketStatus,
  ExternalCmmsField,
  MaxAlarmsLimit,
  ExternalCmmsDomainValueSortField,
  DatetimeRange,
  ExternalCmmsDomainValueQuery,
  FastWorkTicket,
  ERROR_WORK_TICKET_CMMS_SYNC,
} from '@vfi-ui/models';
import {
  getFormattedAlarmProperties,
  head,
  isNil,
  mapAssociatedAlarms,
  result,
} from '@vfi-ui/util/helpers';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap, switchMap, filter } from 'rxjs/operators';
import {
  CREATE_WORK_TICKET_ACTIVITY,
  DELETE_WORK_TICKET_ACTIVITY,
  GET_CLOSED_WORK_STATISTICS_QUERY,
  GET_WORK_EQUIPMENT_HISTORY_QUERY,
  GET_WORK_TICKETS_ACTIVITY_QUERY,
  GET_WORK_TICKETS_COUNTS_QUERY,
  GET_WORK_TICKET_QUERY,
} from '../queries/work-ticket.query';
import { GlobalFiltersService } from './global-filters.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class WorkTicketDataService {
  constructor(
    private apollo: Apollo,
    private globalFiltersService: GlobalFiltersService,
    private notificationService: NotificationService
  ) {}
  /**
   * get work tickets
   *
   * @param { limit, offset, searchTerm, criterion, sort }
   * @returns
   * @memberof WorkTicketDataService
   */
  getWorkTickets({ limit, offset, searchTerm, criterion, sort }) {
    const { where, whereNot } =
      this.globalFiltersService.createWorkFiltersWhere({
        searchTerm,
        criterion,
      });
    const order = !Array.isArray(sort)
      ? this.globalFiltersService.createWorkSortOrderBy(sort)
      : sort;
    const options: WorkTicketOptions = {
      where,
      order,
      whereNot,
      limit: limit,
      offset: offset,
    };
    return this.apollo
      .query<{
        fastWorkTickets: FastWorkTicket[];
        workTicketsAndCount: WorkTicketsAndCountQuery;
      }>({
        fetchPolicy: 'network-only',
        query: GET_FAST_WORK_TICKETS_WITH_COUNTS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => ({
          workTickets: res?.data?.fastWorkTickets,
          count: res?.data?.workTicketsAndCount?.count,
          loading: res.loading,
        }))
      );
  }

  getFastWorkTickets(options): Observable<FastWorkTicket[]> {
    return this.apollo
      .query<{ fastWorkTickets: FastWorkTicket[] }>({
        fetchPolicy: 'network-only',
        query: GET_FAST_WORK_TICKETS_QUERY,
        variables: {
          options: {
            ...options,
            limit: 10000,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.fastWorkTickets)
      );
  }

  /**
   * query for work ticket ids with search
   *
   * @param {number} search
   * @param {number} [offset=0]
   * @returns {Observable<WorkTicketsAndCountQuery>}
   * @memberof WorkTicketDataService
   */
  getWorkTicketIds(
    search: number,
    offset = 0
  ): Observable<WorkTicketsAndCountQuery> {
    const where = search ? { ids: [search] } : {};
    const options = {
      where,
      order: { field: 'ID', direction: OrderDirection.ASC },
      offset,
      limit: 50,
    };
    return this.apollo
      .query<{ workTicketsAndCount: WorkTicketsAndCountQuery }>({
        fetchPolicy: 'network-only',
        query: GET_WORK_TICKET_IDS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => ({
          items: res?.data?.workTicketsAndCount?.items,
          count: res?.data?.workTicketsAndCount?.count,
        }))
      );
  }

  /**
   * get work ticket counts based on criterion
   *
   * @param {*} { limit, offset, searchTerm, criterion, sort }
   * @returns
   * @memberof WorkTicketDataService
   */
  getWorkTicketCounts({ limit, offset, searchTerm, criterion, sort }) {
    const { where, whereNot } =
      this.globalFiltersService.createWorkFiltersWhere({
        searchTerm,
        criterion,
      });
    const order = this.globalFiltersService.createWorkSortOrderBy(sort);
    const options: WorkTicketOptions = {
      where,
      order,
      whereNot,
      limit: limit,
      offset: offset,
    };

    return this.apollo
      .query<{ workTicketsAndCount: WorkTicketsAndCountQuery }>({
        fetchPolicy: 'network-only',
        query: GET_WORK_TICKETS_COUNTS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.workTicketsAndCount.count'))
      );
  }

  /**
   * query for total work tickets counts by route for all work
   *
   * @param {CriterionSelection[]} [criterion]
   * @returns
   * @memberof WorkTicketDataService
   */
  getWorkTicketsTotalCounts(criterion?: CriterionSelection[]) {
    const { where, whereNot } =
      this.globalFiltersService.createWorkFiltersWhere({
        criterion,
      });
    const options = {
      where,
      whereNot,
      limit: 0,
    };
    return this.apollo
      .query<{ workTicketsAndCount: WorkTicketsAndCountQuery }>({
        fetchPolicy: 'network-only',
        query: GET_WORK_TICKETS_COUNTS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.workTicketsAndCount.count'))
      );
  }

  /**
   * query for open work ticket count for a user
   *
   * @param {string} assignee
   * @returns
   * @memberof WorkTicketDataService
   */
  getUserWorkTicketCount(assignee: string) {
    const where = { assignee: [assignee], status: [WorkTicketStatus.Open] };
    return this.apollo
      .query<{ workTicketsAndCount: WorkTicketsAndCountQuery }>({
        fetchPolicy: 'no-cache',
        query: GET_WORK_TICKETS_COUNTS_QUERY,
        variables: {
          options: { where },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.workTicketsAndCount.count'))
      );
  }

  /**
   * query for work ticket count by period
   *
   * @param {DatetimeRange} createdAt
   * @returns
   * @memberof WorkTicketDataService
   */
  getWorkTicketCountByPeriod(createdAt: DatetimeRange) {
    return this.apollo
      .query<{ workTicketsAndCount: WorkTicketsAndCountQuery }>({
        fetchPolicy: 'no-cache',
        query: GET_WORK_TICKETS_COUNTS_QUERY,
        variables: {
          options: { where: { createdAt } },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.workTicketsAndCount.count'))
      );
  }

  /**
   * fetch work ticket and work ticket activity log by id
   *
   * @param {number[]} id
   * @returns {Observable<WorkTicket>}
   * @memberof WorkTicketDataService
   */
  getWorkTicketById(id: number[]): Observable<WorkTicket> {
    const options = {
      where: { ids: id },
    };
    return this.apollo
      .query<{ workTicketsAndCount: WorkTicketsAndCountQuery }>({
        fetchPolicy: 'network-only',
        query: GET_WORK_TICKET_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => ({
          workTicket: head(result(res, 'data.workTicketsAndCount.items', [])),
        })),
        switchMap(({ workTicket }) =>
          this.getWorkTicketActivityLogById(id).pipe(
            filter((d) => !!d),
            map((workActivity) => ({ workTicket, workActivity }))
          )
        ),
        map(({ workTicket, workActivity }) => ({
          ...workTicket,
          workTicketActivity: workActivity,
          associatedAlarms: mapAssociatedAlarms(workTicket),
        })),
        catchError((error) => {
          this.notificationService.showError(ERROR_GET_WORK_TICKET);
          return throwError(error);
        })
      );
  }

  /**
   * fetch work ticket by id
   *
   * @param {number[]} alarmIds
   * @returns {Observable<WorkTicket>}
   * @memberof WorkTicketDataService
   */
  getWorkTicketByAlarmIds(alarmIds: number[]): Observable<WorkTicket[]> {
    const options = {
      where: { alarmIds, state: WorkTicketProcessState.Open },
      order: {
        field: WorkTicketSortField.CREATED_AT,
        direction: OrderDirection.DESC,
      },
    };
    return this.apollo
      .query<{ workTicketsAndCount: WorkTicketsAndCountQuery }>({
        fetchPolicy: 'network-only',
        query: GET_WORK_TICKET_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data.workTicketsAndCount.items),
        catchError((error) => {
          this.notificationService.showError(ERROR_GET_WORK_TICKET);
          return throwError(error);
        })
      );
  }

  /**
   * fetch work ticket activity log by id
   *
   * @param {number[]} id
   * @returns {Observable<WorkTicket>}
   * @memberof WorkTicketDataService
   */
  getWorkTicketActivityLogById(id: number[]): Observable<WorkTicketActivity[]> {
    const options = {
      where: { workTicketIds: id },
      order: {
        field: WorkTicketSortField.CREATED_AT,
        direction: OrderDirection.DESC,
      },
    };
    return this.apollo
      .query<{ workTicketActivityAndCount: WorkTicketsActivityAndCountQuery }>({
        fetchPolicy: 'network-only',
        query: GET_WORK_TICKETS_ACTIVITY_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.workTicketActivityAndCount.items', [])),
        catchError((error) => {
          this.notificationService.showError(ERROR_GET_WORK_TICKET);
          return throwError(error);
        })
      );
  }

  /**
   * fetch work ticket statistics by id
   *
   * @param {number[]} id
   * @returns {Observable<WorkTicketStatistics>}
   * @memberof WorkTicketDataService
   */
  getWorkTicketStatisticById(id: number[]): Observable<WorkTicketStatistics> {
    const options = {
      where: { ids: id },
      order: {
        field: WorkTicketSortField.CREATED_AT,
        direction: OrderDirection.DESC,
      },
    };
    return this.apollo
      .query<{ WorkTicketsAndCount: WorkTicketStatistics }>({
        fetchPolicy: 'network-only',
        query: GET_CLOSED_WORK_STATISTICS_QUERY,
        variables: {
          options,
          PreAlarmReoccurencesRange,
          PostAlarmReoccurencesRange,
          PreWorkReoccurencesRange,
          PostWorkReoccurencesRange,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => head(result(res, 'data.workTicketsAndCount.items', []))),
        catchError((error) => {
          this.notificationService.showError(ERROR_GET_WORK_TICKET);
          return throwError(error);
        })
      );
  }

  /**
   * fetch existing work tickets for alarms by ID
   *
   * @param {number[]} alarmIds
   * @returns
   * @memberof WorkTicketDataService
   */
  getExistingWorkTicketsByAlarmId(alarmIds: number[]) {
    const options = {
      where: { alarmIds, state: WorkTicketProcessState.Open },
      order: { field: 'ID', direction: OrderDirection.ASC },
    };
    return this.apollo
      .query<{ workTicketsAndCount: WorkTicketsAndCountQuery }>({
        fetchPolicy: 'network-only',
        query: GET_EXISTING_WORK_TICKETS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.workTicketsAndCount?.items)
      );
  }

  /**
   * call api to add new work ticket comment
   *
   * @param {number} workTicketId
   * @param {Partial<ActivityLog>} workticketActivity
   * @returns
   * @memberof WorkTicketDataService
   */
  addWorkTicketActivity(
    workTicketId: number,
    workticketActivity: Partial<ActivityLog>
  ) {
    return this.apollo
      .mutate({
        mutation: CREATE_WORK_TICKET_ACTIVITY,
        variables: {
          workTicketId,
          input: {
            comment: workticketActivity.comment,
            isDeleted: workticketActivity.isDeleted,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.createWorkTicketActivity', {})),
        catchError((error) => {
          this.notificationService.showError(ERROR_UPDATE_WORK_TICKET);
          return throwError(error);
        })
      );
  }

  /**
   * call api to delete work ticket comment
   *
   * @param {string} id
   * @returns
   * @memberof WorkTicketDataService
   */
  deleteWorkTicketActivity(id: string) {
    return this.apollo
      .mutate({
        mutation: DELETE_WORK_TICKET_ACTIVITY,
        variables: {
          id,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.deleteWorkTicketActivity', {})),
        catchError((error) => {
          this.notificationService.showError(ERROR_UPDATE_WORK_TICKET);
          return throwError(error);
        })
      );
  }

  /**
   * update work ticket
   *
   * @param {WorkTicket} ticket
   * @param {WorkTicketDetailsUpdateInput} details
   * @param {string} [reassignedUser]
   * @param {string[]} [associateEntities]
   * @returns
   * @memberof WorkTicketDataService
   */
  updateWorkTicket(
    ticket: WorkTicket,
    details: WorkTicketDetailsUpdateInput,
    reassignedUser?: string,
    associateEntities?: string[]
  ) {
    return this.apollo
      .mutate({
        mutation: UPDATE_WORK_TICKET_QUERY,
        variables: {
          id: ticket.id,
          data: { details },
        },
      })
      .pipe(
        filter((d) => !!d),
        tap(() => {
          if (associateEntities) {
            this.notificationService.showSuccess(
              'Entities added successfully',
              `${associateEntities.join(', ')} was added to ${
                ticket?.displayId
              }`
            );
          } else {
            this.notificationService.showSuccess('Work');
            if (reassignedUser) {
              this.notificationService.showInfo(
                'Ticket Successfully Reassigned',
                `${ticket.objective} was successfully reassigned to ${reassignedUser}.`
              );
            }
          }
        }),
        map((res) => result(res, 'data.updateWorkTicket', {})),
        map((wts) => ({
          ...wts,
          associatedAlarms: mapAssociatedAlarms(wts),
        })),
        catchError((error) => {
          this.notificationService.showError(ERROR_UPDATE_WORK_TICKET);
          return throwError(error);
        })
      );
  }

  /**
   * change work ticket state
   *
   * @param {{
   *     id: number;
   *     state: WorkTicketProcessState;
   *   }} {
   *     id,
   *     state
   *   }
   * @returns
   * @memberof WorkTicketDataService
   */
  changeWorkTicketState({
    id,
    state,
    survey,
  }: {
    id: number;
    state?: WorkTicketProcessState;
    survey?: WorkTicketSurvey;
  }) {
    return this.apollo
      .mutate({
        mutation: CHANGE_WORK_TICKET_STATE_QUERY,
        variables: {
          id,
          state,
          survey,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.changeWorkTicketState', {})),
        map((wts) => ({
          ...wts,
          associatedAlarms: mapAssociatedAlarms(wts),
        })),
        catchError((error) => {
          this.notificationService.showError(ERROR_CHANGE_WORK_TICKET_STATE);
          return throwError(error);
        })
      );
  }

  /**
   * query for work history
   *
   * @param {number} id
   * @param {number} offset
   * @returns
   * @memberof WorkTicketDataService
   */
  getWorkHistory(id: number, offset: number) {
    const limit = MAX_TABLE_LIMIT;
    const where = { alarmIds: [id] };
    const order = {
      field: WorkTicketSortField.CREATED_AT,
      direction: OrderDirection.DESC,
    };
    const options: WorkTicketOptions = {
      where,
      order,
      limit,
      offset,
    };
    return this.apollo
      .query<WorkTicketsHistoryAndCountQuery>({
        fetchPolicy: 'no-cache',
        query: GET_WORK_HISTORY_QUERY,
        variables: {
          options,
          PreAlarmReoccurencesRange,
          PostAlarmReoccurencesRange,
          PreWorkReoccurencesRange,
          PostWorkReoccurencesRange,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => ({
          items: result(res, 'data.workTicketsAndCount.items', []),
          count: result(res, 'data.workTicketsAndCount.count', 0),
        }))
      );
  }

  /**
   * query for work equipment history
   *
   * @param {number} id
   * @param {number} page
   * @returns
   * @memberof WorkTicketDataService
   */
  getWorkEquipmentHistory(alarm: CoreAlarm, page: number) {
    const limit = MAX_TABLE_LIMIT;
    const where = this.generateWorkEquipmentHistoryWhere(alarm);
    const order = {
      field: WorkTicketSortField.CREATED_AT,
      direction: OrderDirection.DESC,
    };
    const offset = page === 1 ? 0 : page * limit - limit;
    const options: WorkTicketOptions = {
      where,
      order,
      limit,
      offset,
    };
    return this.apollo
      .query<WorkTicketsHistoryAndCountQuery>({
        fetchPolicy: 'no-cache',
        query: GET_WORK_EQUIPMENT_HISTORY_QUERY,
        variables: {
          options,
          PreAlarmReoccurencesRange,
          PostAlarmReoccurencesRange,
          PreWorkReoccurencesRange,
          PostWorkReoccurencesRange,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => ({
          items: result(res, 'data.workTicketsAndCount.items', []),
          count: result(res, 'data.workTicketsAndCount.count', 0),
        }))
      );
  }

  /**
   * retry work ticket cmms sync
   *
   * @param { number } workTicketId
   * @returns { WorkTicket }
   * @memberof WorkTicketDataService
   */
  retryWorkTicketCMMSSync(workTicketId: number) {
    return this.apollo
      .mutate<{ retryWorkTicketCmmsSync: WorkTicket }>({
        fetchPolicy: 'network-only',
        mutation: RETRY_WORK_TICKET_CMMS_SYNC,
        variables: {
          id: workTicketId,
        },
      })
      .pipe(
        filter((d) => !!d),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Sync',
            `Succesfully initiated CMMS sync for work ticket W${workTicketId}`
          )
        ),
        map((res) => result(res, 'data.retryWorkTicketCmmsSync')),
        catchError((error) => {
          this.notificationService.showError(ERROR_WORK_TICKET_CMMS_SYNC);
          return throwError(() => error);
        })
      );
  }

  /**
   * dismiss work ticket cmms sync header
   *
   * @param { number } workTicketId
   * @returns { WorkTicket }
   * @memberof WorkTicketDataService
   */
  dismissWorkTicketCMMSSync(workTicketId: number) {
    return this.apollo
      .mutate<{ dismissWorkTicketCmmsSyncFailure: WorkTicket }>({
        fetchPolicy: 'network-only',
        mutation: DISMISS_WORK_TICKET_CMMS_SYNC,
        variables: {
          id: workTicketId,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.dismissWorkTicketCmmsSyncFailure'))
      );
  }

  /**
   * fetch external cmms fields
   *
   * @param {number} externalSystemIds
   * @returns
   * @memberof WorkTicketDataService
   */
  fetchExternalCmmsFields(externalSystemIds: number) {
    return this.apollo
      .query<{ externalCmmsFields: ExternalCmmsField[] }>({
        fetchPolicy: 'no-cache',
        query: FETCH_EXTERNAL_CMMS_FIELDS_QUERY,
        variables: {
          input: {
            where: { externalSystemIds, isCreateWorkField: true },
            order: {
              field: 'DISPLAY_ORDER',
              direction: OrderDirection.ASC,
            },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.externalCmmsFields)
      );
  }

  /**
   * fetch cmms field domain options
   *
   * @param {{
   *     externalCmmsFieldId: string;
   *     search: string;
   *     externalDetails: Record<string, any>;
   *     useValueSort: boolean;
   *     offset: number;
   *   }} {
   *     externalCmmsFieldId,
   *     search,
   *     externalDetails,
   *     useValueSort,
   *     offset = 0,
   *   }
   * @returns {Observable<ExternalCmmsDomainValueQuery>}
   * @memberof WorkTicketDataService
   */
  fetchCmmsFieldOptions({
    externalCmmsFieldId,
    search,
    externalDetails,
    useValueSort,
    offset = 0,
  }: {
    externalCmmsFieldId: string;
    search: string;
    externalDetails: Record<string, any>;
    useValueSort: boolean;
    offset: number;
  }): Observable<ExternalCmmsDomainValueQuery> {
    const limit = MaxAlarmsLimit;
    const filtering =
      isNil(search) || search === ''
        ? {}
        : useValueSort
          ? { keyValueLike: `%${search}%` }
          : { nameLike: `%${search}%` };
    return this.apollo
      .query<ExternalCmmsDomainValueQuery>({
        fetchPolicy: 'no-cache',
        query: FETCH_EXTERNAL_CMMS_FIELDS_OPTIONS_QUERY,
        variables: {
          input: {
            limit,
            offset,
            order: {
              field: useValueSort
                ? ExternalCmmsDomainValueSortField.KeyValue
                : ExternalCmmsDomainValueSortField.Name,
              direction: OrderDirection.ASC,
            },
            where: { externalCmmsFieldId, ...filtering },
          },
          externalDetails,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((fields) => fields?.data)
      );
  }

  /**
   * translate cmms values
   *
   * @param {alarmId} alarmId
   * @returns
   * @memberof WorkTicketDataService
   */
  translateCmmsValues(alarmId: number, externalSystemId: number) {
    return this.apollo
      .query<{ translateInternalToExternalCmmsValues: unknown }>({
        fetchPolicy: 'no-cache',
        query: TRANSLATE_CMMS_VALUE_QUERY,
        variables: {
          alarmId,
          externalSystemId,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((fields) => fields?.data?.translateInternalToExternalCmmsValues)
      );
  }

  /**
   * generate where clause for work equipment history table
   *
   * @private
   * @param {number} id
   * @param {CoreAlarm} alarm
   * @param {string} type
   * @returns
   * @memberof WorkTicketDataService
   */
  private generateWorkEquipmentHistoryWhere(alarm: CoreAlarm) {
    let associatedAlarmProperties = [];
    const buildings = getFormattedAlarmProperties(
      'properties',
      alarm,
      'building'
    );

    const equipment = getFormattedAlarmProperties(
      'properties',
      alarm,
      'equipment'
    );
    associatedAlarmProperties = buildings?.values?.length
      ? [buildings, equipment]
      : [equipment];

    return { associatedAlarmProperties };
  }
}
