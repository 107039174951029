import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuListItem } from '@vfi-ui/models';

@Component({
  selector: 'atom-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent {
  @Input() listItems: string[] | MenuListItem[];
  @Input() useIds = false;
  @Input() selectedItem: number;
  @Input() width = 250;
  @Output() itemSelected: EventEmitter<string | number> = new EventEmitter();

  constructor() {}

  /**
   * handle item selected event
   *
   * @param {number} item
   * @memberof MenuListComponent
   */
  selected(item: number) {
    this.selectedItem = item;
    this.itemSelected.emit(this.selectedItem);
  }
}
