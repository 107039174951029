<ng-container
  *ngIf="
    ((mode !== filterModelMode.CREATE_LENS && device === deviceAgent.MOBILE) ||
      device === deviceAgent.DESKTOP) &&
    showCoreCriterion
  "
>
  <div
    class="d-flex align-items-center pb-2"
    *ngFor="let selection of coreCriterion; let index = index; let last = last"
  >
    <ng-container
      *ngTemplateOutlet="
        coreFilterRowTemplate;
        context: {
          selection: selection,
          index: index,
          last: last,
          show: selection?.detail?.dbName !== 'isRecentlyClosed',
          disabled: true,
        }
      "
    ></ng-container>
  </div>
</ng-container>

<div
  class="d-flex align-items-center pb-2"
  *ngFor="let selection of _criterion; let index = index; let last = last"
>
  <ng-container
    *ngTemplateOutlet="
      critFilterRowTemplate;
      context: {
        selection: selection,
        index: index,
        last: last,
        disabled: isReadonlyFilter(selection, mode, createLensCategory),
      }
    "
  ></ng-container>
</div>
<ng-template
  #coreFilterRowTemplate
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
  let-last="last"
  let-show="show"
>
  <div class="d-flex align-items-baseline" *ngIf="show">
    <div class="d-flex align-items-center">
      <i
        class="fas fa-times-circle fa-2x"
        [ngClass]="{
          'neutral-8 disabled': disabled,
        }"
        *ngIf="(!last || disabled) && allowDelete"
        (click)="removeCriterion(index)"
      ></i>
      <i
        aria-label="addFilterBtn"
        class="fas fa-plus-circle fa-2x blue-4"
        *ngIf="last && !disabled && allowDelete"
      ></i>
      <h4
        aria-label="selectionText"
        class="mb-0 filter-by-width"
        *ngIf="index === 0"
        [class.text-capitalize-first]="!allowDelete"
        [class.pl-3]="allowDelete"
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'Exclude'
            : 'filter by'
        }}
      </h4>
      <h4 class="pl-3 mb-0 mobile-and-padding" *ngIf="index !== 0">and</h4>
    </div>
    <ng-container
      *ngTemplateOutlet="
        selectionFields;
        context: {
          selection: selection,
          disabled: disabled,
          index: index,
          last: last,
        }
      "
    ></ng-container>
  </div>
</ng-template>

<ng-template
  #critFilterRowTemplate
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
  let-last="last"
>
  <div class="d-flex align-items-baseline">
    <div class="d-flex align-items-center">
      <i
        class="fas fa-times-circle fa-2x"
        [ngClass]="{
          'neutral-8 disabled': disabled,
        }"
        *ngIf="(!last || disabled) && allowDelete"
        (click)="!disabled && removeCriterion(index)"
      ></i>
      <i
        aria-label="addFilterBtn"
        class="fas fa-plus-circle fa-2x blue-4"
        *ngIf="last && !disabled && allowDelete"
      ></i>
      <h4
        aria-label="selectionText"
        class="mb-0 filter-by-width"
        *ngIf="
          index === 0 &&
          (!coreCriterion.length ||
            coreCriterion[0]?.detail?.dbName === 'isRecentlyClosed' ||
            mode === filterModelMode.CREATE_LENS ||
            mode === filterModelMode.EDIT_LENS)
        "
        [class.text-capitalize-first]="!allowDelete"
        [class.pl-3]="allowDelete"
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'Exclude'
            : 'filter by'
        }}
      </h4>
      <h4
        class="pl-3 mb-0 mobile-and-padding"
        *ngIf="
          index !== 0 ||
          (index === 0 &&
            coreCriterion.length &&
            coreCriterion[0]?.detail?.dbName !== 'isRecentlyClosed' &&
            mode !== filterModelMode.CREATE_LENS &&
            mode !== filterModelMode.EDIT_LENS)
        "
      >
        and
      </h4>
    </div>
    <ng-container
      *ngTemplateOutlet="
        selectionFields;
        context: {
          selection: selection,
          disabled: disabled,
          index: index,
          last: last,
        }
      "
    ></ng-container>
  </div>
</ng-template>

<ng-template
  #selectionFields
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
  let-last="last"
>
  <div
    class="d-flex align-items-center selection-wrapper mobile-selection-inputs"
  >
    <div class="ml-3" [ngClass]="{ 'required-field': required }">
      <ng-select
        class="select-width zero-default-height-options"
        placeholder="Select from dropdown"
        aria-label="filter-dropdown"
        [groupBy]="showGroupLabels ? 'category' : null"
        bindLabel="name"
        [items]="filtersCriterion"
        [hideSelected]="true"
        [clearable]="false"
        [readonly]="disabled"
        [closeOnSelect]="true"
        [searchable]="false"
        [appendTo]="'body'"
        (ngModelChange)="criterionSelected($event, index)"
        [ngModel]="selection.model"
        (ngModelChange)="handleSelectionChange(index, $event)"
      >
        <ng-template ng-option-tmp let-item="item">
          <div
            *ngIf="item.show || selection.model === item.dbName"
            class="item"
          >
            <div>{{ item.name }}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'alarmSource'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          alarmSource;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'cmmsSource'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          cmmsSource;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'select'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          select;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'multiLevelSelector'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          multiLevelSelector;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>

    <div
      *ngIf="
        selection.detail &&
        selection.detail.type === 'dateTime' &&
        device !== deviceAgent.MOBILE
      "
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          dateTime;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>

    <div
      *ngIf="
        selection.detail &&
        selection.detail.type === 'dateTime' &&
        device === deviceAgent.MOBILE
      "
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          mobileDateTime;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>

    <div
      *ngIf="selection.detail && selection.detail.type === 'number'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          number;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'priority'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          priority;
          context: {
            selection: selection,
            disabled: disabled,
            index: index,
            priorities: priorities,
          }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'status'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          status;
          context: {
            selection: selection,
            disabled: disabled,
            index: index,
            statusFilters: statusFilters,
          }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'bool'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          bool;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'customValueSelect'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          customValueSelect;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'customWorkSelect'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          customWorkSelect;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'minMax'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          minMax;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'durationMinMax'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          durationMinMax;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'timeMinMax'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          timeMinMax;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'text'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          text;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'userSelect'"
      class="d-flex pl-3 w-100 align-items-center"
    >
      <div class="includes-padding">
        <h4
          class="mb-0 min-width-includes"
          *ngIf="
            mode === filterModelMode.FILTERS ||
            type === criterionSelectionType.NOTIFICATION_EXCLUDES
          "
        >
          {{
            type === criterionSelectionType.NOTIFICATION_EXCLUDES
              ? 'that is'
              : selection?.detail?.isExclusion
                ? 'excludes'
                : 'includes'
          }}
        </h4>
        <nuclei-include-exclude
          [disabled]="disabled"
          class="include-exclude-padding-padding"
          [isExclusion]="selection?.detail?.isExclusion"
          (selected)="markExclusion($event === 'excludes', index)"
          *ngIf="
            mode !== filterModelMode.FILTERS &&
            type !== criterionSelectionType.NOTIFICATION_EXCLUDES
          "
        ></nuclei-include-exclude>
      </div>
      <atom-assignee-selector
        class="ml-2 assignee-selector"
        [placeholder]="selection.detail.name"
        [multiSelect]="true"
        [value]="selection.value"
        (assigneeChanged)="
          userInputChanged($event, index); selection.value = $event
        "
        [disabled]="disabled"
      >
      </atom-assignee-selector>
    </div>
  </div>
</ng-template>

<ng-template
  #alarmSource
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="w-100 filterMultiSelect d-flex">
      <atom-select-multiple
        [name]="selection.detail.name"
        [customQueryType]="selection.detail.dbName"
        [customQuery]="selection.detail.query"
        [alarmSource]="true"
        [disabled]="disabled"
        [width]="229"
        [appendTo]="'body'"
        [addBlanks]="selection.detail.blanks"
        [value]="
          selection.detail?.selection?.value?.length
            ? selection.detail?.selection?.value
            : null
        "
        placeholder="{{ selection.detail.name }}"
        (fullValuesChanged)="fullValuesInputChanged($event, index)"
        [classes]="
          !hasReqCriterion(selection.detail.dbName) &&
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'required-field'
            : ''
        "
        [required]="required"
      ></atom-select-multiple>
    </div>
  </div>
</ng-template>

<ng-template
  #cmmsSource
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="w-100 filterMultiSelect d-flex">
      <atom-select-multiple
        [name]="selection.detail.name"
        [customQueryType]="selection.detail.dbName"
        [customQuery]="selection.detail.query"
        [cmmsSource]="true"
        [disabled]="disabled"
        [width]="229"
        [appendTo]="'body'"
        [addBlanks]="selection.detail.blanks"
        [value]="
          selection.detail?.selection?.value?.length
            ? selection.detail?.selection?.value
            : null
        "
        placeholder="{{ selection.detail.name }}"
        (fullValuesChanged)="fullValuesInputChanged($event, index)"
        [classes]="
          !hasReqCriterion(selection.detail.dbName) &&
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'required-field'
            : ''
        "
        [required]="required"
      ></atom-select-multiple>
    </div>
  </div>
</ng-template>

<ng-template
  #select
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="w-100 filterMultiSelect d-flex">
      <atom-select-multiple
        [name]="selection.detail.name"
        [customQueryType]="selection.detail.dbName"
        [customQuery]="selection.detail.query"
        [disabled]="disabled"
        [width]="229"
        [appendTo]="'body'"
        [addBlanks]="selection.detail.blanks"
        [useFullValues]="!disabled"
        [value]="
          selection.detail?.selection?.value?.length
            ? selection.detail?.selection?.value
            : null
        "
        [fullValues]="selection?.detail?.selection?.fullValues"
        placeholder="{{ selection.detail.name }}"
        (fullValuesChanged)="fullValuesInputChanged($event, index)"
        [classes]="
          !hasReqCriterion(selection.detail.dbName) &&
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'required-field'
            : ''
        "
        [required]="required"
      ></atom-select-multiple>
    </div>
  </div>
</ng-template>

<ng-template
  #multiLevelSelector
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4 class="mb-0 min-width-includes">includes</h4>
    </div>
    <div class="w-100 multiLevelSelector d-flex">
      <atom-point-entity-selector
        class="w-100"
        placeholder="Location of the entity"
        [value]="selection?.detail?.selection"
        [pointEntity]="selection?.detail?.selection?.name[0]"
        (entityChanged)="multiLevelSelectorChanged($event, index)"
      ></atom-point-entity-selector>
    </div>
  </div>
</ng-template>

<ng-template
  #dateTime
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper d-flex align-items-center between-padding">
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 pr-2"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'between'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="pr-2 include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <nz-range-picker
      [nzSize]="'large'"
      [(ngModel)]="selection.selection"
      [nzDisabled]="disabled"
      nzFormat="MM/d/yyyy h:mm a"
      [nzPlaceHolder]="['Start Time', 'End Time']"
      [nzShowTime]="{ nzFormat: 'h:mm a', nzUse12Hours: true }"
      (ngModelChange)="rangeInputChanged($event, index)"
      [ngClass]="{
        'required-field':
          (!hasReqCriterion(selection.detail.dbName) &&
            type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
          required,
      }"
    ></nz-range-picker>
  </div>
</ng-template>

<ng-template
  #mobileDateTime
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper d-flex align-items-center between-padding">
    <div class="pr-2 pl-2">
      <h4
        class="mb-0 pr-2"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'between'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="d-flex flex-column">
      <nz-date-picker
        class="date-select-width imb-1"
        [nzDropdownClassName]="'calendar-input-none'"
        [nzSize]="'large'"
        [ngModel]="
          selection.selection ? selection.selection[0] : selection.selection
        "
        [nzDisabled]="disabled"
        nzFormat="MM/dd/yyyy HH:mm a"
        [nzPlaceHolder]="'Start Time'"
        (ngModelChange)="
          selection.selection = mobileDateTimeChanged($event, 0, index)
        "
        [ngClass]="{
          'required-field':
            (!hasReqCriterion(selection.detail.dbName) &&
              type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required,
        }"
        [nzInputReadOnly]="true"
      ></nz-date-picker>
      <nz-date-picker
        class="date-select-width"
        [nzDropdownClassName]="'calendar-input-none'"
        [nzSize]="'large'"
        [ngModel]="
          selection.selection ? selection.selection[1] : selection.selection
        "
        [nzDisabled]="disabled"
        nzFormat="MM/dd/yyyy HH:mm a"
        [nzPlaceHolder]="'End Time'"
        (ngModelChange)="
          selection.selection = mobileDateTimeChanged($event, 1, index)
        "
        [ngClass]="{
          'required-field':
            (!hasReqCriterion(selection.detail.dbName) &&
              type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required,
        }"
        [nzInputReadOnly]="true"
      ></nz-date-picker>
    </div>
  </div>
</ng-template>

<ng-template
  #number
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <h4 class="mb-0 pr-3">includes</h4>
    <nz-input-number
      [(ngModel)]="selection.value"
      (ngModelChange)="valueInputChanged($event, index)"
      [nzPlaceHolder]="0"
      [nzSize]="'large'"
      [nzDisabled]="disabled"
      [nzPrecision]="0"
      [nzMin]="0"
      [nzStep]="1"
      [ngClass]="{
        'required-field':
          (!selection.value &&
            type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
          required,
      }"
    ></nz-input-number>
  </div>
</ng-template>

<ng-template
  #priority
  let-selection="selection"
  let-priorities="priorities"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding pr-3"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <atom-dropdown-menu
      [name]="'priority'"
      [options]="priorities"
      [label]="selection.detail.name"
      [allowClear]="true"
      [value]="selection.detail?.selection?.value"
      [multiple]="true"
      [required]="
        ((!selection.value || !selection.value.length) &&
          type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
        required
      "
      [disabled]="disabled"
      (valueChange)="valueInputChanged($event, index); selection.value = $event"
    ></atom-dropdown-menu>
  </div>
</ng-template>

<ng-template
  #status
  let-selection="selection"
  let-statusFilters="statusFilters"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding pr-3"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <atom-dropdown-menu
      [name]="'status'"
      [options]="statusFilters"
      [label]="selection.detail.name"
      [allowClear]="true"
      [value]="selection.detail?.selection?.value"
      [multiple]="true"
      [required]="
        (!selection.value &&
          type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
        required
      "
      [disabled]="disabled"
      (valueChange)="valueInputChanged($event, index); selection.value = $event"
    ></atom-dropdown-menu>
  </div>
</ng-template>

<ng-template
  #bool
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding pr-3"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <atom-dropdown-menu
      [name]="
        selection?.model?.name || selection?.detail?.name || selection?.name
      "
      [options]="boolOptions"
      [label]="
        selection?.model?.name || selection?.detail?.name || selection?.name
      "
      [allowClear]="false"
      [value]="selection.detail?.selection?.value"
      [required]="
        (!hasValue(selection.value) &&
          type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
        required
      "
      [disabled]="disabled"
      (valueChange)="valueInputChanged($event, index); selection.value = $event"
    ></atom-dropdown-menu>
  </div>
</ng-template>

<ng-template
  #customValueSelect
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding pr-3"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <atom-dropdown-menu
      [name]="selection.detail.name"
      [options]="selection.detail.selectValues"
      [label]="selection.detail.name"
      [allowClear]="false"
      [value]="
        selection.detail?.selection?.value || selection?.detail?.selection
      "
      [multiple]="selection?.detail?.multi"
      [required]="
        (!selection.value &&
          type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
        required
      "
      [disabled]="disabled"
      (valueChange)="valueInputChanged($event, index); selection.value = $event"
    ></atom-dropdown-menu>
  </div>
</ng-template>

<ng-template
  #customWorkSelect
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <atom-select-multiple
      class="ml-3"
      [useIds]="true"
      [name]="selection.detail.dbName"
      [addBlanks]="selection.detail.blanks"
      customQuery="workFields"
      [width]="229"
      [appendTo]="'body'"
      [value]="
        selection.detail?.selection?.fullValues?.length
          ? selection.detail?.selection?.fullValues
          : null
      "
      placeholder="Select a {{ selection.detail.name }}"
      (fullValuesChanged)="fullValuesInputChanged($event, index)"
    ></atom-select-multiple>
  </div>
</ng-template>

<ng-template
  #minMax
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'between'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="w-100 mobile-min-max" nz-row>
      <div
        class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
        [class.input-active]="!isNil(selection.detail?.selection?.min)"
      >
        <input
          type="number"
          maxlength="255"
          class="form-control"
          [class.disabled-input]="disabled"
          [value]="selection.detail?.selection?.min"
          (change)="minMaxInputChanged($event.target.value, index, 'min')"
          [disabled]="disabled"
          [ngClass]="{
            'required-field':
              (!isNil(selection.detail?.selection?.min) &&
                type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
              required,
          }"
        />
        <label
          >Min
          <span *ngIf="selection?.model?.minMaxType || selection?.minMaxType"
            >({{ selection?.model?.minMaxType || selection?.minMaxType }})</span
          ></label
        >
      </div>
      <div
        class="input-wrapper min-max"
        [class.input-active]="!isNil(selection.detail?.selection?.max)"
      >
        <input
          type="number"
          min="0"
          maxlength="255"
          class="form-control"
          [class.disabled-input]="disabled"
          [value]="selection.detail?.selection?.max"
          (change)="minMaxInputChanged($event.target.value, index, 'max')"
          [disabled]="disabled"
          [ngClass]="{
            'required-field':
              (!isNil(selection.detail?.selection?.max) &&
                type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
              required,
          }"
        />
        <label
          >Max
          <span *ngIf="selection?.model?.minMaxType || selection?.minMaxType"
            >({{ selection?.model?.minMaxType || selection?.minMaxType }})</span
          >
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #durationMinMax
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'between'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="w-100 d-flex flex-column" nz-row>
      <div class="d-flex align-items-center">
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.minDays)"
        >
          <input
            type="number"
            maxlength="255"
            min="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.minDays"
            (change)="minMaxInputChanged($event.target.value, index, 'minDays')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.minDays &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Min (Days)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.minHrs)"
        >
          <input
            type="number"
            maxlength="255"
            min="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.minHrs"
            (change)="minMaxInputChanged($event.target.value, index, 'minHrs')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.minHrs &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Min (Hrs)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.minMins)"
        >
          <input
            type="number"
            min="0"
            max="59"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="255"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.minMins"
            (change)="minMaxInputChanged($event.target.value, index, 'minMins')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.minMins &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Min (Mins)</label>
        </div>
      </div>
      <div class="d-flex align-items-center mt-1">
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.maxDays)"
        >
          <input
            type="number"
            [min]="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="255"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.maxDays"
            (change)="minMaxInputChanged($event.target.value, index, 'maxDays')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.maxDays &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Max (Days)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.maxHrs)"
        >
          <input
            type="number"
            [min]="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="255"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.maxHrs"
            (change)="minMaxInputChanged($event.target.value, index, 'maxHrs')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.maxHrs &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Max (Hrs)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.maxMins)"
        >
          <input
            type="number"
            min="0"
            max="59"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="255"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.maxMins"
            (change)="minMaxInputChanged($event.target.value, index, 'maxMins')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.maxMins &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Max (Mins)</label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #timeMinMax
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div
    class="sub-selection-wrapper d-flex align-items-center"
    [ngClass]="index === 0 ? 'filter-by-padding' : 'and-padding'"
  >
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'between'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="w-100 d-flex flex-column" nz-row>
      <div class="d-flex align-items-center">
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.minDays)"
        >
          <input
            type="number"
            maxlength="255"
            min="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.minDays"
            (change)="minMaxInputChanged($event.target.value, index, 'minDays')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.minDays &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Min (Days)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.minHrs)"
        >
          <input
            type="number"
            maxlength="23"
            min="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.minHrs"
            (change)="minMaxInputChanged($event.target.value, index, 'minHrs')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.minHrs &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Min (Hrs)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.minMins)"
        >
          <input
            type="number"
            min="0"
            max="59"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="59"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.minMins"
            (change)="minMaxInputChanged($event.target.value, index, 'minMins')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.minMins &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Min (Mins)</label>
        </div>
      </div>
      <div class="d-flex align-items-center mt-1">
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.maxDays)"
        >
          <input
            type="number"
            [min]="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="255"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.maxDays"
            (change)="minMaxInputChanged($event.target.value, index, 'maxDays')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.maxDays &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Max (Days)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.maxHrs)"
        >
          <input
            type="number"
            [min]="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="255"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.maxHrs"
            (change)="minMaxInputChanged($event.target.value, index, 'maxHrs')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.maxHrs &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Max (Hrs)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.maxMins)"
        >
          <input
            type="number"
            min="0"
            max="59"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="255"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.maxMins"
            (change)="minMaxInputChanged($event.target.value, index, 'maxMins')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.maxMins &&
                  type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required,
            }"
          />
          <label>Max (Mins)</label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #text
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper d-flex align-items-center contains-padding">
    <div class="includes-padding pl-2">
      <h4
        class="mb-0"
        *ngIf="
          mode === filterModelMode.FILTERS ||
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode !== filterModelMode.FILTERS &&
          type !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="w-100 ml-3" nz-col>
      <div
        class="input-wrapper"
        [class.input-active]="selection.detail?.selection?.value"
      >
        <input
          type="text"
          maxlength="255"
          class="form-control"
          [class.disabled-input]="disabled"
          [value]="selection.detail?.selection?.value"
          (change)="valueInputChanged($event.target.value, index)"
          [disabled]="disabled"
          [ngClass]="{
            'required-field':
              (!selection.detail?.selection?.value &&
                type === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
              required,
          }"
        />
        <label>{{ selection.detail.name }}</label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #customClearIcon>
  <i class="white far fa-times-circle"></i>
</ng-template>
