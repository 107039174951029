<span class="ellipsis {{ color }} regular-{{ fontSize }}">
  <span *ngIf="timeValues?.days > 0">{{ timeValues?.d }} </span>
  <span *ngIf="timeValues?.hours > 0">{{ timeValues?.h }} </span>
  <span *ngIf="timeValues?.minutes > 0">{{ timeValues?.m }} </span>
  <span
    *ngIf="
      (!timeValues?.days &&
        !timeValues?.hours &&
        !timeValues?.minutes &&
        timeValues?.seconds) ||
      (!isNull(duration) &&
        duration !== '' &&
        (duration === 0 || duration < minuteInMs))
    "
    >&lt;1m</span
  >
  <span
    *ngIf="
      !timeValues?.days &&
      !timeValues?.hours &&
      !timeValues?.minutes &&
      !timeValues?.seconds &&
      (isNull(duration) || duration === '')
    "
    >—</span
  >
</span>
