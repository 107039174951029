import { AlarmsReportSortField } from './../constants/alarms-report.constants';
import { OrderDirection } from '../constants/work-ticket.constant';
import { BaseAlarmProperty } from './alarm-instance.interface';
import { TimeValues } from './core.interface';

export interface AlarmsReport {
  id: number;
  priority: number;
  alarmState: string;
  status: string;
  attributes: BaseAlarmProperty[];
  text: string;
  contextualName: string;
  basPriority: number;
  chatteringCount: number;
  fleetingCount: number;
  nuisanceCount: number;
  notes: String;
  lastAlarmTime: string;
  lastEndTime: string;
  lastDuration: number;
  averageDuration: number;
  instanceCount: number;
  lastAckTime: string;
  lastAckBy: String;
  openWorkIds: number[];
  recentlyClosedWorkIds: number[];
  lastCreatedWorkId: number;
  lastClosedWorkId: number;
  lastWorkCreatedAt: string;
  lastWorkClosedAt: string;
  lastWorkAssignee: String;
  lastWorkAssigneeName: String;
  lastWorkResolution: Boolean;
  openWorkCount: number;
  workCount: number;
  totalWorkDuration: number;
  externalSystemDisplayName: String;
  lastDurationTimeValues: TimeValues;
  averageDurationTimeValues: TimeValues;
  totalWorkDurationTimeValues: TimeValues;
}

export interface AlarmsReportQuery {
  alarmReport: AlarmsReport[];
  alarmReportCount: number;
}

export interface AlarmsReportSort {
  field: AlarmsReportSortField;
  field2: string;
  field3: string;
  direction: OrderDirection;
}

export interface AlarmReportGroupBy {
  groupBy: string;
  count: number;
  lastWorkAssigneeName: string;
}

export interface AlarmReportWhere {
  pattern?: string;
  priority?: number[];
  alarmTime?: string;
  attributes?: any[];
  alarmState?: string;
  lastWorkAssignee?: string[];
  externalSystemId?: number[];
  isNuisance?: boolean;
}

export interface AlarmReportOptions {
  limit?: number;
  offset?: number;
  order?: AlarmsReportSort;
  where?: AlarmReportWhere;
}
