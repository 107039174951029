import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  AuthService,
  MfaService,
  NotificationService,
} from '@vfi-ui/data-access/shared';
import { CountryCode, COUNTRY_CODES } from '@vfi-ui/models';
import { Logout, SignIn } from '@vfi-ui/state';
import { CodeInputComponent } from 'angular-code-input';

@Component({
  selector: 'vfi-auth-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss', '../login/login.component.scss'],
})
export class MfaComponent implements AfterViewInit {
  @ViewChild('codeInput') codeInput!: CodeInputComponent;
  mfaSignInForm: UntypedFormGroup;
  secureForm: UntypedFormGroup;
  flags = COUNTRY_CODES;
  submitted = false;
  mfaVerificationLoading = false;

  constructor(
    public authService: AuthService,
    public mfa: MfaService,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private store: Store
  ) {
    this.secureForm = this.fb.group({
      phoneNumber: ['', [Validators.required]],
      countryCode: [
        this.flags.find((res) => res.code === 'US'),
        Validators.required,
      ],
    });
  }

  /**
   * Returns secureForm controls
   *
   * @readonly
   * @memberof MfaComponent
   */
  get secureFormControls() {
    return this.secureForm.controls;
  }

  async ngAfterViewInit() {
    this.mfa.recaptchaVerifier();
  }

  /**
   * Handler for the back button
   *
   * @memberof MfaComponent
   */
  handleBackButton() {
    if (this.mfa.showSecure && !this.mfa.showVerification) {
      this.store.dispatch(new Logout());
    } else if (
      !this.mfa.showSecure &&
      this.mfa.showVerification &&
      this.mfa.enrollingInMFA
    ) {
      this.mfa.showSecure = true;
      this.mfa.showVerification = false;
    } else if (
      !this.mfa.showSecure &&
      this.mfa.showVerification &&
      !this.mfa.enrollingInMFA
    ) {
      this.store.dispatch(new Logout());
    }
  }

  /**
   * Enroll user in mfa
   *
   * @param {*} value
   * @memberof MfaComponent
   */
  async enrollInMfa(value: { phoneNumber: string; countryCode: CountryCode }) {
    const phoneNumber = `${value.countryCode.dial_code}${value.phoneNumber}`;
    if (value.phoneNumber) {
      const verificationId = await this.mfa.initializePhoneNumberEnroll(
        phoneNumber,
        this.mfa.verificationCaptcha
      );
      this.mfa.verificationId = verificationId;
    }
  }

  /**
   * Sign current user in with mfa
   *
   * @param {*} value
   * @memberof MfaComponent
   */
  async signInWithMFA(value: any) {
    this.mfaVerificationLoading = true;
    this.submitted = true;
    let user;
    if (value && this.mfa.verificationId && this.mfa.verificationResolver) {
      user = await this.mfa.signInWithMFA({
        verificationCode: value,
        verificationId: this.mfa.verificationId,
        resolver: this.mfa.verificationResolver,
      });
    } else {
      user = await this.mfa.verifyEnrollWithPhoneNumber({
        verificationId: this.mfa.verificationId,
        verificationCode: value,
      });
    }

    if (user) {
      this.mfa.verificationId = null;
      this.mfa.verificationResolver = null;
      this.store.dispatch(new SignIn(user));

      if (this.mfa.showMFASuccessToast) {
        this.notificationService.showSuccess(
          'Multi-Factor Authentication Setup Successful',
          "Next time you log in, you'll need to enter the verification code sent to this mobile number. You can edit the mobile number under settings."
        );
      }
      this.submitted = false;
    }
    this.mfaVerificationLoading = false;
  }

  /**
   * Signs in on completely entering the code
   *
   * @param code
   * @memberof MfaComponent
   */
  onCodeCompleted(code: string | number) {
    this.signInWithMFA(`${code}`);
  }

  /**
   * Resend verification code to phone number
   *
   * @memberof MfaComponent
   */
  resendVerificationCode() {
    this.mfa.sendVerificationCode();
  }

  /**
   * Sets default value for nz-select
   *
   * @param option1
   * @param option2
   * @returns {Boolean} comparison between options
   * @memberof MfaComponent
   */
  compareFn(option1: CountryCode, option2: CountryCode): boolean {
    return option1 && option2
      ? option1.code === option2.code
      : option1 === option2;
  }
}
