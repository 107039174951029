import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  WEEKDAYS,
  FREQUENCIES,
  INSTANTANEOUSLY_WARNING,
  WEEKDAY_OPTIONS,
} from '@vfi-ui/models';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { isBefore, isSameHour, isSameMinute } from 'date-fns';
import { isNil } from '@vfi-ui/util/helpers';

@Component({
  selector: 'nuclei-notification-time',
  templateUrl: './notification-time.component.html',
  styleUrls: ['./notification-time.component.scss'],
})
export class NotificationTimeComponent implements OnChanges {
  @Input() isVisible: boolean;
  @Input() form: UntypedFormGroup;
  @Input() timezone: string;
  dayOptions = WEEKDAYS;
  weekdayOptions = WEEKDAY_OPTIONS;
  frequencies = FREQUENCIES;
  showError = false;
  instantaneouslyWarning = INSTANTANEOUSLY_WARNING;

  constructor() {}

  ngOnChanges(change: SimpleChanges) {
    if (change.isVisible && change.isVisible.currentValue) {
      this.updateFormErrors(this.form.get('from'), this.form.get('to'));
    }
  }

  /**
   * handle value changed event
   *
   * @param {string} formName
   * @param {*} value
   * @memberof NotificationTimeComponent
   */
  valueChanged(formName: string, value) {
    let formValue = value;
    if (Array.isArray(value)) {
      formValue = value.map((v) => v.label);
    }
    this.form.get(formName).setValue(formValue);
    this.form.markAsDirty();
  }

  /**
   * update form validity based on time range
   *
   * @memberof NotificationTimeComponent
   */
  updateForm() {
    const startTime = this.form.get('from');
    const endTime = this.form.get('to');
    if (startTime.value || endTime.value) {
      this.updateFormValidators(startTime, endTime);
    }
    if (startTime.value && endTime.value) {
      this.updateFormErrors(startTime, endTime);
    }
    if (!startTime.value && !endTime.value) {
      this.clearValidators(startTime, endTime);
    }
  }

  /**
   * determines if dates are within valid time range
   *
   * @param {*} startTime
   * @param {*} endTime
   * @returns
   * @memberof NotificationTimeComponent
   */
  checkDates(startTime, endTime) {
    if (isNil(startTime) && isNil(endTime)) {
      return false;
    }
    const beforeTime = isBefore(endTime, startTime);
    const sameTime =
      isSameHour(endTime, startTime) && isSameMinute(endTime, startTime);
    return beforeTime || sameTime;
  }

  /**
   * update form validators
   *
   * @param {*} startTime
   * @param {*} endTime
   * @memberof NotificationTimeComponent
   */
  updateFormValidators(startTime, endTime) {
    startTime.setValidators(Validators.required);
    endTime.setValidators(Validators.required);

    startTime.updateValueAndValidity();
    endTime.updateValueAndValidity();
  }

  /**
   * clear form validators
   *
   * @param {*} startTime
   * @param {*} endTime
   * @memberof NotificationTimeComponent
   */
  clearValidators(startTime, endTime) {
    startTime.clearValidators();
    endTime.clearValidators();
    startTime.updateValueAndValidity();
    endTime.updateValueAndValidity();
  }

  /**
   * update for errors
   *
   * @param {*} startTime
   * @param {*} endTime
   * @memberof NotificationTimeComponent
   */
  updateFormErrors(startTime, endTime) {
    this.showError = this.checkDates(startTime.value, endTime.value);
    if (this.showError) {
      startTime.setErrors({ invalid: true });
      endTime.setErrors({ invalid: true });
    }
  }
}
