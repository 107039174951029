<div class="w-100 select-wrapper form-group">
  <div
    class="input-wrapper caret-wrapper h-100"
    [ngClass]="{
      'input-active': value || value?.length > 0 || focused
    }"
  >
    <ng-select
      #select
      [items]="options"
      [multiple]="multiple"
      [(ngModel)]="value"
      [closeOnSelect]="!multiple"
      [clearable]="false"
      [readonly]="disabled"
      [attr.name]="name"
      [searchable]="false"
      [ngClass]="{
        'single-value': !multiple,
        'multiple-value-selected': value?.length > 0 && multiple,
        'no-placeholder': !showPlaceholder,
        'required-field': required && !value
      }"
      bindLabel="label"
      bindValue="value"
      (focus)="focused = true"
      (blur)="focused = false"
      (search)="search($event?.term)"
      (change)="valueChanged($event)"
      (scrollToEnd)="scroll(searchValue)"
      attr.aria-label="{{ name }}-select-dropdown"
    >
      <ng-template ng-header-tmp>
        <input
          [id]="'ng-dropdown-input-' + name"
          class="ng-dropdown-input"
          type="text"
          [(ngModel)]="searchValue"
          placeholder="Search {{ name }}"
          (input)="select.filter($event.target.value)"
          attr.aria-label="{{ name }}-search-input"
          tabIndex="-1"
        />
      </ng-template>

      <ng-template ng-option-tmp let-item$="item$" let-item="item">
        <div
          class="d-flex justify-content-between"
          attr.aria-label="{{ item?.label }}-option"
        >
          <span class="ellipsis">{{ item?.label }}</span>
          <i *ngIf="item$.selected" class="fa fa-check"></i>
        </div>
      </ng-template>
    </ng-select>
    <label class="z-index-max" *ngIf="showPlaceholder"
      ><span *ngIf="required">*</span>{{ label }}</label
    >
  </div>
</div>
