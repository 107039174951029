import {
  SetSortFilterType,
  TotalResetGlobalFilters,
  UsersState,
  UsersStateModel,
  LoadUsers,
  SetSortValue,
  ResetUsers,
  SetCoreSortValue,
} from '@vfi-ui/state';
import { Users, DefaultUserSort } from '@vfi-ui/models';
import { take, filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Store, Select } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class UsersResolver {
  @Select(UsersState.getState) usersState$: Observable<UsersStateModel>;

  constructor(private store: Store) {}

  resolve(): Observable<Users[]> {
    this.store.dispatch(new SetSortFilterType('user'));
    this.store.dispatch(new ResetUsers());
    this.store.dispatch(new TotalResetGlobalFilters(false));
    this.loadData();

    return this.waitForStateToUpdate();
  }

  waitForStateToUpdate(): Observable<Users[]> {
    return this.usersState$.pipe(
      map((res) => res?.items),
      filter((state) => !!state),
      take(1)
    );
  }

  loadData(): void {
    this.usersState$.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new SetSortValue(DefaultUserSort));
      this.store.dispatch(new SetCoreSortValue(DefaultUserSort));
      this.store.dispatch(new LoadUsers());
    });
  }
}
