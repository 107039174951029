import { gql } from 'apollo-angular';

export const CREATE_NOTIFICATION_QUERY = gql`
  mutation createLensNotification($data: LensNotificationCreateInput!) {
    createLensNotification(data: $data)
  }
`;

export const DELETE_NOTIFICATION_QUERY = gql`
  mutation deleteLensNotification($id: String!) {
    deleteLensNotification(id: $id)
  }
`;

export const UPDATE_NOTIFICATION_QUERY = gql`
  mutation updateLensNotification(
    $id: String!
    $data: LensNotificationUpdateInput!
  ) {
    updateLensNotification(id: $id, data: $data)
  }
`;

export const GET_NOTIFICATIONS_QUERY = gql`
  query lensNotificationsAndCount($options: LensNotificationOptions!) {
    lensNotificationsAndCount(options: $options) {
      count
      items {
        id
        name
        notes
        criteria {
          exclude
          dayOfWeek
          from
          to
          hourInterval
          timezone
          includeRTN
          secondsDelay
        }
        displayOptions
        emailUsersToNotify {
          id
          displayName
          avatarUrl
        }
        pushUsersToNotify {
          id
          displayName
          avatarUrl
        }
        smsUsersToNotify {
          id
          displayName
          avatarUrl
        }
      }
    }
  }
`;
