/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AuthState } from '../../../../../state/src/lib/auth/auth.state';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngxs/store';
import { User } from '@vfi-ui/models';
import { Observable, skip } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SuperUserGuard {
  user$: Observable<User> = this.store.select(AuthState.user);

  constructor(
    private router: Router,
    private store: Store
  ) {}

  /**
   * determines if user has access to the page
   * If not redirect to root.
   *
   * @returns {Observable<boolean>}
   * @memberof AuthGuard
   */
  canActivate() {
    this.user$.pipe(skip(1)).subscribe((user: User) => {
      if (user?.isSuper) {
        return true;
      }
      this.router.navigate(['/']);
      return false;
    });
  }
}
