import { Inject, Injectable, inject } from '@angular/core';
import { FirebaseAuthentication } from '@vfacility/capacitor-firebase-authentication';
import { CapacitorGlobal } from '@capacitor/core';
import { Store } from '@ngxs/store';
import { environment } from '@vfi-ui/environments/environment';
import { CAPACITOR_CORE_TOKEN, User } from '@vfi-ui/models';
import { navigateToDeeplink } from '@vfi-ui/util/helpers';
import { Apollo } from 'apollo-angular';
import { firstValueFrom, map } from 'rxjs';
import { UPDATE_USER_QUERY } from '../queries';
import {
  Auth,
  multiFactor,
  MultiFactorAssertion,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  TotpMultiFactorGenerator,
  TotpSecret,
  UserCredential,
} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class MfaService {
  showSecure = true;
  showVerification = false;
  showMFASuccessToast = false;
  verificationId: string;
  verificationCaptcha: RecaptchaVerifier;
  verificationResolver: MultiFactorResolver;
  totpSecret: TotpSecret;
  factorId: string;
  enrollingInMFA = true;
  errorMessage = '';
  phoneInfoOptions: PhoneInfoOptions;
  public afa = inject(Auth);

  constructor(
    private apollo: Apollo,
    private store: Store,
    @Inject(CAPACITOR_CORE_TOKEN) private capacitor: CapacitorGlobal
  ) {}

  /**
   * Returns if MFA is enabled or not
   *
   * @param code
   * @returns {Promise<boolean>}
   * @memberof MfaService
   */
  async isMultiFactorEnabled(): Promise<boolean> {
    const user = multiFactor(this.afa.currentUser);
    return user?.enrolledFactors?.length > 0;
  }

  /**
   * Handles getting auth info when sign in with MFA
   *
   * @param {{
   *     verificationId: string;
   *     verificationCode: string;
   *     resolver: MultiFactorResolver;
   *   }} {
   *     verificationCode,
   *     verificationId,
   *     resolver,
   *   }
   * @returns {Promise<User>}
   * @memberof MfaService
   */
  async signInWithMFA({
    verificationCode,
    verificationId,
    resolver,
  }: {
    verificationId: string;
    verificationCode: string;
    resolver: MultiFactorResolver;
  }): Promise<User> {
    try {
      let multiFactorAssertion: MultiFactorAssertion = null;
      if (this.factorId === TotpMultiFactorGenerator.FACTOR_ID) {
        multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
          verificationId,
          verificationCode
        );
      }
      if (this.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const cred = PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );
        multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      }
      if (multiFactorAssertion) {
        // Complete sign-in.
        const signInResult: UserCredential =
          await resolver.resolveSignIn(multiFactorAssertion);
        return await this.handleSuccess(signInResult);
      }
    } catch (err) {
      console.error(err);
      this.handleError();
    }
  }

  /**
   * Initializes enrollment in 2fa
   *
   * @param {number} phoneNumber
   * @returns {Promise<string>}
   * @memberof MfaService
   */
  public async initializePhoneNumberEnroll(
    phoneNumber: string,
    recaptchaVerifier: RecaptchaVerifier
  ): Promise<string> {
    try {
      let verificationId: string;
      if (this.capacitor.getPlatform() === 'ios') {
        const verificationObject =
          await FirebaseAuthentication.verifyPhoneNumber({ phoneNumber });
        verificationId = verificationObject.verificationId;
      } else {
        const user = multiFactor(this.afa.currentUser);
        const multiFactorSession = await user.getSession();
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession,
        };
        const phoneAuthProvider = new PhoneAuthProvider(this.afa);
        // Send SMS verification code.
        verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
      }
      this.showSecure = false;
      this.showVerification = true;
      return verificationId;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Initializes enroll with totop enroll
   *
   * @return {*}  {Promise<{
   *     totpSecret: TotpSecret;
   *     url: string;
   *   }>}
   * @memberof MfaService
   */
  public async initializeTOTPEnroll(): Promise<{
    totpSecret: TotpSecret;
    url: string;
  }> {
    try {
      const multiFactorSession = await multiFactor(
        this.afa.currentUser
      ).getSession();
      const totpSecret =
        await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
      const url = totpSecret.generateQrCodeUrl(
        this.afa.currentUser.email,
        'Virtual Facility'
      );
      this.showSecure = false;
      this.showVerification = true;
      return { totpSecret, url };
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Verify and complete enrollment in 2fa
   *
   * @param {{
   *     verificationId: string;
   *     verificationCode: string;
   *   }} {
   *     verificationId,
   *     verificationCode,
   *   }
   * @returns {Promise<void>}
   * @memberof MfaService
   */
  public async verifyEnrollWithPhoneNumber({
    verificationId,
    verificationCode,
  }: {
    verificationId: string;
    verificationCode: string;
  }): Promise<void> {
    try {
      // Ask user for the verification code.
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      // Complete enrollment.
      const user = multiFactor(this.afa.currentUser);
      await user.enroll(multiFactorAssertion, 'Text Message');
      const vfUser = await firstValueFrom(
        this.store.select((state) => state.auth.user)
      );

      this.updateUserPhoneVerification(vfUser.id, true).subscribe(() => {
        this.factorId = null;
        navigateToDeeplink();
      });
    } catch (err) {
      console.error('Error in catch:', err);
      this.handleError();
    }
  }

  /**
   * Verify totp works
   *
   * @param {{
   *     totpSecret: TotpSecret;
   *     verificationCode: string;
   *   }} {
   *     totpSecret,
   *     verificationCode,
   *   }
   * @return {*}  {Promise<void>}
   * @memberof MfaService
   */
  public async verifyEnrollWithTOTP({
    totpSecret,
    verificationCode,
  }: {
    totpSecret: TotpSecret;
    verificationCode: string;
  }): Promise<void> {
    try {
      // Ask user for the verification code.
      const multiFactorAssertion =
        TotpMultiFactorGenerator.assertionForEnrollment(
          totpSecret,
          verificationCode
        );
      // Complete enrollment.
      const user = multiFactor(this.afa.currentUser);
      await user.enroll(multiFactorAssertion, 'Authenticator App');
      this.factorId = null;
      navigateToDeeplink();
    } catch (err) {
      console.error('Error in catch:', err);
      this.handleError();
    }
  }

  /**
   * Send verification code to user
   *
   * @param {MultiFactorResolver} resolver
   * @param {RecaptchaVerifier} recaptchaVerifier
   * @param {number} selectedIndex
   * @returns {Promise<string>} verificationId
   * @memberof MfaService
   */
  async sendVerificationCode(
    resolver = this.verificationResolver,
    recaptchaVerifier = this.verificationCaptcha,
    selectedIndex = 0
  ): Promise<string> {
    let verificationId: string;
    if (this.capacitor.getPlatform() === 'ios') {
      const verificationObject =
        await FirebaseAuthentication.verifyPhoneNumber();
      verificationId = verificationObject.verificationId;
    } else {
      this.phoneInfoOptions = {
        multiFactorHint: resolver.hints[selectedIndex],
        session: resolver.session,
      };
      const phoneAuthProvider = new PhoneAuthProvider(this.afa);
      // Send SMS verification code
      verificationId = await phoneAuthProvider.verifyPhoneNumber(
        this.phoneInfoOptions,
        recaptchaVerifier
      );
    }
    this.showSecure = false;
    this.showVerification = true;

    return verificationId;
  }

  /**
   * Formats user info
   *
   * @param {UserCredential} userCredential
   * @returns {Promise<User>}
   * @memberof MfaService
   */
  async handleSuccess(userCredential: UserCredential): Promise<User> {
    const user: User = {
      id: userCredential.user.uid,
      uid: userCredential.user.uid,
      email: userCredential.user.email,
      username: userCredential.user.displayName,
      phone: userCredential.user.phoneNumber,
      active: true,
    };
    return await userCredential.user
      .getIdTokenResult()
      .then((idTokenResult) => {
        const hasuraClaims = idTokenResult.claims
          ? idTokenResult.claims['https://hasura.io/jwt/claims']
          : {};

        return {
          ...user,
          token: idTokenResult.token,
          hasuraClaims,
        };
      });
  }

  /**
   * Sets recaptcha element
   *
   * @memberof MfaService
   */
  async recaptchaVerifier() {
    if (environment.env === 'qa') {
      this.afa.settings.appVerificationDisabledForTesting = true;
    }

    const captchaElement = document.getElementById('mfa-recaptcha');
    if (captchaElement != null) {
      this.verificationCaptcha = new RecaptchaVerifier(
        'mfa-recaptcha',
        { size: 'invisible' },
        this.afa
      );
    }
  }

  /**
   * Error handler for MFA sign in
   *
   * @memberof MfaService
   */
  handleError() {
    this.errorMessage = 'The SMS verification code used is invalid. Try again.';
  }

  /**
   * call api to update user phone verification
   *
   * @param {(string | number)} id
   * @param {boolean} isVerified
   * @param {string} [phone]
   * @returns
   * @memberof MfaService
   */
  public updateUserPhoneVerification(
    id: string | number,
    isVerified: boolean,
    phone?: string
  ) {
    const user = {
      phone: {
        number: phone ? phone : '',
        countryCode: '',
        isVerified,
        isSubscribed: false,
      },
    };
    return this.apollo
      .mutate<{ updateUser: boolean }>({
        mutation: UPDATE_USER_QUERY,
        variables: {
          id,
          user,
        },
      })
      .pipe(map((d) => d?.data?.updateUser));
  }
}
