import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Tab } from '@vfi-ui/models';

@Component({
  selector: 'atom-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input() tabs: Tab[] = [];
  @Input() selectedTab: string;
  @Input() hasChanges = false;
  @Input() showChangeModal = false;
  @Output() tabSelected: EventEmitter<string> = new EventEmitter();
  changeModalVisible = false;
  destinationTab: string;

  constructor() {}

  /**
   * handle tab selected event
   *
   * @param {string} tab
   * @memberof TabsComponent
   */
  selected(tab: string) {
    if (this.showChangeModal && this.hasChanges) {
      this.destinationTab = tab;
      this.changeModalVisible = true;
    } else {
      this.selectedTab = tab;
      this.tabSelected.emit(tab);
    }
  }

  /**
   * do not change tabs
   *
   * @memberof TabsComponent
   */
  stay() {
    this.destinationTab = null;
    this.changeModalVisible = false;
  }

  /**
   * move to new tab
   *
   * @memberof TabsComponent
   */
  leave() {
    this.selectedTab = this.destinationTab;
    this.tabSelected.emit(this.selectedTab);
    this.destinationTab = null;
    this.changeModalVisible = false;
  }
}
