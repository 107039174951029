import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SavedView } from '@vfi-ui/models';

@Component({
  selector: 'atom-saved-views-selector',
  templateUrl: './saved-views-selector.component.html',
  styleUrls: ['./saved-views-selector.component.scss'],
})
export class SavedViewsSelectorComponent implements OnChanges {
  @Input() defaultView: SavedView;
  @Input() selectedOption: SavedView;
  @Input() options: SavedView[] = [];
  @Input() disabled = false;
  @Input() showSavedViews = false;
  @Input() loading = false;
  @Output() viewSelected = new EventEmitter<SavedView>();
  @Output() editClicked = new EventEmitter<any>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.selectedOption?.currentValue) {
      this.selectedOption = changes?.selectedOption?.currentValue;
    }
  }

  /**
   * item selection
   *
   * @param {SavedView} option
   * @memberof SavedViewsSelectorComponent
   */
  isChecked(option: SavedView) {
    this.showSavedViews = false;
    this.viewSelected.emit(option);
  }
}
