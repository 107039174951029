<ul *ngIf="!useIds">
  <li
    *ngFor="let item of listItems"
    class="regular-16 pl-2 d-flex align-items-center mb-1 cursor-pointer"
    [ngClass]="{ selected: selectedItem === item }"
    (click)="selected(item)"
  >
    <span class="itemName">{{ item }}</span>
  </li>
</ul>

<ul *ngIf="useIds">
  <li
    *ngFor="let item of listItems"
    class="regular-16 pl-2 d-flex align-items-center mb-3 cursor-pointer"
    [ngClass]="{ selected: selectedItem === item?.id }"
    (click)="selected(item?.id)"
  >
    <div
      class="d-flex flex-column justify-content-center"
      [ngStyle]="{ width: width + 'px' }"
    >
      <span class="ellipsis">
        <span class="itemName">{{ item?.name }} </span>
        <span *ngIf="item?.secondaryTitle" class="gray-3"
          >({{ item?.secondaryTitle }})</span
        >
      </span>
      <span *ngIf="item?.subText" class="gray-4 regular-12 ellipsis">{{
        item?.subText
      }}</span>
    </div>
  </li>
</ul>
