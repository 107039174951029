import { SavedViewsViewType } from '../constants/saved-views.constants';
import { DisplayOptions } from './core.interface';
import { LensCategory } from './lens-interface';
import { Team } from './team.interface';

export interface CoreMenuItems {
  id?: number;
  name?: string;
  urlName?: string;
  filter?: any;
  parent?: string;
  app?: string;
  icon?: string;
  route?: string;
  routeParams?: string;
  order?: number;
  counter?: number;
  countKey?: string;
  lens?: CoreMenuItems[];
  permissionApp?: string;
  permission?: string;
  show?: boolean;
  header?: string;
  type?: SavedViewsViewType;
  category?: string;
  displayOptions?: DisplayOptions;
  criteria?: any;
  lensId?: string;
  types?: string;
  isCustom?: boolean;
  isBeta?: boolean;
  isActive?: boolean;
  isParent?: boolean;
  entityType?: CoreMenuType;
  isPilot?: boolean;
  useSVG?: boolean;
  isWork?: boolean;
  superUserOnly?: boolean;
  needCC?: boolean;
  isAdvancedAnalytics?: boolean;
  children?: CoreMenuItems[];
  isSectionDefault?: boolean;
}

export type CoreMenuType = 'work' | 'alarm';

export interface Filter {
  status: string;
}

export interface LensCount {
  id: string;
  count: number;
}

export interface Counts {
  [key: string]: number;
}

export interface Aggregate {
  count: number;
}

export type TileType =
  | 'alarm'
  | 'alarms'
  | 'activeWork'
  | 'allWork'
  | 'user'
  | 'nuisance'
  | 'asset'
  | 'assets';

export interface MenuLens {
  name?: string;
  id?: string;
  criteria?: any;
  category?: LensCategory;
  childLens?: MenuLens[];
  displayOptions?: DisplayOptions;
  isActive?: boolean;
  isCustom?: boolean;
  isParent?: boolean;
  isSelected?: boolean;
  type?: string;
  baseUrl?: string;
  count?: number;
  orderValue?: number;
  showHeader?: boolean;
  headerType?: string;
  icon?: string;
  lensType?: string;
  team?: Team;
  isTeam?: boolean;
  tableOptions?: any;
  isSectionDefault?: boolean;
  isHidden?: boolean;
}
