import { CoreWorkFiltersWhere } from './work-order.interface';
import { CoreAlarmsWhere } from './alarm-instance.interface';
import { User } from './user.interface';

export interface AutomationRule {
  id: number;
  createdAt: string;
  createdBy: Partial<User>;
  lastModifiedAt: string;
  lastModifiedBy: Partial<User>;
  name: string;
  description: string;
  executionOrder: number;
  state: AutomationRuleState;
  criteria: any;
  trigger: AutomationRuleTrigger;
  delay: AutomationRuleDelayInput;
  actions?: AutomationRuleAction[];
  isComplete: boolean;
  lastError: string;
  excludeIds: number[];
  lastExecutionAt?: string;
  executionCount?: number;
  schedule?: AutomationRuleScheduleInput;
}

export interface AutomationRuleAction {
  id: string;
  createdAt: string;
  createdBy: Partial<User>;
  lastModifiedAt: string;
  lastModifiedBy: Partial<User>;
  assignedTo: Partial<User>;
  dueIn: number;
  executionAction: AutomationRuleExcutionAction;
  criteria: any;
  config: any;
}

export interface AutomationRuleCreateInput {
  name?: string;
  description?: string;
  state?: AutomationRuleState;
  criteria?: GroupCriteriaInput;
  trigger?: AutomationRuleTrigger;
  delay?: AutomationRuleDelayInput;
  actions?: AutomationRuleActionCreateInput[];
  excludeIds?: number[];
  schedule?: AutomationRuleScheduleInput;
}

export interface AutomationRuleScheduleInput {
  timeInYear?: TimeInYear;
  dayOfMonth?: number;
  everyNthDay?: number;
  everyNthWeek?: number;
  everyNthMonth?: number;
  everyNthYear?: number;
  dayOfWeek?: DayOfWeek[];
  from?: string;
  to?: string;
  intervalInMonth?: IntervalInMonth;
  scheduleCreatedAt?: Date;
}

export interface TimeInYear {
  day: number;
  month: number;
}

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export interface IntervalInMonth {
  dayOfWeek: DayOfWeek;
  month?: number;
  repeatingTimePrefix: RepeatingTimePrefix;
}

export enum RepeatingTimePrefix {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD',
  Fourth = 'FOURTH',
  Last = 'LAST',
}

export interface AutomationRuleDelayInput {
  days: number;
  hours: number;
  minutes: number;
}

export interface AutomationRuleActionCreateInput {
  id?: string;
  executionAction: AutomationRuleExcutionAction;
  config: any;
}

export interface GroupCriteriaInput {
  clause: GroupCriteriaClause;
  data?: GroupCriteriaInput[];
  where?: CoreAlarmsWhere | CoreWorkFiltersWhere;
  whereNot?: CoreAlarmsWhere | CoreWorkFiltersWhere;
  disabled?: boolean;
}

export enum AutomationRuleState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export enum GroupCriteriaClause {
  AND = 'AND',
  OR = 'OR',
  WHERE = 'WHERE',
}

export enum AutomationRuleTrigger {
  ENTER_INTAKE = 'ENTER_INTAKE',
  TURNS_STATLE = 'TURNS_STALE',
}

export enum AutomationRuleExcutionAction {
  CREATE_WORK = 'CREATE_WORK',
  SET_ALARM_END_TIME = 'SET_ALARM_END_TIME',
}

export enum AutomationRuleExclusitonType {
  WHERE = 'where',
  WHERE_NOT = 'whereNot',
}

export enum AutomationRuleExclusitonName {
  CONTAINS = 'Contains',
  CONSTAINS_NOT = 'Does Not Contain',
}

export interface AutomationRuleOrderInput {
  id: number;
  executionOrder: number;
}

export interface GroupCriteriaInput {
  groupId?: string;
  clause: GroupCriteriaClause;
  data?: GroupCriteriaInput[];
  where?: CoreAlarmsWhere | CoreWorkFiltersWhere;
  whereNot?: CoreAlarmsWhere | CoreWorkFiltersWhere;
}

export interface AutomationRuleActionUpdateInput {
  id: string;
  executionAction: AutomationRuleExcutionAction;
  config: any;
}

export interface AutomationRuleUpdateInput {
  name?: string;
  description?: string;
  state?: AutomationRuleState;
  criteria?: GroupCriteriaInput;
  trigger?: AutomationRuleTrigger;
  delay?: AutomationRuleDelayInput;
  actions?: AutomationRuleActionUpdateInput[];
}

export interface AutomationCmmsFields {
  // the name of the field
  name: string;
  // the actual value
  value: string | boolean | number;
  // the actual label
  label?: string | boolean | number;
  // the fallback value
  fallbackValue?: string | boolean | number;
  // the fallback label
  fallbackLabel?: string | boolean | number;
  // optional not required
  dataType?: unknown;
  // true if the field should be mapped to an alarm attribute (e.g. building)
  isAlarmAttribute?: boolean;
  // true if a field is required
  isRequired?: boolean;
}
