import { allWorkTicketFields } from '@vfi-ui/models';
import { gql } from 'apollo-angular';

export const GET_SITUATIONAL_AWARENESS_ENTITIES = gql`
  query situationalDashboardEntities(
    $input: SituationalDashboardEntityOptions!
  ) {
    situationalDashboardEntities(input: $input) {
      id
      entity {
        locationEntities {
          name
          class
        }
        roomName
        roomNumber
      }
      entityId
      name
      buildingName
      lastActiveWorkTicketCreatedBy
      oldestActiveAlarmTime
      lastUnresolvedWorkClosedAt
      lastestUnresolvedWorkTicketId
      lastWorkTicketCreatedAt
      latestActiveAlarmTime
      stateChangedAt
      class
      isAcked
      isCovered
      isActioned
      snoozeExpiresAt
      snoozedBy {
        displayName
      }
      snoozeReason
      isEscalated
      criticality
      state
      lastActiveWorkTicketAssignedTo {
        firstName
        lastName
      }
    }
    situationalDashboardEntityCount(input: $input)
  }
`;

export const GET_SITUATIONAL_AWARENESS_ENTITIY_COUNT = gql`
  query situationalDashboardEntityCount(
    $input: SituationalDashboardEntityOptions!
  ) {
    situationalDashboardEntityCount(input: $input)
  }
`;

export const GET_SITUATIONAL_AWARENESS_TIMERS = gql`
  query situationalDashboardThresholds {
    situationalDashboardThresholds {
      timeToImpact
      timeToEscalation
      criticality
    }
  }
`;

export const GET_SITUATIONAL_AWARENESS_WORK_TICKETS = gql`
  query situationalDashboardWorkTicketsAndCount($options: WorkTicketOptions!) {
    situationalDashboardWorkTicketsAndCount(
      options: $options
    ) {
      items {
        ${allWorkTicketFields}
        entities {
          id
          name
          alarmId
        }
      }
      count
    }
  }
`;

export const GET_SITUATIONAL_AWARENESS_CLOSED_TICKETS_COUNT = gql`
  query situationalDashboardWorkTicketsAndCount($options: WorkTicketOptions!) {
    situationalDashboardWorkTicketsAndCount(options: $options) {
      count
    }
  }
`;

export const GET_SITUATIONAL_AWARENESS_ALARM_COUNT = gql`
  query situationalDashboardAlarmCount(
    $options: SituationalDashboardAlarmOptions!
  ) {
    situationalDashboardAlarmCount(options: $options)
  }
`;

export const GET_SITUATIONAL_AWARENESS_ALARMS = gql`
  query situationalDashboardAlarms(
    $options: SituationalDashboardAlarmOptions!
  ) {
    situationalDashboardAlarms(options: $options) {
      formattedRawText
      activeWorkCount
      contextualName
      alarmName
      id
      displayId
      isAutoDispatch
      isPinned
      isStale
      latestAlarmTime
      latestEndTime
      lastWorkTicketCreatedAt
      lastWorkTicketClosedAt
      priority
      status
      text
      databaseId
      activeWorkCount
      fleetingCount
      chatteringCount
      nuisanceCount
      lastFleetingCount
      lastChatteringCount
      lastNuisanceCount
      isServiceImpacting
      snoozeExpiresAt
      openWorkTickets {
        id
      }
      properties {
        type
        values
        confidence
        highlightConfidenceThreshold
      }
      externalSystem {
        hasProcessVariables
      }
      notes
      alarmType
      entityIds
    }
  }
`;

export const SNOOZE_ENTITY = gql`
  mutation snoozeEntity(
    $entityId: Int!
    $snoozeExpiresAt: DateTime!
    $reason: String!
  ) {
    snoozeEntity(
      entityId: $entityId
      snoozeExpiresAt: $snoozeExpiresAt
      reason: $reason
    )
  }
`;

export const UNSNOOZE_ENTITY = gql`
  mutation unsnoozeEntity($entityId: Int!) {
    unsnoozeEntity(entityId: $entityId)
  }
`;

export const CREATE_SITUATIONAL_AWARENESS_VIEW = gql`
  mutation createSituationalDashboardView {
    createSituationalDashboardView {
      id
      name
      isVisible
      isDeleted
      entitySortOrder
      divisions {
        id
        name
        blocks {
          id
          name
        }
      }
      teams {
        id
        name
        isAllBuildings
      }
    }
  }
`;

export const GET_SITUATIONAL_AWARENESS_VIEW = gql`
  query situationalDashboardViews($options: SituationalDashboardViewOptions!) {
    situationalDashboardViews(options: $options) {
      id
      name
      isVisible
      isDeleted
      entitySortOrder
      mapListTitle
      mapOptions {
        zoom
        center
        bearing
        pitch
        maxBounds
      }
      mapEntities {
        id
        entity {
          locationEntities {
            name
            class
          }
          roomName
          roomNumber
        }
        entityId
        name
        lastActiveWorkTicketCreatedBy
        oldestActiveAlarmTime
        lastUnresolvedWorkClosedAt
        lastestUnresolvedWorkTicketId
        lastWorkTicketCreatedAt
        latestActiveAlarmTime
        stateChangedAt
        class
        isAcked
        isCovered
        isActioned
        snoozeExpiresAt
        snoozedBy {
          displayName
        }
        snoozeReason
        isEscalated
        criticality
        state
        lastActiveWorkTicketAssignedTo {
          firstName
          lastName
        }
        geoJSON
      }
      divisions {
        id
        name
        blocks {
          id
          name
          criticality
          entities {
            id
            name
            alarmIds
          }
          classes {
            id
            name
          }
          classIds
          shops
          spaceTypes
          showInactive
        }
      }
      teams {
        id
        name
        isAllBuildings
      }
    }
  }
`;

export const GET_VISIBLE_SITUATIONAL_AWARENESS_VIEW = gql`
  query situationalDashboardViews($options: SituationalDashboardViewOptions!) {
    situationalDashboardViews(options: $options) {
      id
      name
      isVisible
    }
  }
`;

export const GET_SITUATIONAL_AWARENESS_VIEW_COUNTS_QUERY = gql`
  query situationalDashboardViews($options: SituationalDashboardViewOptions!) {
    situationalDashboardViews(options: $options) {
      id
      activeEntityCounts {
        count
        escalatedCount
      }
    }
  }
`;

export const UPDATE_SITUATIONAL_AWARENESS_VIEW = gql`
  mutation updateSituationalDashboardView(
    $id: String!
    $input: SituationalDashboardViewUpdateInput!
  ) {
    updateSituationalDashboardView(id: $id, input: $input) {
      id
      name
      isVisible
      isDeleted
      entitySortOrder
      divisions {
        id
        name
        blocks {
          id
          name
          entities {
            id
            name
          }
        }
      }
      teams {
        id
        name
        isAllBuildings
      }
    }
  }
`;

export const DELETE_SITUATIONAL_AWARENESS_VIEW = gql`
  mutation deleteSituationalDashboardView($id: String!) {
    deleteSituationalDashboardView(id: $id) {
      id
    }
  }
`;

export const CREATE_SITUATIONAL_AWARENESS_BLOCK = gql`
  mutation createSituationalDashboardBlock(
    $input: SituationalDashboardBlockCreateInput!
  ) {
    createSituationalDashboardBlock(input: $input) {
      id
      name
      entities {
        id
        name
        alarmIds
      }
      criticality
    }
  }
`;

export const UPDATE_SITUATIONAL_AWARENESS_DIVISION = gql`
  mutation updateSituationalDashboardDivision(
    $id: String!
    $input: SituationalDashboardDivisionUpdateInput!
  ) {
    updateSituationalDashboardDivision(id: $id, input: $input) {
      id
      name
      blocks {
        id
        name
        entities {
          id
          name
          alarmIds
        }
        criticality
      }
    }
  }
`;

export const UPDATE_SITUATIONAL_AWARENESS_BLOCK = gql`
  mutation updateSituationalDashboardBlock(
    $id: String!
    $input: SituationalDashboardBlockUpdateInput!
  ) {
    updateSituationalDashboardBlock(id: $id, input: $input) {
      id
      name
      entities {
        id
        name
        alarmIds
      }
      criticality
    }
  }
`;

export const DELETE_SITUATIONAL_AWARENESS_BLOCK = gql`
  mutation deleteSituationalDashboardBlock($id: String!) {
    deleteSituationalDashboardBlock(id: $id)
  }
`;

export const FETCH_SITUATIONAL_AWARENESS_ENTITIES = gql`
  query entities($options: EntityOptions!) {
    entities(options: $options) {
      id
      name
      alarmIds
    }
  }
`;

export const FETCH_RELATED_ENTITIES = gql`
  query situationalDashboardRelatedEntities(
    $input: SituationalDashboardRelatedEntityOptions!
  ) {
    situationalDashboardRelatedEntities(input: $input) {
      id
      entityId
      name
      spaceType
      lastActiveWorkTicketCreatedBy
      lastActiveWorkTicketAssignedTo {
        firstName
        lastName
      }
      isCovered
      direction
      className
      locationEntities {
        name
        class
      }
    }
    situationalDashboardRelatedEntityCount(input: $input)
  }
`;

export const FETCH_CURRENT_ENTITY_ISSUES = gql`
  query entities($options: EntityOptions!) {
    entities(options: $options) {
      id
      name
      class
      rawEntityClass
      spaceType {
        name
      }
      locationEntities {
        name
        class
      }
    }
  }
`;

export const FETCH_CHILD_ALARM = gql`
  query situationalDashboardChildAlarms(
    $options: SituationalDashboardAlarmOptions!
  ) {
    situationalDashboardChildAlarms(options: $options) {
      entityId
      alarms {
        formattedRawText
        activeWorkCount
        contextualName
        id
        displayId
        isAutoDispatch
        isPinned
        isStale
        latestAlarmTime
        latestEndTime
        lastWorkTicketClosedAt
        priority
        status
        text
        databaseId
        activeWorkCount
        fleetingCount
        chatteringCount
        nuisanceCount
        lastFleetingCount
        lastChatteringCount
        lastNuisanceCount
        properties {
          type
          values
          confidence
          highlightConfidenceThreshold
        }
        externalSystem {
          hasProcessVariables
        }
        notes
        alarmType
      }
      class
      name
    }
  }
`;

export const RECALCULATE_ALL_SAE = gql`
  mutation {
    recalculateAllSituationalDashboardEntities
  }
`;
