import { caseSensitiveSort, fastParse, isNil } from '@vfi-ui/util/helpers';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthService } from '@vfi-ui/data-access/shared';
import { BaseComponent } from '@vfi-ui/feature/core';
import {
  GlobalFilterSort,
  MultiLevelInputOption,
  TeamMember,
  TeamMemberRole,
  Users,
} from '@vfi-ui/models';
import { TeamsState, AuthState } from '@vfi-ui/state';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'atom-vfi-assignee-selector',
  templateUrl: './vfi-assignee-selector.component.html',
  styleUrls: ['./vfi-assignee-selector.component.scss'],
})
export class VfiAssigneeSelectorComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() placeholder: string;
  @Input() value: MultiLevelInputOption | MultiLevelInputOption[];
  @Input() label: string;
  @Input() reset: Subject<boolean>;
  @Input() disabled = false;
  @Input() required = false;
  @Input() highlightRequired = false;
  @Input() readOnly = false;
  @Input() showMultiLevelSelect = false;
  @Input() multiSelect = false;
  @Input() optionsByTeams = true;
  @Input() teamId: string;
  @Input() displaySelectedValue = true;
  @Input() showCurrentUser = true;
  @Input() displayName: string;
  @Input() activeUsers: boolean;
  @Input() sort: GlobalFilterSort;
  @Input() vendorUsers: boolean;
  @Input() disabledUsers: boolean;
  @Input() pendingUsers: boolean;
  @Input() removeSelectedUsers = false;
  @Input() selectedUsers: string[];
  @Input() disablePushUsers = false;
  @Input() disableTextUsers = false;
  @Output() assigneeChanged = new EventEmitter<
    MultiLevelInputOption | MultiLevelInputOption[]
  >();
  users: Users[];
  options = [];
  teamOptions = {};
  unassignedTeamOptions = [];
  loading = false;
  isArray = Array.isArray;
  selectedValue: MultiLevelInputOption | MultiLevelInputOption[];

  constructor(
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    if (!this.teamId) {
      this.getUsers();
    } else {
      this.getUsersByTeams();
    }

    if (this.value) {
      this.selectedValue = this.value;
    }

    if (this.reset) {
      this.reset.pipe(take(1)).subscribe(() => {
        this.value = null;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value?.currentValue) {
      this.selectedValue = changes.value.currentValue;
    }
  }

  /**
   * search for users
   * @param {string} displayName
   * @memberof AssigneeSelectorComponent
   */
  searchUser(displayName: string) {
    this.unassignedTeamOptions = [];
    if (!this.teamId) {
      this.getUsers(displayName);
    } else {
      this.getUsersByTeams(displayName);
    }
  }

  /**
   * get users from api
   * @param {string} [displayName]
   * @memberof AssigneeSelectorComponent
   */
  getUsers(displayName?: string) {
    this.loading = true;
    this.authService
      .getUsers({
        displayName: displayName || this.displayName,
        showActive: this.activeUsers,
        sort: this.sort,
        showVendors: this.vendorUsers,
        showDisabled: this.disabledUsers,
        showPending: this.pendingUsers,
      })
      .pipe(take(1))
      .subscribe((users) => this.handleFormatUsers(users));
  }

  /**
   * get users by teams from api
   * @memberof AssigneeSelectorComponent
   */
  getUsersByTeams(displayName?: string) {
    this.loading = true;
    this.authService
      .getUsersByTeams([this.teamId], displayName || this.displayName)
      .pipe(take(1))
      .subscribe((users) => this.handleFormatUsers(users));
  }

  /**
   * emit value on changes
   * @param {MultiLevelInputOption | MultiLevelInputOption[]} option
   * @memberof AssigneeSelectorComponent
   */
  onValueChange(option: MultiLevelInputOption | MultiLevelInputOption[]) {
    this.value = option;
    this.assigneeChanged.emit(this.value);
    this.showMultiLevelSelect = false;
  }

  /**
   * format users to multi level input option
   * @param {Users[]} users
   * @memberof AssigneeSelectorComponent
   */
  formatUsers(users: Users[]) {
    this.options = users.map((user) => ({
      id: user.id,
      name: user.displayName,
      meta: {
        avatarUrl: user.avatarUrl,
        disabled: this.determineDisable(user),
        disabledText: this.getDisabledUserText(),
      },
    }));
    this.loading = false;
    this.cd.detectChanges();
  }

  /**
   * format sectioned to multi level input option
   * @param {Users[]} users
   * @memberof AssigneeSelectorComponent
   */
  formatSectionedUsers(users: Users[]) {
    this.store.selectSnapshot(TeamsState.getTeams)?.forEach((team) => {
      if ((this.teamId && this.teamId === team.id) || !this.teamId) {
        this.teamOptions[team.name] = [];
      }
    });

    caseSensitiveSort(users, 'displayName').forEach((user) => {
      if (!user.teamMembers.length) {
        this.addToOptions(null, user);
      }

      user.teamMembers.forEach((teamMember) => {
        if (
          (this.teamId && this.teamId === teamMember.team.id) ||
          !this.teamId
        ) {
          this.addToOptions(teamMember, user);
        }
      });
    });

    for (const prop in this.teamOptions) {
      if (!this.teamOptions[prop].length) {
        delete this.teamOptions[prop];
      }
    }

    this.loading = false;
    this.cd.detectChanges();
  }

  /**
   * set disabled meta data for users
   *
   * @private
   * @param {Users} user
   * @returns
   * @memberof AssigneeSelectorComponent
   */
  private determineDisable(user: Users) {
    if (this.disablePushUsers) {
      return !user.isPushNotificationEnabled;
    }
    if (this.disableTextUsers) {
      return isNil(user.phone.number);
    }
    return false;
  }

  /**
   * return disabled user text
   *
   * @private
   * @returns
   * @memberof AssigneeSelectorComponent
   */
  private getDisabledUserText() {
    if (this.disablePushUsers) {
      return 'User has opted out of push notifications';
    }
    if (this.disableTextUsers) {
      return 'User does not have a mobile number';
    }
    return null;
  }

  private handleFormatUsers(users: Users[]) {
    this.users = fastParse(users);
    if (!this.showCurrentUser) {
      const userId = this.store.selectSnapshot(AuthState.getId);
      users = users.filter((user) => user.id !== userId);
    }

    if (this.removeSelectedUsers) {
      users = users.filter(
        (user) => !this.selectedUsers.includes(String(user?.id))
      );
    }

    if (this.optionsByTeams) {
      this.formatSectionedUsers(users);
    } else {
      this.formatUsers(users);
    }
  }

  /**
   * adds user to team options
   * @param {teamMember} teamMember
   * @param {Users} user
   * @memberof AssigneeSelectorComponent
   */
  private addToOptions(teamMember: TeamMember, user: Users) {
    if (!teamMember) {
      const unassignedUser = this.unassignedTeamOptions.find(
        (option) => option.id === user.id
      );

      if (!unassignedUser) {
        this.unassignedTeamOptions.push({
          id: user.id,
          name: user.displayName,
          meta: {
            avatarUrl: user.avatarUrl,
            disabled: this.determineDisable(user),
            disabledText: this.getDisabledUserText(),
          },
        });
      }
    }

    if (teamMember && teamMember?.permissions.role === TeamMemberRole.Admin) {
      this.teamOptions?.[teamMember.team.name]?.unshift({
        id: user.id,
        name: user.displayName,
        meta: {
          avatarUrl: user.avatarUrl,
          teamLead: true,
          disabled: this.determineDisable(user),
          disabledText: this.getDisabledUserText(),
        },
      });
    } else if (teamMember) {
      this.teamOptions?.[teamMember.team.name]?.push({
        id: user.id,
        name: user.displayName,
        meta: {
          avatarUrl: user.avatarUrl,
          disabled: this.determineDisable(user),
          disabledText: this.getDisabledUserText(),
        },
      });
    }
  }
}
