import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import { AlarmsDataService } from '@vfi-ui/data-access/shared';
import { BaseComponent } from '@vfi-ui/feature/core';
import {
  AlarmDetailsPriorityOptions,
  AlarmProperty,
  ALARM_ATTRIBUTE_COLUMN_TO_LABEL_MAP,
  BASE_EQUIPMENT_FIELDS,
  BooleanOptions,
  CoreAlarm,
  PriorityFontColors,
  SPACE_ASSET_FIELDS,
  VfiSelectOption,
} from '@vfi-ui/models';
import { AlarmsState } from '@vfi-ui/state';
import { isNil, startCaseCap } from '@vfi-ui/util/helpers';
import { finalize, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'atom-alarm-fields',
  templateUrl: './alarm-fields.component.html',
  styleUrls: ['./alarm-fields.component.scss'],
})
export class AlarmFieldsComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() alarm: CoreAlarm;
  @Input() resetForm: Subject<boolean>;
  @Input() readOnly = false;
  @Output() formChanged: EventEmitter<UntypedFormGroup> = new EventEmitter();
  form: UntypedFormGroup;
  equipmentFields: AlarmProperty[] = [];
  alarmFields: AlarmProperty[] = [];
  startCase = startCaseCap;
  options: VfiSelectOption[] = [];
  dropdownOptions: VfiSelectOption[] = [];
  optionsLoading = false;
  values = {};
  priorityOptions = AlarmDetailsPriorityOptions;
  priorityFontColors = PriorityFontColors;
  booleanOptions = BooleanOptions;
  multiSelectFields = ['component', 'category'];
  confidence: { [key: string]: boolean } = {};
  fieldNameMap = ALARM_ATTRIBUTE_COLUMN_TO_LABEL_MAP;

  constructor(
    private store: Store,
    private alarmsDataService: AlarmsDataService,
    private formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.resetForm
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => this.populateFields(this.alarm));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.alarm?.currentValue) {
      this.values = {};
      this.formatFields();
      this.populateFields(changes?.alarm?.currentValue);
    }
  }

  /**
   * format alarm fields
   *
   * @memberof AlarmFieldsComponent
   */
  formatFields() {
    const dynamicFields = this.store.selectSnapshot(
      AlarmsState.getAlarmProperties
    );
    const fields = dynamicFields.filter(
      (field) => !SPACE_ASSET_FIELDS.includes(field?.name)
    );
    this.equipmentFields = BASE_EQUIPMENT_FIELDS.map((f) =>
      fields.find((field) => field?.name === f)
    ).filter((i) => i);
    const localDynamicFields = fields.filter(
      (f) => !BASE_EQUIPMENT_FIELDS.includes(f?.name)
    );
    this.alarmFields = [...localDynamicFields];
    this.confidence = this.formatConfidence(this.alarm, [
      ...this.alarmFields,
      ...this.equipmentFields,
    ]);
  }

  /**
   * populate alarm fields
   *
   * @param {CoreAlarm} alarm
   * @memberof AlarmFieldsComponent
   */
  populateFields(alarm: CoreAlarm) {
    // Reset previous values
    this.values = {};

    // Populate alarm fields and populate alarm field form
    const form = [...this.equipmentFields, ...this.alarmFields].reduce(
      (prev, field) => {
        const property = alarm?.properties.find((p) => p?.type === field?.name);
        if (property) {
          Object.assign(prev, { [field?.name]: property?.values[0] });
          if (property.values.length) {
            if (this.multiSelectFields.includes(property.type)) {
              // Handle multi select
              Object.assign(this.values, {
                [field?.name]: property.values.map((prop) => ({
                  value: prop,
                  label: prop,
                })),
              });
            } else {
              // Handle single select
              Object.assign(this.values, {
                [field?.name]: {
                  value: property?.values[0],
                  label: property?.values[0],
                },
              });
            }
          }
        } else {
          prev[field?.name] = null;
        }
        return prev;
      },
      {}
    );

    // Populate priority, status, alarm failure fields
    Object.assign(this.values, {
      priority: AlarmDetailsPriorityOptions.find(
        (option) => option?.value === alarm?.priority
      ),
      isServiceImpacting: BooleanOptions.find(
        (option) => option?.value === alarm?.isServiceImpacting
      ),
    });
    Object.assign(form, {
      priority: alarm?.priority,
      status: alarm?.status,
      isServiceImpacting: alarm?.isServiceImpacting,
    });

    this.form = this.formBuilder.group(form);
    this.form.markAsPristine();
    this.formChanged.emit(this.form);
  }

  /**
   * handle dropdown visible change
   *
   * @param {string} type
   * @param {boolean} show
   * @memberof AlarmFieldsComponent
   */
  onDropdownVisibleChange(type: string, show: boolean) {
    if (show) {
      this.options = [];
      this.dropdownOptions = [];
      this.fetchOptions(type);
    }
  }

  /**
   * handle option selected event
   *
   * @param {string} type
   * @param {VfiSelectOption} option
   * @memberof AlarmFieldsComponent
   */
  onOptionSelect(type: string, option: VfiSelectOption) {
    Object.assign(this.values, { [type]: option });
    this.form.controls[type].setValue(option?.value);
    this.form.controls[type].markAsDirty();
    this.formChanged.emit(this.form);
  }

  /**
   * handle multi option selected event
   *
   * @param {string} type
   * @param {VfiSelectOption[]} options
   * @memberof AlarmFieldsComponent
   */
  onMultiOptionSelect(type: string, options: VfiSelectOption[]) {
    Object.assign(this.values, { [type]: options });
    this.form.controls[type].setValue(options.map((op) => op?.value));
    this.form.controls[type].markAsDirty();
    this.formChanged.emit(this.form);
  }

  /**
   * call data service to fetch options for alarm fields
   *
   * @param {string} type
   * @memberof AlarmFieldsComponent
   */
  fetchOptions(type: string) {
    this.optionsLoading = true;
    const equipmentType = this.alarm?.properties?.find(
      (prop) => prop.type === 'equipment_type'
    )?.values?.[0];
    this.alarmsDataService
      .getAlarmAutoCompleteWithId({
        property: type,
        offset: 0,
        assetClass: equipmentType,
      })
      .pipe(finalize(() => (this.optionsLoading = false)))
      .subscribe((data) => {
        this.options = data.items.map((d) => ({
          label: d?.value,
          value: d?.value,
        }));
        this.dropdownOptions = this.options;
      });
  }

  /**
   * handle option cleared event
   *
   * @param {string} type
   * @memberof AlarmFieldsComponent
   */
  onCleared(type: string) {
    Object.assign(this.values, { [type]: null });
    this.form.controls[type].setValue(null);
    this.form.controls[type].markAsDirty();
    this.formChanged.emit(this.form);
  }

  /**
   * return multi select values
   *
   * @param {VfiSelectOption[]} values
   * @returns
   * @memberof AlarmFieldsComponent
   */
  getMultiSelectValue(values: VfiSelectOption[]) {
    if (values?.length) {
      return values.map((val) => val.value);
    } else {
      return [];
    }
  }

  /**
   * search options
   *
   * @param {string} search
   * @memberof AlarmFieldsComponent
   */
  searchOptions(search: string) {
    if (search) {
      this.dropdownOptions = this.options.filter((op) =>
        op.label.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      this.dropdownOptions = this.options;
    }
  }

  /**
   * format confidence values
   *
   * @private
   * @param {CoreAlarm} alarm
   * @param {AlarmProperty[]} fields
   * @returns
   * @memberof AlarmFieldsComponent
   */
  private formatConfidence(alarm: CoreAlarm, fields: AlarmProperty[]) {
    const object = {};
    return fields.reduce((obj, key) => {
      const property = alarm.properties.find((prop) => prop.type === key.name);
      Object.assign(obj, {
        [key.name]: !isNil(property) && property.confidence === 1,
      });
      return obj;
    }, object);
  }
}
