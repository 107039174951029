<div class="form-group w-100" *ngIf="!newDisabled">
  <div
    class="input-wrapper caret-wrapper"
    [ngClass]="{
      'required-select': required,
      'input-active': value?.length || focused,
    }"
  >
    <ng-select
      ngDefaultControl
      style="min-width: 200px; height: 50px"
      [attr.name]="name"
      [(ngModel)]="value"
      [searchable]="false"
      [clearable]="false"
      [readonly]="disabled"
      [loading]="loading"
      [clearable]="true"
      [class.cursor-default]="disabled"
      (focus)="fetchFields(null)"
      (blur)="checkValidInput(value)"
      (clear)="focused = false"
      (close)="handleCloseChange()"
      (ngModelChange)="valueChanged(value)"
      (scrollToEnd)="onScroll(searchValue)"
    >
      <ng-template ng-header-tmp>
        <input
          [id]="'ng-dropdown-input-' + name"
          class="ng-dropdown-input"
          type="text"
          placeholder="Search {{ placeholder }}"
          (input)="fetchFields($event.target.value)"
          attr.aria-label="{{ name }}-search-input"
          tabIndex="-1"
        />
      </ng-template>
      <ng-option
        *ngFor="let option of options"
        [value]="customQuery === 'cmms' ? option?.value : option"
        >{{ getOptionLabel(option, customQuery) }}</ng-option
      >
    </ng-select>
    <label class="z-index-max">{{ placeholder || name }}</label>
  </div>
</div>

<atom-disabled-input
  *ngIf="newDisabled"
  [placeholder]="placeholder"
  [values]="getDisabledValue(customQuery, defaultLabel, defaultValue, value)"
></atom-disabled-input>

<ng-template #customClearIcon>
  <i class="white far fa-times-circle"></i>
</ng-template>
