export const GRAPH_PERIODS = [
  {
    value: 30,
    label: '1 month',
  },
  {
    value: 90,
    label: '3 months',
  },
  {
    value: 180,
    label: '6 months',
  },
  {
    value: 365,
    label: '1 year',
  },
  {
    value: 0,
    label: 'Custom range',
  },
];
