import { UntypedFormGroup } from '@angular/forms';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import {
  Users,
  notificationModalMode,
  MultiLevelInputOption,
} from '@vfi-ui/models';
import { arrayIsEqual, fastParse, uniq } from '@vfi-ui/util/helpers';
import { Subject } from 'rxjs';
import { BaseComponent } from '@vfi-ui/feature/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nuclei-user-selection-list',
  templateUrl: './user-selection-list.component.html',
  styleUrls: ['./user-selection-list.component.scss'],
})
export class UserSelectionListComponent
  extends BaseComponent
  implements OnChanges, OnInit
{
  @Input() form: UntypedFormGroup;
  @Input() formName: string;
  @Input() isVisible: boolean;
  @Input() mode: notificationModalMode = notificationModalMode.CREATE;
  @Input() users: Users[];
  @Input() existingUsers: string[];
  @Input() resetForm: boolean;
  @Input() formReset: Subject<boolean>;
  @Input() disabled = false;
  @Input() disablePushUsers = false;
  @Input() disableTextUsers = false;
  selectedUsers: Users[];
  usersList: Users[];
  selectedUserIds = [];
  modalFirstOpen: boolean;

  constructor() {
    super();
  }

  ngOnInit() {
    this.formReset
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((reset) => {
        if (reset) {
          this.resetNotification();
        } else {
          this.form.markAsDirty();
        }
      });
  }

  ngOnChanges(change: SimpleChanges) {
    if (change?.users && change?.users?.currentValue) {
      this.usersList = fastParse(this.users);
    }
    if (
      change?.existingUsers &&
      change?.existingUsers?.currentValue &&
      !arrayIsEqual(
        change?.existingUsers?.previousValue,
        change?.existingUsers?.currentValue
      ) &&
      this.mode === notificationModalMode.EDIT
    ) {
      this.updateUsers();
    }
    if (change?.isVisible && change.isVisible.currentValue) {
      this.modalFirstOpen = true;
    }
  }

  /**
   * add selected users to list of recipients to notify
   *
   * @param {number} id
   * @memberof UserSelectionComponent
   */
  addRecipient(id: string | number, options?: MultiLevelInputOption[]) {
    const users = fastParse(this.users);
    if (id) {
      this.selectedUserIds?.push(id);
    }
    if (options?.length) {
      options.forEach((o) => this.selectedUserIds.push(o.id));
    }
    this.selectedUserIds = uniq(this.selectedUserIds);
    this.selectedUsers = users?.filter((user) =>
      this.selectedUserIds.includes(user?.id)
    );
    this.usersList = users?.filter(
      (user) => !this.selectedUserIds?.includes(user.id)
    );
    let formUsers = this.form.get(this.formName).value;
    formUsers = uniq(formUsers.concat(this.selectedUserIds));
    this.form.controls[this.formName].patchValue(formUsers);
    if (!this.modalFirstOpen) {
      this.form.markAsDirty();
    }
  }

  /**
   * remove recipient from list to notify
   *
   * @param {number} id
   * @memberof UserSelectionComponent
   */
  removeRecipient(id: string) {
    const users = fastParse(this.users);
    let formUsers = this.form.get(this.formName).value;
    this.selectedUserIds = this.selectedUserIds.filter((i) => i !== id);
    formUsers = formUsers.filter((u) => u !== id);
    this.selectedUsers = this.selectedUsers.filter((user) =>
      this.selectedUserIds.includes(user.id)
    );
    this.usersList = users.filter(
      (user) => !this.selectedUserIds.includes(user.id)
    );
    formUsers = uniq(formUsers.concat(this.selectedUserIds));
    this.form.get(this.formName).patchValue(formUsers);
    this.form.get(this.formName).updateValueAndValidity();
    this.form.markAsDirty();
  }

  /**
   * update selected users
   *
   * @memberof UserSelectionListComponent
   */
  updateUsers() {
    this.existingUsers.forEach((id) => {
      this.addRecipient(String(id));
    });
    this.modalFirstOpen = false;
  }

  /**
   * reset notifications
   *
   * @private
   * @memberof UserSelectionListComponent
   */
  private resetNotification() {
    this.usersList = fastParse(this.users);
    this.selectedUsers = [];
    this.selectedUserIds = [];
  }
}
