import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmModalService } from '../../services/confirm-modal.service';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vfi-core-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent extends BaseComponent implements OnInit {
  @Input() isVisible: boolean;
  @Output() confirmation: EventEmitter<boolean> = new EventEmitter();

  constructor(private confirmModalService: ConfirmModalService) {
    super();
  }

  ngOnInit() {
    this.confirmModalService.showConfirmModal
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => (this.isVisible = true));
  }

  /**
   * calls stay from confirmModalService and closes modal
   *
   * @memberof ConfirmModalComponent
   */
  stay() {
    this.isVisible = false;
    this.confirmation.emit(false);
    this.confirmModalService.stay();
  }

  /**
   * calls leave from confirmModalService and closes modal
   *
   * @memberof ConfirmModalComponent
   */
  leave() {
    this.isVisible = false;
    this.confirmation.emit(true);
    this.confirmModalService.leave();
  }
}
