import {
  WorkOrderDataService,
  WorkTicketDataService,
} from '@vfi-ui/data-access/shared';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  ViewChildren,
  ElementRef,
  QueryList,
} from '@angular/core';
import { take } from 'rxjs/operators';
import {
  FormGroupDirective,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ALARM_ATTRIBUTE_OPTION, CmmsDisplayTemplate } from '@vfi-ui/models';
import { fastParse, isEmpty, omit } from '@vfi-ui/util/helpers';

@Component({
  selector: 'atom-select-single',
  templateUrl: './select-single.component.html',
  styleUrls: ['./select-single.component.scss'],
})
export class SelectSingleComponent implements OnInit, OnChanges {
  @Input() inputformControlName: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() defaultLabel: string;
  @Input() defaultValue: string;
  @Input() required = false;
  @Input() disabled: boolean;
  @Input() newDisabled = false;
  @Input() customQuery: string;
  @Input() displayTemplate: CmmsDisplayTemplate;
  @Input() customQueryType: string;
  @Input() classes: string;
  @Input() focused: boolean;
  @Input() mode: 'default' | 'multiple' | 'tags' = 'default';
  @Input() customValidator: boolean;
  @Input() clearValueOnChange = false;
  @Input() useAlarmAttributeOption = false;
  @Input() inputId: string;
  @Input() cmmsUseIds = false;
  @Input() showTooltip = false;
  @Input() cmmsValueSort = false;
  @Output() inputChanged: EventEmitter<string | string[]> = new EventEmitter();
  @Output() enterEvent: EventEmitter<string> = new EventEmitter();
  @Output() focusChanged: EventEmitter<string> = new EventEmitter();
  @ViewChildren('input') input: QueryList<ElementRef>;
  childForm;
  baseOptions = [];
  options = [];
  copyOptions = [];
  optionsTotalCount = 0;
  searchValue = null;
  loading = false;
  people = [
    {
      id: '5a15b13c36e7a7f00cf0d7cb',
      index: 2,
      isActive: true,
      picture: 'http://placehold.it/32x32',
      age: 23,
      name: 'Karyn Wright',
      gender: 'female',
      company: 'ZOLAR',
      email: 'karynwright@zolar.com',
      phone: '+1 (851) 583-2547',
    },
    {
      id: '5a15b13c2340978ec3d2c0ea',
      index: 3,
      isActive: false,
      picture: 'http://placehold.it/32x32',
      age: 35,
      name: 'Rochelle Estes',
      disabled: true,
      gender: 'female',
      company: 'EXTRAWEAR',
      email: 'rochelleestes@extrawear.com',
      phone: '+1 (849) 408-2029',
    },
  ];

  constructor(
    private parentForm: FormGroupDirective,
    private changeDetection: ChangeDetectorRef,
    private workOrderService: WorkOrderDataService,
    private workTicketService: WorkTicketDataService
  ) {}

  ngOnInit() {
    if (this.inputformControlName) {
      this.setFormGroup();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      if (this.customQuery === 'cmms') {
        this.options.push({ name: this.defaultLabel, value: this.value });
        this.copyOptions.push({ name: this.defaultLabel, value: this.value });
      } else {
        this.options.push(this.value);
        this.copyOptions.push(this.value);
      }
    }
    if (changes.name && this.clearValueOnChange) {
      this.value = null;
    }
  }

  /**
   * set set child form group
   *
   * @memberof SelectSingleComponent
   */
  setFormGroup() {
    this.childForm = this.parentForm.form;
    if (this.customValidator && this.inputformControlName) {
      this.childForm.controls[this.inputformControlName].setValidators([
        Validators.required,
        this.inputValidator.bind(this),
      ]);
    }
  }

  /**
   * fetch custom query if it is available
   *
   * @param {string} $event
   * @memberof SelectSingleComponent
   */
  fetchFields($event: string, offset = 0) {
    this.searchValue = $event;
    this.focused = true;
    this.loading = true;
    if (this.customQuery === 'workDetails') {
      this.getRelationalWorkOrderAutoCompleteFields($event, offset);
    } else if (this.customQuery === 'cmms') {
      this.getCmmsFieldOptions(
        $event,
        this.inputId,
        this.cmmsValueSort,
        offset
      );
    }

    this.focusChanged.emit(this.name);
  }

  onScroll(search: string) {
    if (this.baseOptions.length !== this.optionsTotalCount) {
      this.fetchFields(search, this.baseOptions.length);
    }
  }

  /**
   * sets the options for the attributes
   *
   * @param data
   * @memberof SelectSingleComponent
   */
  setAttributeOptions(data) {
    this.options = fastParse(data);
    if (this.useAlarmAttributeOption) {
      this.options.unshift(ALARM_ATTRIBUTE_OPTION);
    }
    this.loading = false;
    if (!isEmpty(this.options)) {
      this.copyOptions = [...this.copyOptions, ...this.options];
    }
    this.changeDetection.detectChanges();
  }

  /**
   * call data service to fetch dynamic cmms options
   *
   * @param {string} event
   * @param {string} externalCmmsFieldId
   * @param {boolean} useValueSort
   * @param {number} [offset=0]
   * @memberof SelectSingleComponent
   */
  getCmmsFieldOptions(
    event: string,
    externalCmmsFieldId: string,
    useValueSort: boolean,
    offset = 0
  ) {
    const search = event || '';
    const externalDetails = this.childForm?.value || {};
    this.workTicketService
      .fetchCmmsFieldOptions({
        externalCmmsFieldId,
        search,
        externalDetails,
        useValueSort,
        offset,
      })
      .pipe(take(1))
      .subscribe((data) => {
        const cmmsFields =
          offset > 0
            ? [...this.baseOptions, ...data.externalCmmsDomainValues]
            : [...data.externalCmmsDomainValues];
        this.baseOptions = fastParse(cmmsFields);
        this.options = cmmsFields;
        this.optionsTotalCount = data.externalCmmsDomainValueCount;
        if (this.useAlarmAttributeOption) {
          this.options.unshift({
            name: ALARM_ATTRIBUTE_OPTION,
            value: ALARM_ATTRIBUTE_OPTION,
          });
        }
        this.loading = false;
        if (!isEmpty(this.options)) {
          this.copyOptions = this.options;
        }
        this.changeDetection.detectChanges();
      });
  }

  /**
   * calls work order service to query for work details auto-complete fields
   *
   * @param {string} event
   * @param {number} [offset=0]
   * @memberof SelectSingleComponent
   */
  getRelationalWorkOrderAutoCompleteFields(event: string, offset = 0) {
    const formData = this.inputformControlName
      ? omit(this.childForm.value, ['timeScheduled', 'timeRequired'])
      : {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] === ALARM_ATTRIBUTE_OPTION) {
        formData[key] = null;
      }
    });

    const field = this.name;
    const value = event || '';
    this.workOrderService
      .getRelationalWorkOrderAutoCompleteFields({
        field,
        filters: value,
        data: formData,
        offset,
      })
      .pipe(take(1))
      .subscribe((data) => {
        const workFields = offset
          ? [...this.baseOptions, ...data.currentWorkOrderDomain]
          : [...data.currentWorkOrderDomain];
        this.baseOptions = fastParse(workFields);
        this.options = workFields;
        this.optionsTotalCount = data.currentWorkOrderDomainCount;
        if (this.useAlarmAttributeOption) {
          this.options.unshift(ALARM_ATTRIBUTE_OPTION);
        }
        this.loading = false;
        if (!isEmpty(this.options)) {
          this.copyOptions = this.options;
        }
        this.changeDetection.detectChanges();
      });
  }

  /**
   * emits value when changed
   *
   * @param {string} value
   * @memberof SelectSingleComponent
   */
  valueChanged(value: string) {
    if (this.childForm && this.inputformControlName) {
      this.childForm.patchValue({
        [this.inputformControlName]: value,
      });
      this.childForm.markAsDirty();
      this.childForm.controls[this.inputformControlName].markAsDirty();
    }
    this.inputChanged.emit(value);
  }

  /**
   * checks if the entered input is valid, clear input if not valid
   *
   * @param {*} event
   * @memberof SelectSingleComponent
   */
  checkValidInput(event: string[] | string) {
    this.focused = false;
    const input = Array.isArray(event) ? event : [event];
    let included = input.filter((opt) => {
      if (this.customQuery === 'cmms') {
        return this.copyOptions.find((o) => o?.value === opt);
      } else {
        return this.copyOptions.includes(opt);
      }
    });
    included = included.length ? included : [null];
    this.updateValidInput(included);
    this.focusChanged.emit('');
  }

  /**
   * return option label
   *
   * @param {*} option
   * @param {string} customQuery
   * @returns
   * @memberof SelectSingleComponent
   */
  getOptionLabel(option, customQuery: string) {
    let op = option;
    if (customQuery === 'cmms') {
      op = option?.name;
      if (this.displayTemplate === CmmsDisplayTemplate.KeyValue) {
        op = `${option?.value} - ${option?.name}`;
      } else if (this.displayTemplate === CmmsDisplayTemplate.Value) {
        op = `${option?.value}`;
      }
    }
    return op;
  }

  /**
   * return disabled readOnly value
   *
   * @param {string} customQuery
   * @param {string} defaultLabel
   * @param {string} defaultValue
   * @param {string} value
   * @returns
   * @memberof SelectSingleComponent
   */
  getDisabledValue(
    customQuery: string,
    defaultLabel: string,
    defaultValue: string,
    value: string
  ) {
    let val = value;
    if (customQuery === 'cmms') {
      val = defaultLabel;
      if (this.displayTemplate === CmmsDisplayTemplate.KeyValue) {
        val = `${defaultValue} - ${defaultLabel}`;
      } else if (this.displayTemplate === CmmsDisplayTemplate.Value) {
        val = `${defaultValue}`;
      }
    }
    return [val];
  }

  /**
   * handle dropdown close event
   *
   * @memberof SelectSingleComponent
   */
  handleCloseChange() {
    this.baseOptions = [];
    this.optionsTotalCount = 0;
    this.searchValue = null;
  }

  /**
   * update valid input
   *
   * @private
   * @param {*} included
   * @memberof SelectSingleComponent
   */
  private updateValidInput(included) {
    if (this.inputformControlName) {
      this.childForm.patchValue({
        [this.inputformControlName]:
          included.length === 1 ? included[0] : included,
      });
    } else {
      this.value = included.length === 1 ? included[0] : included;
    }
  }

  /**
   * custom validator to check if the entered input is valid
   *
   * @private
   * @param {*} control
   * @returns {(ValidationErrors | null)}
   * @memberof SelectSingleComponent
   */
  private inputValidator(control): ValidationErrors | null {
    if (control.value !== '') {
      if (this.copyOptions.includes(control.value)) {
        return null;
      }
      return { inputValid: false };
    }
    return { inputValid: false };
  }
}
