export interface SpaceType {
  id: string;
  name: string;
  criticality?: number;
  associatedEntities?: number;
}

export interface SpaceTypeQuery {
  spaceTypeCount: number;
  spaceTypes: SpaceType[];
}
