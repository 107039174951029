<div class="d-flex align-items-center pagination">
  <nz-pagination
    aria-label="pagination"
    [(nzPageIndex)]="pageIndex"
    [nzTotal]="totalItems"
    [ngClass]="{ disableInput: disabled }"
    [nzPageSize]="pageLimit"
    [nzSize]="'small'"
    nzSimple
    (nzPageIndexChange)="pageChanged.emit($event)"
  ></nz-pagination>
  <nz-select
    *ngIf="allowPageChange"
    class="iml-2"
    aria-label="pageSize"
    [nzSize]="'small'"
    [(ngModel)]="pageLimit"
    (ngModelChange)="pageSizeChanged.emit($event)"
  >
    <nz-option
      nzLabel="{{ option }} / page"
      [nzValue]="option"
      *ngFor="let option of pageOptions"
    >
    </nz-option>
  </nz-select>
</div>
