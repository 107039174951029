import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'nuclei-include-exclude',
  templateUrl: './include-exclude.component.html',
  styleUrls: ['./include-exclude.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncludeExcludeComponent implements OnChanges {
  @Output() selected: EventEmitter<string> = new EventEmitter();
  @Input() isExclusion: boolean;
  @Input() disabled: boolean;
  @Input() option = ['includes', 'excludes'];

  value: string = this.option[0];
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isExclusion?.currentValue) {
      this.value = this.option[1];
    } else {
      this.value = this.option[0];
    }
  }

  /**
   * select options for selected value
   *
   * @param { string } option
   * @memberof IncludeExcludeComponent
   */
  optionSelected(option: string): void {
    this.value = option;
    this.selected.emit(this.value);
  }
}
