import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  AlarmInstance,
  AlarmActivity,
  AlarmProperty,
  MaxAlarmsLimit,
  CoreAlarm,
  CoreAlarmsOptions,
  AlarmsAndCountQuery,
  AlarmsAndCountResolve,
  CoreAlarmsWhere,
  CoreAlarmsOrderSortDirection,
  CriterionSelection,
  MAX_TABLE_LIMIT,
  AlarmActivityAndCountQuery,
  AlarmPriorityNames,
  AlarmActivityValues,
  AlarmConfiguration,
  BaseAlarmProperty,
  VFI_SUPPORT,
  ERROR_GET_ALARM_DETAILS,
  ERROR_PIN_ALARMS,
  ERROR_CHANGE_STATUS,
  ERROR_ADD_ALARM_DETAILS_PROPERTY_CONFIGURATION,
  ERROR_CHANGE_ALARM_DETAILS_PROPERTY_CONFIGURATION,
  ERROR_CHANGE_PRIORITY,
  ERROR_GET_ALARM_TILES,
  AlarmInstancesAndCountQuery,
  ERROR_GET_ALARM_RAW_ATTRIBUTES,
  RawAttributes,
  GlobalFilterSort,
  AlarmLens,
  MaxAlarmPropertyLimit,
  CUSTOMER_ID_HEADER,
  ERROR_BULK_UPDATE_ALARMS,
  ERROR_UPDATE_ALARM_NOTES,
  Entity,
  OrderDirection,
  ERROR_UPDATE_ALARM_DETAILS,
  AlarmDetailsUpdate,
  AlarmInstanceCount,
  ERROR_DOWNLOAD_CSV,
  EntityListQuery,
  AlarmPropertyQuery,
  FastAlarm,
  FastAlarmsAndCountQuery,
  FastAlarmsAndCountResolve,
  ERROR_UPDATE_CONTEXTUAL_NAME,
  Alarm,
  PointQuery,
  ERROR_POINTS,
} from '@vfi-ui/models';
import { map, catchError, tap, take, filter } from 'rxjs/operators';
import {
  GET_INSTANCE_HISTORY_QUERY,
  GET_ALARM_ACTIVITY_QUERY,
  GET_AUTO_COMPLETE_QUERY,
  GET_ALARM_OCCURRENCES_QUERY,
  PIN_ALARMS_QUERY,
  SAVE_ALARM_CONFIG_QUERY,
  MOVE_ALARMS_QUERY,
  ALARMS_QUERY,
  ALARMS_QUERY_COUNTS,
  GET_ALARM_STATUSE_QUERY,
  ALARMS_QUERY_FOR_TILE_LIST,
  GET_ALARM_PROPERTIES_QUERY,
  UPDATE_ALARM_PRIORITY_QUERY,
  ADD_ALARM_PROPERTY,
  UPDATE_ALARM_PROPERTY_QUERY,
  BULK_UPDATE_ALARMS_QUERY,
  WORK_TIMELINE_ALARMS_QUERY,
  ALARM_RAW_ATTRIBUTES_QUERY,
  GET_CUSTOM_STATUSES,
  CREATE_ALARM_STATUS,
  UPDATE_ALARM_STATUS,
  MERGE_ALARM_PROPERTY_QUERY,
  UPDATE_ALARM_NOTES_QUERY,
  GET_ALARM_ENTITIES,
  UPDATE_ALARM_POINT_ENTITY_MUTATION,
  UPDATE_ALARM_DETAILS_MUTATION,
  DELETE_ALARM_STATUS,
  SNOOZE_ALARM_MUTATION,
  UNSNOOZE_ALARM_MUTATION,
  GET_ENTITIES,
  FAST_ALARMS_QUERY,
  FAST_ALARM_QUERY_FOR_TILE_LIST,
  UPDATE_ALARM_CONTEXTUAL_NAME_MUTATION,
  GET_POINTS_QUERY,
} from './../queries/alarms.query';
import { GlobalFiltersService } from './global-filters.service';
import { NotificationService } from './notification.service';
import {
  fastParse,
  handleBlank,
  safeParseBoolean,
  startCase,
  snakeCase,
  result,
  uniq,
  downloadFile,
} from '@vfi-ui/util/helpers';
import { ObjectControlDataService } from './object-control-data.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@vfi-ui/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AlarmsDataService {
  abortController = new AbortController();
  context = {
    context: {
      fetchOptions: {
        signal: this.abortController.signal,
      },
    },
  };

  constructor(
    private apollo: Apollo,
    private globalFiltersService: GlobalFiltersService,
    private notification: NotificationService,
    private objectControlDataService: ObjectControlDataService,
    private http: HttpClient
  ) {}

  /**
   * use abort controller to abort pending request
   *
   * @memberof AlarmsDataService
   */
  abortEffect() {
    this.abortController.abort();
  }

  /**
   * query for alarms
   *
   * @param {*} { limit, offset, searchTerm, criterion, sort, lens, alarmProperties }
   * @param {*} route
   * @returns {Observable<AlarmsAndCountResolve>}
   * @memberof AlarmsDataService
   */
  getAlarms({
    limit,
    offset,
    searchTerm,
    criterion,
    sort,
    lens,
    alarmProperties,
  }): Observable<AlarmsAndCountResolve> {
    const options = this.generateAlarmsAndCountOptions(
      limit,
      offset,
      criterion,
      searchTerm,
      sort,
      lens,
      alarmProperties
    );

    return this.apollo
      .query<AlarmsAndCountQuery>({
        fetchPolicy: 'network-only',
        query: ALARMS_QUERY_FOR_TILE_LIST,
        variables: {
          options,
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_GET_ALARM_TILES);
          return throwError(error);
        }),
        map((res) => {
          const mappedAlarms = res.data.alarmsAndCount.Alarm.map((alarm) =>
            this.appendToAlarm(alarm)
          );
          return {
            Alarm: mappedAlarms,
            count: res.data.alarmsAndCount.count,
            loading: res.loading,
            alarmQueryWhere: options?.where,
          };
        })
      );
  }

  /**
   * Gets fast alarms and count
   *
   * @param {*} {
   *     limit,
   *     offset,
   *     searchTerm,
   *     criterion,
   *     sort,
   *     lens,
   *     alarmProperties,
   *   }
   * @return {*}  {Observable<FastAlarmsAndCountQuery>}
   * @memberof AlarmsDataService
   */
  getFastAlarmsAndCount({
    limit,
    offset,
    searchTerm,
    criterion,
    sort,
    lens,
    alarmProperties,
  }): Observable<FastAlarmsAndCountResolve> {
    const options = this.generateAlarmsAndCountOptions(
      limit,
      offset,
      criterion,
      searchTerm,
      sort,
      lens,
      alarmProperties
    );

    return this.apollo
      .query<FastAlarmsAndCountQuery>({
        fetchPolicy: 'network-only',
        query: FAST_ALARM_QUERY_FOR_TILE_LIST,
        variables: {
          options,
          countOptions: { ...options, limit: 0, offset: 0 },
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_GET_ALARM_TILES);
          return throwError(error);
        }),
        map((res) => {
          const mappedAlarms = res.data.fastAlarms.map((alarm) =>
            this.appendToAlarm(alarm)
          );
          return {
            Alarm: mappedAlarms,
            count: res.data.alarmsAndCount.count,
            loading: res.loading,
            alarmQueryWhere: options?.where,
          };
        })
      );
  }

  /**
   * query for alarms but faster
   *
   * @param {*} {
   *     limit,
   *     offset,
   *     searchTerm,
   *     criterion,
   *     sort,
   *     lens,
   *     alarmProperties,
   *   }
   * @returns {Observable<FastAlarm[]>}
   * @memberof AlarmsDataService
   */
  getFastAlarms({
    limit,
    offset,
    searchTerm,
    criterion,
    sort,
    lens,
    alarmProperties,
  }): Observable<FastAlarm[]> {
    const options = this.generateAlarmsAndCountOptions(
      limit,
      offset,
      criterion,
      searchTerm,
      sort,
      lens,
      alarmProperties
    );

    return this.apollo
      .query<{ fastAlarms: FastAlarm[] }>({
        fetchPolicy: 'network-only',
        query: FAST_ALARMS_QUERY,
        variables: {
          options,
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_GET_ALARM_TILES);
          return throwError(error);
        }),
        map((res) => res?.data?.fastAlarms)
      );
  }

  /**
   * query for total alarms counts by route for all alarms
   *
   * @param {*} { route }
   * @param {CriterionSelection} [criterion]
   * @param {AlarmProperty} [alarmProperties]
   * @returns {Observable<number>}
   * @memberof AlarmsDataService
   */
  getAlarmTotalCountsAll(
    criterion: CriterionSelection[],
    alarmProperties: AlarmProperty[]
  ): Observable<number> {
    const where: CoreAlarmsWhere = {};
    const routeWhere = this.globalFiltersService.createFiltersWhere(
      { criterion },
      alarmProperties
    );
    const options = {
      where: { ...where, ...routeWhere?.where },
      whereNot: routeWhere?.whereNot,
      limit: 0,
    };
    return this.apollo
      .query<AlarmsAndCountQuery>({
        fetchPolicy: 'network-only',
        query: ALARMS_QUERY_COUNTS,
        variables: {
          options,
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res.data.alarmsAndCount.count)
      );
  }

  /**
   * query for Alarm by ID
   *
   * @param {(number | string)} [id]
   * @param {number} [offset=0]
   * @returns {Observable<AlarmsAndCountResolve>}
   * @memberof AlarmsDataService
   */
  getAlarm(
    id?: number | string,
    offset = 0
  ): Observable<AlarmsAndCountResolve> {
    const where = id
      ? {
          ids: [+id],
        }
      : {};
    return this.apollo
      .query<AlarmsAndCountQuery>({
        fetchPolicy: 'network-only',
        query: ALARMS_QUERY,
        variables: {
          options: {
            where,
            offset,
            limit: 50,
          },
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => {
          const mappedAlarms = res.data.alarmsAndCount.Alarm.map((alarm) =>
            this.appendToAlarm(alarm)
          );
          return {
            Alarm: mappedAlarms,
            count: res.data.alarmsAndCount.count,
            loading: res.loading,
          };
        }),
        catchError((error) => {
          this.notification.showError(ERROR_GET_ALARM_DETAILS(id));
          return throwError(error);
        })
      );
  }

  /**
   * get alarms by ids
   *
   * @param {number[]} ids
   * @returns {Observable<CoreAlarm[]>}
   * @memberof AlarmsDataService
   */
  getAlarmsByIds(ids: number[]): Observable<CoreAlarm[]> {
    const options: CoreAlarmsOptions = {
      where: {
        ids,
      },
    };
    return this.apollo
      .query<{ alarmsAndCount: { Alarm: CoreAlarm[] } }>({
        fetchPolicy: 'network-only',
        query: ALARMS_QUERY,
        variables: {
          options,
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.alarmsAndCount?.Alarm)
      );
  }

  /**
   * fetch alarm raw attributes
   *
   * @param {(number | string)} id
   * @returns {*}
   * @memberof AlarmsDataService
   */
  getAlarmRawAttributes(id: number | string): Observable<RawAttributes[]> {
    const options: CoreAlarmsOptions = {
      where: {
        ids: [+id],
      },
    };
    return this.apollo
      .query<AlarmsAndCountQuery>({
        fetchPolicy: 'no-cache',
        query: ALARM_RAW_ATTRIBUTES_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) =>
          result(res, 'data.alarmsAndCount.Alarm[0].rawAttributes', [])
        ),
        catchError((error) => {
          this.notification.showError(ERROR_GET_ALARM_RAW_ATTRIBUTES);
          return throwError(error);
        })
      );
  }

  /**
   * query for alarm instance history by ID
   *
   * @param {(number | string)} id
   * @param {number} [offset=0]
   * @returns {Observable<AlarmInstancesAndCountQuery>}
   * @memberof AlarmsDataService
   */
  getAlarmInstanceHistory(
    id: number | string,
    offset: number = 0
  ): Observable<AlarmInstancesAndCountQuery> {
    const limit = MAX_TABLE_LIMIT;
    return this.apollo
      .query<{ alarmInstancesAndCount: { items: AlarmInstance[] } }>({
        fetchPolicy: 'network-only',
        query: GET_INSTANCE_HISTORY_QUERY,
        variables: {
          options: {
            where: { alarmIds: [+id] },
            order: { field: 'ALARM_TIME', direction: 'DESC' },
            offset,
            limit,
          },
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        map((instance) => ({
          alarmInstancesAndCount: {
            Alarm: this.mapProcessVariableValues(
              result(instance, 'data.alarmInstancesAndCount.items', [])
            ),
            count: result(instance, 'data.alarmInstancesAndCount.count'),
            loading: instance.loading,
          },
        })),
        catchError((error) => throwError(error))
      );
  }

  /**
   * query alarm activity by ID
   *
   * @param {(number | string)} id
   * @param {number} [page]
   * @returns {Observable<AlarmActivityAndCountQuery>}
   * @memberof AlarmsDataService
   */
  getAlarmActivity(
    id: number | string,
    page: number = 1,
    isSuper: boolean
  ): Observable<AlarmActivityAndCountQuery> {
    const limit = MAX_TABLE_LIMIT;
    const offset = page === 1 ? 0 : page * limit - limit;
    return this.apollo
      .query<{ alarmActivityAndCount: { items: AlarmActivity[] } }>({
        fetchPolicy: 'network-only',
        query: GET_ALARM_ACTIVITY_QUERY,
        variables: {
          options: {
            where: { alarmIds: [+id] },
            order: {
              field: 'CREATED_AT',
              direction: CoreAlarmsOrderSortDirection.desc,
            },
            offset,
            limit,
          },
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => ({
          activity: this.mapAlarmActivity(
            result(activity, 'data.alarmActivityAndCount.items', []),
            isSuper
          ),
          count: result(activity, 'data.alarmActivityAndCount.count'),
        })),
        catchError((error) => throwError(error))
      );
  }

  /**
   * fetch alarm change history
   *
   * @param {(number | string)} id
   * @param {number} offset
   * @param {boolean} isSuper
   * @returns {Observable<AlarmActivityAndCountQuery>}
   * @memberof AlarmsDataService
   */
  getAlarmChangeHistory(
    id: number | string,
    offset: number,
    isSuper: boolean
  ): Observable<AlarmActivityAndCountQuery> {
    const limit = 20;
    return this.apollo
      .query<{ alarmActivityAndCount: { items: AlarmActivity[] } }>({
        fetchPolicy: 'network-only',
        query: GET_ALARM_ACTIVITY_QUERY,
        variables: {
          options: {
            where: { alarmIds: [+id] },
            order: {
              field: 'CREATED_AT',
              direction: CoreAlarmsOrderSortDirection.desc,
            },
            offset,
            limit,
          },
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => ({
          activity: this.mapAlarmActivity(
            result(activity, 'data.alarmActivityAndCount.items', []),
            isSuper
          ),
          count: result(activity, 'data.alarmActivityAndCount.count'),
        })),
        catchError((error) => throwError(error))
      );
  }

  /**
   * fetch alarm auto complete fields
   *
   * @param {string} property
   * @param {string} [value]
   * @returns {Observable<string[]>}
   * @memberof AlarmsDataService
   */
  getAlarmAutoComplete(property: string, value?: string): Observable<string[]> {
    const valueLike = value ? { valueLike: `%${value}%` } : {};
    const type = { types: [snakeCase(property).toLowerCase()] };
    return this.apollo
      .query<{ alarmPropertiesAndCount: { items: AlarmProperty[] } }>({
        fetchPolicy: 'network-only',
        query: GET_AUTO_COMPLETE_QUERY,
        variables: {
          options: {
            where: { ...type, ...valueLike },
            limit: MaxAlarmsLimit,
            order: { field: 'VALUE', direction: 'ASC' },
          },
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        map((activity) =>
          activity.data.alarmPropertiesAndCount.items.map((item) => item.value)
        ),
        catchError((error) => throwError(error))
      );
  }

  /**
   * fetch alarm auto complete fields
   *
   * @param {{
   *     property: string;
   *     value?: string;
   *     loadAll?: boolean;
   *     customHeader?: string;
   *     dbName?: string;
   *   }} {
   *     property,
   *     value,
   *     loadAll,
   *     customHeader,
   *     dbName,
   *   }
   * @returns {Observable<AlarmProperty[]>}
   * @memberof AlarmsDataService
   */
  getAlarmAutoCompleteWithId({
    property,
    offset = 0,
    value,
    loadAll,
    customHeader,
    dbName,
    assetClass,
  }: {
    property: string;
    offset: number;
    value?: string;
    loadAll?: boolean;
    customHeader?: string;
    dbName?: string;
    assetClass?: string;
  }): Observable<AlarmPropertyQuery> {
    const valueLike = value ? { valueLike: `%${value}%` } : {};
    const type = {
      types: dbName ? [dbName] : [property.toLowerCase()],
      assetClass,
    };
    const context = {
      headers: {
        [CUSTOMER_ID_HEADER]: customHeader,
      },
    };
    return this.apollo
      .query<{
        alarmPropertiesAndCount: { items: AlarmProperty[]; count: number };
      }>({
        fetchPolicy: 'network-only',
        query: GET_AUTO_COMPLETE_QUERY,
        variables: {
          options: {
            where: { ...type, ...valueLike },
            limit: loadAll ? null : MaxAlarmPropertyLimit,
            offset,
            order: { field: 'VALUE', direction: 'ASC' },
          },
        },
        context: customHeader ? context : {},
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => ({
          items: activity.data.alarmPropertiesAndCount.items,
          count: activity.data.alarmPropertiesAndCount.count,
        })),
        catchError((error) => throwError(error))
      );
  }

  /**
   * query for alarm occurrences
   *
   * @param {number | string} id
   * @param {Date} now
   * @param {Date} then
   * @returns
   * @memberof AlarmsDataService
   */
  getAlarmOccurrences({
    id,
    now,
    then,
    timezone,
  }: {
    id: number | string;
    now: Date;
    then: Date;
    timezone: string;
  }): Observable<AlarmInstanceCount[]> {
    return this.apollo
      .query<{ alarmInstanceDaily: AlarmInstanceCount[] }>({
        fetchPolicy: 'network-only',
        query: GET_ALARM_OCCURRENCES_QUERY,
        variables: {
          alarmId: +id,
          range: { from: then, to: now },
          timezone,
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => activity.data.alarmInstanceDaily),
        catchError((error) => throwError(error))
      );
  }

  /**
   * query to work timeline alarms
   *
   * @param {((number | string)[])} ids
   * @param {Date} now
   * @param {Date} then
   * @returns {Observable<AlarmInstance[]>}
   * @memberof AlarmsDataService
   */
  getWorkTimelineAlarms(
    ids: (number | string)[],
    now: Date,
    then: Date
  ): Observable<AlarmInstance[]> {
    return this.apollo
      .query<{ alarmInstancesAndCount: { items: AlarmInstance[] } }>({
        fetchPolicy: 'no-cache',
        query: WORK_TIMELINE_ALARMS_QUERY,
        variables: {
          options: {
            where: {
              alarmIds: ids,
              activeWindow: { from: then, to: now },
            },
            order: { field: 'ALARM_TIME', direction: 'ASC' },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((activity) =>
          result(activity, 'data.alarmInstancesAndCount.items', [])
        ),
        catchError((error) => throwError(error))
      );
  }

  /**
   * query for dynamic alarm properties
   *
   * @returns {Observable<string[]>}
   * @memberof AlarmsDataService
   */
  getDynamicAlarmProperties(): Observable<AlarmProperty[]> {
    return this.apollo
      .query<{ alarmPropertyTypesAndCount: { items: AlarmProperty[] } }>({
        fetchPolicy: 'no-cache',
        query: GET_ALARM_PROPERTIES_QUERY,
      })
      .pipe(
        filter((d) => !!d),
        map((properties) =>
          result(properties, 'data.alarmPropertyTypesAndCount.items', [])
        ),
        catchError((error) => throwError(error))
      );
  }

  /**
   * post pinned alarm to backend
   *
   * @param {((string | number)[])} ids
   * @param {boolean} pin
   * @returns
   * @memberof AlarmsDataService
   */
  postPinnedAlarms(ids: (string | number)[], pin: boolean) {
    return this.apollo
      .mutate({
        mutation: PIN_ALARMS_QUERY,
        variables: { ids, pin },
      })
      .pipe(
        filter((d) => !!d),
        tap(() => this.notification.showSuccess('Pin Alarms')),
        catchError((error) => {
          this.notification.showError(ERROR_PIN_ALARMS);
          return throwError(error);
        })
      );
  }

  /**
   * move alarms between lens
   *
   * @param {((string | number)[])} ids
   * @param {string} status
   * @returns
   * @memberof AlarmsDataService
   */
  moveAlarms(ids: (string | number)[], status: string, reason?: string) {
    return this.apollo
      .mutate({
        mutation: MOVE_ALARMS_QUERY,
        variables: { input: { ids, status, reason } },
      })
      .pipe(
        filter((d) => !!d),
        tap(() => this.notification.showSuccess('Change Status')),
        catchError((error) => {
          this.notification.showError(ERROR_CHANGE_STATUS);
          return throwError(error);
        })
      );
  }

  /**
   * post priority changes for alarm to backend
   *
   * @param {((string | number))} id
   * @param {CoreAlarmConfiguration} configuration
   * @returns
   * @memberof AlarmsDataService
   */
  saveAlarmPropertyConfiguration(
    id: string | number,
    configuration: AlarmConfiguration[]
  ) {
    return this.apollo
      .mutate({
        mutation: SAVE_ALARM_CONFIG_QUERY,
        variables: { id, configuration },
      })
      .pipe(
        filter((d) => !!d),
        tap(() => this.notification.showSuccess('Alarm details')),
        map((res) => result(res, 'data.updateAlarmPropertyConfiguration', [])),
        catchError((error) => {
          this.notification.showError(
            ERROR_CHANGE_ALARM_DETAILS_PROPERTY_CONFIGURATION
          );
          return throwError(error);
        })
      );
  }

  /**
   * save alarm notes
   *
   * @param {number} id
   * @param {string} notes
   * @returns
   * @memberof AlarmsDataService
   */
  saveAlarmNotes(id: number, notes: string) {
    return this.apollo
      .mutate({
        mutation: UPDATE_ALARM_NOTES_QUERY,
        variables: { id, notes },
      })
      .pipe(
        filter((d) => !!d),
        tap(() => this.notification.showSuccess('Alarm Notes')),
        map((res) => result(res, 'data.updateAlarmNotes', [])),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_ALARM_NOTES);
          return throwError(error);
        })
      );
  }

  /**
   * add alarm properties
   *
   * @param {BaseAlarmProperty} data
   * @returns
   * @memberof AlarmsDataService
   */
  addAlarmProperty(data: BaseAlarmProperty, customHeader?: string) {
    const context = {
      headers: {
        [CUSTOMER_ID_HEADER]: customHeader,
      },
    };
    data.values = uniq(data.values);
    return this.apollo
      .mutate({
        mutation: ADD_ALARM_PROPERTY,
        variables: data,
        context: customHeader ? context : {},
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          if (
            error &&
            error.message &&
            error.message.match(
              /One or more alarm property values for type [a-z_\s]+ already exists/
            )
          ) {
            // fail silently when duplicate error
            return;
          }
          this.notification.showError(
            ERROR_ADD_ALARM_DETAILS_PROPERTY_CONFIGURATION
          );
          return throwError(error);
        })
      );
  }

  /**
   * get alarm statuses
   *
   * @returns {Observable<string[]>}
   * @memberof AlarmsDataService
   */
  getAlarmStatuses(): Observable<string[]> {
    return this.apollo
      .query<AlarmsAndCountQuery>({
        fetchPolicy: 'network-only',
        query: GET_ALARM_STATUSE_QUERY,
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.alarmStatuses', [])),
        catchError((error) => throwError(error))
      );
  }

  /**
   * update alarm priority
   *
   * @param {number[]} ids
   * @param {number} priority
   * @returns
   * @memberof AlarmsDataService
   */
  updateAlarmPriority(ids: number[], priority: number) {
    return this.apollo
      .mutate({
        mutation: UPDATE_ALARM_PRIORITY_QUERY,
        variables: { ids, priority },
      })
      .pipe(
        filter((d) => !!d),
        tap(() => this.notification.showSuccess('Alarm details')),
        catchError((error) => {
          this.notification.showError(ERROR_CHANGE_PRIORITY);
          return throwError(error);
        })
      );
  }

  /**
   * update an alarm property
   *
   * @param {number} id
   * @param {string} value
   * @returns
   * @memberof AlarmsDataService
   */
  updateAlarmProperty(id: number, value: string) {
    return this.apollo
      .mutate({
        mutation: UPDATE_ALARM_PROPERTY_QUERY,
        variables: { id: String(id), data: { value } },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(
            ERROR_ADD_ALARM_DETAILS_PROPERTY_CONFIGURATION
          );
          return throwError(error);
        })
      );
  }

  /**
   * merge alarm properties
   *
   * @param {string} fromId
   * @param {string} toId
   * @returns
   * @memberof AlarmsDataService
   */
  mergeAlarmProperty(fromId: string, toId: string) {
    return this.apollo
      .mutate({
        mutation: MERGE_ALARM_PROPERTY_QUERY,
        variables: { fromId, toId },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(
            ERROR_ADD_ALARM_DETAILS_PROPERTY_CONFIGURATION
          );
          return throwError(error);
        })
      );
  }

  /**
   * bulk update alarms
   *
   * @param {*} input
   * @returns
   * @memberof AlarmsDataService
   */
  updateAlarms(input) {
    const mutation = this.apollo
      .mutate({
        mutation: BULK_UPDATE_ALARMS_QUERY,
        variables: { input },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_BULK_UPDATE_ALARMS);
          return throwError(error);
        })
      );
    const sockets = this.objectControlDataService.listenToSocketEvents({
      successMsg: 'Alarm Details',
      initiator$: mutation,
    });
    return sockets.pipe(
      filter((d) => !!d),
      take(1)
    );
  }

  /**
   * bulk update alarm
   *
   * @param {*} input
   * @returns
   * @memberof AlarmsDataService
   */
  updateAlarm(input) {
    return this.apollo
      .mutate({
        mutation: BULK_UPDATE_ALARMS_QUERY,
        variables: { input },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_BULK_UPDATE_ALARMS);
          return throwError(error);
        })
      );
  }

  /**
   * fetch alarm custom system lenses
   *
   * @returns {Observable<string[]>}
   * @memberof AlarmsDataService
   */
  getCustomAlarmSystemLenses(): Observable<string[]> {
    return this.http
      .request<{
        data: { alarmStatusesCustom: string[] };
      }>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: GET_CUSTOM_STATUSES,
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.alarmStatusesCustom)
      );
  }

  /**
   * create alarm status
   *
   * @param {string} status
   * @returns
   * @memberof AlarmsDataService
   */
  createAlarmStatus(status: string) {
    return this.http
      .request<any>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: CREATE_ALARM_STATUS,
          variables: { status },
        }),
      })
      .pipe(
        filter((d) => !!d),
        tap((res) => {
          if (res.errors) {
            this.notification.showError(
              'Custom System Lenses',
              `Custom system lens "${status}" already exists.`
            );
          }
        }),
        catchError((err) => {
          this.notification.showError(
            'Custom System Lenses',
            'Failed to create custom system lens. Please try again later.'
          );
          return throwError(err);
        })
      );
  }

  /**
   * delete alarm status
   *
   * @param {string} status
   * @returns
   * @memberof AlarmsDataService
   */
  deleteAlarmStatus(status: string) {
    return this.http
      .request<any>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: DELETE_ALARM_STATUS,
          variables: { status },
        }),
      })
      .pipe(
        filter((d) => !!d),
        tap(() => {
          this.notification.showSuccess(
            'Custom System Lenses',
            `Custom system lens "${status}" deleted.`
          );
        }),
        catchError((err) => {
          this.notification.showError(
            'Custom System Lenses',
            'Failed to delete custom system lens. Please try again later.'
          );
          return throwError(err);
        })
      );
  }

  /**
   * update alarm status
   *
   * @param {{ currentStatus: string; newStatus: string }} data
   * @returns
   * @memberof AlarmsDataService
   */
  updateAlarmStatus(data: { currentStatus: string; newStatus: string }) {
    return this.http
      .request<any>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: UPDATE_ALARM_STATUS,
          variables: data,
        }),
      })
      .pipe(
        filter((d) => !!d),
        tap((res) => {
          if (res.errors) {
            this.notification.showError(
              'Custom System Lenses',
              `Custom system lens "${data.newStatus}" already exists.`
            );
          }
        }),
        catchError((err) => {
          this.notification.showError(
            'Custom System Lenses',
            'Failed to save custom system lens. Please try again later.'
          );
          return throwError(err);
        })
      );
  }

  /**
   * get alarm entities by alarm ids
   *
   * @param {number[]} alarmIds
   * @returns
   * @memberof AlarmsDataService
   */
  getAlarmEntities(alarmIds: number[]) {
    return this.apollo
      .query<{ alarmEntities: Entity[] }>({
        fetchPolicy: 'network-only',
        query: GET_ALARM_ENTITIES,
        variables: {
          options: {
            order: { field: 'NAME', direction: OrderDirection.ASC },
            where: { alarmIds },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((r) => r?.data?.alarmEntities),
        catchError((error) => throwError(error))
      );
  }

  /**
   * update alarm details, priority and status
   *
   * @param {AlarmDetailsUpdate} variables
   * @returns {Observable<any>}
   * @memberof AlarmsDataService
   */
  updateAlarmDetails(
    id: number,
    variables: AlarmDetailsUpdate
  ): Observable<Alarm> {
    return this.apollo
      .mutate<{ updateAlarmDetails: Alarm }>({
        mutation: UPDATE_ALARM_DETAILS_MUTATION,
        variables: {
          id,
          input: Object.keys(variables).reduce((acc, key) => {
            const value = variables[key];
            if (variables[key] !== null) {
              acc[key] = value;
            }
            return acc;
          }, {}),
        },
      })
      .pipe(
        filter((d) => !!d),
        tap(() =>
          this.notification.showSuccess(
            'Alarm Details',
            'Alarm is being updated. Changes will appear momentarily.'
          )
        ),
        map((r) => r?.data?.updateAlarmDetails),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_ALARM_DETAILS);
          return throwError(error);
        })
      );
  }

  /**
   * update alarm point entity
   * @param alarmId
   * @param entityId
   * @returns
   * @memberof AlarmsDataService
   */
  updateAlarmPointEntity(
    alarmId: string | number,
    entityId: number,
    alarmType?: string
  ) {
    return this.apollo
      .mutate({
        mutation: UPDATE_ALARM_POINT_ENTITY_MUTATION,
        variables: {
          id: alarmId,
          entityId,
          alarmType,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res: any) => res?.data.updateAlarmPointEntity)
      );
  }
  /**
   * Downloads the alarm activity
   *
   * @return {*}
   * @memberof AlarmsDataService
   */
  downloadAlarmActivityCSV(): Observable<HttpResponse<ArrayBuffer>> {
    return this.http
      .get(environment.backend + '/alarm-activity/download', {
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .pipe(
        filter((d) => !!d),
        catchError((err) => {
          this.notification.showError(ERROR_DOWNLOAD_CSV);
          return throwError(() => err);
        }),
        tap((res) => {
          let fileName = '';
          try {
            fileName = res.headers
              .get('content-disposition')
              .replace('attachment; filename=', '');
          } catch (error) {
            fileName = 'alarm-activity.csv';
          }

          const file = new Blob([res.body], {
            type: 'application/gzip',
          });
          downloadFile(file, fileName);
        })
      );
  }

  /**
   * Downloads the lens notification activity
   *
   * @return {*}  {Observable<HttpResponse<ArrayBuffer>>}
   * @memberof AlarmsDataService
   */
  downloadLensNotificationActivityCSV(): Observable<HttpResponse<ArrayBuffer>> {
    return this.http
      .get(environment.backend + '/alarm-activity/lens-notifications', {
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .pipe(
        filter((d) => !!d),
        catchError((err) => {
          this.notification.showError(ERROR_DOWNLOAD_CSV);
          return throwError(() => err);
        }),
        tap((res) => {
          let fileName = '';
          try {
            fileName = res.headers
              .get('content-disposition')
              .replace('attachment; filename=', '');
          } catch (error) {
            fileName = 'lens-notification-activity.csv';
          }

          const file = new Blob([res.body], {
            type: 'application/gzip',
          });
          downloadFile(file, fileName);
        })
      );
  }

  /**
   * fetch alarm entities
   *
   * @param {string} property
   * @param {string} [nameLike]
   * @returns {Observable<EntityListQuery>}
   * @memberof AlarmsDataService
   */
  getEntities(
    property: string,
    offset = 0,
    nameLike?: string
  ): Observable<EntityListQuery> {
    const where = {
      class: property.toUpperCase(),
      isPaid: true,
      nameLike: `%${nameLike}%`,
    };

    return this.apollo
      .query<EntityListQuery>({
        fetchPolicy: 'network-only',
        query: GET_ENTITIES,
        variables: {
          options: {
            where: { ...where },
            limit: MaxAlarmsLimit,
            offset,
            order: { field: 'NAME', direction: 'ASC' },
          },
        },
        ...this.context,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Snooze alarm
   *
   * @param {number} alarmId
   * @param {string} expiresAt
   * @param {string} reason
   * @return {Observable<void>}
   * @memberof AlarmsDataService
   */
  snooze(alarmId: number, expiresAt: string, reason: string): Observable<void> {
    return this.apollo
      .mutate({
        mutation: SNOOZE_ALARM_MUTATION,
        variables: {
          id: alarmId,
          snoozeExpiresAt: expiresAt,
          reason,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res: any) => res?.data.snoozeAlarm),
        tap(() => this.notification.showSuccess('Snooze'))
      );
  }

  /**
   * Unsnooze alarms
   *
   * @param {number} alarmId
   * @return {Observable<void>}
   * @memberof AlarmsDataService
   */
  unsnooze(alarmId: number): Observable<void> {
    return this.apollo
      .mutate({
        mutation: UNSNOOZE_ALARM_MUTATION,
        variables: {
          id: alarmId,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res: any) => res?.data.unsnoozeAlarm),
        tap(() => this.notification.showSuccess('Cancel Snooze'))
      );
  }

  /**
   * update alarm contextual name
   *
   * @param {number} id
   * @param {string} contextualName
   * @returns {Observable<void>}
   * @memberof AlarmsDataService
   */
  updateContextualName(id: number, contextualName: string): Observable<void> {
    return this.apollo
      .mutate({
        mutation: UPDATE_ALARM_CONTEXTUAL_NAME_MUTATION,
        variables: {
          id,
          contextualName,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res: any) => res?.data.snoozeAlarm),
        catchError((err) => {
          this.notification.showError(ERROR_UPDATE_CONTEXTUAL_NAME);
          return throwError(() => err);
        })
      );
  }

  /**
   * Gets the points
   *
   * @param {string} search
   * @param {number} offset
   * @return {Observable<PointQuery>}
   * @memberof AlarmsDataService
   */
  getPointOptions(search: string, offset: number): Observable<PointQuery> {
    const where = {
      nameLike: `%${search || ''}%`,
    };
    return this.apollo
      .query<PointQuery>({
        fetchPolicy: 'network-only',
        query: GET_POINTS_QUERY,
        variables: {
          input: {
            limit: 50,
            offset,
            order: { field: 'NAME', direction: OrderDirection.ASC },
            where,
          },
        },
      })
      .pipe(
        map((res) => res?.data),
        catchError((err) => {
          this.notification.showError(ERROR_POINTS);
          return throwError(err);
        })
      );
  }

  /**
   * set additional properties for CoreAlarms
   *
   * @private
   * @param {CoreAlarm} alarm
   * @returns {CoreAlarm}
   * @memberof AlarmsDataService
   */
  private appendToAlarm<T extends { nuisanceCount: number }>(alarm: T): T {
    return {
      ...alarm,
      isNuisance: alarm.nuisanceCount > 0,
    };
  }

  /**
   * map alarm activity fields and values
   *
   * @private
   * @param {AlarmActivity[]} activity
   * @returns
   * @memberof AlarmsDataService
   */
  private mapAlarmActivity(activity: AlarmActivity[], isSuper: boolean) {
    const formattedActivity = fastParse(activity);
    const updatedActivity = formattedActivity.map((act) => {
      act = this.setAlarmActivityValuesByField(act);
      act = this.updateSuperUserDisplayName(act, isSuper);
      return act;
    });
    return updatedActivity;
  }

  /**
   * update alarm activity display name if user is a super user
   *
   * @private
   * @param {AlarmActivity} act
   * @param {boolean} isSuper
   * @returns
   * @memberof AlarmsDataService
   */
  private updateSuperUserDisplayName(act: AlarmActivity, isSuper: boolean) {
    if (!isSuper) {
      if (act?.user?.isSuper) {
        act.user.displayName = VFI_SUPPORT;
      }
    }
    return act;
  }

  /**
   * update alarm activity values based on activity field
   *
   * @private
   * @param {AlarmActivity} act
   * @returns
   * @memberof AlarmsDataService
   */
  private setAlarmActivityValuesByField(act: AlarmActivity) {
    if (act.field === 'priority') {
      act.revValue = AlarmPriorityNames[act.revValue];
      act.prevValue = AlarmPriorityNames[act.prevValue];
      act.field = startCase(act.field);
    } else if (act.field === 'is_pinned') {
      act.revValue = AlarmActivityValues[act.revValue];
      act.prevValue = AlarmActivityValues[act.prevValue];
      act.field = 'Pinned';
    } else {
      act.revValue = handleBlank(
        act.revValue,
        AlarmActivityValues[act.revValue]
      );
      act.prevValue = handleBlank(
        act.prevValue,
        AlarmActivityValues[act.prevValue]
      );
      act.field = startCase(act.field);
    }
    return act;
  }

  /**
   * generate alarm query options
   *
   * @private
   * @param {*} limit
   * @param {*} offset
   * @param {*} criterion
   * @param {*} searchTerm
   * @param {*} sort
   * @param {*} lens
   * @param {*} alarmProperties
   * @returns
   * @memberof AlarmsDataService
   */
  private generateAlarmsAndCountOptions(
    limit: number,
    offset: number,
    criterion: CriterionSelection[],
    searchTerm: string,
    sort: GlobalFilterSort | GlobalFilterSort[],
    lens: Partial<AlarmLens>,
    alarmProperties: AlarmProperty[]
  ) {
    const formatted = this.globalFiltersService.createFiltersWhere(
      { searchTerm, criterion },
      alarmProperties
    );

    if (
      !formatted?.where?.minChattering &&
      !formatted?.where?.minFleeting &&
      lens.parent === 'nuisance'
    ) {
      formatted.where = { ...formatted.where, minNuisance: 1 };
    }

    let sortOrder = null;
    if (!Array.isArray(sort)) {
      sortOrder = this.globalFiltersService.createSortOrderBy(sort);
    }

    return {
      limit,
      offset,
      whereNot: formatted.whereNot,
      where: formatted.where,
      order: sortOrder || sort,
    };
  }

  /**
   * parse process variable values
   *
   * @private
   * @param {AlarmInstance[]} alarms
   * @returns
   * @memberof AlarmsDataService
   */
  private mapProcessVariableValues(alarms: AlarmInstance[]) {
    if (alarms.length) {
      return alarms.map((alarm) => ({
        ...alarm,
        processVariableValue: {
          ...alarm.processVariableValue,
          value: alarm.processVariableValue
            ? safeParseBoolean(alarm.processVariableValue.value)
            : null,
        },
      }));
    }
    return alarms;
  }
}
