import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  EntityManagementDataService,
  SpaceManagementDataService,
} from '@vfi-ui/data-access/shared';
import { BaseComponent } from '@vfi-ui/feature/core';
import { VfiSelectOption, SelectSize, AssetClass } from '@vfi-ui/models';
import { fastParse, isNil } from '@vfi-ui/util/helpers';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'atom-vfi-select-single',
  templateUrl: './vfi-select-single.component.html',
  styleUrls: ['./vfi-select-single.component.scss'],
})
export class VfiSelectSingleComponent extends BaseComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() size = SelectSize.MEDIUM;
  @Input() ariaLabel = 'vfi-select';
  @Input() placeholder = '';
  @Input() value: string;
  @Input() label: string;
  @Input() type: string;
  @Input() hint: string;
  @Input() required = false;
  @Input() iconLeft: string;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() subtle = false;
  @Input() success = false;
  @Input() warning = false;
  @Input() warningMessage: string;
  @Input() error = false;
  @Input() errorMessage: string;
  @Input() showSearch = true;
  @Input() loading = false;
  @Input() canClear = false;
  @Input() enableDropdown = true;
  @Input() autofocus = false;
  @Input() closeOnSelect = false;
  @Input() placement = null;
  @Input() queryType = null;
  @Output() cleared: EventEmitter<boolean> = new EventEmitter();
  @Output() dropdownVisibleChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() scrolled: EventEmitter<boolean> = new EventEmitter();
  @Output() optionSelected: EventEmitter<VfiSelectOption> = new EventEmitter();
  @Output() drilldownSelected: EventEmitter<VfiSelectOption> =
    new EventEmitter();
  options: VfiSelectOption[] = [];
  baseOptions = [];
  showDropdown = false;
  searchValue = null;
  seach$ = new Subject<string>();

  constructor(
    private cdr: ChangeDetectorRef,
    private spaceManagementService: SpaceManagementDataService,
    private entityService: EntityManagementDataService
  ) {
    super();
  }

  ngOnInit() {
    this.seach$.pipe(debounceTime(300)).subscribe((search) => {
      this.fetchOptions(search);
    });
  }

  /**
   * fetch options based on type
   *
   * @param {string} [$event]
   * @param {number} [offset=0]
   * @memberof VfiSelectSingleComponent
   */
  fetchOptions($event?: string, offset = 0) {
    this.searchValue = $event;
    this.loading = true;
    if (this.type === 'spaceType') {
      this.getSpaceTypeOptions($event, offset);
    } else if (this.type === 'entityClasses') {
      this.getEntityClassOptions(this.queryType, $event, offset);
    }
  }

  /**
   * call data service to fetch space type options
   *
   * @param {string} search
   * @param {number} offset
   * @memberof VfiSelectSingleComponent
   */
  getSpaceTypeOptions(search: string, offset: number) {
    search = isNil(search) ? '' : search;
    this.spaceManagementService
      .fetchSpaceTypes(offset, search)
      .subscribe((r) => {
        const spaceTypes =
          offset > 0 ? [...this.baseOptions, ...r.spaceTypes] : r.spaceTypes;
        this.baseOptions = fastParse(spaceTypes);
        this.options = spaceTypes.map((spaceType) => ({
          label: spaceType?.name,
          value: spaceType?.name,
        })) as VfiSelectOption[];
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  /**
   * call data service to fetch entity class options
   *
   * @param {AssetClass} type
   * @param {string} search
   * @param {number} offset
   * @memberof VfiSelectSingleComponent
   */
  getEntityClassOptions(type: AssetClass, search: string, offset: number) {
    search = isNil(search) ? '' : search;
    this.entityService
      .fetchEntityClassOptions(type, search, offset)
      .subscribe((r) => {
        const entityClasses =
          offset > 0
            ? [...this.baseOptions, ...r.entityClasses]
            : r.entityClasses;
        this.baseOptions = fastParse(entityClasses);
        this.options = entityClasses.map((spaceType) => ({
          label: spaceType?.name,
          value: spaceType?.id,
        })) as VfiSelectOption[];
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  /**
   * handle option select event
   *
   * @param {VfiSelectOption} option
   * @memberof VfiSelectSingleComponent
   */
  onOptionSelect(option: VfiSelectOption) {
    this.optionSelected.emit(option);

    if (this.closeOnSelect) {
      this.showDropdown = false;
      this.onPopoverVisibleChange(false);
    }
  }

  /**
   * handle on search event
   *
   * @param {string} ev
   * @memberof VfiSelectSingleComponent
   */
  onSearch(search: string) {
    this.seach$.next(search);
  }

  /**
   * handle popover visible change event
   *
   * @param {boolean} show
   * @memberof VfiSelectSingleComponent
   */
  onPopoverVisibleChange(show: boolean) {
    this.dropdownVisibleChanged.emit(show);
    if (show) {
      this.fetchOptions();
      this.autoFocusSearch();
    }
  }

  /**
   * handle select clicked event
   *
   * @memberof VfiSelectSingleComponent
   */
  onSelectClicked() {
    if (!this.enableDropdown) {
      this.dropdownVisibleChanged.emit(true);
    }
  }

  /**
   * handle options scroll event
   *
   * @memberof VfiSelectSingleComponent
   */
  onOptionsScroll() {
    this.fetchOptions(this.searchValue, this.baseOptions.length);
  }

  /**
   * auto focus on search input
   *
   * @memberof VfiSelectSingleComponent
   */
  autoFocusSearch() {
    this.cdr.detectChanges();
    if (this.autofocus && this.showSearch) {
      this.searchInput.nativeElement.focus();
    }
  }
}
