import { OrderDirection } from './../constants/work-ticket.constant';
import {
  SavedViewOrderField,
  SavedViewsViewType,
} from '../constants/saved-views.constants';

export interface SavedViewOrder {
  field?: SavedViewOrderField;
  direction?: OrderDirection;
}

export interface SavedViewWhere {
  ids?: string[];
  isDefault?: boolean;
  viewType: SavedViewsViewType;
}

export interface SavedViewOptions {
  limit?: number;
  offset?: number;
  order?: SavedViewOrder;
  where?: SavedViewWhere;
}

export interface SavedView {
  id: string;
  name: string;
  viewType: SavedViewsViewType;
  criteria?: any;
  isDefault: boolean;
  columns: any;
}

export interface SavedViewCreateInput {
  name?: string;
  viewType?: SavedViewsViewType;
  criteria?: Object;
  isDefault?: boolean;
  columns?: any;
}

export interface SavedViewModalData {
  name: string;
  isDefault: boolean;
}
