<div class="d-flex align-items-end">
  <div
    [formGroup]="form"
    class="d-flex flex-column w-50"
    aria-label="notificationTime"
  >
    <atom-vfi-multi-select
      label="Send notifications"
      [value]="form?.value?.dayOfWeek"
      placeholder="Select Days"
      [options]="weekdayOptions"
      [showSearch]="false"
      placement="bottom"
      (optionSelected)="valueChanged('dayOfWeek', $event)"
    ></atom-vfi-multi-select>
    <div class="d-flex align-items-center mt-2">
      <div class="d-flex flex-column mr-1 w-50">
        <span class="label"
          >Start Time
          {{ !(form?.controls)['from'].valid ? '(Required)' : '' }}</span
        >
        <nz-time-picker
          aria-label="notificationFromTime"
          formControlName="from"
          (ngModelChange)="updateForm()"
          [nzUse12Hours]="true"
          nzFormat="h:mm a"
          [nzPlaceHolder]="'Start Time'"
        >
        </nz-time-picker>
      </div>
      <div class="d-flex flex-column w-50">
        <span class="label"
          >End Time
          {{
            (!(form?.controls)['to'].value && !(form?.controls)['to'].valid) ||
            !(form?.controls)['to'].valid
              ? '(Required)'
              : ''
          }}
        </span>
        <nz-time-picker
          aria-label="notificationToTime"
          formControlName="to"
          (ngModelChange)="updateForm()"
          [nzUse12Hours]="true"
          nzFormat="h:mm a"
          [nzPlaceHolder]="'End Time'"
        ></nz-time-picker>
      </div>
    </div>
  </div>
  <i
    class="fas fa-info-circle ml-2 mb-3 regular-12"
    [nzTooltipTitle]="timezoneTooltip"
    nzTooltipPlacement="top"
    nz-tooltip
  ></i>
  <ng-template #timezoneTooltip>
    <span
      >Times are based on the time zone of the facility ({{ timezone }})</span
    >
  </ng-template>
</div>
<p class="error-text mb-0" *ngIf="showError" aria-label="notificationError">
  Please select a time span within the 24 hours of one day e.g. from 12:00 am
  until 11:59 pm
</p>
<div class="mt-2 w-50">
  <atom-vfi-input
    label="Delay Notification by X amount of minutes"
    placeholder="Minutes Delay"
    [value]="form?.value?.minutesDelay"
    type="number"
    (valueChanged)="valueChanged('minutesDelay', $event)"
  ></atom-vfi-input>
  <p class="warning-text mb-0" aria-label="nuisanceMessage">
    Nuisance alarm alerts will be sent every hour
  </p>
</div>
<!-- </div> -->

<ng-template #customClearIcon>
  <i class="white far fa-times-circle"></i>
</ng-template>
