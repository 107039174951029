import { BaseAlarmStatus } from '../constants/alarms.constant';
import { AlarmPriority } from '../constants/priorities.constant';
import {
  SituationalAwarenessViewEntitySortOrder,
  SituationalDashboardAlarmSortField,
  SituationalDashboardEntityClass,
  SituationalDashboardEntitySortField,
  SituationalDashboardEntityState,
  SituationalDashboardEntityWorkCreator,
} from '../constants/situational-awareness.constants';
import { CoreAlarm } from './alarm-instance.interface';
import {
  BrickClass,
  Entity,
  SAChildAlarm,
  SAChildAlarmCount,
} from './entity-management.interface';
import {
  ResolverMultiOrderOptions,
  ResolverOrder,
} from './graphql-api.interface';
import { Team } from './team.interface';
import { User } from './user.interface';
import { WorkTicket } from './work-order.interface';
import { FeatureCollection, Point, Feature } from 'geojson';

export interface SituationalDashboardDivision {
  id: string;
  name: string;
  blocks: SituationalDashboardBlock[];
}

export interface SituationalDashboardDivisionUpdateInput {
  name: string;
}

export interface SituationalAwarenessDivision {
  id: string;
  name: string;
  blocks: SituationalDashboardBlock[];
}

export type GeoJSON = FeatureCollection<Point> | Feature<Point>;

export interface SituationalDashboardEntity {
  id: string;
  entityId: number;
  name: string;
  buildingName: string;
  state: SituationalDashboardEntityState;
  lastActiveWorkTicketCreatedBy: SituationalDashboardEntityWorkCreator;
  oldestActiveAlarmTime: string;
  lastUnresolvedWorkClosedAt: string;
  lastestUnresolvedWorkTicketId: number;
  lastWorkTicketCreatedAt: string;
  latestActiveAlarmTime: string;
  stateChangedAt: string;
  class: SituationalDashboardEntityClass;
  isAcked: boolean;
  snoozeExpiresAt: string;
  snoozedBy: User;
  snoozeReason: string;
  entityType?: SituationalDashboardEntityClass;
  isEscalated?: boolean;
  isCovered: boolean;
  isActioned: boolean;
  lastActiveWorkTicketAssignedTo: User;
  criticality: number;
  geoJSON?: GeoJSON;
}

export interface SituationalAwarenessEntitySocket {
  blockId: string;
  entity: SituationalDashboardEntity;
}

export interface SituationalAwarenessMapEntitySocket {
  viewId: string;
  entity: SituationalDashboardEntity;
}

export interface SituationalAwarenessViewCountSocket {
  viewId: string;
  count: number;
  escalatedCount: number;
}

export interface SituationalAwarenessWorkTicketSocketPayload {
  viewId: string;
  workTicket: WorkTicket;
}

export interface SituationalAwarenessTimers {
  timeToImpact: number;
  timeToEscalation: number;
  criticality: number;
}

export interface WorkTicketEntity {
  id: number;
  name: string;
  alarmId: number;
}

export interface AlarmEntity {
  id: number;
  name: string;
  alarmId: number;
  criticality?: number;
}

export interface SituationalAwarenessAlarmsQuery {
  situationalDashboardAlarms: CoreAlarm[];
}

export interface SituationalAwanressWorkQuery {
  situationalDashboardWorkTickets: WorkTicket[];
  situationalDashboardWorkTicketsCount: number;
}

export interface EntityWorkTicketInput {
  entityId: number;
  alarmId: number;
}

export interface MapOptions {
  center: [number, number];
  zoom: number;
  pitch: number;
  bearing: number;
  maxBounds?: [number, number, number, number];
}
export interface SituationalDashboardView {
  id: string;
  name: string;
  isVisible: boolean;
  isDeleted: boolean;
  mapOptions: MapOptions;
  mapEntities: SituationalDashboardEntity[];
  mapListTitle?: string;
  entitySortOrder: SituationalAwarenessViewEntitySortOrder;
  activeEntityCounts: {
    count: number;
    escalatedCount: number;
  };
  displayOrder: number;
  divisions: SituationalAwarenessDivision[];
  teams: Team[];
}

export interface SituationalDashboardBlock {
  id: string;
  name: string;
  entities?: Entity[];
  criticality?: number[];
  entityIds?: number[];
  classes?: BrickClass[];
  classIds?: number[];
  spaceTypes?: string[];
  shops?: string[];
  showInactive?: boolean;
}

export interface SituationalDashboardViewUpdateInput {
  name?: string;
  isVisible?: boolean;
  displayOrder?: number;
  entitySortOrder?: SituationalAwarenessViewEntitySortOrder;
  teamIds: string[];
}

export interface SituationalDashboardBlockCreateInput {
  name?: string;
  entityIds?: number[];
  situationalAwarenessDivisionId?: string;
  criticality?: number[];
  displayOrder?: number;
}

export interface RelatedEntityQuery {
  situationalDashboardRelatedEntities: Partial<Entity>[];
  situationalDashboardRelatedEntityCount: number;
}

export interface SAChildAlarmCountQuery {
  situationalDashboardChildAlarms: SAChildAlarm[];
  activeChildrenCount: SAChildAlarmCount[];
  inactiveChildrenCount: SAChildAlarmCount[];
}

export interface SituationalDashboardEntityWhere {
  ids?: string[];
  notIds?: string[];
  isActive?: boolean;
  blockId: string;
  class?: SituationalDashboardEntityClass;
}

export type SituationalDashboardOrder =
  ResolverOrder<SituationalDashboardEntitySortField>;

export type SituationalDashboardEntityOptions = ResolverMultiOrderOptions<
  SituationalDashboardEntitySortField,
  SituationalDashboardEntityWhere
>;

export interface SituationalDashboardAlarmWhere {
  entityIds?: number[];
  priority?: AlarmPriority[];
  status?: BaseAlarmStatus[];
  isActive?: boolean;
}

export type SituationalDashboardAlarmOrder =
  ResolverOrder<SituationalDashboardAlarmSortField>;

export type SituationalDashboardAlarmOptions = ResolverMultiOrderOptions<
  SituationalDashboardAlarmSortField,
  SituationalDashboardAlarmWhere
>;

export interface SituationalDashboardEntityAndCount {
  situationalDashboardEntities: SituationalDashboardEntity[];
  situationalDashboardEntityCount: number;
}

export interface SituationalDashboardWorkingBlock {
  Equipment?: SituationalDashboardEntityAndCount;
  Room?: SituationalDashboardEntityAndCount;
  showInactive?: boolean;
}

export interface SituationalDashboardWorkingView {
  [blockId: string]: SituationalDashboardWorkingBlock;
}

export interface SituationalDashboardWorkingViewGroupedByBuilding {
  [blockId: string]: {
    Equipment?: { [buildingName: string]: SituationalDashboardEntityAndCount };
    Room?: { [buildingName: string]: SituationalDashboardEntityAndCount };
  };
}
