<div class="multi-level-select">
  <div
    *ngIf="label"
    class="vfi-label"
    [class.yellow-6]="highlightRequired && required && !readOnly"
  >
    <span>{{ label }}</span>
    <span *ngIf="required">&nbsp;(Required)</span>
  </div>
  <button
    class="dropdown-button vfi-assignee-selector"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    nz-popover
    [(nzPopoverVisible)]="showMultiLevelSelect"
    [nzPopoverPlacement]="['bottom', 'top']"
    nzPopoverTrigger="click"
    [nzPopoverContent]="multiLevelDropdown"
    [nzPopoverOverlayClassName]="'multi-level-select-popover'"
    (nzPopoverVisibleChange)="teamId ? getUsersByTeams() : getUsers()"
    [disabled]="disabled"
    [class.gray-8-bg]="disabled"
    [class.readOnly]="readOnly"
  >
    <div
      class="dropdown-label-placeholder"
      *ngIf="
        !displaySelectedValue || !value || (isArray(value) && !value.length)
      "
    >
      {{ placeholder }}
    </div>
    <div
      class="w-100"
      *ngIf="
        ((!isArray(value) && value) || (isArray(value) && value.length)) &&
        displaySelectedValue
      "
    >
      <div
        class="dropdown-label d-flex align-items-start"
        *ngIf="!isArray(value)"
      >
        {{ value.name }}
      </div>
      <div
        class="dropdown-label d-flex align-items-start"
        *ngIf="isArray(value)"
      >
        <span class="pill mr-1" *ngFor="let item of value">
          {{ item.name }}
        </span>
      </div>
    </div>
  </button>
</div>
<ng-template #multiLevelDropdown>
  <atom-multi-level-input
    [options]="optionsByTeams ? teamOptions : options"
    [unassignedOptions]="unassignedTeamOptions"
    [multiSelect]="multiSelect"
    [optionsBySection]="optionsByTeams"
    (searchChange)="searchUser($event)"
    [selectedValue]="selectedValue"
    (valueChange)="onValueChange($event)"
    [loading]="loading"
  ></atom-multi-level-input>
</ng-template>
