import {
  Directive,
  Output,
  EventEmitter,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[atomLongPress]',
})
export class LongPressDirective {
  @Input() duration = 500;
  @Output()
  afterLongPress = new EventEmitter();
  pressing: boolean;
  timeout: ReturnType<typeof setTimeout>;
  interval: number;

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.pressing = true;
    if (this.duration > 0) {
      this.timeout = setTimeout(() => {
        this.afterLongPress.emit(event);
      }, this.duration);
    }
  }

  @HostListener('touchend')
  @HostListener('mouseup')
  @HostListener('mouseleave')
  endPress() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
    this.pressing = false;
  }
}
