import {
  ConnectionTypeFields,
  ExternalSystemCommandType,
} from '../interfaces/external-systems.interface';
import { AlarmState } from './alarms.constant';

export enum ExternalSystemTypes {
  BUILDING = 'BUILDING_AUTOMATION_SYSTEM',
  WORK = 'WORK_ORDER_SYSTEM',
}

export enum ExternalSystemSchema {
  ENVISION = 'envision',
  COMPASS = 'compass',
  WEBCONTROL = 'webcontrol',
  NIAGARA4 = 'niagara4',
  MINUS80 = 'minus80',
  AWAREMANAGER = 'awareManager',
  MAXIMO = 'maximo',
  MAXIMO_SERVICE_REQUEST = 'maximoServiceRequest',
  FAMIS = 'famis',
  METASYS = 'johnsonMetasys',
  SIEMENSDESIGO = 'siemensDesigo',
  SIEMENSAPOGEE = 'siemensApogee',
  HONEYWELLEBI = 'honeywellEbi',
  WEBTMA = 'webTMA',
  TMS = 'tms',
  SCHOOLDUDE = 'schoolDude',
  SIEMENSAPOGEEFULLACCESS = 'siemensApogeeFullAccess',
  HONEYWELLEBISQL = 'honeywellEbiSql',
  JOHNSONMETASYSCHICAGO = 'johnsonMetasysChicago',
  JOHNSONMETASYSAPI = 'johnsonMetasysApi',
  SIEMENSAPOGEECHICAGO = 'siemensApogeeChicago',
  SCHNEIDERECOSTRUXURE = 'schneiderEcoStruxure',
  SCHNEIDERECOSTRUXURESQL = 'schneiderEcoStruxureSql',
  PEVCO = 'pevco',
  DELTACONTROLSENTELIWEB = 'deltaControlsEnteliWEB',
  SCHNEIDERANDOVERCONTINUUM = 'schneiderAndoverContinuum',
  EMEENERGYPERFORMANCE = 'emeEnergyPerformance',
  WEBTMACLOUD = 'webTMACloud',
  SMARTCONNECTOR = 'smartConnector',
  INFOR_EAM = 'inforEAM',
  MAINTENANCE_CONNECTION = 'maintenanceConnection',
  SABRE = 'sabre',
}

export enum ExternalSystemConnectionTypes {
  DATAPUMP = 'DATAPUMP',
  EMAIL = 'EMAIL',
  INBOUND = 'INBOUND_API',
  OUTBOUND = 'OUTBOUND_API',
  OUTBOUND_RUNNER = 'OUTBOUND_RUNNER',
}

export enum ExternalSystemDirections {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export const INTEGRATION_DIRECTIONS = [
  { label: 'Inbound', value: ExternalSystemDirections.INBOUND },
  { label: 'Outbound', value: ExternalSystemDirections.OUTBOUND },
];
export const INBOUND_CONNECTION_TYPE_OPTIONS = [
  { label: 'Data Pump', value: ExternalSystemConnectionTypes.DATAPUMP },
  { label: 'Email', value: ExternalSystemConnectionTypes.EMAIL },
  { label: 'Inbound API', value: ExternalSystemConnectionTypes.INBOUND },
];
export const OUTBOUND_CONNECTION_TYPE_OPTIONS = [
  { label: 'Outbound API', value: ExternalSystemConnectionTypes.OUTBOUND },
  {
    label: 'Outbound Runner',
    value: ExternalSystemConnectionTypes.OUTBOUND_RUNNER,
  },
];

export const ETL_IGNORABLE_STATE_OPTIONS = [
  { label: AlarmState.WARN, value: 'WARN' },
  { label: AlarmState.FAULT, value: 'FAULT' },
  { label: AlarmState.UNRELIABLE, value: 'UNRELIABLE' },
];

export const INTEGRATION_OPTIONS_CONNECTION_TYPE = {
  INBOUND: [
    ExternalSystemConnectionTypes.DATAPUMP,
    ExternalSystemConnectionTypes.EMAIL,
    ExternalSystemConnectionTypes.INBOUND,
  ],
  OUTBOUND: [
    ExternalSystemConnectionTypes.OUTBOUND,
    ExternalSystemConnectionTypes.OUTBOUND_RUNNER,
  ],
};

export const INBOUND_CONNECTION_TYPE_FIELDS: ConnectionTypeFields[] = [
  {
    name: 'Inbound Connection Type',
    formName: 'connectionType',
    type: 'select',
    required: true,
    editDisabled: true,
    options: INBOUND_CONNECTION_TYPE_OPTIONS,
  },
  {
    name: 'Hide Confidence Threshold',
    formName: 'hideConfidenceThreshold',
    type: 'slider',
    defaultValue: 0,
  },
  {
    name: 'Rescale Confidence Threshold',
    formName: 'rescaleConfidenceThreshold',
    type: 'slider',
    defaultValue: 0,
  },
  {
    name: 'Highlight Confidence Threshold',
    formName: 'highlightConfidenceThreshold',
    type: 'slider',
    defaultValue: 60,
  },
  {
    name: 'Levenshtein Confidence Threshold',
    formName: 'levenshteinConfidenceThreshold',
    type: 'slider',
    defaultValue: 75,
  },
  {
    name: 'Operating System',
    formName: 'operatingSystemType',
    type: 'select',
    required: false,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.DATAPUMP],
    options: [
      { label: 'Linux', value: 'LINUX' },
      { label: 'Windows', value: 'WINDOWS' },
    ],
  },
  {
    name: 'VF Inbound Address',
    formName: 'inboundEmailAddress',
    type: 'text',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.EMAIL],
  },
  {
    name: 'Sender Address',
    formName: 'senderAddress',
    type: 'text',
    required: false,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.EMAIL],
  },
];

export const OUTBOUND_CONNECTION_TYPE_FIELDS: ConnectionTypeFields[] = [
  {
    name: 'Outbound Connection Type',
    formName: 'connectionType',
    type: 'select',
    required: true,
    editDisabled: true,
    options: OUTBOUND_CONNECTION_TYPE_OPTIONS,
  },
  {
    name: 'Host',
    formName: 'host',
    type: 'text',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [
      ExternalSystemSchema.MAXIMO,
      ExternalSystemSchema.MAXIMO_SERVICE_REQUEST,
      ExternalSystemSchema.FAMIS,
      ExternalSystemSchema.TMS,
      ExternalSystemSchema.SCHOOLDUDE,
      ExternalSystemSchema.INFOR_EAM,
      ExternalSystemSchema.MAINTENANCE_CONNECTION,
      ExternalSystemSchema.SABRE,
    ],
  },
  {
    name: 'Username',
    formName: 'username',
    type: 'text',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [
      ExternalSystemSchema.MAXIMO,
      ExternalSystemSchema.MAXIMO_SERVICE_REQUEST,
      ExternalSystemSchema.FAMIS,
      ExternalSystemSchema.TMS,
      ExternalSystemSchema.INFOR_EAM,
      ExternalSystemSchema.SABRE,
    ],
  },
  {
    name: 'Password',
    formName: 'password',
    type: 'password',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [
      ExternalSystemSchema.MAXIMO,
      ExternalSystemSchema.MAXIMO_SERVICE_REQUEST,
      ExternalSystemSchema.FAMIS,
      ExternalSystemSchema.TMS,
      ExternalSystemSchema.INFOR_EAM,
      ExternalSystemSchema.SABRE,
    ],
  },
  {
    name: 'Site ID',
    formName: 'siteId',
    type: 'text',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [
      ExternalSystemSchema.MAXIMO,
      ExternalSystemSchema.MAXIMO_SERVICE_REQUEST,
    ],
  },
  {
    name: 'Zombies Supported',
    formName: 'isZombieEnabled',
    type: 'boolean',
    required: false,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [
      ExternalSystemSchema.MAXIMO,
      ExternalSystemSchema.MAXIMO_SERVICE_REQUEST,
    ],
  },
  {
    name: 'Segment ID',
    formName: 'segmentId',
    type: 'text',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [ExternalSystemSchema.TMS],
  },
  {
    name: 'Partner Account Number',
    formName: 'partnerAccountNumber',
    type: 'number',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [ExternalSystemSchema.SCHOOLDUDE],
  },
  {
    name: 'Partner Pass Code',
    formName: 'partnerPassCode',
    type: 'password',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [ExternalSystemSchema.SCHOOLDUDE],
  },
  {
    name: 'Client Account Number',
    formName: 'clientAccountNumber',
    type: 'number',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [ExternalSystemSchema.SCHOOLDUDE],
  },
  {
    name: 'Tenant',
    formName: 'tenant',
    type: 'text',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [ExternalSystemSchema.INFOR_EAM],
  },
  {
    name: 'Organiziation',
    formName: 'organization',
    type: 'text',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [ExternalSystemSchema.INFOR_EAM],
  },
  {
    name: 'Client Id (OpenID Client Identifier)',
    formName: 'clientId',
    type: 'text',
    required: false,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [ExternalSystemSchema.INFOR_EAM],
  },
  {
    name: 'API key',
    formName: 'apiKey',
    type: 'password',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [ExternalSystemSchema.MAINTENANCE_CONNECTION],
  },
  {
    name: 'Connection key',
    formName: 'connectionKey',
    type: 'password',
    required: true,
    showOnlyConnectionTypes: [ExternalSystemConnectionTypes.OUTBOUND],
    showOnlySchemas: [ExternalSystemSchema.MAINTENANCE_CONNECTION],
  },
];

export const DATA_PUMP_FIELDS = [
  {
    name: 'Operating System',
    type: 'text',
    required: false,
  },
];

export const EMAIL_FIELDS = [
  {
    name: 'VFI Inbound Address',
    type: 'text',
    required: true,
  },
  {
    name: 'Sender Address',
    type: 'text',
    required: false,
  },
];

export const INTEGRATION_FIELDS = {
  INBOUND: INBOUND_CONNECTION_TYPE_FIELDS,
  OUTBOUND: OUTBOUND_CONNECTION_TYPE_FIELDS,
};

export const INTEGRATION_BASE_FIELDS = [
  'displayName',
  'licenseExpiresAt',
  'type',
  'schema',
  'operatingSystemType',
  'hideConfidenceThreshold',
  'rescaleConfidenceThreshold',
  'highlightConfidenceThreshold',
  'levenshteinConfidenceThreshold',
  'inboundEmailAddress',
  'direction',
  'connectionType',
  'isExternalAlertsEnabled',
  'isAutomationRuleCmmsSyncEnabled',
  'hasProcessVariables',
  'externalAlertEmails',
  'alarmThroughputEmailThreshold',
  'lastAccessOnSlackThreshold',
  'lastAccessOnResolutionThreshold',
  'etlNormalizeVersion',
  'etlIgnorableStates',
  'etlTimestampOffset',
  'cmmsWorkTicketSyncThreshold',
  'isCloseWorkButtonEnabled',
  'workTicketObjectiveTemplate',
  'excludedAlarmTexts',
  'isCmmsPollingBackSyncEnabled',
];

export const NON_EDITABLE_INTEGRATION_FIELDS = [
  'schema',
  'connectionType',
  'direction',
];

export enum ExternalSystemIssueType {
  'LostConnection' = 'LOST_CONNECTION',
  'CreateWorkFailure' = 'CREATE_WORK_TICKET_FAILURE',
  'UpdateWorkFailure' = 'UPDATE_WORK_TICKET_FAILURE',
}

export enum ExternalSystemIssueTypeKey {
  'LOST_CONNECTION' = 'Lost Connection',
  'CREATE_WORK_TICKET_FAILURE' = 'failed to create',
  'UPDATE_WORK_TICKET_FAILURE' = 'failed to sync',
}

export enum ExternalSystemIssueResolvedSubtex {
  'LOST_CONNECTION' = 'Reconnected on',
  'CREATE_WORK_TICKET_FAILURE' = 'Resynced on',
  'UPDATE_WORK_TICKET_FAILURE' = 'Reupdated on',
}

export enum HealthState {
  'Healthy' = 'HEALTHY',
  'Moderate' = 'MODERATELY_HEALTHY',
  'Unhealthy' = 'UNHEALTHY',
}

export const ExternalSystemErrorLogCriticality = {
  [ExternalSystemIssueType.LostConnection]: HealthState.Unhealthy,
  [ExternalSystemIssueType.CreateWorkFailure]: HealthState.Moderate,
  [ExternalSystemIssueType.UpdateWorkFailure]: HealthState.Moderate,
};

export const ExternalSystemSubtext = {
  [ExternalSystemIssueType.LostConnection]: 'Lost connection',
  [ExternalSystemIssueType.CreateWorkFailure]: 'Sync error',
  [ExternalSystemIssueType.UpdateWorkFailure]: 'Sync error',
};

export const ExternalSystemIssuesSubtext = {
  [ExternalSystemIssueType.LostConnection]: 'Please contact your system admin',
  [ExternalSystemIssueType.CreateWorkFailure]:
    'Please retry syncing the ticket',
  [ExternalSystemIssueType.UpdateWorkFailure]:
    'Please retry syncing the ticket',
};

export const CmmsIntegrationType = [
  ExternalSystemSchema.MAXIMO,
  ExternalSystemSchema.MAXIMO_SERVICE_REQUEST,
  ExternalSystemSchema.AWAREMANAGER,
  ExternalSystemSchema.FAMIS,
  ExternalSystemSchema.WEBTMA,
  ExternalSystemSchema.WEBTMACLOUD,
  ExternalSystemSchema.TMS,
  ExternalSystemSchema.SCHOOLDUDE,
  ExternalSystemSchema.INFOR_EAM,
  ExternalSystemSchema.MAINTENANCE_CONNECTION,
  ExternalSystemSchema.SABRE,
];

export const GET_ASSETS_COMMAND_TYPES = [
  ExternalSystemCommandType.GetBASEquipmentAssets,
  ExternalSystemCommandType.GetBASNetworkDeviceAssets,
  ExternalSystemCommandType.GetBASObjectAssets,
  ExternalSystemCommandType.GetBASSpaceAssets,
  ExternalSystemCommandType.GetWebcontrolNodes,
  ExternalSystemCommandType.GetEnvisionEventMsg,
  ExternalSystemCommandType.GetEnvisionSysXRef,
  ExternalSystemCommandType.GetEnvisionAlarmHistory,
  ExternalSystemCommandType.GetEnvisionAlarmMessage,
  ExternalSystemCommandType.GetEnvisionDevCapability,
  ExternalSystemCommandType.GetEnvisionDevice,
  ExternalSystemCommandType.GetEnvisionOperatorActivity,
];

export const EXTERNAL_SYSTEM_RETRY_COMMAND_TYPES = [
  ...GET_ASSETS_COMMAND_TYPES,
];
