<span
  *ngIf="ms !== 0"
  class="font-spacer"
  [ngClass]="{
    'red-8': ms > 0 && theme === themeType.DARK,
    'red-7': ms > 0 && theme === themeType.LIGHT,
    'green-8': ms < 0
  }"
  ><i
    class="fas"
    [ngClass]="{ 'fa-caret-down': ms < 0, 'fa-caret-up': ms > 0 }"
  ></i
  >{{ readableMSDaysHoursMinutes(abs(ms)) }}</span
>
