import { ClipboardModule } from '@angular/cdk/clipboard';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import {
  CoreConfig,
  CoreService,
  CoreConfigService,
} from './services/core.service';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateTime24HoursPipe } from './pipes/date-time24-hours.pipe';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { CanDeactivateGuard } from './guards';
import { ConfirmModalService } from './services/confirm-modal.service';
import { LinkAlarmsService } from './services/link-alarms.service';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { GlobalErrorHandler } from './handlers';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import xRange from 'highcharts/modules/xrange.js';
import Tree from 'highcharts/modules/treemap';
import NoData from 'highcharts/modules/no-data-to-display';

import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NgSelectModule } from '@ng-select/ng-select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { IntegerPipe } from './pipes/integer.pipe';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DateTimePipe } from './pipes/date-time.pipe';

registerLocaleData(en);

export const featureCoreRoutes: Route[] = [];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzMenuModule,
    NzDrawerModule,
    NzNotificationModule,
    NzInputModule,
    NzButtonModule,
    NzCarouselModule,
    NzTableModule,
    NzCollapseModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    NzToolTipModule,
    NzAvatarModule,
    NzSpinModule,
    NzDropDownModule,
    NzSelectModule,
    NzBadgeModule,
    NzCardModule,
    NzPaginationModule,
    NzTimePickerModule,
    NzTableModule,
    NzModalModule,
    NzFormModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzSwitchModule,
    NzUploadModule,
    NzEmptyModule,
    NzInputNumberModule,
    NzRadioModule,
    NzTagModule,
    NzTreeModule,
    NzSkeletonModule,
    NgxDatatableModule,
    NzSliderModule,
    NzPopoverModule,
    NzTabsModule,
    NzNoAnimationModule,
    NzTimelineModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DragDropModule,
    ClipboardModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    CanDeactivateGuard,
    ConfirmModalService,
    LinkAlarmsService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HIGHCHARTS_MODULES, useFactory: () => [xRange, Tree, NoData] },
    provideNgxMask(),
  ],
  exports: [
    RouterModule,
    NzMenuModule,
    NzNotificationModule,
    NzInputModule,
    NzButtonModule,
    NzCarouselModule,
    NzTableModule,
    NzCollapseModule,
    FormsModule,
    ReactiveFormsModule,
    DateTime24HoursPipe,
    ChartModule,
    NzToolTipModule,
    NzAvatarModule,
    NzSpinModule,
    NavigationMenuComponent,
    NzDropDownModule,
    NzSelectModule,
    NzBadgeModule,
    NzCardModule,
    NzPaginationModule,
    NzTimePickerModule,
    NzTableModule,
    NzModalModule,
    NzFormModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzSwitchModule,
    NzUploadModule,
    NzEmptyModule,
    NzInputNumberModule,
    NzRadioModule,
    NzTagModule,
    NzTreeModule,
    NzDrawerModule,
    NzSkeletonModule,
    NgxDatatableModule,
    NzSliderModule,
    NgSelectModule,
    NzPopoverModule,
    NzTabsModule,
    IntegerPipe,
    NzTimelineModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DragDropModule,
    ClipboardModule,
    DateTimePipe,
  ],
  declarations: [
    DateTime24HoursPipe,
    IntegerPipe,
    NavigationMenuComponent,
    DateTimePipe,
  ],
})
export class FeatureCoreModule {
  static forRoot(config: CoreConfig): ModuleWithProviders<FeatureCoreModule> {
    return {
      ngModule: FeatureCoreModule,
      providers: [
        CoreService,
        {
          provide: CoreConfigService,
          useValue: config,
        },
      ],
    };
  }
}
