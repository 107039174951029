// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UiAtomsModule } from '@vfi-ui/ui/atoms';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureCoreModule, ConfirmModalComponent } from '@vfi-ui/feature/core';
import { FormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SetPasswordComponent } from './set-password/set-password.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { CriterionSelectionComponent } from './criterion-selection/criterion-selection.component';
import { NotificationTimeComponent } from './notification-time/notification-time.component';
import { UserSelectionListComponent } from './user-selection/user-selection-list.component';
import { AdminTabsMainComponent } from './admin-tabs/admin-tabs-main.component';
import { AlarmSourceComponent } from './alarm-source/alarm-source.component';
import { CreateIntegrationComponent } from './create-integration/create-integration.component';
import { CreateCustomerModelComponent } from './create-customer-model/create-customer-model.component';
import { IncludeExcludeComponent } from './include-exclude/include-exclude.component';
import { CreateSsoComponent } from './create-sso/create-sso.component';
import { CreateCmmsDomainValueComponent } from './create-cmms-domain-value/create-cmms-domain-value.component';
import { CreateCmmsValueTranslationComponent } from './create-cmms-value-translation/create-cmms-value-translation.component';
import { CreateCmmsMappingComponent } from './create-cmms-mapping/create-cmms-mapping.component';
import { CreateCmmsRelationComponent } from './create-cmms-relation/create-cmms-relation.component';
import { DurationValueComponent } from './duration-value/duration-value.component';
import { WorkInstanceIconComponent } from './work-instance-icon/work-instance-icon.component';
import { WorkIconComponent } from './work-icon/work-icon.component';
import { PopupContainerComponent } from './popup-container/popup-container.component';
import { ReplayAlarmsComponent } from './replay-alarms/replay-alarms.component';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  imports: [
    CommonModule,
    FeatureCoreModule,
    FormsModule,
    DragulaModule,
    InfiniteScrollModule,
    NgxsFormPluginModule,
    NzModalModule,
    UiAtomsModule,
    AgGridModule,
  ],
  declarations: [
    SetPasswordComponent,
    CriterionSelectionComponent,
    NotificationTimeComponent,
    UserSelectionListComponent,
    AdminTabsMainComponent,
    AlarmSourceComponent,
    ConfirmModalComponent,
    CreateIntegrationComponent,
    CreateCustomerModelComponent,
    IncludeExcludeComponent,
    CreateSsoComponent,
    CreateCmmsDomainValueComponent,
    CreateCmmsValueTranslationComponent,
    CreateCmmsMappingComponent,
    CreateCmmsRelationComponent,
    DurationValueComponent,
    WorkIconComponent,
    WorkInstanceIconComponent,
    PopupContainerComponent,
    ReplayAlarmsComponent,
  ],
  exports: [
    SetPasswordComponent,
    CriterionSelectionComponent,
    NotificationTimeComponent,
    UserSelectionListComponent,
    AdminTabsMainComponent,
    AlarmSourceComponent,
    ConfirmModalComponent,
    CreateIntegrationComponent,
    CreateCustomerModelComponent,
    IncludeExcludeComponent,
    CreateSsoComponent,
    CreateCmmsDomainValueComponent,
    CreateCmmsValueTranslationComponent,
    CreateCmmsMappingComponent,
    CreateCmmsRelationComponent,
    DurationValueComponent,
    WorkIconComponent,
    WorkInstanceIconComponent,
    PopupContainerComponent,
    ReplayAlarmsComponent,
  ],
})
export class UiNucleusModule {}
