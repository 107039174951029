import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import {
  CoreAlarmsOrderSortDirection,
  ERROR_GET_EXTERNAL_SYSTEM_COMMANDS,
  ERROR_RETRY_EXTERNAL_SYSTEM_COMMAND,
  ExternalSystemCommand,
  ExternalSystemCommandSort,
  ExternalSystemCommandWhere,
  ExternalSystemIssueLog,
  ExternalSystemTypes,
  Integrations,
  MaxAlarmPropertyLimit,
  OrderDirection,
} from '@vfi-ui/models';
import {
  GET_EXTERNAL_SYSTEM_COMMANDS_QUERY,
  GET_EXTERNAL_SYSTEM_ERROR_LOGS_QUERY,
  RETRY_EXTERNAL_SYSTEM_COMMAND,
  systemSource,
} from '../queries/external-system.query';
import { map, catchError, filter, tap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalSystemsDataService {
  constructor(
    private readonly apollo: Apollo,
    private notification: NotificationService
  ) {}

  /**
   * fetch system status
   *
   * @param {string} [value]
   * @returns
   * @memberof ExternalSystemsDataService
   */
  getSourceSystemStatus(value?: string) {
    const where: any = {};
    if (value) {
      where.displayNameLike = `%${value}%`;
    }
    return this.apollo
      .query<{
        externalSystemsAndCount: {
          items: Array<{
            name: string;
            displayName: string;
            type: ExternalSystemTypes;
          }>;
        };
      }>({
        fetchPolicy: 'network-only',
        query: systemSource,
        variables: {
          options: {
            where,
            limit: MaxAlarmPropertyLimit,
            offset: 0,
            order: {
              field: 'DISPLAY_NAME',
              direction: CoreAlarmsOrderSortDirection.desc,
            },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((data) => data.data),
        map((data) => {
          const externalSystems = data.externalSystemsAndCount.items;
          return externalSystems.filter(
            (r) => r.type !== ExternalSystemTypes.WORK
          );
        }),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Gets the cmms's
   *
   * @param {string} [value]
   * @return {*}
   * @memberof ExternalSystemsDataService
   */
  getCMMS(value?: string) {
    const where: any = { type: ExternalSystemTypes.WORK };
    if (value) {
      where.displayNameLike = `%${value}%`;
    }
    return this.apollo
      .query<{
        externalSystemsAndCount: {
          items: Array<{
            name: string;
            displayName: string;
            type: ExternalSystemTypes;
          }>;
        };
      }>({
        fetchPolicy: 'network-only',
        query: systemSource,
        variables: {
          options: {
            where,
            limit: MaxAlarmPropertyLimit,
            offset: 0,
            order: {
              field: 'DISPLAY_NAME',
              direction: CoreAlarmsOrderSortDirection.desc,
            },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((data) => data.data.externalSystemsAndCount.items),
        catchError((err) => throwError(() => err))
      );
  }
  /**
   * fetch all system integrations
   *
   * @returns
   * @memberof ExternalSystemsDataService
   */
  getAllSourceSystemStatus(): Observable<Integrations[]> {
    return this.apollo
      .query<{
        externalSystemsAndCount: {
          items: Array<Integrations>;
        };
      }>({
        fetchPolicy: 'network-only',
        query: systemSource,
        variables: {
          options: {
            offset: 0,
            order: {
              field: 'DISPLAY_NAME',
              direction: CoreAlarmsOrderSortDirection.desc,
            },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((data) => data.data),
        map((data) => data.externalSystemsAndCount.items),
        catchError((err) => throwError(err))
      );
  }

  /**
   * fetch alarm source
   *
   * @param {string} [value]
   * @returns
   * @memberof ExternalSystemsDataService
   */
  getAlarmSource(value?: string) {
    return this.apollo
      .query<{
        externalSystemsAndCount: {
          items: Array<{ name: string; displayName: string }>;
        };
      }>({
        fetchPolicy: 'network-only',
        query: systemSource,
        variables: {
          options: {
            where: { names: [value] },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((data) => data.data.externalSystemsAndCount.items),
        catchError((err) => throwError(err))
      );
  }

  /**
   * fetch external system error logs
   *
   * @param {number} id
   * @returns
   * @memberof ExternalSystemsDataService
   */
  getExternalSystemErrorLogs(id: number) {
    const options = {
      order: {
        field: 'CREATED_AT',
        direction: OrderDirection.DESC,
      },
      where: {
        externalSystemIds: [id],
      },
    };
    return this.apollo
      .query<{ getExternalSystemIssueLogs: ExternalSystemIssueLog[] }>({
        fetchPolicy: 'no-cache',
        query: GET_EXTERNAL_SYSTEM_ERROR_LOGS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((data) => data?.data?.getExternalSystemIssueLogs),
        catchError((err) => throwError(err))
      );
  }

  /**
   * Fetches a list of external system commands.
   *
   * @param {object} params
   * @param {number} params.id - The id of the external system
   * @param {number} params.limit - The id of the external system
   * @param {number} params.offset - The id of the external system
   * @param {ExternalSystemCommandSort} params.order - The order to return the results
   *  to fetch the commands for
   * @returns An observable that emits the command results
   * @memberof ExternalSystemsDataService
   */
  getExternalSystemCommands({
    where,
    limit,
    offset,
    order,
  }: {
    where: ExternalSystemCommandWhere;
    limit: number;
    offset: number;
    order: ExternalSystemCommandSort;
  }) {
    const options = {
      where,
      limit,
      offset,
      order,
    };
    return this.apollo
      .query<{
        getExternalSystemCommands: ExternalSystemCommand[];
        getExternalSystemCommandCount: number;
      }>({
        fetchPolicy: 'no-cache',
        query: GET_EXTERNAL_SYSTEM_COMMANDS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_GET_EXTERNAL_SYSTEM_COMMANDS);
          return throwError(error);
        }),
        map((res) => res?.data)
      );
  }

  /**
   * Retries an external system command.
   *
   * @param {object} commandId - The id of the command to retry
   *  to fetch the commands for
   * @returns An observable
   * @memberof ExternalSystemsDataService
   */
  retryExternalSystemCommand(commandId: string) {
    return this.apollo
      .mutate({
        mutation: RETRY_EXTERNAL_SYSTEM_COMMAND,
        variables: { id: commandId },
      })
      .pipe(
        filter((d) => !!d),
        tap(() =>
          this.notification.showSuccess(
            'Initialization of command retry was successful'
          )
        ),
        catchError((error) => {
          this.notification.showError(ERROR_RETRY_EXTERNAL_SYSTEM_COMMAND);
          return throwError(error);
        })
      );
  }
}
