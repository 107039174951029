import { AlarmExcludeWhere } from './alarm-instance.interface';
import { DisplayOptions } from './core.interface';

export interface NotificationData {
  lensId?: string;
  name?: string;
  criteria?: NotificationCriteria;
  displayOptions?: DisplayOptions;
  emailUsersToNotify?: string[];
  id?: string;
  pushUsersToNotify?: string[];
  smsUsersToNotify?: string[];
}

export interface NotificationCriteria {
  exclude?: AlarmExcludeWhere;
  dayOfWeek?: string[];
  from?: string;
  to?: string;
  hourInterval?: number;
  secondsDelay?: number;
  timezone?: string;
  includeRTN?: boolean;
}
