import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthService } from '@vfi-ui/data-access/shared';
import { BaseComponent } from '@vfi-ui/feature/core';
import { SSO_SIGN_IN, VFI_CLAIMS } from '@vfi-ui/models';
import { Logout } from '@vfi-ui/state';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atom-indicator-404',
  templateUrl: './indicator-404.component.html',
  styleUrls: ['./indicator-404.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Indicator404Component extends BaseComponent implements OnInit {
  userError = false;
  hasSsoActivated = false;
  verifyingSso = false;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private readonly auth: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(async (params: Params) => {
      this.verifyingSso = true;
      this.userError = params?.id;
      if (this.userError) {
        const token = await firstValueFrom(this.auth.getCurrentUserIdToken());
        const isSsoUser = token?.signInProvider?.match(/^saml\..*/);
        const isClaimsValid = token?.claims?.[VFI_CLAIMS];
        if (isSsoUser && !isClaimsValid) {
          this.hasSsoActivated = await firstValueFrom(
            this.auth.activeSsoUser()
          );
          if (this.hasSsoActivated) {
            localStorage.setItem(SSO_SIGN_IN, 'true');
            window.location.assign(`/${window.location.search}`);
          }
        }
      }
      this.verifyingSso = false;
      this.cdr.detectChanges();
    });
  }

  /**
   * Navigate to default home
   *
   * @memberof Indicator404Component
   */
  navigateToHome() {
    if (this.userError) {
      this.store.dispatch(new Logout());
    } else {
      window.location.href = '/';
    }
  }
}
