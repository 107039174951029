import { LensDataService, MenuService } from '@vfi-ui/data-access/shared';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import {
  LensCategory,
  filterModelMode,
  Counts,
  MenuLens,
  LensType,
  TileType,
  ALARM_SORT,
  WORK_SORT,
  LensAlarmCategories,
  LensWorkCategories,
  HIDE_BADGE_CATEGORIES,
} from '@vfi-ui/models';
import { Observable, of } from 'rxjs';
import {
  LensState,
  SetCurrentlySelectedLens,
  ResetGlobalFilters,
  ToggleFilterModal,
  ToggleSystemLensModal,
  SetTriageCounts,
  GlobalFiltersState,
  AuthState,
  ToggleCreateLensFilterMode,
  SetGlobalCurrentLens,
  NavigateToCurrentlySelectedLens,
  SetSort,
  SetLensTeam,
} from '@vfi-ui/state';
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { fetchLensTeamName, isNil } from '@vfi-ui/util/helpers';

@Component({
  selector: 'vfi-core-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() type: LensType;
  @Output() routeChange: EventEmitter<string> = new EventEmitter();
  @Select(LensState.getTriageCounts) triageCounts$: Observable<Counts>;
  @Select(LensState.getLenses) lens$: Observable<MenuLens[]>;
  lensCategory = LensCategory;
  lensType = LensType;
  parent: string;
  lenses: MenuLens[];
  currentSelectedLens: MenuLens;
  menuOpenState = {};
  defaultLensId: string;
  showStarAnimation = false;
  canEditTeamLenses = false;

  constructor(
    private store: Store,
    private router: Router,
    private menuService: MenuService,
    private lensService: LensDataService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * returns if lens should show counts
   *
   * @param {LensCategory} category
   * @returns
   * @memberof NavigationMenuComponent
   */
  showCounts(category: LensCategory) {
    if (!category) {
      return false;
    }
    return !HIDE_BADGE_CATEGORIES.includes(category);
  }

  ngOnInit() {
    // Set permissions
    this.canEditTeamLenses = this.store.selectSnapshot(
      AuthState.userPermissions
    )?.lenses?.manageTeamLenses;

    this.defaultLensId = this.store.selectSnapshot(
      LensState.getDefaultLens(this.type)
    )?.id;
    this.getLensCount(this.type);
    this.parent = this.store.selectSnapshot(GlobalFiltersState.getLens).parent;
    this.store
      .select(LensState.getFormattedLens)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((lenses) => {
        this.lenses = lenses;
        this.cdr.detectChanges();
      });
    this.store
      .select(LensState.getCurrentlySelectedLens)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((lens) => {
        this.currentSelectedLens = lens;
        this.cdr.detectChanges();
      });
    this.menuService
      .getTriageLensCount()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((counts) => this.store.dispatch(new SetTriageCounts(counts)));
    this.menuService.menuUpdated
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((len) => {
        const authRole = this.store.selectSnapshot(AuthState.getAuthRole);
        let waitingMenuItems$ = of([]);
        if (len.type === LensType.ALARM) {
          waitingMenuItems$ = this.menuService.getMenuItems().pipe(
            take(1),
            switchMap(() =>
              this.store.dispatch(new SetCurrentlySelectedLens(len.id))
            )
          );
        } else if (len.type === LensType.WORK) {
          waitingMenuItems$ = this.menuService
            .getReportingMenuItems(authRole)
            .pipe(
              take(1),
              switchMap(() =>
                this.store.dispatch(new SetCurrentlySelectedLens(len.id))
              )
            );
        } else if (len.type === LensType.ASSET) {
          waitingMenuItems$ = this.menuService.getAssetMenuItems().pipe(
            take(1),
            switchMap(() =>
              this.store.dispatch(new SetCurrentlySelectedLens(len.id))
            )
          );
        }
        waitingMenuItems$
          .pipe(
            tap(() => {
              this.getLensCount(this.type);
              this.store.dispatch([
                new NavigateToCurrentlySelectedLens(),
                new SetGlobalCurrentLens({
                  parent: len?.category,
                  child: len?.name,
                  parentId: len?.id,
                  isCustom: len?.isCustom,
                  type: len?.type as TileType,
                  category: len?.category,
                }),
              ]);
            })
          )
          .subscribe(() => {
            this.menuOpenState = this.setMenuOpenState(len);
            this.cdr.detectChanges();
          });
      });
    this.menuOpenState = this.setMenuOpenState();
    this.cdr.detectChanges();
  }

  /**
   * open new lens model by dispatching reset and toggle states
   *
   * @param {LensCategory} category
   * @param {MenuLens} lens
   * @memberof NavigationMenuComponent
   */
  openLensModel(category: LensCategory, lens: MenuLens) {
    this.store.dispatch(new ResetGlobalFilters()).subscribe(() => {
      const team = lens.id ? { name: lens?.name, id: lens?.id } : null;
      this.store.dispatch(new SetLensTeam(team));
      if (LensAlarmCategories.includes(category)) {
        this.store.dispatch(new SetSort(ALARM_SORT));
      }
      if (LensWorkCategories.includes(category)) {
        this.store.dispatch(new SetSort(WORK_SORT));
      }
      this.store.dispatch([
        new ToggleCreateLensFilterMode({
          mode: filterModelMode.CREATE_LENS,
          category,
        }),
        new ToggleFilterModal(true),
      ]);
    });
  }

  /**
   * open edit lens model by dispatching reset and toggle states
   *
   * @memberof NavigationMenuComponent
   */
  updateLens(category: LensCategory, isCustom: boolean) {
    if (isCustom) {
      this.store.dispatch([
        new ToggleCreateLensFilterMode({
          mode: filterModelMode.EDIT_LENS,
          category,
        }),
        new ToggleFilterModal(true),
      ]);
    } else {
      this.store.dispatch(new ToggleSystemLensModal(true));
    }
  }

  /**
   * navigate to lens route
   *
   * @param {Lens} lens
   * @memberof NavigationMenuComponent
   */
  routeChanged(lens: MenuLens) {
    const teamName = fetchLensTeamName(lens);
    let type = 'alarms';
    if (lens.type === LensType.ASSET) {
      type = 'assets';
    }
    if (lens.type === LensType.WORK) {
      type = 'work';
    }
    this.router.navigate([type, teamName, lens.name]);
  }

  /**
   * set default lens
   *
   * @param {string} lensId
   * @memberof NavigationMenuComponent
   */
  setDefaultLens(lensId: string) {
    this.menuService.setDefaultLens(lensId).subscribe();
    this.defaultLensId = lensId;
    this.showStarAnimation = true;
    setTimeout(() => {
      this.showStarAnimation = false;
      this.cdr.detectChanges();
    }, 800);
  }

  /**
   * return if user can edit lens
   *
   * @param {MenuLens} lens
   * @returns
   * @memberof NavigationMenuComponent
   */
  canEditLens(lens: MenuLens) {
    if (!lens || (!lens.isCustom && lens?.type === LensType.WORK)) {
      return false;
    }
    if (lens?.isCustom && !lens?.team) {
      return true;
    }
    if (lens?.isCustom && lens?.team) {
      return this.canEditTeamLenses;
    }
    return true;
  }

  /**
   * return if user can create lens
   *
   * @param {MenuLens} parent
   * @param {MenuLens} lens
   * @returns
   * @memberof NavigationMenuComponent
   */
  canCreateLens(parent: MenuLens, lens: MenuLens) {
    if (lens.type === LensType.CREATE) {
      return parent.isTeam ? this.canEditTeamLenses : true;
    }
    return false;
  }

  /**
   * set menu open state
   *
   * @private
   * @param {MenuLens} [lens]
   * @returns
   * @memberof NavigationMenuComponent
   */
  private setMenuOpenState(lens?: MenuLens) {
    const lenses = this.store.selectSnapshot(LensState.getFormattedLens);
    const defaultLens = this.store.selectSnapshot(
      LensState.getDefaultLens(this.type)
    );
    const headers = lenses.map((l) => l.name);
    const menuState = {};
    headers.reduce((obj, key, index) => {
      const isSelectedHeader = lenses[index].childLens.find((l) =>
        lens ? l?.id === lens?.id : l?.id === defaultLens?.id
      );
      Object.assign(obj, {
        [key]: !isNil(isSelectedHeader) || false,
      });
      return obj;
    }, menuState);
    return menuState;
  }

  /**
   * fetch counts for lens
   *
   * @private
   * @memberof NavigationMenuComponent
   */
  private getLensCount(type: LensType) {
    this.lensService.getLensCounts(type).subscribe((counts) => {
      const triageCounts = {};
      counts.reduce((obj, key) => {
        Object.assign(obj, { [key?.id]: +key?.count });
        return obj;
      }, triageCounts);
      this.store.dispatch(new SetTriageCounts(triageCounts));
    });
  }
}
