import { AfterViewInit, Component, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {
  AuthService,
  InfoDataService,
  MfaService,
  NotificationService,
} from '@vfi-ui/data-access/shared';
import { environment } from '@vfi-ui/environments/environment';
import {
  CAPACITOR_DEPLOY_TOKEN,
  ReleaseVersion,
  SSO_SIGN_IN,
  User,
} from '@vfi-ui/models';
import { AuthState, CoreState, SignIn } from '@vfi-ui/state';
import { getCurrentYear } from '@vfi-ui/util/helpers';
import { DeployClass } from 'cordova-plugin-ionic';
import { firstValueFrom, Observable } from 'rxjs';
@Component({
  selector: 'vfi-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit {
  @Select(AuthState.user) user$: Observable<User>;
  emailForm: UntypedFormGroup;
  signInForm: UntypedFormGroup;
  submitted = false;
  currentYear = getCurrentYear();
  env = environment.env;
  otaVersion: string;
  showSignInForm = false;
  release$: Observable<ReleaseVersion>;

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private mfa: MfaService,
    private readonly infoData: InfoDataService,
    public readonly authService: AuthService,
    @Inject(CAPACITOR_DEPLOY_TOKEN) private readonly deploy: DeployClass,
    public activeRoute: ActivatedRoute,
    private readonly notification: NotificationService
  ) {
    this.signInForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
    });
    this.emailForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
    });
    this.release$ = this.infoData.getReleaseInfo();

    this.deploy
      .getCurrentVersion()
      .then((d) => {
        this.otaVersion = d?.versionId;
      })
      .catch((err) => console.error(err));
  }

  /**
   * returns signInForm controls
   *
   * @readonly
   * @memberof LoginComponent
   */
  get signInFormControls() {
    return this.signInForm.controls;
  }

  async ngAfterViewInit() {
    const isMobile = this.store.selectSnapshot(CoreState.isMobile);
    this.mfa.recaptchaVerifier();
    const email = this.activeRoute?.snapshot?.queryParams['emailAddress'];
    if (email && !this.authService.ssoConfigError) {
      this.emailForm.patchValue({
        email,
      });
      this.checkEmail({ email });
    } else if (this.authService.ssoConfigError && !isMobile) {
      const error = new Error(
        `SSO failed with error message err: ${this.authService.ssoConfigError.message}`
      );
      this.notification.showError(
        'SSO CONFIG ISSUE',
        this.authService.ssoConfigError.message
      );
      throw error;
    }
  }

  /**
   * Checks whether user should use sso or not
   *
   * @param {*} value
   * @memberof LoginComponent
   */
  async checkEmail(value: any) {
    if (this.emailForm.valid) {
      const loginInfo = await firstValueFrom(
        this.authService.checkEmailTenant(value.email)
      );
      if (loginInfo.isSso) {
        localStorage.setItem(SSO_SIGN_IN, 'true');
        await this.authService.signInWithRedirect(
          loginInfo.tenantId,
          loginInfo.providerId,
          value.email
        );
      } else {
        this.signInForm.patchValue({ email: value.email });
        this.showSignInForm = true;
      }
    }
  }

  /**
   * Sign current user in
   *
   * @param {*} value
   * @memberof LoginComponent
   */
  async signIn(value: any) {
    this.submitted = true;
    if (this.signInForm.valid) {
      const res = await this.authService.signInWith(
        {
          email: value.email,
          password: value.password,
        },
        this.mfa.verificationCaptcha
      );
      if (res?.user) {
        this.store.dispatch(new SignIn(res.user));
      } else if (res?.factorId) {
        this.mfa.verificationId = res.verificationId;
        this.mfa.verificationResolver = res.resolver;
        this.mfa.factorId = res.factorId;
        this.submitted = false;
      }
    }
  }
}
