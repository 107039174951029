import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  SelectSize,
  VfiSelectOption,
  VfiSelectParentData,
} from '@vfi-ui/models';

@Component({
  selector: 'atom-vfi-select',
  templateUrl: './vfi-select.component.html',
  styleUrls: ['./vfi-select.component.scss'],
})
export class VfiSelectComponent {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() size = SelectSize.MEDIUM;
  @Input() ariaLabel = 'vfi-select';
  @Input() placeholder = '';
  @Input() value: string;
  @Input() label: string;
  @Input() hint: string;
  @Input() required = false;
  @Input() iconLeft: string;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() subtle = false;
  @Input() success = false;
  @Input() warning = false;
  @Input() warningMessage: string;
  @Input() error = false;
  @Input() errorMessage: string;
  @Input() showSearch = true;
  @Input() options: VfiSelectOption[] = [];
  @Input() loading = false;
  @Input() parentData: VfiSelectParentData;
  @Input() canClear = false;
  @Input() enableDropdown = true;
  @Input() autofocus = false;
  @Input() closeOnSelect = false;
  @Input() placement = null;
  @Output() cleared: EventEmitter<boolean> = new EventEmitter();
  @Output() seached: EventEmitter<string> = new EventEmitter();
  @Output() dropdownVisibleChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() scrolled: EventEmitter<boolean> = new EventEmitter();
  @Output() backSelected: EventEmitter<VfiSelectParentData> =
    new EventEmitter();
  @Output() optionSelected: EventEmitter<VfiSelectOption> = new EventEmitter();
  @Output() drilldownSelected: EventEmitter<VfiSelectOption> =
    new EventEmitter();
  showDropdown = false;

  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * handle option select event
   *
   * @param {VfiSelectOption} option
   * @memberof VfiSelectComponent
   */
  onOptionSelect(option: VfiSelectOption) {
    if (!option.hasChildren) {
      this.optionSelected.emit(option);
    } else {
      this.drilldownSelected.emit(option);
    }

    if (this.closeOnSelect) {
      this.showDropdown = false;
      this.onPopoverVisibleChange(false);
    }
  }

  /**
   * handle on search event
   *
   * @param {string} ev
   * @memberof VfiSelectComponent
   */
  onSearch(search: string) {
    this.seached.emit(search);
  }

  /**
   * handle on back select event
   *
   * @param {VfiSelectParentData} parent
   * @memberof VfiSelectComponent
   */
  onBackSelected(parent: VfiSelectParentData) {
    this.backSelected.emit(parent);
  }

  /**
   * handle popover visible change event
   *
   * @param {boolean} show
   * @memberof VfiSelectComponent
   */
  onPopoverVisibleChange(show: boolean) {
    this.dropdownVisibleChanged.emit(show);
    if (show) {
      this.autoFocusSearch();
    }
  }

  /**
   * handle select clicked event
   *
   * @memberof VfiSelectComponent
   */
  onSelectClicked() {
    if (!this.enableDropdown) {
      this.dropdownVisibleChanged.emit(true);
    }
  }

  /**
   * handle options scroll event
   *
   * @memberof VfiSelectComponent
   */
  onOptionsScroll() {
    this.scrolled.emit(true);
  }

  /**
   * auto focus on search input
   *
   * @memberof VfiSelectComponent
   */
  autoFocusSearch() {
    this.cdr.detectChanges();
    if (this.autofocus && this.showSearch) {
      this.searchInput.nativeElement.focus();
    }
  }
}
