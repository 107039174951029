import { Location } from './locations.interface';
import {
  Integrations,
  OrderDirection,
  TileType,
  WorkTicketSortField,
  AlarmPropertyVoteType,
  nuisanceType,
  AlarmEntity,
  EndTimeSource,
  AlarmType,
  LensCategory,
  BaseAlarmStatus,
} from '@vfi-ui/models';
import { User } from './user.interface';
import { WorkConfiguration, WorkTicket } from './work-order.interface';

export class BaseAlarm {
  id?: string | number;
}

export interface RawAttributes {
  field?: string;
  value?: string;
}

export interface AlarmInstance extends BaseAlarm {
  ackBy?: string;
  ackDuration?: string;
  ackTime?: string;
  unpinTime?: string;
  alarmTime?: string;
  alarm?: CoreAlarm;
  alarmId?: string;
  duration?: string | number;
  endTime?: string;
  endTimeSource?: EndTimeSource;
  processVariableValue?: ProcessVariableValue;
}

export interface CoreAlarm extends BaseAlarm {
  displayId: string;
  status: string;
  state?: string;
  latestAlarmTime: string;
  latestEndTime?: string;
  lastWorkTicketClosedAt?: Date;
  contextualName: string;
  databaseId: string;
  isAutoDispatch: boolean;
  isPinned: boolean;
  isStale: boolean;
  priority: number;
  point?: string;
  text: string;
  activeWorkCount: number;
  fleetingCount: number;
  chatteringCount: number;
  nuisanceCount: number;
  lastFleetingCount: number;
  lastChatteringCount: number;
  lastNuisanceCount: number;
  isNuisance?: boolean;
  externalSystem?: Integrations;
  properties: BaseAlarmProperty[];
  cmmsAttributes?: { [key: string]: string };
  rawAttributes: RawAttributes[];
  updatedFields?: UpdatedField[];
  notes?: string;
  formattedRawText?: string;
  entityIds?: number[];
  entities?: AlarmEntity[];
  alarmType?: AlarmType;
  duration?: string;
  nuisanceBehavior?: string;
  alarmAssetName?: string;
  alarmName?: string;
  spaceType?: string;
  systemType?: string;
  systemName?: string;
  componentType?: string;
  componentName?: string;
  lastWorkTicketCreatedAt?: string;
  openWorkTickets?: WorkTicket[];
  isServiceImpacting?: boolean;
  snoozeExpiresAt?: string;
  assetCriticality?: number;
  priorityLastModifiedBy?: string;
  serviceImpactLastModifiedBy?: string;
  pointEntityId?: number;
  pointEntityLastModifiedBy?: string;
}

export interface FastAlarm {
  contextualName: string;
  assetCriticality: number;
  activeWorkCount: number;
  id: number;
  latestAlarmTime: string;
  latestEndTime: string;
  lastWorkTicketCreatedAt: string;
  priority: number;
  isPinned: boolean;
  basPriority: number;
  fleetingCount: number;
  chatteringCount: number;
  isServiceImpacting: boolean;
  text: string;
  isStale: boolean;
  nuisanceCount: number;
  status: BaseAlarmStatus;
  alarmType: AlarmType;
  condition: string[];
  category: string[];
  flowDirection: string[];
  instrumentType: string[];
  medium: string[];
  parameter: string[];
  parameterPrefix: string[];
  shop: string[];
  building: string;
  floor: string;
  room: string;
  externalSystemDisplayName: string;
  alarmName: string;
  alarmAssetName: string;
  componentName: string;
  componentType: string;
  spaceName: string;
  spaceType: string;
  equipmentName: string;
  equipmentType: string;
  systemName: string;
  systemType: string;
  openWorkTicketIds: number[];
}

export interface UpdatedField {
  field: string;
  updatedValue: string[];
}

export interface BaseAlarmProperty {
  type: string;
  values: string[];
  confidence?: number;
  highlightConfidenceThreshold?: number;
}
export interface CoreAlarmsWhere {
  alarmTime?: CoreAlarmsWhereDatetimeRange;
  databaseId?: string;
  endTime?: CoreAlarmsWhereDatetimeRange;
  isAutoDispatch?: boolean;
  isPinned?: boolean;
  pattern?: string;
  priority?: number;
  nuisance?: boolean;
  priorities?: number[];
  statuses?: string[];
  minBasPriority?: number;
  maxBasPriority?: number;
  minChattering?: number;
  maxChattering?: number;
  minFleeting?: number;
  maxFleeting?: number;
  minActiveWork?: number;
  minNuisance?: number;
  isStale?: boolean;
  isNuisance?: nuisanceType;
  ids?: (number | string)[];
  text?: string;
  properties?: { type: string; values: string[] }[];
  workTicketIds?: number[];
  isActive?: boolean;
  alarmTypes?: AlarmType[];
  assetCriticality?: number[];
}

export interface CoreAlarmsFiltersWhere {
  where?: CoreAlarmsWhere;
  whereNot?: CoreAlarmsWhere;
}

export interface CoreAlarmsWhereDatetimeRange {
  from: string;
  to: string;
}

export interface AlarmsAndCountQuery {
  alarmsAndCount: {
    Alarm: CoreAlarm[];
    count: number;
  };
}

export interface FastAlarmsAndCountQuery {
  fastAlarms: FastAlarm[];
  alarmsAndCount: { count: number };
}

export interface AlarmInstancesAndCountQuery {
  alarmInstancesAndCount: {
    Alarm: AlarmInstance[];
    count: number;
    loading: boolean;
  };
}

export interface AlarmActivityAndCountQuery {
  activity: AlarmActivity[];
  count: number;
}

export interface AlarmsAndCountResolve {
  Alarm: CoreAlarm[];
  count: number;
  loading: boolean;
  alarmQueryWhere?: CoreAlarmsWhere;
}

export interface FastAlarmsAndCountResolve {
  Alarm: FastAlarm[];
  count: number;
  loading: boolean;
  alarmQueryWhere?: CoreAlarmsWhere;
}

export interface CoreAlarmsOptions {
  limit?: number;
  offset?: number;
  order?: CoreAlarmsOrderOptions;
  where?: CoreAlarmsWhere;
  whereNot?: CoreAlarmsWhere;
}
export interface CoreTicketOptions {
  limit?: number;
  offset?: number;
  order?: CoreTicketOrderOptions[];
  where?: CoreAlarmsWhere;
  whereNot?: CoreAlarmsWhere;
}

export interface CoreAlarmsOrderOptions {
  field: CoreAlarmsOrderSortField;
  field2?: string | null;
  direction: CoreAlarmsOrderSortDirection;
}

export interface CoreTicketOrderOptions {
  field: WorkTicketSortField;
  field2?: string | null;
  direction: OrderDirection;
}

export interface CoreAlarmConfiguration {
  priority?: number;
  buildings?: string[];
  floors?: string[];
  rooms?: string[];
  parameters?: string[];
  mediums?: string[];
  contacts?: string[];
  conditions?: string[];
  affixes?: string[];
  equipment?: string[];
  sensors?: string[];
  areas?: string[];
  specialInstructions?: string[];
  systemTypes?: string[];
  equipmentTypes?: string[];
  spaceTypes?: string[];
}

export interface Alarm extends BaseAlarm {
  displayId: string;
  buildings?: string;
  floors?: string;
  rooms?: string;
  mediums?: string;
  parameters?: string;
  conditions?: string;
  equipmentTypes?: string;
  equipment?: string;
  sensors?: string;
  spaceTypes?: string;
  systemTypes?: string;
  contacts?: string;
  text?: string;
  databaseId?: string;
  contextualName?: string;
  priority?: number;
  alarmTime?: string;
  status?: string;
  isAutoDispatch: boolean;
  fleetingCount: number;
  chatteringCount: number;
  nuisanceCount: number;
  lastFleetingCount: number;
  lastChatteringCount: number;
  lastNuisanceCount: number;
  isNuisance: boolean;
  workOrderConfiguration?: WorkConfiguration;
  isPinned?: boolean;
  isStale?: boolean;
  rawAttributes: RawAttributes[];
}

export interface AlarmInstanceCreated extends BaseAlarm {
  ackTime: string;
  ackBy: string;
  ackDuration: string;
  endTime: string;
  duration: string;
  alarm_id?: BaseAlarm['id'];
  alarmId?: BaseAlarm['id'];
  chattering: string;
  fleeting: string;
}

export interface LiveAlarmUpdated {
  new: LiveAlarmUpdateChanges;
  old: LiveAlarmUpdateChanges;
}

export interface LiveAlarmUpdateChanges extends BaseAlarm {
  text: string;
  parsedText: string;
  priority: number;
  alarmKey: string;
  schema: string;
  databaseId: string;
  isAutoDispatch: boolean;
  work_order_configuration_id: string;
  contextualName: number | string;
  OBJECT_: string;
  DEVICE_: string;
  tostate: number;
  TO_STATE_: string;
  status: string;
  isPinned: boolean;
}
export interface AlarmProperty {
  id?: string | number;
  value?: any;
  name?: string;
  custom?: boolean;
  type?: string;
  alarmCount?: number;
  sortOrder?: number;
  totalCount?: number;
  activeCount?: number;
  alarmDensityThresholdLastExceededAt?: string;
  lastHourChange?: number;
  lastTwoHourChange?: number;
  children?: AlarmProperty[];
  alarms?: CoreAlarm[];
  densityExceededAt?: string;
  priorityActiveCount?: number;
  priorityTotalCount?: number;
  priorityExceededAt?: string;
  priorityHourChange?: number;
  priorityLastTwoHourChange?: number;
  hasLocations?: boolean;
  locations?: Location[];
}

export interface SelectedAlarmProperty extends AlarmProperty {
  hidden?: boolean;
  selected?: boolean;
}
export interface ChangeAlarm extends BaseAlarm {
  excludeIds?: number[];
  status: boolean | number;
}

export interface Tile {
  tileTitle: string;
  altTitle?: string | null;
  tileID: BaseAlarm['id'];
  tileDisplayID: string;
  dateTime: string;
  endDateTime?: string;
  tileSubText: string[];
  selected: boolean;
  checked: boolean;
  loading: boolean;
  pinned: boolean;
  priorityIcon: string;
  status: string;
  top1Icon: string;
  top2Icon: string;
  top3Icon: string;
  top4Icon: string;
  level3Icon: string;
  level2Icon: string;
  priorityIconColor: string;
  chatteringFleetingTotal: number;
  footer?: string;
  top1Text?: string;
  priority?: number;
  source?: string;
  closed?: boolean;
  user?: string;
  successfulWork?: boolean;
  count?: number;
  totalCount?: number;
  cmmsSyncStatus?: string;
  cmmsHeaderHasBeenDismissed?: boolean;
  externalSystem?: Integrations;
  entityIds?: number[];
  entities?: AlarmEntity[];
  isExternalSystemActive?: boolean;
  assignee?: string;
  assigneeAvatarUrl?: string;
}

export interface AlarmActivityMetadata {
  statusUpdateReason?: string;
  snoozeReason?: string;
  snoozeDuration?: number;
}

export interface AlarmActivity {
  id?: string;
  alarmId?: number;
  field: string;
  revValue: string;
  prevValue: string;
  createdAt: string;
  user: User;
  metadata?: AlarmActivityMetadata;
}

export enum AlarmActivityStaticField {
  Status = 'Status',
  AlarmType = 'Asset Class',
  Priority = 'Priority',
  IsPinned = 'Is Pinned',
  Notes = 'Notes',
  EndTime = 'End Time',
  Point = 'Point',
  Snooze = 'Snooze',
  Unsnooze = 'Unsnooze',
  SnoozeEnded = 'Snooze Ended',
  ServiceImpact = 'Service Impact',
}

export interface AlarmLens {
  parent: string;
  child: string;
  parentId?: string;
  childId?: string;
  isCustom?: boolean;
  type?: TileType;
  category?: LensCategory;
}

export interface TableColumn {
  isEditable: boolean;
  isVisible: boolean;
  name: string;
  value: string;
  subGroup: string;
  isAlarmAttribute: boolean;
}

export interface AlarmTableColumns extends TableColumn {
  hasConfidence: boolean;
}

export interface CoreNuisanceAlarm {
  trendingFleeting?: number;
  trendingChattering?: number;
  previousFleetingCount?: number;
  previousChatteringCount?: number;
  currentChatteringCount?: number;
  alarm_id?: number;
  currentFleetingCount?: number;
  Alarm?: CoreAlarm;
  search_chattering_fleeting_alarms_aggregate?: {
    aggregate: {
      count: number;
    };
  };
}

export interface AlarmConfiguarationData {
  values: string[];
  names: string[];
  fullValues?: AlarmConfiguarationData[];
}

export interface AlarmConfiguration {
  type?: string;
  items?: AlarmProperty[];
  confidence?: number;
  highlightConfidenceThreshold?: number;
  ids?: number[];
  values?: string[];
  voteType?: AlarmPropertyVoteType;
}

export interface AlarmProperties {
  alarmPropertyConfiguration?: Alarm;
  workOrderConfiguration?: WorkConfiguration;
  buildings?: AlarmConfiguarationData;
  conditions?: AlarmConfiguarationData;
  contacts?: AlarmConfiguarationData;
  equipment?: AlarmConfiguarationData;
  equipmentTypes?: AlarmConfiguarationData;
  floors?: AlarmConfiguarationData;
  mediums?: AlarmConfiguarationData;
  parameters?: AlarmConfiguarationData;
  rooms?: AlarmConfiguarationData;
  sensors?: AlarmConfiguarationData;
  spaceTypes?: AlarmConfiguarationData;
  systemTypes?: AlarmConfiguarationData;
}

export enum CoreAlarmsOrderSortDirection {
  asc = 'ASC',
  desc = 'DESC',
  ASC = 'ASC',
  DESC = 'DESC',
}
export enum CoreAlarmsOrderSortField {
  alarmTime = 'LATEST_ALARM_TIME',
  alarmStatus = 'STATUS',
  latestAlarmTime = 'LATEST_ALARM_TIME',
  id = 'ID',
  chattering = 'chatteringCount',
  databaseId = 'databaseId',
  endTime = 'endTime',
  fleeting = 'fleetingCount',
  isAutoDispatch = 'isAutoDispatch',
  nuisance = 'NUISANCE_COUNT',
  activeWork = 'activeWorkCount',
  priority = 'PRIORITY',
  isPinned = 'IS_PINNED',
  text = 'TEXT',
  state = 'STATE',
  contextualName = 'CONTEXTUAL_NAME',
  isActive = 'IS_ACTIVE',
  roomName = 'room_name',
  roomNumber = 'room_number',
  status = 'status',
  basPriority = 'BAS_PRIORITY',
}

export interface AlarmGroupBy {
  time?: string;
  groupBy?: string;
  count?: number;
}

export interface AlarmGroupByQuery {
  loading: boolean;
  data: AlarmGroupBy;
}

export interface AlarmTimeWindow {
  from: Date;
  to: Date;
}

export interface ComparisonPeriod {
  alarmTimeWindow: AlarmTimeWindow;
}

export interface AlarmInstanceCount {
  time: number | Date;
  count: number;
}

export interface AlarmExcludeProperties {
  type: string;
  values: string[];
}

export interface AlarmExcludeWhere {
  externalSystemId?: number[];
  buildings?: number[];
  equipmentTypes?: number[];
  floors?: number[];
  mediums?: number[];
  parameters?: number[];
  priorities?: number[];
  isPinned?: boolean;
  sensors?: number[];
  spaceTypes?: number[];
  minNuisance?: number;
  minChattering?: number;
  minFleeting?: number;
  maxChattering?: number;
  minBasPriority?: number;
  maxBasPriority?: number;
  maxFleeting?: number;
  isStale?: boolean;
  text?: string;
  alarmTime?: {
    from: string;
    to: string;
  };
  properties?: AlarmExcludeProperties[];
}

export interface BulkChange {
  [x: string]: string | number | boolean;
}

export interface AlarmPropertyObjectChange {
  value: string;
  parentId: string;
}

export interface ProcessVariableValue {
  value: boolean | string;
  unit: string;
}

export interface FormattedChartData {
  name: string;
  y: number;
  color: string;
  numValue: string;
}

export interface AlarmDetailsUpdate {
  configuration: AlarmConfiguration[];
  priority?: number;
  isServiceImpacting?: boolean;
  entityId?: number;
}

export interface AlarmLocationSelect {
  id: number;
}

export interface AlarmEntityPath {
  id: number;
  name: string;
  class: string;
  baseClass: string;
  depth: number;
}

export interface AlarmPropertyQuery {
  items: AlarmProperty[];
  count: number;
}
