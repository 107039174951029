<div [ngClass]="{ 'cursor-not-allowed': disabled }">
  <div
    class="vfi-input {{ size }}"
    [ngClass]="{
      'pointer-none': readOnly || disabled,
      disabled,
    }"
  >
    <div
      *ngIf="label"
      class="vfi-label"
      [class.yellow-6]="highlightRequired && required && !readOnly"
    >
      <span>{{ label }}</span>
      <span *ngIf="required">&nbsp;(Required)</span>
    </div>
    <div
      class="input-icons position-relative"
      [ngClass]="{ subtle, success, warning, error }"
    >
      <span [ngClass]="{ hasIcon: iconLeft }"></span>
      <input
        *ngIf="!allowSpecialCharacters"
        #inputElm
        atomPreventSpecialCharacter
        nz-input
        [ngClass]="{
          'input-icon-left-spacing': iconLeft,
          'input-icon-right-spacing': value && !success && !error && !warning,
          'input-icon-right-state-spacing':
            value && !subtle && (success || warning || error),
          readOnly,
          disabled,
        }"
        [placeholder]="placeholder"
        [ngModel]="value"
        (blur)="onBlur($event)"
        (ngModelChange)="changed($event)"
      />
      <input
        *ngIf="allowSpecialCharacters"
        #inputElm
        nz-input
        [type]="type"
        [ngClass]="{
          'input-icon-left-spacing': iconLeft,
          'input-icon-right-spacing': value && !success && !error && !warning,
          'input-icon-right-state-spacing':
            value && !subtle && (success || warning || error),
          readOnly,
          disabled,
        }"
        [placeholder]="placeholder"
        [ngModel]="value"
        (blur)="onBlur($event)"
        (ngModelChange)="changed($event)"
      />
      <i class="{{ iconLeft }} icon icon-left"></i>
      <span class="action-icons">
        <i
          *ngIf="!value && value === '' && !readOnly && !disabled"
          class="fa-solid fa-pen icon icon-right edit-icon"
          [ngClass]="{
            'right-icon-no-state-spacing':
              !success && !warning && !error && !subtle,
            'right-icon-state-spacing':
              (success || warning || error) && !subtle,
          }"
        ></i>
        <i
          *ngIf="value && !readOnly && !disabled && showCancel"
          class="fa-regular fa-circle-xmark icon icon-right cursor-pointer clear-icon"
          [ngClass]="{
            'right-icon-no-state-spacing':
              !success && !warning && !error && !subtle,
            'right-icon-state-spacing':
              (success || warning || error) && !subtle,
          }"
          (click)="clear()"
        ></i>
      </span>
      <span>
        <i
          *ngIf="success && !subtle"
          class="fa-solid fa-check icon cursor-pointer success-icon icon-right"
        ></i>
        <i
          *ngIf="warning && !subtle"
          class="fa-solid fa-triangle-exclamation icon cursor-pointer warning-icon icon-right"
        ></i>
        <i
          *ngIf="error && !subtle"
          class="fa-solid fa-circle-exclamation icon cursor-pointer error-icon icon-right"
        ></i>
      </span>
    </div>
    <div class="edit-btns" *ngIf="showEditButtons">
      <atom-vfi-button
        icon="fa-solid fa-check"
        type="secondary"
        [size]="buttonSize.SMALL"
        (click)="onSave(value)"
      ></atom-vfi-button>
      <atom-vfi-button
        icon="fa-solid fa-x"
        type="secondary"
        [size]="buttonSize.SMALL"
        (click)="onCancel()"
      ></atom-vfi-button>
    </div>
    <p *ngIf="hint && !warning && !error" class="mb-0 hint-text">{{ hint }}</p>
    <p *ngIf="warning && warningMessage && !subtle" class="mb-0 hint-text">
      {{ warningMessage || hint }}
    </p>
    <p *ngIf="error && errorMessage && !subtle" class="mb-0 error-text">
      {{ errorMessage || hint }}
    </p>
  </div>
</div>
