import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SsoService } from '@vfi-ui/data-access/shared';
import { SsoConfig } from '@vfi-ui/models';
import { keys } from '@vfi-ui/util/helpers';

@Component({
  selector: 'nuclei-create-sso',
  templateUrl: './create-sso.component.html',
  styleUrls: ['./create-sso.component.scss'],
})
export class CreateSsoComponent implements OnChanges {
  @Input() isVisible = false;
  @Input() mode: string;
  @Input() form: FormGroup;
  @Input() model: any;
  @Input() editData: SsoConfig;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @Output() createSso: EventEmitter<SsoConfig> = new EventEmitter();
  @Output() updateSso: EventEmitter<SsoConfig> = new EventEmitter();
  @Output() deleteSso: EventEmitter<string> = new EventEmitter();

  constructor(private readonly ssoService: SsoService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.mode?.currentValue === 'edit') {
      this.populateEditForm(this.editData);
    }
  }

  /**
   * Populates the edit form
   *
   * @param {SsoConfig} data
   * @memberof CreateSsoComponent
   */
  populateEditForm(data: SsoConfig) {
    keys(this.form.value).forEach((key) =>
      this.form.controls[key].setValue(data[key])
    );
  }

  /**
   * Handles creating sso
   *
   * @memberof CreateSsoComponent
   */
  handleCreateSso() {
    this.createSso.emit({ ...this.form.value });
  }

  /**
   * Handles updating sso
   *
   * @memberof CreateSsoComponent
   */
  handleUpdateSso() {
    this.updateSso.emit({ ...this.form.value });
  }

  /**
   * Handles deleting sso
   *
   * @memberof CreateSsoComponent
   */
  handleDeleteSso() {
    this.deleteSso.emit(this.form.value['emailDomain']);
  }

  /**
   * Handles uploading metadata and updating form
   *
   * @param {*} event
   * @memberof CreateSsoComponent
   */
  handleUpload = (item: { file: File }) => {
    this.ssoService.uploadMetdata(item.file).subscribe((res) => {
      this.form.patchValue({ ...res });
      this.form.markAsDirty();
    });
  };
}
