import { ExternalCmmsResolvedField } from './cmms.interface';
import {
  EntityWorkTicketInput,
  WorkTicketEntity,
} from './situational-awareness.interface';
import { AutomationRule } from './automation-rules.interface';
import { WorkTicketActivity } from './activity-log.interface';
import {
  WorkTicketCmmsSyncStatus,
  WorkTicketProcessState,
} from './../constants/work-ticket.constant';
import {
  BaseAlarmProperty,
  CoreAlarm,
  Tile,
  AlarmInstance,
  FastAlarm,
} from './alarm-instance.interface';
import {
  Users,
  WorkTicketStatus,
  WorkTicketSortField,
  OrderDirection,
  Integrations,
} from '..';
export interface WorkOrder {
  id: number;
  shop: string;
  status: string;
  timeClosed: string;
  timeEntered: string;
  alarms_aggregate?: any;
  timeRequired?: string;
  timeScheduled?: string;
  description?: string;
  building?: string;
  floor?: string;
  room?: string;
  alarmCount?: number;
  isClosed?: boolean;
  apiId?: string;
  unit?: string;
  category?: string;
  type?: string;
  priority?: string;
  phone?: string;
  campus?: string;
  equipmentType?: string;
  equipment?: string;
  maintenance?: string;
  notes?: string;
  interimMeasure?: boolean;
  billable?: boolean;
  posted?: boolean;
  timeReceived?: string;
  timePosted?: string;
  timeStarted?: string;
  timeCompleted?: string;
  source?: string;
  alarms?: CoreAlarm[];
  workOrderNumber?: string;
  wonum?: string;
  TMARequestNumber?: string;
  TMAWorkOrderNumber?: string;
}

export interface WorkConfiguration {
  description?: string;
  building?: string;
  unit?: string;
  shop?: string;
  status?: string;
  category?: string;
  type?: string;
  priority?: string;
  phone?: string;
  campus?: string;
  floor?: string;
  room?: string;
  equipmentType?: string;
  equipment?: string;
  maintenance?: string;
  billable?: boolean;
  interimMeasure?: boolean;
  location?: string;
  asset?: string;
  workType?: string;
  workGroup?: string;
}

export interface WorkTicketsAndCountQuery {
  items: WorkTicket[];
  count: number;
}
export interface WorkTicketsHistoryAndCountQuery {
  items: WorkTicket[];
  count: number;
}
export interface WorkTicketsActivityAndCountQuery {
  items: WorkTicketActivity[];
  count: number;
}

export interface WorkOrderConfiguration {
  isAutoDispatch?: boolean;
  workOrderConfiguration?: Partial<WorkConfiguration>;
}
export interface WorkOrderQueryData {
  id?: string;
  property?: string;
  data: WorkOrderConfiguration;
}
export interface WorkTicket {
  id: number;
  displayId: string;
  assignee: Users;
  reviewer: Users;
  closedAt: string;
  createdAt: string;
  createdBy: Users;
  closedBy: Users;
  completedWorkDetails: string;
  state: WorkTicketProcessState;
  status: WorkTicketStatus;
  dueAt: string;
  lastModifiedAt: string;
  objective: string;
  problemDetails: string;
  externalId: string;
  externalType: string;
  externalDetails: WorkOrder;
  externalStatus: string;
  logs: WorkTicketAuditLog[];
  workTicketActivity: WorkTicketActivity[];
  alarms: CoreAlarm[];
  associatedAlarms?: Tile[];
  hasSurvey: boolean;
  alarmCount: number;
  isExternalSyncEnabled: boolean;
  isClosedExternally?: boolean;
  oneHourSinceLastClosed?: CoreAlarm[];
  twentyFourHourSinceLastClosed?: CoreAlarm[];
  oneDaySinceLastClosed?: AlarmInstance;
  alarmsUnresolvedAfterClose?: CoreAlarm[];
  automationRule?: AutomationRule;
  cmmsSyncStatus?: WorkTicketCmmsSyncStatus;
  lastCmmsSyncAttemptAt?: Date;
  lastCmmsSyncAttemptBy?: string;
  cmmsSyncFailureNotificationDismissedBy?: string;
  cmmsSyncFailureNotificationDismissedAt?: Date;
  formattedExternalDetails?: ExternalCmmsResolvedField[];
  reissueEntity?: string;
  entities?: WorkTicketEntity[];
  closestEntityImpactAt: Date;
  isResolved?: boolean;
  externalSystem?: Integrations;
  assigneeName?: string;
}

export interface WorkTicketStatistics {
  preAlarmReoccurences: number;
  postAlarmReoccurences: number;
  preWorkReoccurences: number;
  postWorkReoccurences: number;
  alarmInstanceReoccurenceFirst: AlarmInstance;
  workReoccurenceFirst: WorkTicket;
}

export interface WorkTicketHistory {
  alarmCount: number;
  assignee: Users;
  closedAt: string;
  createdAt: string;
  id: number;
  objective: string;
  postAlarmReoccurences: number;
  postWorkReoccurences: number;
  preAlarmReoccurences: number;
  preWorkReoccurences: number;
  state: WorkTicketProcessState;
  status: WorkTicketStatus;
  alarmReduction?: string;
  workReduction?: string;
}

export interface CoreWorkFiltersWhere {
  where?: WorkTicketWhere;
  whereNot?: WorkTicketWhere;
}

export interface WorkTicketOptions {
  limit: number;
  offset: number;
  order: WorkTicketOrder | WorkTicketOrder[];
  where: WorkTicketWhere;
  whereNot?: WorkTicketWhere;
}
export interface WorkTicketWhere {
  ids?: number[];
  pattern?: string;
  assignee?: string[];
  reviewer?: string[];
  createdBy?: string[];
  state?: WorkTicketProcessState;
  status?: WorkTicketStatus;
  createdAt?: DatetimeRange;
  closedAt?: DatetimeRange;
  dueAt?: DatetimeRange;
  ageDaysMin?: number;
  ageDaysMax?: number;
  isRecentlyClosed?: boolean;
  isCreatedByAutomationRule?: boolean;
  alarmIds?: number[];
  associatedAlarmProperties?: BaseAlarmProperty[];
  isResolved?: boolean;
  teamIds?: string[];
}
export interface WorkTicketDetailsCreateInput {
  objective: string;
  problemDetails: string;
  assignedTo: string;
  dueBy: string;
  activity: Partial<WorkTicketActivity>[];
}
export interface WorkTicketOrder {
  field: WorkTicketSortField;
  direction: OrderDirection;
}

export interface WorkTicketCreateInput {
  details: WorkTicketDetailsCreateInput;
  additionalDetails?: any;
  associatedAlarms: (string | number)[];
  affectedSpace?: any;
  isExternalSyncEnabled?: boolean;
  externalSystemId?: number;
}

export interface WorkTicketAuditLog {
  id: string;
  workTicketId: string;
  state: WorkTicketProcessState;
  status: WorkTicketStatus;
  createdAt: string;
  createdBy: Users;
}

export interface DatetimeRange {
  from: string;
  to: string;
}

export interface DateTimeRangeChange {
  dates: { timeFrame: DatetimeRange; previousTimeFrame: DatetimeRange };
  range: number;
}

export interface WorkTicketSurvey {
  difficulty?: number;
  durationHours?: number;
  notes?: string;
  expectedCost?: number;
  expectedDuration?: number;
  expectedResults?: number;
}
export interface WorkTicketStateChange {
  id: number;
  state: WorkTicketProcessState;
  survey?: WorkTicketSurvey;
}
export interface WorkTicketDetailsUpdateInput {
  problemDetails?: string;
  assignedTo?: string;
  spaceLocationProperties?: BaseAlarmProperty[];
  state?: string;
  status?: string;
  associatedEntities?: EntityWorkTicketInput[];
}

export interface CurrentWorkOrderDomainQuery {
  currentWorkOrderDomain: string[];
  currentWorkOrderDomainCount: number;
}

export interface FastWorkTicket {
  id: number;
  state: WorkTicketProcessState;
  status: WorkTicketStatus;
  objective: string;
  isOverlapped: boolean;
  displayId: string;
  isResolved: boolean;
  createdAt: string;
  closedAt: string;
  automationRuleId: number;
  dueAt: string;
  externalId: number;
  cmmsSyncStatus: WorkTicketCmmsSyncStatus;
  cmmsSyncFailureNotificationDismissedAt: string;
  cmmsSyncFailureNotificationDismissedBy: string;
  externalStatus: string;
  externalSystemName: string;
  isRepeat: boolean;
  assignee: string;
  assigneeAvatarUrl: string;
  creator: string;
  alarms: FastAlarm[];
  activeAlarmCount: number;
  inactiveAlarmCount: number;
  isExternalSystemActive: boolean;
}
