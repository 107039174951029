<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="{{ mode === 'create' ? 'Create Sso' : 'Update Sso' }}"
  [nzBodyStyle]="{ 'min-height': '400px' }"
  [nzFooter]="modalFooter"
  [nzWidth]="900"
  (nzOnCancel)="closeModal.emit()"
>
  <ng-template nzModalContent>
    <section
      aria-label="createSsoFields"
      class="d-flex flex-column scrollable-frame modalBodyHeight"
    >
      <div class="d-flex flex-column w-100" [formGroup]="form">
        <div class="d-flex">
          <div class="d-flex flex-column w-100">
            <div
              class="input-wrapper mr-2 mb-1"
              [ngClass]="{
                'input-active': form?.controls?.emailDomain?.value
              }"
              nzTooltipTitle="This is typically the domain name of the customers users e.g. bloomberg.net"
              nzTooltipPlacement="topLeft"
              nz-tooltip
            >
              <input
                aria-label="profile-entity-id"
                [ngClass]="{
                  'required-field': form.controls.emailDomain.invalid
                }"
                #emailDomain
                type="text"
                class="form-control"
                formControlName="emailDomain"
                [disabled]="mode !== 'create'"
                [readonly]="mode !== 'create'"
              />
              <label>Email Domain</label>
            </div>
            <div
              class="input-wrapper mr-2 mb-1"
              [ngClass]="{
                'input-active': form?.controls?.entityId?.value
              }"
            >
              <input
                aria-label="profile-entity-id"
                [ngClass]="{
                  'required-field': form.controls.entityId.invalid
                }"
                #entityId
                type="text"
                class="form-control"
                formControlName="entityId"
              />
              <label>Identifier</label>
            </div>
          </div>
          <div class="d-flex flex-column w-100">
            <div
              class="input-wrapper mr-2 mb-1"
              [ngClass]="{
                'input-active': form?.controls?.providerEntityId?.value
              }"
              nzTooltipTitle="This is the id (or name) entered by the customer within their SSO identity provider. Its used to identify Virtual Facility."
              nzTooltipPlacement="topLeft"
              nz-tooltip
            >
              <input
                aria-label="profile-provider-entity-id"
                [ngClass]="{
                  'required-field': form.controls.providerEntityId.invalid
                }"
                #providerEntityId
                type="text"
                class="form-control"
                formControlName="providerEntityId"
              />
              <label>Entity Id</label>
            </div>
            <div
              class="input-wrapper mr-2 mb-1"
              [ngClass]="{
                'input-active': form?.controls?.ssoUrl?.value
              }"
            >
              <input
                aria-label="profile-sso-url"
                [ngClass]="{
                  'required-field': form.controls.ssoUrl.invalid
                }"
                #ssoUrl
                type="text"
                class="form-control"
                formControlName="ssoUrl"
              />
              <label>Login Endpoint</label>
            </div>
          </div>
        </div>
        <div
          class="input-wrapper mr-2 mb-1 cert-input"
          [ngClass]="{
            'input-active': form?.controls?.certificate?.value
          }"
        >
          <textarea
            [maximumHeight]="1000"
            [minHeight]="48"
            maxlength="5000"
            atomAutoGrow
            aria-label="profile-certificate"
            [ngClass]="{
              'required-field': form.controls.certificate.invalid
            }"
            #certificate
            type="text"
            class="form-control"
            formControlName="certificate"
          ></textarea>
          <label>Certificate</label>
        </div>
      </div>
    </section>
  </ng-template>
  <ng-template #modalFooter>
    <div class="input-wrapper mr-2 mb-1">
      <nz-upload
        nzAccept=".xml"
        [nzSize]="1000"
        [nzCustomRequest]="handleUpload"
        [nzShowUploadList]="false"
        class="mt-3"
      >
        <atom-vfi-button
          text="Upload Metadata"
          icon="fas fa-file-upload"
          iconPosition="left"
          type="primary"
        ></atom-vfi-button>
      </nz-upload>
    </div>
    <div class="w-100 d-flex justify-content-end">
      <atom-vfi-button
        [text]="'Cancel'"
        [type]="'secondary'"
        (clicked)="closeModal.emit()"
      ></atom-vfi-button>
      <atom-vfi-button
        *ngIf="mode === 'create'"
        class="ml-1"
        [text]="'Create Sso'"
        [type]="'primary'"
        [disabled]="form.invalid"
        (clicked)="handleCreateSso()"
      ></atom-vfi-button>
      <atom-vfi-button
        *ngIf="mode === 'edit'"
        class="ml-1"
        [text]="'Save Sso'"
        [type]="'primary'"
        [disabled]="form.invalid || form.pristine"
        (clicked)="handleUpdateSso()"
      ></atom-vfi-button>
      <atom-vfi-button
        *ngIf="mode === 'edit'"
        class="ml-1"
        [text]="'Delete Sso'"
        [type]="'danger'"
        (clicked)="handleDeleteSso()"
      ></atom-vfi-button>
    </div>
  </ng-template>
</nz-modal>

<ng-template #checkedTemplate><i class="fas fa-check dm00"></i></ng-template>
<ng-template #unCheckedTemplate><i class="fas fa-times dm00"></i></ng-template>
