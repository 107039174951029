import { Component, OnInit } from '@angular/core';
import { ReportExportDataService } from '@vfi-ui/data-access/shared';
import { ReportExport, ReportExportStatus } from '@vfi-ui/models';

@Component({
  selector: 'atom-report-export',
  templateUrl: './report-export.component.html',
  styleUrls: ['./report-export.component.scss'],
})
export class ReportExportComponent implements OnInit {
  reportExports: ReportExport[] = [];
  reportExportStatus = ReportExportStatus;

  constructor(private reportExportService: ReportExportDataService) {}

  ngOnInit() {
    this.fetchReportExports();
  }

  /**
   * fetch report exports
   *
   * @memberof ReportExportComponent
   */
  fetchReportExports() {
    this.reportExportService
      .getReportExports()
      .subscribe((e) => (this.reportExports = e));
  }

  /**
   * download export
   *
   * @param {string} fileUrl
   * @param {string} fileName
   * @memberof ReportExportComponent
   */
  downloadExport(fileUrl: string, fileName: string) {
    this.reportExportService
      .downloadReportExport(fileUrl, fileName)
      .subscribe();
  }
}
