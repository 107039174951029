import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule, inject } from '@angular/core';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  InitialNavigation,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { NgIdleModule } from '@ng-idle/core';
import { AuthGuard, DataAccessSharedModule } from '@vfi-ui/data-access/shared';
import { environment } from '@vfi-ui/environments/environment';
import {
  AdminLayoutComponent,
  FeatureAdminDashboardModule,
} from '@vfi-ui/feature/admin-dashboard';
import {
  FeatureCoreModule,
  MeResolver,
  SuperUserResolver,
} from '@vfi-ui/feature/core';
import { StateModule } from '@vfi-ui/state';
import { Indicator404Component } from '@vfi-ui/ui/atoms';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import {
  FeatureAuthModule,
  AuthModuleRoutes,
  GraphQLModule,
} from '@vfi-ui/feature/auth';
import { AppComponent } from './app.component';
import { AG_GRID_LICENSE_KEY, CAPACITOR_CORE_PROVIDER } from '@vfi-ui/models';
import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
registerLocaleData(en);

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [
      (state: RouterStateSnapshot) => inject(AuthGuard).canActivate(state),
    ],
    resolve: {
      me: () => inject(MeResolver).resolve(),
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/users',
      },
      {
        path: '',
        canActivate: [
          (state: RouterStateSnapshot) => inject(AuthGuard).canActivate(state),
        ],
        resolve: {
          me: () => inject(MeResolver).resolve(),
          superUser: () => inject(SuperUserResolver).resolve(),
        },
        children: [
          {
            path: 'users',
            loadChildren: () =>
              import('@vfi-ui/feature/admin').then((m) => m.FeatureAdminModule),
          },
          {
            path: 'alarm-throughput',
            loadChildren: () =>
              import('@vfi-ui/feature/alarm-throughput').then(
                (m) => m.FeatureAlarmThroughputModule
              ),
          },
        ],
      },
    ],
  },
  // Authentication and other `ROOT-LEVEL` Routes
  // ie: /login (within AuthModuleRoutes)
  // ... etc ...
  {
    path: '',
    children: [...AuthModuleRoutes],
  },
  { path: '404', component: Indicator404Component },
  { path: '**', pathMatch: 'full', redirectTo: '404' },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FeatureCoreModule.forRoot(environment),
    FeatureAdminDashboardModule,
    FeatureAuthModule,
    DataAccessSharedModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled' as InitialNavigation,
      paramsInheritanceStrategy: 'always',
    }),
    NgIdleModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    FormsModule,
    HttpClientModule,
    GraphQLModule,
    BrowserAnimationsModule,
    StateModule,
    AgGridModule,
  ],
  providers: [
    MeResolver,
    { provide: NZ_I18N, useValue: en_US },
    SuperUserResolver,
    CAPACITOR_CORE_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
