import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DropdownOption } from '@vfi-ui/models';

@Component({
  selector: 'atom-vfi-dropdown',
  templateUrl: './vfi-dropdown.component.html',
  styleUrls: ['./vfi-dropdown.component.scss'],
})
export class VfiDropdownComponent {
  @Input() options: DropdownOption[];
  @Input() label: string;
  @Input() value: any;
  @Input() allowClear = false;
  @Input() multiple = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() showSearch = true;
  @Input() name: string;
  @Input() showPlaceholder = true;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() searched: EventEmitter<string> = new EventEmitter();
  @Output() scrolled: EventEmitter<string> = new EventEmitter();
  focused = false;
  searchValue = '';

  constructor() {}

  /**
   * handle value change event
   *
   * @param {*} value
   * @memberof VfiDropdownComponent
   */
  valueChanged(value: any) {
    if (value?.label && value?.value) {
      this.valueChange.emit(value.value);
    }
  }

  /**
   * handle search event
   *
   * @param {string} searchTerm
   * @memberof VfiDropdownComponent
   */
  search(searchTerm: string) {
    this.searchValue = searchTerm;
    this.searched.emit(searchTerm);
  }

  /**
   * handle scroll event
   *
   * @param {string} searchTerm
   * @memberof VfiDropdownComponent
   */
  scroll(searchTerm: string) {
    this.scrolled.emit(searchTerm);
  }
}
