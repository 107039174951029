import {
  AlarmInstanceReportGroupByField,
  AlarmInstanceReportGroupByOrder,
  AlarmInstanceReportOptions,
} from './alarm-instance-report.interface';

export enum ReportExportType {
  AlarmHistoryReportExport = 'ALARM_HISTORY_REPORT_EXPORT',
}

export enum ReportExportStatus {
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
}

export interface ReportExport {
  id: string;
  exportType: ReportExportType;
  status: ReportExportStatus;
  fileUrl: string;
  error: any;
  fileName: string;
  completedAt: string;
}

export interface AlarmInstanceReportExportInput {
  input: AlarmInstanceReportOptions;
  columns: string;
  groupBy: AlarmInstanceReportGroupByField;
  groupByAlarmAttribute: String;
  groupByOrder: AlarmInstanceReportGroupByOrder;
}

export enum LookerViewType {
  ProblematicEquipment = 'PROBLEMATIC_EQUIPMENT',
  SpaceHistoricalFailure = 'SPACE_HISTORICAL_FAILURE',
  EquipmentHistoricalFailure = 'EQUIPMENT_HISTORICAL_FAILURE',
  Nuisance = 'NUISANCE',
  CurrentStatus = 'CURRENT_STATUS',
  AlarmHistoricalDetail = 'ALARM_HISTORICAL_DETAIL',
  WorkHistory = 'WORK_HISTORY',
}
