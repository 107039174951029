import { Component, Input } from '@angular/core';
import {
  WorkTicket,
  WorkTicketProcessState,
  WorkTicketStatus,
} from '@vfi-ui/models';

@Component({
  selector: 'nuclei-work-icon',
  templateUrl: './work-icon.component.html',
  styleUrls: ['./work-icon.component.scss'],
})
export class WorkIconComponent {
  @Input() workTicket: WorkTicket;
  @Input() size = 14;
  workTicketState = WorkTicketProcessState;
  workTicketStatus = WorkTicketStatus;

  constructor() {}
}
