import { Directive, HostListener, ElementRef, Input } from '@angular/core';
@Directive({
  selector: '[atomPreventSpecialCharacter]',
})
export class PreventSpecialCharacterDirective {
  @Input() isAlphaNumeric: boolean;
  regexStr = '^[a-zA-Z0-9- ]*$';

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  /**
   * validate input value
   *
   * @param {*} event
   * @memberof PreventSpecialCharacterDirective
   */
  validateFields(event) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value
        .replace(/[^A-Za-z ]/g, '')
        .replace(/\s/g, '');
      event.preventDefault();
    }, 100);
  }
}
