import {
  EntityActivityCategory,
  EntityRelationDirection,
} from '../interfaces/entity-management.interface';
import { ASSET_CRITICALITY_MAP } from './assets.constants';

export const MaxEntityCount = 50;
export const EntityManagementLocationFields = ['building', 'floor', 'room'];

export const EntityManagementCriticalityOptions = [
  {
    label: ASSET_CRITICALITY_MAP.get(5),
    value: 5,
  },
  {
    label: ASSET_CRITICALITY_MAP.get(4),
    value: 4,
  },
  {
    label: ASSET_CRITICALITY_MAP.get(3),
    value: 3,
  },
  {
    label: ASSET_CRITICALITY_MAP.get(2),
    value: 2,
  },
  {
    label: ASSET_CRITICALITY_MAP.get(1),
    value: 1,
  },
  { label: ASSET_CRITICALITY_MAP.get(0), value: 0 },
];

export const EntityManagementRelationTitle = {
  [EntityRelationDirection.Upstream]: 'Upstream (Is fed by)',
  [EntityRelationDirection.Downstream]: 'Downstream (Feeds)',
};
export const EntityManagementDetailsTabs = [
  { name: 'Activity', isVisible: true },
  { name: 'Recent History', isVisible: true },
  { name: 'Details', isVisible: true },
  { name: 'Relationships', isVisible: true },
];
export const EntityHistoryFilters = [
  { label: 'Entity details', value: EntityActivityCategory.EntityDetails },
  { label: 'Relationships', value: EntityActivityCategory.Relationships },
  { label: 'Alarm details', value: EntityActivityCategory.AlarmDetails },
  { label: 'Work', value: EntityActivityCategory.Work },
];
