import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ButtonSize } from '@vfi-ui/models';
import { isNil } from '@vfi-ui/util/helpers';

@Component({
  selector: 'atom-vfi-input',
  templateUrl: './vfi-input.component.html',
  styleUrls: ['./vfi-input.component.scss'],
})
export class VfiInputComponent implements AfterViewInit {
  @ViewChild('inputElm') inputElm: ElementRef;
  @Input() value: string;
  @Input() label: string;
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() hint: string;
  @Input() required = false;
  @Input() highlightRequired = false;
  @Input() size = 'medium';
  @Input() iconLeft: string;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() subtle = false;
  @Input() success = false;
  @Input() warning = false;
  @Input() warningMessage: string;
  @Input() error = false;
  @Input() errorMessage: string;
  @Input() autofocus = false;
  @Input() showCancel = true;
  @Input() showEditButtons = false;
  @Input() allowSpecialCharacters = true;
  @Output() blurChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  @Output() save: EventEmitter<string> = new EventEmitter();
  buttonSize = ButtonSize;

  constructor() {}

  ngAfterViewInit() {
    // Autofocus on input
    if (this.autofocus) {
      setTimeout(() => this.inputElm.nativeElement.focus(), 500);
    }
  }

  /**
   * handle input changed event
   *
   * @param {string} value
   * @memberof VfiInputComponent
   */
  changed(value: string) {
    this.value = value;
    this.valueChanged.emit(this.value);
  }

  /**
   * clear input
   *
   * @memberof VfiInputComponent
   */
  clear() {
    this.changed('');
  }

  /**
   * handle blur event
   *
   * @param {FocusEvent} ev
   * @memberof VfiInputComponent
   */
  onBlur(ev: FocusEvent) {
    // Prevent blur event from firing when clicking on buttons
    if (isNil(ev.relatedTarget)) {
      this.onCancel();
    }
  }

  /**
   * handle save event
   *
   * @param {string} value
   * @memberof VfiInputComponent
   */
  onSave(value: string) {
    this.save.emit(value);
  }

  /**
   * handle cancel event
   *
   * @memberof VfiInputComponent
   */
  onCancel() {
    this.blurChanged.emit(true);
  }
}
