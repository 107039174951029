import {
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  DropdownMenu,
} from '@vfi-ui/models';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atom-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss'],
})
export class ActionsMenuComponent {
  @Input() title = 'Menu Title';
  @Input() mobileIconOnly = false;
  @Input() mobileIcon: string;
  @Input() dropdownMenu: DropdownMenu[];
  @Input() buttonSize: ButtonSize = ButtonSize.DEFAULT;
  @Input() buttonType: ButtonType = ButtonType.SECONDARY;
  @Output() menuVisibleChange: EventEmitter<boolean> = new EventEmitter();
  ButtonIconPosition = ButtonIconPosition;
}
