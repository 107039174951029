import { MultiFactorResolver } from '@angular/fire/auth';
import { User } from './user.interface';

export interface CountryCode {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
}
export interface InitMFAResponse {
  verificationId: string;
  resolver: MultiFactorResolver;
  factorId: string;
}

export interface LoginResponse extends Partial<InitMFAResponse> {
  user?: User;
}
