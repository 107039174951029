import { GroupCriteriaClause } from '@vfi-ui/models';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atom-and-or',
  templateUrl: './and-or.component.html',
  styleUrls: ['./and-or.component.scss'],
})
export class AndOrComponent {
  @Input() show: boolean;
  @Input() value: string;
  @Output()
  valueChanged: EventEmitter<GroupCriteriaClause> = new EventEmitter();
  groupClause = GroupCriteriaClause;

  constructor() {}
}
