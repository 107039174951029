import { GeoJSON } from './situational-awareness.interface';
export interface MapViewBuildingAsset {
  assetId: number;
  assetName: string;
  name: string;
  address: string;
  featureId: number;
  coordinates?: [number, number];
  geoJson: GeoJSON;
  formattedGeoJson?: string;
  isManuallySelected?: boolean;
  hasCoordinates?: boolean;
}
