<div
  class="login w-100 d-flex align-content-center justify-content-center ios-header"
>
  <div class="align-self-center">
    <div class="login_wrapper">
      <div class="login_form">
        <div class="login_box">
          <div
            class="regular-24 cursor-pointer"
            *ngIf="mfa.showSecure || mfa.showVerification"
            (click)="handleBackButton()"
          >
            &lt;
          </div>
          <section
            class="login_content d-flex flex-column align-items-center justify-content-between"
          >
            <img
              src="/assets/vfi-logo.svg"
              alt="vfi-logo"
              class="vfi-logo mb-4"
            />

            <div class="login_content" *ngIf="mfa.showSecure">
              <div
                class="form_wrapper d-flex flex-column align-items-center w-100"
              >
                <h1>Secure Your Account</h1>
                <p>
                  Enter the phone number to receive an SMS text message with a
                  verification code.
                </p>
                <form [formGroup]="secureForm" class="w-100">
                  <div class="mb-3">
                    <div
                      class="ant-input d-flex align-items-center justify-content-center"
                    >
                      <nz-select
                        class="country_code"
                        formControlName="countryCode"
                        [compareWith]="compareFn"
                        [nzShowArrow]="true"
                      >
                        <nz-option
                          *ngFor="let country of flags"
                          [nzLabel]="
                            country.flag + '&nbsp;' + country.dial_code
                          "
                          [nzValue]="country"
                        ></nz-option>
                      </nz-select>
                      <input
                        style="margin-top: 0"
                        nz-input
                        type="email"
                        placeholder="(555) 555-5555"
                        formControlName="phoneNumber"
                      />
                    </div>
                  </div>
                  <atom-vfi-button
                    id="loginBtn"
                    text="Confirm"
                    [ariaLabel]="'loginBtn'"
                    type="primary"
                    [classes]="'block-button'"
                    (clicked)="enrollInMfa(secureForm.value)"
                  ></atom-vfi-button>
                </form>
              </div>
            </div>
            <div class="login_content" *ngIf="mfa.showVerification">
              <div
                class="form_wrapper d-flex flex-column align-items-center w-100"
              >
                <h1>Enter the Verification Code</h1>
                <p>A 6-digit code has been sent.</p>
                <form class="w-100">
                  <div>
                    <code-input
                      class="mb-3"
                      #codeInput
                      codeLength="6"
                      [initialFocusField]="0"
                      (codeCompleted)="onCodeCompleted($event)"
                    >
                    </code-input>
                    <div *ngIf="mfa.errorMessage" class="red mt-2 mb-2">
                      {{ mfa.errorMessage }}
                    </div>
                    <atom-vfi-button
                      text="Submit"
                      ariaLabel="submitBtn"
                      [classes]="'block-button'"
                      [loading]="mfaVerificationLoading"
                      (clicked)="onCodeCompleted(codeInput.code)"
                    ></atom-vfi-button>

                    <p class="text-left">
                      Didn't receive a code?
                      <span
                        (click)="resendVerificationCode()"
                        class="underline cursor-pointer"
                        >Resend it</span
                      >
                      or
                      <a class="underline" href="mailto:support@vfacility.ai"
                        >contact support</a
                      >
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="mfa-recaptcha"></div>
