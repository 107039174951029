import { SpaceType } from './space-management.interface';
import { SituationalDashboardEntityWorkCreator } from '../constants/situational-awareness.constants';
import { User } from './user.interface';
import { WorkTicket } from './work-order.interface';
import { AlarmTimeWindow, CoreAlarm } from './alarm-instance.interface';
import { AssetActivityType, AssetClass } from './assets.interface';

export interface Entity {
  id: number;
  name: string;
  alarmIds: number[];
  assetClass: AssetClass;
  class: EntityClass;
  classId: number;
  rawEntityClass: string | number;
  criticality: number | string;
  criticalityPath: number[];
  properties: any;
  location: string;
  locationEntities: Entity[];
  infoSchema: any;
  roomName: string;
  roomNumber: string;
  spaceType: SpaceType;
  isPaid: boolean;
  lastActiveWorkTicketAssignedTo: User;
  lastActiveWorkTicketCreatedBy: SituationalDashboardEntityWorkCreator;
}

export interface SearchEntity {
  id: number;
  name: string;
  count: number;
  class: string;
  building: string;
  floor: string;
  roomName: string;
  roomNumber: string;
}

export enum EntityClass {
  Building = 'BUILDING',
  Floor = 'FLOOR',
  Room = 'ROOM',
  Equipment = 'EQUIPMENT',
  System = 'SYSTEM',
}

export enum EntityType {
  Asset = 'ASSET',
  Space = 'SPACE',
  System = 'SYSTEM',
  Component = 'COMPONENT',
}

export interface PointQuery {
  points: string[];
  pointCount: number;
}

export interface EntityListQuery {
  entities: Partial<Entity>[];
  entityCount: number;
}

export interface SearchEntityClassesQuery {
  searchEntityClasses: Partial<SearchEntity>[];
  searchEntityClassCount: number;
}

export interface SearchEntityQuery {
  searchEntities: Partial<SearchEntityResult>[];
  searchEntityCount: number;
}

export enum EntityRelationDirection {
  Upstream = 'UPSTREAM',
  Downstream = 'DOWNSTREAM',
}

export interface EntityRelationship {
  [EntityRelationDirection.Upstream]: EntityListQuery;
  [EntityRelationDirection.Downstream]: EntityListQuery;
}

export interface EntityHistoryLog {
  id: string;
  entityId: number;
  field: string;
  revValue: string;
  prevValue: string;
  createdAt: string;
  user: User;
}

export interface EntityOptionResult {
  searchEntities: SearchEntityResult;
  searchEntityCount: number;
}

export interface SearchEntityResult {
  id?: number;
  name?: string;
  count?: number;
  building?: string;
  class?: string;
  floor?: string;
}

export interface BrickClass {
  id: number;
  name: string;
  label?: string;
  value?: string;
}

export interface EntityClassQuery {
  entityClasses: BrickClass[];
  entityClassCount: number;
}

export enum EntityActivityCategory {
  EntityDetails = 'ENTITY_DETAILS',
  Relationships = 'RELATIONSHIPS',
  AlarmDetails = 'ALARM_DETAILS',
  Work = 'WORK',
}

export enum EntityActivityType {
  SnoozeEnded = 'SNOOZE_ENDED',
  SnoozeCancelled = 'SNOOZE_CANCELLED',
  SnoozeStarted = 'SNOOZE_STARTED',
  WorkCreated = 'WORK_CREATED',
  WorkOpened = 'WORK_OPENED',
  WorkClosed = 'WORK_CLOSED',
  // CREATED = When entity was created
  Created = 'CREATED',
  PropertyUpdated = 'PROPERTY_UPDATED',
  LocationUpdated = 'LOCATION_UPDATED',
  RelationshipAdded = 'RELATIONSHIP_ADDED',
  RelationshipRemoved = 'RELATIONSHIP_REMOVED',
  AlarmPointAdded = 'ALARM_POINT_ADDED',
  AlarmPointRemoved = 'ALARM_POINT_REMOVED',
  AlarmRTN = 'ALARM_RTN',
  AlarmActive = 'ALARM_ACTIVE',
  AlarmDeclined = 'ALARM_DECLINED',
}

export interface EntityActivity {
  id: string;
  entityId: number;
  entity: Entity;
  activityType: EntityActivityType;
  property: string;
  propertyRevValue: string;
  propertyPrevValue: string;
  createdAt: string;
  createdBy: User;
  alarmId: number;
  workTicket: WorkTicket;
  relatedEntityId: number;
  relatedEntity: Entity;
  snoozeReason: string;
  snoozeDuration: number;
  relationshipDirection: EntityRelationDirection;
  type: AssetActivityType;
}

export interface EntityActivityQuery {
  entityActivity: EntityActivity[];
  entityActivityCount: number;
}

export interface EntityActivityWhere {
  entityIds: number[];
  categories?: EntityActivityCategory[];
  createdAt?: AlarmTimeWindow;
}

export interface AlarmPointEntityInput {
  entityId?: string | number;
  alarmId?: string | number;
}

export interface SAChild {
  entityId: number;
  class: string;
  name: string;
  isActive?: boolean;
  inactiveCount?: number;
  activeCount?: number;
  alarms?: CoreAlarm[];
  count?: number;
}

export interface SAChildAlarmCount extends SAChild {
  count: number;
}

export interface SAChildAlarm extends SAChild {
  alarms: CoreAlarm[];
}

export interface EntityCreateInput {
  name: string;
  classId: number;
  class: AssetClass;
  criticality: number;
  locationEntityId: number;
  baseEntityId: number;
  roomNumber: string;
  roomName: string;
  spaceType: string;
}

export interface EntityUpdateInput {
  name: string;
  classId: number;
  criticality: number;
  locationEntityId: number;
  roomNumber: string;
  roomName: string;
  spaceType: string;
  isPaid: boolean;
}
