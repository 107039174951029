import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize } from '@vfi-ui/models';

@Component({
  selector: 'atom-vfi-date-picker',
  templateUrl: './vfi-date-picker.component.html',
  styleUrls: ['./vfi-date-picker.component.scss'],
})
export class VfiDatePickerComponent {
  @Input() value;
  @Input() label: string;
  @Input() placeholder = '';
  @Input() required = false;
  @Input() highlightRequired = false;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() subtle = false;
  @Input() errorMessage: string;
  @Input() autofocus = false;
  @Input() showCancel = true;
  @Input() showEditButtons = false;
  @Input() allowSpecialCharacters = true;
  @Input() disabledDates;
  @Output() blurChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  @Output() save: EventEmitter<string> = new EventEmitter();
  buttonSize = ButtonSize;

  constructor() {}

  /**
   * handle input changed event
   *
   * @param {string} value
   * @memberof VfiInputComponent
   */
  changed(value) {
    this.value = value;
    this.valueChanged.emit(this.value);
  }
}
