/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AuthState } from '../../../../../state/src/lib/auth/auth.state';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { PagesUrls, User, UIPermission } from '@vfi-ui/models';
import { Observable, skip } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RolesGuard {
  user$: Observable<User> = this.store.select(AuthState.user);

  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate() {
    const url = window.location.href;

    this.user$.pipe(skip(1)).subscribe((user) => {
      if (!user) {
        return false;
      }
      if (user?.isSuper) {
        return true;
      }

      const canAccessAutomation =
        user?.permissions?.automation?.automation?.includes(UIPermission.view);

      if (url.includes(PagesUrls.automation) && !canAccessAutomation) {
        this.router.navigate(['/']);
        return false;
      }

      for (const prop in (user?.permissions as any)?.pages) {
        if (
          url.includes(PagesUrls[prop]) &&
          !(user.permissions as any)?.pages[prop]
        ) {
          if (url.includes('/settings')) {
            this.router.navigate(['/settings']);
          } else {
            this.router.navigate(['/']);
          }
          return false;
        }
      }
    });

    return true;
  }
}
