import { ExternalSystems } from './external-systems.interface';
import { Counts } from './menu.interface';

export interface InitalSocketMessage {
  externalSystems: ExternalSystems[];
  counts: Counts;
}
export interface ListControlEvents {
  customerId: string;
  taskId: string;
  totalAlarmCount: number;
  updatedAlarmCount: string;
  userId: string;
}
