import { Navigate } from '@ngxs/router-plugin';
import {
  SetLens,
  SetCurrentlySelectedLens,
  SetTriageCounts,
  SetAlarmView,
  NavigateToCurrentlySelectedLens,
  RemoveLens,
  SetMobileLens,
  RemoveMobileLens,
  SetLensTeam,
  UpdateLens,
} from './lens.actions';
import {
  State,
  Selector,
  Action,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import {
  MenuLens,
  Counts,
  LensType,
  AlarmMenuIcon,
  WorkMenuIcon,
  LensCategory,
  AlarmViews,
  SavedViewsViewType,
  categoryBaseTileUrl,
  Team,
} from '@vfi-ui/models';
import { patch } from '@ngxs/store/operators';
import {
  caseSensitiveSort,
  fastParse,
  fetchLensTeamName,
} from '@vfi-ui/util/helpers';
import { Injectable } from '@angular/core';
import { ResetGlobalFilters } from '../global-filters/global-filters.actions';
import { DisplayOptionsService } from '@vfi-ui/data-access/shared';
import { AuthState } from '../auth/auth.state';
import { CoreState } from '../core.state';

export interface LensStateModel {
  lenses: MenuLens[];
  formattedMobileLens: MenuLens[];
  formattedLens: MenuLens[];
  currentlySelected: MenuLens;
  triageCounts: Counts;
  alarmView: AlarmViews;
  team: Partial<Team>;
}

export const lensStateDefaults: LensStateModel = {
  lenses: [],
  formattedMobileLens: [],
  formattedLens: [],
  currentlySelected: {},
  triageCounts: {},
  alarmView: AlarmViews.table,
  team: null,
};

@State<LensStateModel>({
  name: 'lens',
  defaults: lensStateDefaults,
})
@Injectable({
  providedIn: 'root',
})
export class LensState {
  constructor(
    private readonly displayOptionsService: DisplayOptionsService,
    private store: Store
  ) {}

  @Selector()
  public static getState(state: LensStateModel) {
    return state;
  }

  @Selector()
  public static getLenses(state: LensStateModel) {
    return state.lenses;
  }

  @Selector()
  public static hasLenses(state: LensStateModel) {
    return state.lenses.length > 0;
  }

  @Selector()
  public static getFormattedMobileLens(state: LensStateModel) {
    return state.formattedMobileLens;
  }

  @Selector()
  public static getFormattedLens(state: LensStateModel) {
    return state.formattedLens;
  }

  @Selector()
  public static getCurrentlySelectedLens(state: LensStateModel) {
    return state.currentlySelected;
  }

  @Selector()
  public static getTriageCounts(state: LensStateModel) {
    return state.triageCounts;
  }

  @Selector()
  public static getCurrentLensTileCount(state: LensStateModel) {
    return state.triageCounts[state.currentlySelected.id];
  }

  @Selector()
  public static getAlarmView(state: LensStateModel) {
    return state.alarmView;
  }

  @Selector()
  public static getLensTeam(state: LensStateModel) {
    return state.team;
  }

  @Selector()
  public static getDefaultLens(lensType: LensType) {
    return createSelector([LensState], (state) =>
      state?.lens?.lenses.find(
        (lens) => lens.type === lensType && lens.isSectionDefault
      )
    );
  }

  @Action(SetLens)
  public async setLens(ctx: StateContext<LensStateModel>, { payload }) {
    const state = ctx.getState();
    const lenses = await Promise.all(
      payload.map(async (p) => {
        let viewType = SavedViewsViewType.AlarmHistory;
        if (p.type === LensType.WORK) {
          viewType = SavedViewsViewType.WorkHistoryReport;
        } else if (p.type === LensType.ASSET) {
          viewType = SavedViewsViewType.Asset;
        }
        const { options: displayOptions } =
          await this.displayOptionsService.resolverOptionsToDisplayOptions({
            resolverOptions: p.criteria,
            viewType,
            seperateQuickFilters: false,
          });
        return {
          ...p,
          displayOptions,
        };
      })
    );
    ctx.setState({
      ...state,
      lenses,
      formattedLens: this.formatDesktopLens(lenses),
    });
  }

  @Action(SetMobileLens)
  public async setMobileLens(ctx: StateContext<LensStateModel>, { payload }) {
    const state = ctx.getState();
    const lenses = await Promise.all(
      payload.map(async (p) => {
        const { options: displayOptions } =
          await this.displayOptionsService.resolverOptionsToDisplayOptions({
            resolverOptions: p.criteria,
            viewType:
              p.type === LensType.ALARM
                ? SavedViewsViewType.AlarmHistory
                : SavedViewsViewType.WorkHistoryReport,
            seperateQuickFilters: false,
          });
        return {
          ...p,
          displayOptions,
        };
      })
    );
    ctx.setState({
      ...state,
      lenses,
      formattedMobileLens: this.formatMobileLens(lenses),
    });
  }

  @Action(SetCurrentlySelectedLens)
  public setActiveLens(ctx: StateContext<LensStateModel>, { payload }) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentlySelected: state.lenses.find((l) => l.id === payload),
    });
  }

  @Action(NavigateToCurrentlySelectedLens)
  public navigateToCurrentlySelectedLens(ctx: StateContext<LensStateModel>) {
    const isMobile = this.store.selectSnapshot(CoreState.isMobile);
    const state = ctx.getState();
    const lens = state.currentlySelected;
    let baseUrl;
    if (isMobile) {
      baseUrl = categoryBaseTileUrl[lens?.category];
    } else if (lens.type === LensType.ASSET) {
      baseUrl = `assets/${fetchLensTeamName(lens)}`;
    } else if (lens.type === LensType.ALARM) {
      baseUrl = `alarms/${fetchLensTeamName(lens)}`;
    } else if (lens.type === LensType.WORK) {
      baseUrl = `work/${fetchLensTeamName(lens)}`;
    }
    const url = isMobile
      ? [baseUrl, lens?.category, lens?.name, lens?.id]
      : [baseUrl, lens?.name];

    ctx.dispatch([new Navigate(url), new ResetGlobalFilters(true)]);
  }

  @Action(SetTriageCounts)
  addTriageCounts(ctx: StateContext<LensStateModel>, { payload }) {
    ctx.setState(
      patch({
        triageCounts: patch(payload),
      })
    );
  }

  @Action(RemoveLens)
  public removeLens(ctx: StateContext<LensStateModel>, { payload }) {
    const state = ctx.getState();
    const lenses = state.lenses.filter((l) => l.id !== payload);
    ctx.setState({
      ...state,
      lenses,
      formattedLens: this.formatDesktopLens(lenses),
    });
  }

  @Action(RemoveMobileLens)
  public removeMobileLens(ctx: StateContext<LensStateModel>, { payload }) {
    const state = ctx.getState();
    const lenses = state.lenses.filter((l) => l.id !== payload);
    ctx.setState({
      ...state,
      lenses,
      formattedMobileLens: this.formatMobileLens(lenses),
    });
  }

  @Action(SetAlarmView)
  public setAlarmView(ctx: StateContext<LensStateModel>, { payload }) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      alarmView: payload,
    });
  }

  @Action(SetLensTeam)
  public setLensTeam(ctx: StateContext<LensStateModel>, { payload }) {
    ctx.setState(
      patch({
        team: payload,
      })
    );
  }

  @Action(UpdateLens)
  public updateLens(ctx: StateContext<LensStateModel>, { payload }) {
    const state = ctx.getState();
    const lenses = fastParse(state.lenses);
    const index = lenses.findIndex((lens) => lens?.id === payload?.id);
    if (index > -1) {
      lenses[index] = payload;
    }
    this.store.dispatch(new SetLens(lenses));
  }

  /**
   * format lenses for mobile navigation menu
   *
   * @private
   * @param {MenuLens[]} lenses
   * @returns
   * @memberof LensState
   */
  private formatMobileLens(lenses: MenuLens[]) {
    const parentLens = lenses
      .filter((lens) => lens.isParent)
      .sort((a, b) => {
        const result = a.orderValue - b.orderValue;
        return result !== 0 ? result : a.type.localeCompare(b.type);
      });
    const formattedChild = this.setChildLens(parentLens, lenses);
    const formattedAllowEdit = this.setAllowEditLens(formattedChild);
    const formattedHeaders = this.setParentHeaders(formattedAllowEdit);
    const formattedLenses = this.setParentIcons(formattedHeaders);

    return formattedLenses;
  }

  /**
   * format lenses for desktop navigation menu
   *
   * @private
   * @param {MenuLens[]} lenses
   * @returns
   * @memberof LensState
   */
  private formatDesktopLens(lenses: MenuLens[]) {
    // Determine custom lens section name and lens category
    let customLensName = 'My Alarm Lenses';
    let lensCategory = LensCategory.ACTIVE_ALARMS;
    if (lenses.length && lenses[0].type === LensType.WORK) {
      customLensName = 'My Work Lenses';
      lensCategory = LensCategory.ACTIVE_WORK;
    } else if (lenses.length && lenses[0].type === LensType.ASSET) {
      customLensName = 'My Asset Lenses';
      lensCategory = LensCategory.ACTIVE_ASSETS;
    }

    // Fetch user teams
    const teams = this.store
      .selectSnapshot(AuthState.getUserTeams)
      .map((team) => team.team);

    // Filter out standard lenses
    const standardLenses = caseSensitiveSort(
      lenses.filter((lens) => !lens.isCustom),
      'name'
    ).sort((a, b) => a?.orderValue - b?.orderValue);

    // Filter out custom lenses
    const customLenses = caseSensitiveSort(
      lenses.filter((lens) => lens.isCustom && !lens.team),
      'name'
    );

    // Filter out and format team lenses
    const teamLenses = caseSensitiveSort(
      lenses.filter((lens) => lens.team),
      'name'
    );
    const formattedTeamLenses = [];
    teams.reduce((arr, key) => {
      arr.push({
        name: key.name,
        childLens: teamLenses.filter((team) => team.team.id === key.id),
        category: lensCategory,
        id: key.id,
        isTeam: true,
        isCustom: true,
      });
      return arr;
    }, formattedTeamLenses);

    const lensPermissions = this.store.selectSnapshot(
      AuthState.getPermissions
    )?.lenses;
    const isSuperUser = this.store.selectSnapshot(AuthState.isSuper);

    const formattedLenses = [];
    if (lensPermissions?.viewStandardLenses || isSuperUser) {
      formattedLenses.push({
        name: 'Standard Lenses',
        childLens: standardLenses,
        category: lensCategory,
        isTeam: false,
        isCustom: false,
      });
    }
    if (lensPermissions?.viewMyLenses || isSuperUser) {
      formattedLenses.push({
        name: customLensName,
        childLens: customLenses,
        category: lensCategory,
        isTeam: false,
        isCustom: true,
      });
    }
    if (lensPermissions?.viewTeamLenses || isSuperUser) {
      formattedLenses.push(...formattedTeamLenses);
    }

    return this.setAllowEditLens(formattedLenses);
  }

  /**
   * set parent child lenses
   *
   * @private
   * @param {MenuLens[]} parentLens
   * @param {MenuLens[]} lenses
   * @returns
   * @memberof LensState
   */
  private setChildLens(parentLens: MenuLens[], lenses: MenuLens[]) {
    const lensCopy = fastParse(lenses);
    return parentLens.map((parent) => {
      const childLens = lensCopy.filter(
        (lens) =>
          lens.name !== parent.name &&
          lens.category === parent.category &&
          !lens.isParent
      );

      let notCustom = childLens.filter((l) => !l.isCustom);
      let isCustom = childLens.filter((l) => l.isCustom);

      notCustom = notCustom.sort((a, b) => {
        const result = a.orderValue - b.orderValue;
        if (a.orderValue === null) {
          return 1;
        }
        if (b.orderValue === null) {
          return -1;
        }
        if (result !== 0) {
          return result;
        }

        return a.name.localeCompare(b.name);
      });

      isCustom = isCustom.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
      );

      return (parent = {
        ...parent,
        childLens: [...notCustom, ...isCustom],
      });
    });
  }

  /**
   * sets which lens is editable
   *
   * @private
   * @param {MenuLens[]} lenses
   * @returns
   * @memberof LensState
   */
  private setAllowEditLens(lenses: MenuLens[]) {
    let tempLenses = fastParse(lenses);
    tempLenses = this.updateChildLens(tempLenses);
    return tempLenses;
  }

  /**
   * update child lens
   *
   * @private
   * @param {MenuLens[]} tempLenses
   * @returns
   * @memberof LensState
   */
  private updateChildLens(tempLenses: MenuLens[]) {
    const lenses = tempLenses.map((lens) => {
      const childLens = this.formatChildLens(lens);
      return { ...lens, childLens };
    });
    return lenses;
  }

  /**
   * format child lens
   *
   * @private
   * @param {MenuLens} lens
   * @returns
   * @memberof LensState
   */
  private formatChildLens(lens: MenuLens) {
    const childLens = lens.childLens;

    if (lens.category !== LensCategory.RECENTLY_CLOSED_WORK) {
      const createLens = {
        type: LensType.CREATE,
        category: lens.category,
        lensType: lens.type,
      };
      childLens.push(createLens);
    }

    return childLens;
  }

  /**
   * set which lens show a header
   *
   * @private
   * @param {MenuLens[]} lenses
   * @returns
   * @memberof LensState
   */
  private setParentHeaders(lenses: MenuLens[]) {
    lenses.forEach((lens) => {
      lens.headerType = lens.type === LensType.ALARM ? 'Alarms' : 'Work';
      lens.showHeader =
        lens.orderValue === 1 &&
        lens.category !== LensCategory.RECENTLY_CLOSED_WORK;
    });
    return lenses;
  }

  /**
   * set parent lens icon
   *
   * @private
   * @param {MenuLens[]} lenses
   * @returns
   * @memberof LensState
   */
  private setParentIcons(lenses: MenuLens[]) {
    lenses.forEach(
      (lens) =>
        (lens.icon =
          lens.type === LensType.ALARM ? AlarmMenuIcon : WorkMenuIcon)
    );
    return lenses;
  }
}
