import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { isNil } from '@vfi-ui/util/helpers';
import { Subject } from 'rxjs';

@Component({
  selector: 'atom-vfi-textarea',
  templateUrl: './vfi-textarea.component.html',
  styleUrls: ['./vfi-textarea.component.scss'],
})
export class VfiTextareaComponent implements AfterViewInit {
  @ViewChild('inputElm') inputElm: ElementRef;
  @Input() value: string;
  @Input() label: string;
  @Input() placeholder = '';
  @Input() hint: string;
  @Input() required = false;
  @Input() highlightRequired = false;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() subtle = false;
  @Input() success = false;
  @Input() warning = false;
  @Input() warningMessage: string;
  @Input() error = false;
  @Input() errorMessage: string;
  @Input() autofocus = false;
  @Input() reset: Subject<boolean> = new Subject<boolean>();
  @Output() blurChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  @Output() save: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngAfterViewInit() {
    // Autofocus on input
    if (this.autofocus) {
      this.inputElm.nativeElement.focus();
    }
  }

  /**
   * handle blur event
   *
   * @param {FocusEvent} ev
   * @memberof VfiTextareaComponent
   */
  onBlur(ev: FocusEvent) {
    // Prevent blur event from firing when clicking on buttons
    if (isNil(ev.relatedTarget)) {
      this.onCancel();
    }
  }

  /**
   * handle save event
   *
   * @param {string} value
   * @memberof VfiTextareaComponent
   */
  onSave(value: string) {
    this.save.emit(value);
  }

  /**
   * handle cancel event
   *
   * @memberof VfiTextareaComponent
   */
  onCancel() {
    this.blurChanged.emit(true);
  }
}
