import { readableMSDaysHoursMinutes } from '@vfi-ui/util/helpers';
import { Component, Input } from '@angular/core';
import { VFI_THEME } from '@vfi-ui/models';

@Component({
  selector: 'atom-rate-of-time-change',
  templateUrl: './rate-of-time-change.component.html',
  styleUrls: ['./rate-of-time-change.component.scss'],
})
export class RateOfTimeChangeComponent {
  @Input() ms: number;
  @Input() theme: VFI_THEME = VFI_THEME.DARK;
  readableMSDaysHoursMinutes = readableMSDaysHoursMinutes;
  abs = Math.abs;
  themeType = VFI_THEME;

  constructor() {}
}
