import { Location } from './locations.interface';
import { Users } from './user.interface';
import { TeamMemberRole } from './../constants/teams.constants';
import { DisplayOptions } from './core.interface';
import { Entity } from './entity-management.interface';

export interface TeamMemberCreateInput {
  userId: string;
  role: TeamMemberRole;
}

export interface TeamView {
  teamId: string;
  teamName: string;
  locationNames: string[];
  teamMemberCount: number;
  lastTeamMemberActiveAt: string;
  createdBy: string;
}

export interface TeamCreateInput {
  name: string;
  locationIds: string[];
  teamMembers: TeamMemberCreateInput[];
  isAllBuildings: boolean;
}

export interface TeamUpdateInput {
  name: string;
  locationIds: string[];
  isAllBuildings: boolean;
  buildings: string[];
}

export interface Team {
  id: string;
  name: string;
  createdBy: Users;
  createdAt: string;
  lastModifiedBy: Users;
  lastModifiedAt: string;
  locations: Location[];
  isAllBuildings: boolean;
  isSystem: boolean;
  displayOptions?: DisplayOptions;
  buildings: Entity[];
}

export interface TeamMember {
  user: Users;
  team: Team;
  permissions: Partial<TeamMemberCreateInput>;
}
