import { Inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { CapacitorGlobal } from '@capacitor/core';
import { Router } from '@angular/router';
import { AuthService, MfaService } from '../services';
import { CAPACITOR_CORE_TOKEN } from '@vfi-ui/models';

@Injectable({
  providedIn: 'root',
})
export class MfaGuard {
  constructor(
    private mfaService: MfaService,
    private authService: AuthService,
    private router: Router,
    @Inject(CAPACITOR_CORE_TOKEN) private capacitor: CapacitorGlobal
  ) {}

  /**
   * determines if user has access to mfa pages.
   * If no, redirect to login page
   *
   * @returns {Observable<boolean>}
   * @memberof LoginGuard
   */
  canActivate(): Observable<boolean> {
    if (this.capacitor.isNativePlatform()) {
      return of(true);
    }
    if (this.mfaService.phoneInfoOptions) {
      return of(true);
    }

    return this.authService.getFirebaseUser().pipe(
      map((user) => {
        if (user) {
          this.router.navigate(['/']);
          return true;
        }

        this.router.navigate(['/login']);
        return false;
      })
    );
  }
}
