<div [ngClass]="{ 'cursor-not-allowed': disabled }">
  <div
    class="vfi-input"
    [ngClass]="{
      'pointer-none': readOnly || disabled,
      disabled,
    }"
  >
    <div
      *ngIf="label"
      class="vfi-label"
      [class.yellow-6]="highlightRequired && required && !readOnly"
    >
      <span>{{ label }}</span>
      <span *ngIf="required">&nbsp;(Required)</span>
    </div>
    <nz-date-picker
      class="w-100"
      [class.readOnly]="readOnly"
      [ngModel]="value"
      [nzPlaceHolder]="placeholder"
      nzShowTime
      nzFormat="MM/dd/yyyy hh:mm a"
      [nzDisabledDate]="disabledDates"
      (ngModelChange)="changed($event)"
    ></nz-date-picker>
  </div>
</div>
