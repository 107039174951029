import { Component, Input } from '@angular/core';
import { WorkResolutionState } from '@vfi-ui/models';

@Component({
  selector: 'nuclei-work-instance-icon',
  templateUrl: './work-instance-icon.component.html',
  styleUrls: ['./work-instance-icon.component.scss'],
})
export class WorkInstanceIconComponent {
  @Input() workState: WorkResolutionState;
  @Input() size = 14;
  workResolutionState = WorkResolutionState;

  constructor() {}
}
