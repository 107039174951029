import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isEmpty } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  /**
   * determines if user has access to rest of the application.
   * If not logged in. redirect to login screen.
   *
   * @returns {Observable<boolean>}
   * @memberof AuthGuard
   */
  canActivate(state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.getFirebaseUser().pipe(
      map((res) => {
        if (res) {
          return true;
        }
        const deepLink = window.location.href;
        localStorage.setItem('vfi-deepLink', deepLink);
        if (!isEmpty(state.root.queryParams)) {
          localStorage.setItem(
            'vfi-deepLink-params',
            JSON.stringify(state.root.queryParams)
          );
        }
        this.router.navigate(['/login']);
        return false;
      })
    );
  }
}
