import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  SelectSize,
  VfiSelectOption,
  VfiSelectParentData,
} from '@vfi-ui/models';

@Component({
  selector: 'atom-vfi-multi-select',
  templateUrl: './vfi-multi-select.component.html',
  styleUrls: ['./vfi-multi-select.component.scss'],
})
export class VfiMultiSelectComponent {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() size = SelectSize.MEDIUM;
  @Input() ariaLabel = 'vfi-multi-select';
  @Input() placeholder = '';
  @Input() value: string[];
  @Input() label: string;
  @Input() hint: string;
  @Input() required = false;
  @Input() iconLeft: string;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() subtle = false;
  @Input() success = false;
  @Input() warning = false;
  @Input() warningMessage: string;
  @Input() error = false;
  @Input() errorMessage: string;
  @Input() showSearch = true;
  @Input() options: VfiSelectOption[] = [];
  @Input() loading = false;
  @Input() parentData: VfiSelectParentData;
  @Input() canClear = false;
  @Input() autofocus = false;
  @Output() cleared: EventEmitter<boolean> = new EventEmitter();
  @Output() seached: EventEmitter<string> = new EventEmitter();
  @Output() dropdownVisibleChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() scrolled: EventEmitter<boolean> = new EventEmitter();
  @Output() backSelected: EventEmitter<VfiSelectParentData> =
    new EventEmitter();
  @Output() optionSelected: EventEmitter<VfiSelectOption[]> =
    new EventEmitter();
  @Output() drilldownSelected: EventEmitter<VfiSelectOption> =
    new EventEmitter();
  showDropdown = false;

  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * handle option select event
   *
   * @param {VfiSelectOption} option
   * @memberof VfiMultiSelectComponent
   */
  onOptionSelect(option: VfiSelectOption) {
    if (!option.hasChildren) {
      let o = this.value;
      if (!o.includes(option?.value)) {
        o.push(option?.value);
      } else {
        o = o.filter((op) => op !== option?.value);
      }
      const options = o.map((op) => ({ label: op, value: op }));
      this.optionSelected.emit(options);
    } else {
      this.drilldownSelected.emit(option);
    }
  }

  /**
   * handle on search event
   *
   * @param {string} ev
   * @memberof VfiMultiSelectComponent
   */
  onSearch(search: string) {
    this.seached.emit(search);
  }

  /**
   * handle on back select event
   *
   * @param {VfiSelectParentData} parent
   * @memberof VfiMultiSelectComponent
   */
  onBackSelected(parent: VfiSelectParentData) {
    this.backSelected.emit(parent);
  }

  /**
   * handle popover visible change event
   *
   * @param {boolean} show
   * @memberof VfiMultiSelectComponent
   */
  onPopoverVisibleChange(show: boolean) {
    this.dropdownVisibleChanged.emit(show);
    if (show) {
      this.autoFocusSearch();
    }
  }

  /**
   * handle options scroll event
   *
   * @memberof VfiMultiSelectComponent
   */
  onOptionsScroll() {
    this.scrolled.emit(true);
  }

  /**
   * auto focus on search input
   *
   * @memberof VfiMultiSelectComponent
   */
  autoFocusSearch() {
    this.cdr.detectChanges();
    if (this.autofocus && this.showSearch) {
      this.searchInput.nativeElement.focus();
    }
  }
}
