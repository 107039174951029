<div class="form-group w-100" *ngIf="!newDisabled">
  <div
    *ngIf="label"
    class="vfi-label"
    [class.yellow-6]="highlightRequired && required && !readOnly"
  >
    <span>{{ label }}</span>
    <span *ngIf="required">&nbsp;(Required)</span>
  </div>
  <ng-select
    ngDefaultControl
    style="min-width: 200px; height: 50px"
    class="vfi-ng-select"
    [class.readOnly]="readOnly"
    [placeholder]="placeholder || name"
    [attr.name]="name"
    [(ngModel)]="value"
    [searchable]="false"
    [clearable]="false"
    [readonly]="disabled"
    [loading]="loading"
    [clearable]="true"
    [class.cursor-default]="disabled"
    (focus)="fetchFields(null)"
    (blur)="checkValidInput(value)"
    (clear)="focused = false"
    (close)="handleCloseChange()"
    (ngModelChange)="valueChanged(value)"
    (scrollToEnd)="onScroll(searchValue)"
  >
    <ng-template ng-header-tmp>
      <input
        [id]="'ng-dropdown-input-' + name"
        class="ng-dropdown-input"
        type="text"
        placeholder="Search {{ placeholder }}"
        (input)="fetchFields($event.target.value)"
        attr.aria-label="{{ name }}-search-input"
        tabIndex="-1"
      />
    </ng-template>
    <ng-option
      *ngFor="let option of options"
      [value]="customQuery === 'cmms' ? option?.value : option"
      >{{ getOptionLabel(option, customQuery) }}</ng-option
    >
  </ng-select>
</div>

<atom-disabled-input
  *ngIf="newDisabled"
  [placeholder]="placeholder"
  [values]="getDisabledValue(customQuery, defaultLabel, defaultValue, value)"
></atom-disabled-input>

<ng-template #customClearIcon>
  <i class="white far fa-times-circle"></i>
</ng-template>
