export interface LoginSsoConfig {
  isSso: boolean;
  tenantId?: string;
  providerId?: string;
}

export interface SsoConfig {
  entityId: string;
  certificate: string;
  providerEntityId: string;
  ssoUrl: string;
}
