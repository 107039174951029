import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atom-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
})
export class DropdownItemComponent {
  @Input() showCheckbox = true;
  @Input() title: string;
  @Input() selected: boolean;
  @Input() showRightIcon: boolean;
  @Input() showLeftIcon: boolean;
  @Input() showBadge = true;
  @Input() badgeCount = 0;
  @Input() item: any;
  @Input() showDrilldown = true;
  @Input() disabled: boolean;
  @Input() customRightIcon: string;
  @Input() alwaysShowRightIcon = false;
  @Output() rightIconClick: EventEmitter<any> = new EventEmitter();
  @Output() selectChange: EventEmitter<any> = new EventEmitter();
  @Output() drillDownChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  /**
   * drill down to child
   *
   * @memberof DropdownItemComponent
   */
  drillDown() {
    this.drillDownChange.emit(this.item);
  }

  /**
   * select item
   *
   * @memberof DropdownItemComponent
   */
  selectItem() {
    if (!this.selected) {
      this.selected = true;
      this.selectChange.emit(this.item);
    } else {
      this.selected = false;
      this.selectChange.emit(null);
    }
  }
}
