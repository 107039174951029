<i
  *ngIf="workState === workResolutionState.OpenWork"
  class="fa-solid fa-clipboard-list mr-1 regular-{{ size }} openWork"
  nzTooltipTitle="Open Work"
  nzTooltipPlacement="top"
  nz-tooltip
></i>
<img
  *ngIf="workState === workResolutionState.MultipleWork"
  src="/assets/work-multiple.svg"
  class="mr-1"
  [width]="size"
  [height]="size"
  alt="multiple_work"
  nzTooltipTitle="Multiple Work Tickets"
  nzTooltipPlacement="top"
  nz-tooltip
/>
<img
  *ngIf="workState === workResolutionState.GoodClosedWork"
  src="/assets/work-closed-good.svg"
  class="mr-1"
  [width]="size"
  [height]="size"
  alt="work_closed_good"
  nzTooltipTitle="Resolved Closed Work"
  nzTooltipPlacement="top"
  nz-tooltip
/>
<img
  *ngIf="workState === workResolutionState.BadClosedWork"
  src="/assets/work-closed-bad.svg"
  class="mr-1"
  [width]="size"
  [height]="size"
  alt="work_closed_bad"
  nzTooltipTitle="Unresolved Closed Work"
  nzTooltipPlacement="top"
  nz-tooltip
/>
<span *ngIf="workState === workResolutionState.NoWork" class="mr-1">—</span>
