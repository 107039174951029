<atom-vfi-button
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzPlacement="bottomRight"
  nzTrigger="click"
  [text]="mobileIconOnly ? '' : title"
  icon="fa {{ mobileIconOnly ? mobileIcon : 'fa-chevron-down' }}"
  type="secondary"
  [iconPosition]="ButtonIconPosition.RIGHT"
  [size]="buttonSize"
  [type]="buttonType"
  (nzVisibleChange)="menuVisibleChange.emit($event)"
>
</atom-vfi-button>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <ng-container *ngFor="let item of dropdownMenu">
      <!-- grouped items -->
      <ng-container *ngIf="!item.hide">
        <li nz-menu-group [nzTitle]="item.label" *ngIf="item.grouped">
          <ul>
            <ng-container *ngFor="let child of item.children">
              <li
                nz-submenu
                [nzTitle]="child.label"
                [nzDisabled]="child.disabled"
                *ngIf="!child.hide && child.children && !child.grouped"
              >
                <ul>
                  <ng-container *ngFor="let subChild of child.children">
                    <li
                      nz-menu-item
                      *ngIf="!subChild.hide"
                      class="d-flex"
                      [nzDisabled]="subChild.disabled"
                      (click)="subChild.disabled ? null : subChild.action()"
                    >
                      <i
                        class="mr-2 fa {{ subChild.icon }}"
                        *ngIf="subChild.icon"
                      ></i>
                      {{ subChild.label }}
                    </li>
                  </ng-container>
                </ul>
              </li>

              <li
                nz-menu-item
                *ngIf="!child.hide && !child.children"
                class="d-flex"
                [nzDisabled]="child.disabled"
                (click)="child.disabled ? null : child.action()"
              >
                <i class="mr-2 fa {{ child.icon }}" *ngIf="child.icon"></i>
                {{ child.label }}
              </li>
            </ng-container>
          </ul>
        </li>

        <!-- single items -->
        <ng-container *ngIf="!item.hide">
          <li
            nz-menu-item
            *ngIf="!item.children && !item.grouped"
            class="d-flex"
            [nzDisabled]="item.disabled"
            (click)="item.disabled ? null : item.action()"
          >
            <i class="mr-2 fa {{ item.icon }}" *ngIf="item.icon"></i>
            {{ item.label }}
          </li>
        </ng-container>

        <!-- sub-menu -->
        <li
          nz-submenu
          [nzTitle]="item.label"
          *ngIf="item.children && !item.grouped"
        >
          <ul>
            <ng-container *ngFor="let child of item.children">
              <li
                nz-menu-item
                *ngIf="!child.hide"
                class="d-flex"
                [nzDisabled]="child.disabled"
                (click)="child.disabled ? null : child.action()"
              >
                <i class="mr-2 fa {{ child.icon }}" *ngIf="child.icon"></i>
                {{ child.label }}
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</nz-dropdown-menu>
