export interface VfiSelectOption {
  label: string;
  value: any;
  hasChildren?: boolean;
  level?: number;
}

export interface VfiSelectParentData {
  name: string;
  id: any;
  path: VfiSelectOption[];
  currentLevel: number;
}
