import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  ACTIVE_WORK_SORTS_CRITERION,
  ALARM_SORTS_CRITERION,
  AlarmInstanceCoveredSource,
  AlarmInstanceReportWhere,
  AlarmState,
  AssetClassFilterOptions,
  BaseAlarmProperty,
  CriterionSelection,
  DefaultAlarmSort,
  DefaultWorkOrderSort,
  DisplayOptions,
  DisplayOptionsCache,
  Integrations,
  OrderDirection,
  QuickFilters,
  SavedViewsViewType,
  StaleStatus,
  Team,
  Users,
  VfiWarnState,
  WorkTicketProcessState,
  filterCategoryType,
  nuisanceType,
  AssetStatusOptions,
  AssetCriticalityOptions,
  AssetClassOptions,
  AssetSourceOptions,
  ALARM_ATTRIBUTE_COLUMN_TO_LABEL_MAP,
  LAST_DATE_SELECTION,
  NEXT_DATE_SELECTION,
} from '@vfi-ui/models';
import {
  fastParse,
  isNull,
  snakeCase,
  startCaseCap,
  uniqBy,
  upperFirst,
} from '@vfi-ui/util/helpers';
import { AuthService } from './auth.service';
import { TeamService } from './team.service';
import { ExternalSystemsDataService } from './external-systems-data.service';
import { AssetsDataService } from './assets-data.service';

const QUICK_FILTER_KEY_MAP = new Map<SavedViewsViewType, string[]>([
  [
    SavedViewsViewType.AlarmHistory,
    [
      'alarmTime',
      'priority',
      'attributes',
      'isActive',
      'isNuisance',
      'dateSelection',
    ],
  ],
  [SavedViewsViewType.Kpi, ['priority', 'attributes', 'isNuisance']],
]);

const QUICK_FILTER_ATTRIBUTES = ['building'];

@Injectable({
  providedIn: 'root',
})
export class DisplayOptionsService {
  cachedMap = new Map<DisplayOptionsCache, { id: string | number }[]>()
    .set(DisplayOptionsCache.Integration, [])
    .set(DisplayOptionsCache.Team, [])
    .set(DisplayOptionsCache.User, [])
    .set(DisplayOptionsCache.AssetId, []);
  constructor(
    private readonly authService: AuthService,
    private readonly teamService: TeamService,
    private readonly assetService: AssetsDataService,
    private readonly externalSystemService: ExternalSystemsDataService
  ) {}

  /**
   * Converts attributes to display form
   *
   * @param {string} name
   * @returns {string}
   * @memberof DisplayOptionsService
   */
  upperCase(name: string): string {
    return name
      .replace('_', ' ')
      .split(' ')
      .map((i) => upperFirst(i))
      .join(' ');
  }
  /**
   * Gets the cached items
   *
   * @template T
   * @param {((string | number)[])} ids
   * @param {DisplayOptionsCache} itemType
   * @return {*}  {Promise<T[]>}
   * @memberof DisplayOptionsService
   */
  async getCacheItems<T extends { id?: number | string }>(
    ids: (string | number)[],
    itemType: DisplayOptionsCache
  ): Promise<T[]> {
    const currentCache = this.cachedMap.get(itemType);
    const cachedItems = currentCache.filter((i) => ids.includes(i.id));
    const nonCachedIds = ids.filter(
      (id) => !currentCache.find((item) => item.id === id)
    );
    if (nonCachedIds.length) {
      let nonCachedItems = [];
      switch (itemType) {
        case DisplayOptionsCache.User:
          nonCachedItems = await firstValueFrom(
            this.authService.getUsersByIds(nonCachedIds as string[])
          );
          break;
        case DisplayOptionsCache.Team:
          nonCachedItems = nonCachedItems = await firstValueFrom(
            this.teamService.getAllTeams()
          );
          break;
        case DisplayOptionsCache.Integration:
          nonCachedItems = nonCachedItems = await firstValueFrom(
            this.externalSystemService.getAllSourceSystemStatus()
          );
          break;
        case DisplayOptionsCache.AssetId:
          nonCachedItems = await firstValueFrom(
            this.assetService.getAllAssets()
          );
      }
      const filteredNonCachedItems = nonCachedItems.filter((item) =>
        nonCachedIds.includes(item.id)
      );
      currentCache.push(...filteredNonCachedItems);
      cachedItems.push(...filteredNonCachedItems);
    }
    return cachedItems as T[];
  }

  /**
   * Handles all the cases of work fields
   *
   * @param {{
   *     key: string;
   *     value: any;
   *     where: any;
   *     exclusionKeys: string[];
   *   }} {
   *     key,
   *     value,
   *     where,
   *     exclusionKeys,
   *   }
   * @returns {Promise<CriterionSelection[]>}
   * @memberof DisplayOptionsService
   */
  async resolveWorkGlobalFiltersCriteria({
    key,
    value,
    where,
    exclusionKeys,
  }: {
    key: string;
    value: any;
    where: any;
    exclusionKeys: string[];
  }): Promise<CriterionSelection[]> {
    switch (key) {
      case 'state':
        return [
          {
            model: 'State',
            value: value,
            detail: {
              name: 'State',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'state',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              dbProperty: 'name',
              multi: true,
              selectValues: [
                {
                  label: 'Open',
                  value: WorkTicketProcessState.Open,
                },
                {
                  label: 'Closed',
                  value: WorkTicketProcessState.Closed,
                },
              ],
              selection: {
                value,
              },
            },
          },
        ];
      case 'assignee': {
        const assigneeUsers = await this.getCacheItems<Users>(
          value,
          DisplayOptionsCache.User
        );
        return [
          {
            model: 'Assignee',
            value: assigneeUsers.map((user) => ({
              id: user.id,
              name: user.displayName,
            })),
            detail: {
              name: 'Assignee',
              show: true,
              type: 'userSelect',
              multi: true,
              dbName: 'assignee',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              selectValues: assigneeUsers,
              selection: {
                value,
              },
            },
          },
        ];
      }
      case 'createdBy': {
        const creatorUsers = await this.getCacheItems<Users>(
          value,
          DisplayOptionsCache.User
        );
        return [
          {
            model: 'Creator',
            value: creatorUsers.map((user) => ({
              id: user.id,
              name: user.displayName,
            })),
            detail: {
              name: 'Creator',
              show: true,
              type: 'userSelect',
              multi: true,
              dbName: 'createdBy',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              selectValues: creatorUsers,
              selection: {
                value,
              },
            },
          },
        ];
      }
      case 'isRecentlyClosed':
        return [
          {
            model: 'Recently Closed',
            value: value ? 'true' : 'false',
            detail: {
              name: 'Recently Closed',
              show: true,
              type: 'bool',
              multi: false,
              dbName: 'isRecentlyClosed',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              selection: { value: value ? 'true' : 'false' },
            },
          },
        ];
      case 'closedAtLastXHours':
        return [
          {
            model: 'Closed Date',
            value: +value,
            detail: {
              name: 'Closed Date',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'closedAtLastXHours',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              dbProperty: 'closedAtLastXHours',
              multi: false,
              selectValues: LAST_DATE_SELECTION,
              selection: { value },
            },
            selection: { value: +value } as any,
          },
        ];
      case 'dueAtLastXHours':
        return [
          {
            model: 'Due Date',
            value: +value,
            detail: {
              name: 'Due Date',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'dueAtLastXHours',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              dbProperty: 'dueAtLastXHours',
              multi: false,
              selectValues: LAST_DATE_SELECTION,
              selection: { value },
            },
            selection: { value: +value } as any,
          },
        ];
      case 'dueAtNextXHours':
        return [
          {
            model: 'Due Date',
            value: +value,
            detail: {
              name: 'Due Date',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'dueAtNextXHours',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              dbProperty: 'dueAtNextXHours',
              multi: false,
              selectValues: NEXT_DATE_SELECTION,
              selection: { value },
            },
            selection: { value: +value } as any,
          },
        ];
      case 'createdAtLastXHours':
        return [
          {
            model: 'Created Date',
            value: +value,
            detail: {
              name: 'Created Date',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'createdAtLastXHours',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              dbProperty: 'createdAtLastXHours',
              multi: false,
              selectValues: LAST_DATE_SELECTION,
              selection: { value },
            },
            selection: { value: +value } as any,
          },
        ];
      case 'closedAt':
        return [
          {
            model: 'Closed Date',
            value,
            detail: {
              name: 'Closed Date',
              show: true,
              type: 'dateTime',
              dbName: 'closedAt',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              selection: {
                min: value.from,
                max: value.to,
              },
            },
            selection: [value.from, value.to],
          },
        ];
      case 'createdAt':
        return [
          {
            model: 'Created Date',
            value,
            detail: {
              name: 'Created Date',
              show: true,
              type: 'dateTime',
              dbName: 'createdAt',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              selection: {
                min: value.from,
                max: value.to,
              },
            },
            selection: [value.from, value.to],
          },
        ];
      case 'dueAt':
        return [
          {
            model: 'Due Date',
            value,
            detail: {
              name: 'Due Date',
              show: true,
              type: 'dateTime',
              dbName: 'dueAt',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              selection: {
                min: value.from,
                max: value.to,
              },
            },
            selection: [value.from, value.to],
          },
        ];
      case 'teamIds': {
        const teams = await this.getCacheItems<Team>(
          value,
          DisplayOptionsCache.Team
        );
        const selectValues = teams.map((t) => ({ label: t.name, value: t.id }));
        return [
          {
            model: 'Work Assignee (Team)',
            detail: {
              name: 'Work Assignee (Team)',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'teamIds',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              dbProperty: 'name',
              multi: true,
              selectValues,
              selection: {
                value,
              },
            },
            value,
          },
        ];
      }
      case 'ageDaysMin':
      case 'ageDaysMax':
        return [
          {
            model: 'Ticket Age',
            value,
            detail: {
              name: 'Ticket Age',
              show: true,
              type: 'minMax',
              dbName: 'ageDays',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              selection: { min: where.ageDaysMin, max: where.ageDaysMax },
            },
          },
        ];
      case 'activeAlarmCountMin':
      case 'activeAlarmCountMax':
        return [
          {
            model: 'Active Alarm Count',
            value,
            detail: {
              name: 'Active Alarm Count',
              show: true,
              type: 'minMax',
              dbName: 'activeAlarmCount',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              selection: {
                min: where.activeAlarmCountMin,
                max: where.activeAlarmCountMax,
              },
            },
          },
        ];
      case 'inactiveAlarmCountMin':
      case 'inactiveAlarmCountMax':
        return [
          {
            model: 'Inactive Alarm Count',
            value,
            detail: {
              name: 'Inactive Alarm Count',
              show: true,
              type: 'minMax',
              dbName: 'inactiveAlarmCount',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              selection: {
                min: where.inactiveAlarmCountMin,
                max: where.inactiveAlarmCountMax,
              },
            },
          },
        ];
      case 'isResolved':
        return [
          {
            model: 'Resolution',
            value: value ? ['true'] : ['false'],
            detail: {
              name: 'Resolution',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'isResolved',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.WORK,
              dbProperty: 'name',
              multi: true,
              selectValues: [
                {
                  label: 'Resolved',
                  value: 'true',
                },
                {
                  label: 'Unresolved',
                  value: 'false',
                },
              ],
              selection: {
                value: value ? ['true'] : ['false'],
              },
            },
          },
        ];
    }
    return [];
  }

  /**
   * Handles all the cases of alarm fields
   *
   * @param {{
   *     key: string;
   *     value: any;
   *     where: any;
   *     exclusionKeys: string[];
   *   }} {
   *     key,
   *     value,
   *     where,
   *     exclusionKeys,
   *   }
   * @returns {Promise<CriterionSelection[]>}
   * @memberof DisplayOptionsService
   */
  async resolveAlarmGlobalFiltersCriteria({
    key,
    value,
    where,
    exclusionKeys,
  }: {
    key: string;
    value: any;
    where: any;
    exclusionKeys: string[];
  }): Promise<CriterionSelection[]> {
    switch (key) {
      case 'properties':
      case 'attributes':
        return (value as BaseAlarmProperty[]).map((input) => {
          const name =
            ALARM_ATTRIBUTE_COLUMN_TO_LABEL_MAP.get(input.type) ||
            this.upperCase(input.type);
          return {
            model: name,
            detail: {
              name,
              show: true,
              type: 'select',
              blanks: true,
              dbName: input.type,
              category: filterCategoryType.ALARMS,
              selection: {
                name: input.values.map((v) => (v === null ? `(blanks)` : v)),
                value: input.values.map((v) => String(v)),
                fullValues: input.values.map((attributeValue) => ({
                  name: attributeValue === null ? `(blanks)` : attributeValue,
                  value: String(attributeValue),
                })),
              },
              dbProperty: 'name',
              isExclusion: exclusionKeys.includes(input.type),
            },
          } as CriterionSelection;
        });
      case 'endTime':
        return [
          {
            model: startCaseCap(snakeCase(key)),
            detail: {
              name: startCaseCap(snakeCase(key)),
              show: true,
              type: 'dateTime',
              blanks: false,
              dbName: key,
              category: filterCategoryType.ALARMS,
              isExclusion: exclusionKeys.includes(key),
              selection: {
                max: value.to,
                min: value.from,
              },
            },
            selection: [value.from, value.to],
          },
        ];
      case 'externalSystemId': {
        const externalSystems = await this.getCacheItems<Integrations>(
          value,
          DisplayOptionsCache.Integration
        );
        const systems = fastParse(externalSystems);
        const values = value
          .map((v) => systems.find((s) => s?.name === v))
          .filter((i) => i);
        const name = values.map((v) => v?.displayName);
        const fv = values.map((v) => ({
          name: v?.displayName,
          value: v?.name,
        }));
        return [
          {
            model: 'Alarm Source',
            detail: {
              name: 'Alarm Source',
              show: true,
              type: 'alarmSource',
              query: 'systemSource',
              blanks: false,
              dbName: 'externalSystemId',
              category: filterCategoryType.ALARMS,
              isExclusion: exclusionKeys.includes(key),
              selection: {
                name,
                value,
                fullValues: fv,
              },
            },
          },
        ];
      }
      case 'durationMax':
      case 'durationMin': {
        let minValues = {};
        if (where.durationMin != null) {
          minValues = {
            minDays: Math.floor(where.durationMin / (24 * 60 * 60 * 1000)),
            minHrs: Math.floor(
              (where.durationMin % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
            ),
            minMins: (where.durationMin % (60 * 60 * 1000)) / (1000 * 60),
          };
        }
        let maxValues = {};
        if (where.durationMax != null) {
          maxValues = {
            maxDays: Math.floor(where.durationMax / (24 * 60 * 60 * 1000)),
            maxHrs: Math.floor(
              (where.durationMax % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
            ),
            maxMins: (where.durationMax % (60 * 60 * 1000)) / (1000 * 60),
          };
        }
        return [
          {
            model: 'Time in Alarm',
            detail: {
              name: 'Time in Alarm',
              show: true,
              type: 'durationMinMax',
              dbName: 'duration',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              selection: {
                ...minValues,
                ...maxValues,
              },
            },
          },
        ];
      }
      case 'coveredSource':
        return [
          {
            model: 'Work Source',
            value,
            detail: {
              name: 'Work Source',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'coveredSource',
              category: filterCategoryType.ALARMS,
              selection: {
                value: value,
              },
              dbProperty: 'coveredSource',
              isExclusion: exclusionKeys.includes(key),
              selectValues: [
                {
                  label: 'User',
                  value: AlarmInstanceCoveredSource.User,
                },
                {
                  label: 'Automation',
                  value: AlarmInstanceCoveredSource.Automation,
                },
              ],
            },
          },
        ];
      case 'isFleeting':
        return [
          {
            model: 'Fleeting',
            value: value ? 'true' : 'false',
            detail: {
              name: 'Fleeting',
              show: true,
              type: 'bool',
              blanks: false,
              dbName: 'isFleeting',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              selection: {
                value: value ? 'true' : 'false',
              },
            },
          },
        ];
      case 'isChattering':
        return [
          {
            model: 'Chattering',
            value: value ? 'true' : 'false',
            detail: {
              name: 'Chattering',
              show: true,
              type: 'bool',
              blanks: false,
              dbName: 'isChattering',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              selection: {
                value: value ? 'true' : 'false',
              },
            },
          },
        ];
      case 'isCovered':
        return [
          {
            model: 'Has Work',
            value: value ? 'true' : 'false',
            detail: {
              name: 'Has Work',
              show: true,
              type: 'bool',
              blanks: false,
              dbName: 'isCovered',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              selection: {
                value: value ? 'true' : 'false',
              },
            },
          },
        ];
      case 'isActive':
        return [
          {
            model: 'Alarm State',
            value,
            detail: {
              name: 'Alarm State',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'isActive',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              selection: {
                value,
              },
              selectValues: [
                {
                  label: 'Active',
                  value: true,
                },
                {
                  label: 'Inactive',
                  value: false,
                },
              ],
            },
          },
        ];
      case 'isPinned':
        return [
          {
            model: 'Pinned',
            value: value ? 'true' : 'false',
            detail: {
              name: 'Pinned',
              show: true,
              type: 'bool',
              blanks: false,
              dbName: 'isPinned',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              selection: {
                value: value ? 'true' : 'false',
              },
            },
          },
        ];
      case 'isServiceImpacting':
        return [
          {
            model: 'Service Failure Alarm',
            value: value ? 'true' : 'false',
            detail: {
              name: 'Service Failure Alarm',
              show: true,
              type: 'bool',
              blanks: false,
              dbName: 'isServiceImpacting',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              dbProperty: 'isServiceImpacting',
              selection: {
                value: value ? 'true' : 'false',
              },
            },
          },
        ];
      case 'isStale':
        return [
          {
            model: 'Stale Status',
            value: value ? StaleStatus.STALE : StaleStatus.NOT_STALE,
            detail: {
              name: 'Stale Status',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'isStale',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              dbProperty: 'isStale',
              selectValues: [
                {
                  label: StaleStatus.STALE,
                  value: StaleStatus.STALE,
                },
                {
                  label: StaleStatus.NOT_STALE,
                  value: StaleStatus.NOT_STALE,
                },
              ],
              selection: {
                value: value ? StaleStatus.STALE : StaleStatus.NOT_STALE,
              },
            },
          },
        ];
      case 'alarmIds': {
        const formatted = value.map((v) => `A${v}`);
        const fullValues = value.map((v) => ({
          name: `A${v}`,
          value: `A${v}`,
        }));
        return [
          {
            model: 'Alarm ID',
            detail: {
              name: 'Alarm ID',
              dbName: 'alarmIds',
              isExclusion: exclusionKeys.includes(key),
              query: 'alarmId',
              show: true,
              type: 'select',
              blanks: false,
              category: filterCategoryType.ALARMS,
              selection: {
                value: formatted,
                name: formatted,
                fullValues,
              },
            },
          },
        ];
      }
      case 'ids': {
        const formatted = value.map((v) => `A${v}`);
        const fullValues = value.map((v) => ({
          name: `A${v}`,
          value: `A${v}`,
        }));
        return [
          {
            model: 'Alarm ID',
            detail: {
              name: 'Alarm ID',
              dbName: 'ids',
              isExclusion: exclusionKeys.includes(key),
              query: 'alarmId',
              show: true,
              type: 'select',
              blanks: false,
              category: filterCategoryType.ALARMS,
              selection: {
                value: formatted,
                name: formatted,
                fullValues,
              },
            },
          },
        ];
      }
      case 'minActiveWork':
      case 'maxActiveWork':
      case 'activeWork':
        return [
          {
            model: 'Active Work',
            value: String(value),
            detail: {
              name: 'Active Work',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'activeWork',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              selection: {
                value: String(value),
              },
              selectValues: [
                {
                  label: 'True',
                  value: '1',
                },
                {
                  label: 'False',
                  value: '0',
                },
              ],
            },
          },
        ];
      case 'minBasPriority':
      case 'maxBasPriority':
        return [
          {
            model: 'BAS Priority',
            detail: {
              name: 'BAS Priority',
              show: true,
              type: 'minMax',
              blanks: false,
              dbName: 'basPriority',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              selection: {
                min: where.minBasPriority,
                max: where.maxBasPriority,
              },
            },
          },
        ];
      case 'priorities':
        return [
          {
            model: 'Priority',
            value,
            detail: {
              name: 'Priority',
              show: true,
              type: 'priority',
              blanks: false,
              dbName: 'priority',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              min: 0,
              max: 5,
              selection: {
                value,
              },
            },
          },
        ];
      case 'statuses':
        return [
          {
            model: 'Status',
            value,
            detail: {
              name: 'Status',
              show: true,
              type: 'status',
              blanks: false,
              dbName: 'statuses',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              dbProperty: 'statuses',
              selectValues: value.map((v) => ({ label: v, value: v })),
              selection: { value },
            },
          },
        ];
      case 'states':
        return [
          {
            model: 'State',
            value,
            detail: {
              name: 'State',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              dbName: 'states',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              dbProperty: 'name',
              multi: true,
              selectValues: [
                {
                  label: AlarmState.ALARM,
                  value: AlarmState.ALARM.toUpperCase(),
                },
                {
                  label: AlarmState.NORMAL,
                  value: AlarmState.NORMAL.toUpperCase(),
                },
                {
                  label: VfiWarnState,
                  value: AlarmState.WARN.toUpperCase(),
                },
              ],
              selection: {
                value,
              },
            },
          },
        ];
      case 'nuisanceBehavior':
        return [
          {
            model: 'Nuisance Behavior',
            value,
            detail: {
              name: 'Nuisance Behavior',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              multi: true,
              dbName: 'nuisanceBehavior',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              dbProperty: 'name',
              selectValues: [
                {
                  label: nuisanceType.CHATTERING,
                  value: 'CHATTERING',
                },
                {
                  label: nuisanceType.FLEETING,
                  value: 'FLEETING',
                },
                {
                  label: nuisanceType.CHATTERING_AND_FLEETING,
                  value: 'CHATTERING_AND_FLEETING',
                },
                {
                  label: nuisanceType.NONE,
                  value: 'NONE',
                },
              ],
              selection: { value },
            },
          },
        ];
      case 'alarmTypes': {
        return [
          {
            model: 'Asset Class',
            value,
            detail: {
              name: 'Asset Class',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              multi: true,
              dbName: 'alarmTypes',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              dbProperty: 'alarmTypes',
              selectValues: AssetClassFilterOptions,
              selection: { value: value.map(String) },
            },
          },
        ];
      }
      case 'alarmAssetNames':
        return [
          {
            model: 'Alarmed Asset Name',
            value,
            detail: {
              name: 'Alarmed Asset Name',
              show: true,
              type: 'select',
              blanks: false,
              dbName: 'alarmAssetNames',
              isExclusion: exclusionKeys.includes(key),
              dbProperty: 'name',
              category: filterCategoryType.ALARMS,
              selection: { value },
            },
          },
        ];
      case 'equipmentNames':
        return [
          {
            model: 'Equipment Name',
            value,
            detail: {
              name: 'Equipment Name',
              show: true,
              type: 'select',
              blanks: false,
              dbName: 'equipmentNames',
              isExclusion: exclusionKeys.includes(key),
              dbProperty: 'name',
              category: filterCategoryType.ALARMS,
              selection: { value },
            },
          },
        ];
      case 'equipmentTypes':
        return [
          {
            model: 'Equipment Type',
            value,
            detail: {
              name: 'Equipment Type',
              show: true,
              type: 'select',
              blanks: false,
              dbName: 'equipmentTypes',
              isExclusion: exclusionKeys.includes(key),
              dbProperty: 'name',
              category: filterCategoryType.ALARMS,
              selection: { value },
            },
          },
        ];
      case 'spaceNames':
        return [
          {
            model: 'Space Name',
            value,
            detail: {
              name: 'Space Name',
              show: true,
              type: 'select',
              blanks: false,
              dbName: 'spaceNames',
              isExclusion: exclusionKeys.includes(key),
              dbProperty: 'name',
              category: filterCategoryType.ALARMS,
              selection: { value },
            },
          },
        ];
      case 'spaceTypes':
        return [
          {
            model: 'Space Types',
            value,
            detail: {
              name: 'Space Types',
              show: true,
              type: 'select',
              blanks: true,
              dbName: 'spaceTypes',
              isExclusion: exclusionKeys.includes(key),
              dbProperty: 'name',
              category: filterCategoryType.ALARMS,
              selection: { value },
            },
          },
        ];
      case 'systemNames':
        return [
          {
            model: 'System Name',
            value,
            detail: {
              name: 'System Name',
              show: true,
              type: 'select',
              blanks: false,
              dbName: 'systemNames',
              isExclusion: exclusionKeys.includes(key),
              dbProperty: 'name',
              category: filterCategoryType.ALARMS,
              selection: { value },
            },
          },
        ];
      case 'systemTypes':
        return [
          {
            model: 'System Type',
            value,
            detail: {
              name: 'System Type',
              show: true,
              type: 'select',
              blanks: false,
              dbName: 'systemTypes',
              isExclusion: exclusionKeys.includes(key),
              dbProperty: 'name',
              category: filterCategoryType.ALARMS,
              selection: { value },
            },
          },
        ];
      case 'workTicketIds':
        return [
          {
            model: 'Work ID',
            value,
            detail: {
              name: 'Work ID',
              dbName: 'workTicketIds',
              isExclusion: exclusionKeys.includes(key),
              query: 'workTickets',
              show: true,
              type: 'select',
              blanks: false,
              category: filterCategoryType.WORK,
              selection: { value },
            },
          },
        ];
      case 'text':
        return [
          {
            model: 'Keyword',
            value,
            detail: {
              name: 'Keyword',
              show: true,
              type: 'text',
              blanks: false,
              dbName: 'text',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              dbProperty: 'name',
              selection: { value },
            },
          },
        ];
      case 'assetCriticality':
        return [
          {
            model: 'Asset Criticality',
            value,
            detail: {
              name: 'Asset Criticality',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              multi: true,
              dbName: 'assetCriticality',
              category: filterCategoryType.ALARMS,
              selectValues: AssetCriticalityOptions,
              selection: { value },
            },
          },
        ];
      case 'pointLike':
        return [
          {
            model: 'Alarm Point Like',
            value,
            detail: {
              name: 'Alarm Point Like',
              show: true,
              type: 'text',
              blanks: false,
              dbName: 'pointLike',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              dbProperty: 'name',
              selection: { value: value.replace(/%/g, '') },
            },
          },
        ];
      case 'points':
        return [
          {
            model: 'Alarm Point',
            value,
            detail: {
              name: 'Alarm Point',
              show: true,
              type: 'select',
              blanks: false,
              dbName: 'points',
              isExclusion: exclusionKeys.includes(key),
              dbProperty: 'name',
              category: filterCategoryType.ALARMS,
              selection: { value },
            },
          },
        ];
    }
    return [];
  }

  /**
   * Handles all the cases of asset fields
   *
   * @param {{
   *     key: string;
   *     value: any;
   *     exclusionKeys: string[];
   *   }} {
   *     key,
   *     value,
   *     exclusionKeys,
   *   }
   * @returns {Promise<CriterionSelection[]>}
   * @memberof DisplayOptionsService
   */
  async resolveAssetGlobalFiltersCriteria({
    key,
    value,
    exclusionKeys,
  }: {
    key: string;
    value: any;
    exclusionKeys: string[];
  }): Promise<CriterionSelection[]> {
    switch (key) {
      case 'isActive':
        return [
          {
            model: 'Active',
            value: value ? 'true' : 'false',
            detail: {
              name: 'Active',
              show: true,
              type: 'bool',
              multi: false,
              dbName: 'isActive',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ASSETS,
              selection: {
                value: value ? 'true' : 'false',
              },
            },
          },
        ];
      case 'ids':
        return [
          {
            model: 'Asset ID',
            detail: {
              name: 'Asset ID',
              show: true,
              type: 'select',
              multi: true,
              dbName: 'ids',
              isExclusion: exclusionKeys.includes(key),
              query: 'assetIds',
              category: filterCategoryType.ASSETS,
              selection: {
                value,
                name: value.map((id) => +id),
              },
            },
          },
        ];
      case 'name': {
        return [
          {
            model: 'Asset Name',
            detail: {
              name: 'Asset Name',
              show: true,
              type: 'select',
              multi: true,
              dbName: 'name',
              isExclusion: exclusionKeys.includes(key),
              query: 'assetNames',
              category: filterCategoryType.ASSETS,
              selectValues: value?.map((name) => ({ name })),
              selection: {
                value,
              },
            },
          },
        ];
      }
      case 'type':
        return [
          {
            model: 'Asset Type',
            detail: {
              name: 'Asset Type',
              show: true,
              type: 'select',
              multi: true,
              dbName: 'type',
              isExclusion: exclusionKeys.includes(key),
              query: 'entityManagement',
              category: filterCategoryType.ASSETS,
              selection: {
                value,
              },
            },
          },
        ];
      case 'class':
        return [
          {
            model: 'Asset Class',
            value,
            detail: {
              name: 'Asset Class',
              show: true,
              type: 'customValueSelect',
              multi: true,
              dbName: 'class',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ASSETS,
              selectValues: AssetClassOptions,
              selection: {
                value,
              },
            },
          },
        ];
      case 'building':
        return [
          {
            model: 'Location - Building',
            detail: {
              name: 'Location - Building',
              show: true,
              type: 'select',
              multi: true,
              dbName: 'building',
              isExclusion: exclusionKeys.includes(key),
              query: 'assetBuildings',
              category: filterCategoryType.ASSETS,
              selection: {
                value,
              },
            },
          },
        ];
      case 'floor':
        return [
          {
            model: 'Location - Floor',
            detail: {
              name: 'Location - Floor',
              show: true,
              type: 'select',
              multi: true,
              dbName: 'floor',
              isExclusion: exclusionKeys.includes(key),
              query: 'assetFloors',
              category: filterCategoryType.ASSETS,
              selection: {
                value,
              },
            },
          },
        ];
      case 'room':
        return [
          {
            model: 'Location - Room',
            detail: {
              name: 'Location - Room',
              show: true,
              type: 'select',
              multi: true,
              dbName: 'room',
              isExclusion: exclusionKeys.includes(key),
              query: 'assetRooms',
              category: filterCategoryType.ASSETS,
              selection: {
                value,
              },
            },
          },
        ];
      case 'spaceType':
        return [
          {
            model: 'Space Type',
            detail: {
              name: 'Space Type',
              show: true,
              type: 'select',
              blanks: true,
              dbName: 'spaceTypes',
              isExclusion: exclusionKeys.includes(key),
              dbProperty: 'name',
              category: filterCategoryType.ALARMS,
              selection: {
                value: value.map((v) => (isNull(v) ? 'null' : v)),
              },
            },
          },
        ];
      case 'label':
        return [
          {
            model: 'Asset Label',
            detail: {
              name: 'Asset Label',
              show: true,
              type: 'select',
              blanks: true,
              dbName: 'label',
              query: 'assetLabels',
              isExclusion: exclusionKeys.includes(key),
              dbProperty: 'name',
              category: filterCategoryType.ASSETS,
              selection: {
                value: value.map((v) => (isNull(v) ? 'null' : v)),
              },
            },
          },
        ];
      case 'source':
        return [
          {
            model: 'Alarm Source',
            value,
            detail: {
              name: 'Asset Source',
              show: true,
              type: 'customValueSelect',
              multi: true,
              dbName: 'source',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ASSETS,
              selectValues: AssetSourceOptions,
              selection: {
                value,
              },
            },
          },
        ];
      case 'status':
        return [
          {
            model: 'Alarm Status',
            value,
            detail: {
              name: 'Asset Status',
              show: true,
              type: 'customValueSelect',
              multi: true,
              dbName: 'status',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ASSETS,
              selectValues: AssetStatusOptions,
              selection: {
                value,
              },
            },
          },
        ];
      case 'criticality':
        return [
          {
            model: 'Asset Criticality',
            value,
            detail: {
              name: 'Asset Criticality',
              show: true,
              type: 'customValueSelect',
              blanks: false,
              multi: true,
              dbName: 'assetCriticality',
              isExclusion: exclusionKeys.includes(key),
              category: filterCategoryType.ALARMS,
              selectValues: AssetCriticalityOptions,
              selection: { value },
            },
          },
        ];
    }
    return [];
  }

  /**
   * Iterates through each field and get criterion
   *
   * @param {{
   *     resolverWhere: any;
   *     viewType: SavedViewsViewType;
   *     seperateQuickFilters: boolean;
   *     exclusionKeys?: string[];
   *   }} {
   *     resolverWhere,
   *     viewType,
   *     seperateQuickFilters,
   *     exclusionKeys = [],
   *   }
   * @returns {Promise<CriterionSelection[]>}
   * @memberof DisplayOptionsService
   */
  async getCriterion({
    resolverWhere,
    viewType,
    seperateQuickFilters,
    exclusionKeys = [],
  }: {
    resolverWhere: any;
    viewType: SavedViewsViewType;
    seperateQuickFilters: boolean;
    exclusionKeys?: string[];
  }): Promise<CriterionSelection[]> {
    const quickFilterColumns = QUICK_FILTER_KEY_MAP.get(viewType) || [];
    const criteria = await Promise.all(
      Object.keys(resolverWhere || {}).map((key) => {
        let value = resolverWhere[key];
        if (seperateQuickFilters && quickFilterColumns.includes(key)) {
          if (key === 'attributes') {
            value = resolverWhere[key].filter(
              (attribute) => !QUICK_FILTER_ATTRIBUTES.includes(attribute.type)
            );
          } else {
            return;
          }
        }
        if (viewType === SavedViewsViewType.WorkHistoryReport) {
          return this.resolveWorkGlobalFiltersCriteria({
            key,
            value,
            where: resolverWhere,
            exclusionKeys,
          });
        } else if (viewType === SavedViewsViewType.AlarmHistory) {
          return this.resolveAlarmGlobalFiltersCriteria({
            key: key as keyof AlarmInstanceReportWhere,
            value,
            where: resolverWhere,
            exclusionKeys,
          });
        } else if (viewType === SavedViewsViewType.Asset) {
          return this.resolveAssetGlobalFiltersCriteria({
            key,
            value,
            exclusionKeys,
          });
        }
      })
    );

    const readonlyCriteria =
      viewType === SavedViewsViewType.WorkHistoryReport
        ? ['State']
        : ['Active'];
    return uniqBy(
      criteria.filter((i) => i).flatMap((i) => i),
      'model'
    ).sort((a, b) => {
      const isAReadonly = readonlyCriteria.includes(a.model);
      const isBReadonly = readonlyCriteria.includes(b.model);
      if (isAReadonly === isBReadonly) {
        return a.model > b.model ? 1 : -1;
      }
      if (isAReadonly) {
        return -1;
      }
      if (isBReadonly) {
        return 1;
      }
    });
  }
  /**
   * Gets the quick filters
   *
   * @param {*} resolverWhere
   * @param {SavedViewsViewType} viewType
   * @return {*}  {Promise<QuickFilters>}
   * @memberof DisplayOptionsService
   */
  async getQuickFilters(
    resolverWhere: any,
    viewType: SavedViewsViewType
  ): Promise<QuickFilters> {
    const quickFilterColumns = QUICK_FILTER_KEY_MAP.get(viewType) || [];
    return Object.keys(resolverWhere || {})
      .filter((key) => quickFilterColumns.includes(key))
      .reduce((acc, key) => {
        if (key === 'attributes') {
          const buildings = (resolverWhere[key] as BaseAlarmProperty[])?.find(
            (prop) => prop.type === 'building'
          )?.values;
          if (buildings) {
            acc['building'] = buildings;
          }
        } else {
          acc[key] = resolverWhere[key];
        }
        return acc;
      }, {});
  }
  /**
   * Resolves display options for a given options
   *
   * @param {AlarmOptions} resolverOptions
   * @returns {Promise<DisplayOptions>}
   * @memberof DisplayOptionsService
   */
  async resolverOptionsToDisplayOptions({
    resolverOptions,
    viewType,
    seperateQuickFilters = false,
  }: {
    resolverOptions: any;
    viewType: SavedViewsViewType;
    seperateQuickFilters?: boolean;
  }): Promise<{
    options: DisplayOptions;
    quickFilters: QuickFilters;
    groupBy?: string;
    orderBy?: { field: string; direction: OrderDirection };
  }> {
    const groupBy = resolverOptions.groupBy;
    const orderBy = resolverOptions?.input?.order || resolverOptions?.order;
    const where = resolverOptions?.input?.where || resolverOptions?.where || {};
    const whereNot =
      resolverOptions?.input?.whereNot || resolverOptions?.whereNot || {};

    // Fetch exclusion keys for where not
    const excludedProperties = whereNot.properties
      ? whereNot.properties.map((p) => p.type)
      : [];
    const excludedKeys = Object.keys(whereNot).filter(
      (k) => k !== 'properties'
    );
    const exclusionKeys = [...excludedKeys, ...excludedProperties];

    // Combine where and where not properties
    const properties = [
      ...(where?.properties || []),
      ...(whereNot?.properties || []),
    ];

    const criterion: CriterionSelection[] = await this.getCriterion({
      resolverWhere: {
        ...where,
        ...whereNot,
        properties,
      },
      viewType,
      seperateQuickFilters,
      exclusionKeys,
    });
    const defaultSort =
      viewType === SavedViewsViewType.AlarmHistory
        ? {
            sortValue: DefaultAlarmSort.name,
            sortType: OrderDirection.DESC,
            sortDbName: DefaultAlarmSort.dbName,
          }
        : {
            sortValue: DefaultWorkOrderSort.sortValue,
            sortType: OrderDirection.DESC,
            sortDbName: DefaultWorkOrderSort.sortDbName,
          };
    const sortFields =
      viewType === SavedViewsViewType.AlarmHistory
        ? ALARM_SORTS_CRITERION
        : ACTIVE_WORK_SORTS_CRITERION;
    const translatedSort = {
      sortValue:
        sortFields.find((field) => field.dbName === orderBy?.field)?.name ||
        (orderBy?.field && startCaseCap(orderBy?.field)),
      sortType: orderBy?.direction,
      sortDbName: orderBy?.field,
      sortValue2: orderBy?.field2,
    };
    const options = {
      criterion,
      sort: orderBy ? translatedSort : defaultSort,
    };

    const quickFilters =
      seperateQuickFilters && (await this.getQuickFilters(where, viewType));

    return { options, quickFilters, groupBy, orderBy };
  }
}
