import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional, inject } from '@angular/core';
import {
  InAppBrowserObject,
  InAppBrowser,
} from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { FirebaseAuthentication } from '@vfacility/capacitor-firebase-authentication';
import { environment } from '@vfi-ui/environments/environment';
import {
  AuthRoles,
  CoreAlarmsOrderSortDirection,
  CreateCustomers,
  Customers,
  CUSTOMER_ID_HEADER,
  DefaultUserSort,
  ERROR_ACTIVATE_USER,
  ERROR_ADD_DEVICE_REGISTRATION_TOKEN,
  ERROR_CREATE_EXISTING_USER,
  ERROR_CHANGE_PASSWORD,
  ERROR_CREATE_CUSTOMER,
  ERROR_CREATE_ORGANIZATIONS,
  ERROR_GET_ORGANIZATIONS,
  ERROR_GET_USER_DETAILS,
  ERROR_GET_USER_TILES,
  ERROR_INVITE,
  ERROR_LOGIN,
  ERROR_LOGIN_AUTH,
  ERROR_MFA_UPDATE,
  ERROR_RESEND_INVITE,
  ERROR_RESET_PASSWORD,
  ERROR_SIGNOUT,
  ERROR_UPDATE_CUSTOMER,
  ERROR_UPDATE_PROFILE,
  ERROR_UPDATE_PROFILE_PHOTO,
  ERROR_UPDATE_USER_ACCESS,
  GlobalFilterSort,
  LoginSsoConfig,
  OrderDirection,
  OrganizationOptions,
  Products,
  Status,
  User,
  Users,
  ValidateUser,
  LoginResponse,
  InitMFAResponse,
  CAPACITOR_CORE_TOKEN,
  CustomerAlarmConfig,
  ERROR_CUSTOMER_ALARM_CONFIG_UPDATE,
  LandingPage,
  SavedViewsViewType,
  ERROR_SET_DEFAULT_LENS,
  Roles,
  ERROR_CREATE_EXISTING_EMAIL,
} from '@vfi-ui/models';
import { get, head, isNil, orderBy as _orderBy } from '@vfi-ui/util/helpers';
import { Apollo } from 'apollo-angular';
import { Observable, firstValueFrom, from, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GET_ALL_USERS_QUERY, GET_USERS_BY_IDS_QUERY } from '../queries';
import {
  ACTIVATE_SSO_USER,
  ACTIVATE_USER_QUERY,
  ADD_DEVICE_REGISTRATION_TOKEN,
  NOTIFY_PASSWORD_CHANGED_QUERY,
  CREATE_CUSTOMER,
  CREATE_ORGANIZATION_QUERY,
  CREATE_USER_QUERY,
  DELETE_USER_QUERY,
  DISABLE_MFA_QUERY,
  GET_ALL_CUSTOMERS,
  GET_CUSTOMERS_BY_ID,
  GET_CUSTOMERS_INFO,
  GET_CUSTOM_TOKEN,
  GET_LOGIN_SSO_CONFIG,
  GET_ORGANIZATIONS_QUERY,
  GET_USERS_BY_UIDS_QUERY,
  GET_ZEN_DESK_TOKEN,
  REMOVE_DEVICE_REGISTRATION_TOKEN,
  RESEND_INVITE_USER_QUERY,
  RESET_PASSWORD_QUERY,
  SEND_RESET_PASSWORD,
  SET_USER_ACCESS_QUERY,
  UPDATE_CUSTOMER_QUERY,
  UPDATE_USER_QUERY,
  VALIDATE_TOKEN_QUERY,
  RELATED_ENTITY_CONFIG_QUERY,
  UPDATE_RELATED_ENTITY_CONFIG_QUERY,
  GET_CUSTOMER_ALARM_CONFIG_QUERY,
  UPDATE_CUSTOMER_ALARM_CONFIG_QUERY,
} from './../queries/auth.query';
import { MfaService } from './mfa.service';
import { NotificationService } from './notification.service';
import {
  Auth,
  MultiFactorError,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  SAMLAuthProvider,
  TotpMultiFactorGenerator,
  getMultiFactorResolver,
  User as firebaseUser,
  IdTokenResult,
  UserCredential,
  updateEmail,
  updateProfile,
  signInWithRedirect,
  signInWithCredential,
  signInWithPopup,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  updatePassword,
  signInWithCustomToken,
  onAuthStateChanged,
  getRedirectResult,
  EmailAuthProvider,
  multiFactor,
} from '@angular/fire/auth';
import { CapacitorGlobal } from '@capacitor/core';
import { UntypedFormGroup } from '@angular/forms';

declare const pendo;

interface ICredentials {
  email: string;
  password: string;
}

const VALID_MOBILE_PLATFORMS = ['ios', 'android'];

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoading: boolean;
  ssoConfigError: Error;
  public afa = inject(Auth);
  constructor(
    private notification: NotificationService,
    private http: HttpClient,
    private apollo: Apollo,
    private mfa: MfaService,
    private store: Store,
    @Inject(CAPACITOR_CORE_TOKEN) private capacitor: CapacitorGlobal,
    @Optional()
    private iab: InAppBrowser
  ) {}

  /**
   * reset password
   *
   * @param {string} email
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  public resetPassword(email: string): Promise<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return firstValueFrom(
      this.http.post<boolean>(
        environment.backend,
        JSON.stringify({
          query: SEND_RESET_PASSWORD,
          variables: { email },
        }),
        {
          headers: headers,
        }
      )
    )
      .then(() => {
        this.notification.showSuccess(
          'Reset Password',
          'Please check your email for instructions.'
        );
      })
      .catch((error) => {
        console.error(error);
        this.notification.showError(ERROR_RESET_PASSWORD);
      });
  }

  /**
   * updates the user profile
   *
   * @param {Partial<firebaseUser>} user
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  public async updateProfile(user: Partial<firebaseUser>): Promise<void> {
    const forAllToUpdate = [];
    if (user.email) {
      forAllToUpdate.push(updateEmail(this.afa.currentUser, user.email));
    }
    if (user.displayName) {
      forAllToUpdate.push(
        updateProfile(this.afa.currentUser, {
          displayName: user.displayName,
        })
      );
    }
    return Promise.all(forAllToUpdate)
      .then(() => {
        this.notification.showSuccess(
          'Update User',
          'User successfully updated.'
        );
      })
      .catch(() => {
        this.notification.showError(ERROR_UPDATE_PROFILE);
      });
  }
  /**
   * uploads the profile picture
   *
   * @param {string} userId
   * @param {Blob} file
   * @returns
   * @memberof AuthService
   */
  public uploadProfile(userId: string, file: Blob): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);
    return this.http
      .post(`${environment.backend}/user/avatar/upload`, formData)
      .pipe(
        catchError(({ error }) => {
          this.notification.showError(ERROR_UPDATE_PROFILE_PHOTO);
          return throwError(error);
        }),
        tap(() => {
          this.notification.showSuccess('Upload Profile', 'Profile Updated.');
        })
      );
  }
  /**
   * returns whether the email is verified
   *
   * @returns
   * @memberof AuthService
   */
  public async getEmailVerified() {
    return this.afa.currentUser.emailVerified;
  }

  /**
   * gets users belonging to the same customer
   *
   * @param {{
   *     displayName?: string;
   *     showActive?: boolean;
   *     sort?: GlobalFilterSort;
   *     showVendors?: boolean;
   *     showDisabled?: boolean;
   *     showPending?: boolean;
   *   }} {
   *     displayName,
   *     showActive,
   *     sort,
   *     showVendors,
   *     showDisabled,
   *     showPending,
   *   }
   * @return {*}  {Observable<Users[]>}
   * @memberof AuthService
   */
  public getUsers({
    displayName,
    showActive: activeUsers,
    sort,
    showVendors: vendorUsers,
    showDisabled: disabledUsers,
    showPending: pendingUsers,
  }: {
    displayName?: string;
    showActive?: boolean;
    sort?: GlobalFilterSort;
    showVendors?: boolean;
    showDisabled?: boolean;
    showPending?: boolean;
  }): Observable<Users[]> {
    const contentType = { 'Content-Type': 'application/json' };
    const authRole = !vendorUsers
      ? [AuthRoles.standard, AuthRoles.admin]
      : [AuthRoles.vendor, AuthRoles.standard, AuthRoles.admin, AuthRoles.none];
    const status = this.generateFetchUserStatus(disabledUsers, pendingUsers);
    const activeUsersWhere = {
      status,
      authRole,
    };
    const orderBy = sort
      ? { [sort.sortDbName]: CoreAlarmsOrderSortDirection[sort.sortType] }
      : { displayName: 'ASC' };
    const displayNameSort = displayName ? { displayName } : {};
    const where = activeUsers
      ? { ...displayNameSort, ...activeUsersWhere }
      : { ...displayNameSort };
    return this.http
      .request<{ data: { Users: Users[] } }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: GET_ALL_USERS_QUERY,
          variables: {
            where: where,
            orderBy,
          },
        }),
      })
      .pipe(
        map((res) => {
          const users = get(res, 'data.Users', []);
          return this.sortUsers(users, sort);
        }),
        catchError((error) => {
          this.notification.showError(ERROR_GET_USER_TILES);
          return throwError(error);
        })
      );
  }

  /**
   * get users for admin app
   *
   * @param {GlobalFilterSort} [sort]
   * @returns {Observable<Users[]>}
   * @memberof AuthService
   */
  public getAdminAppUsers(sort?: GlobalFilterSort): Observable<Users[]> {
    const contentType = { 'Content-Type': 'application/json' };
    const where = {
      authRole: [AuthRoles.vendor, AuthRoles.standard, AuthRoles.admin],
    };
    const orderBy = sort
      ? { [sort.sortDbName]: CoreAlarmsOrderSortDirection[sort.sortType] }
      : { displayName: 'ASC' };
    return this.http
      .request<{ data: { Users: Users[] } }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: GET_ALL_USERS_QUERY,
          variables: {
            where,
            orderBy,
          },
        }),
      })
      .pipe(
        map((res) => {
          const users = get(res, 'data.Users', []);
          return this.sortUsers(users, sort);
        }),
        catchError((error) => {
          this.notification.showError(ERROR_GET_USER_TILES);
          return throwError(error);
        })
      );
  }

  /**
   * fetch users by teams
   *
   * @param {string[]} teamIds
   * @returns {Observable<Users[]>}
   * @memberof AuthService
   */
  public getUsersByTeams(
    teamIds: string[],
    displayName?: string
  ): Observable<Users[]> {
    const contentType = { 'Content-Type': 'application/json' };
    const where = {
      teamIds,
      displayName,
    };
    const orderBy = { displayName: 'ASC' };
    return this.http
      .request<{ data: { Users: Users[] } }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: GET_ALL_USERS_QUERY,
          variables: {
            where,
            orderBy,
          },
        }),
      })
      .pipe(
        map((res) => get(res, 'data.Users', [])),
        catchError((error) => {
          this.notification.showError(ERROR_GET_USER_TILES);
          return throwError(error);
        })
      );
  }

  /**
   * gets users belonging to the same customer by ids
   *
   * @param {string[]} ids
   * @returns {Observable<Users[]>}
   * @memberof AuthService
   */
  public getUsersByIds(ids: string[]): Observable<Users[]> {
    const contentType = { 'Content-Type': 'application/json' };
    return this.http
      .request<{ data: { Users: Users[] } }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: GET_USERS_BY_IDS_QUERY,
          variables: { where: { ids, includeSuperUsers: true } },
        }),
      })
      .pipe(
        map((res) => get(res, 'data.Users', [])),
        catchError((error) => {
          this.notification.showError(ERROR_GET_USER_DETAILS);
          return throwError(error);
        })
      );
  }

  /**
   * gets users belonging to the same customer by uids
   *
   * @param {string[]} uids
   * @returns {Observable<Users[]>}
   * @memberof AuthService
   */
  public getUsersByUids(uids: string[]): Observable<Users[]> {
    return this.http
      .request<{ data: { Users: Users[] } }>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: GET_USERS_BY_UIDS_QUERY,
          variables: { where: { uids, includeSuperUsers: true } },
        }),
      })
      .pipe(
        map((res) => get(res, 'data.Users', [])),
        catchError((error) => {
          this.notification.showError(ERROR_GET_USER_DETAILS);
          return throwError(error);
        })
      );
  }

  /**
   * Checks if a user belong to an tenant
   *
   * @param {string} email
   * @returns {Observable<LoginSsoConfig>}
   * @memberof AuthService
   */
  public checkEmailTenant(email: string): Observable<LoginSsoConfig> {
    return this.http
      .request<{ data: { getLoginSsoConfig: LoginSsoConfig } }>(
        'POST',
        environment.backend,
        {
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: GET_LOGIN_SSO_CONFIG,
            variables: { email },
          }),
        }
      )
      .pipe(
        map((res) => get(res, 'data.getLoginSsoConfig')),
        catchError((error) => {
          this.notification.showError(ERROR_LOGIN);
          return throwError(error);
        })
      );
  }

  /**
   * Uses tenant to sign in with sso
   *
   * @param {string} tenantId
   * @param {string} providerId
   * @memberof AuthService
   */
  public async signInWithRedirect(
    tenantId: string,
    providerId: string,
    email: string
  ) {
    const nativePlatform = this.capacitor.getPlatform();
    const isMobileFlow =
      nativePlatform &&
      VALID_MOBILE_PLATFORMS.includes(nativePlatform) &&
      this.capacitor.isNativePlatform();
    if (isMobileFlow) {
      this.afa.tenantId = tenantId;

      const iab = this.iab.create(
        `${environment.frontend}/mobileSsoLogin?email=${email}`,
        '_blank',
        {
          location: environment.production ? 'no' : 'yes',
        }
      );
      iab.on('loadstop').subscribe(async (event) => {
        this.mobileSAMLSignIn(event.url, iab);
      });

      iab.on('loadstart').subscribe(async (event) => {
        this.mobileSAMLSignIn(event.url, iab);
      });
    } else {
      this.afa.tenantId = tenantId;
      const provider = new SAMLAuthProvider(providerId);
      await signInWithRedirect(this.afa, provider);
    }
  }

  /**
   * Sign in with sso on mobile
   *
   * @param {string} href
   * @param {InAppBrowserObject} iab
   * @return {Promise<void>}
   * @memberof AuthService
   */
  async mobileSAMLSignIn(href: string, iab: InAppBrowserObject): Promise<void> {
    const url = new URL(href);
    if (url.searchParams.has('credential')) {
      const cred = atob(url.searchParams.get('credential'));

      const oauthcred = SAMLAuthProvider.credentialFromResult(JSON.parse(cred));

      iab.close();

      const signInResult = await signInWithCredential(
        this.afa,
        oauthcred
      ).catch((err) => {
        console.error(
          'An error occurred while signing in. Please try again.' +
            JSON.stringify(err)
        );
        return false;
      });

      if (!signInResult) {
        return;
      }

      window.location.href = '/';
    }
  }

  /**
   * Uses tenant to sign in with sso with popup
   *
   * @param {string} tenantId
   * @param {string} providerId
   * @param {RecaptchaVerifier} [recaptchaVerifier]
   * @returns {Promise<LoginResponse>}
   * @memberof AuthService
   */
  public async signInWithPopup(
    tenantId: string,
    providerId: string,
    recaptchaVerifier?: RecaptchaVerifier
  ): Promise<LoginResponse> {
    try {
      this.isLoading = true;
      this.afa.tenantId = tenantId;
      const provider = new SAMLAuthProvider(providerId);
      const signInResult = await signInWithPopup(this.afa, provider);
      const user = await this.handleSuccess(signInResult);
      return { user };
    } catch (err) {
      if (err.code === 'auth/multi-factor-auth-required') {
        const res = await this.handleMFAErrorCode({
          auth: this.afa,
          err,
          recaptchaVerifier,
        });
        if (res) {
          return res;
        }
      }
      this.handleError();
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Handles MFA Errors codes
   *
   * @param {{
   *     auth: FirebaseAuth;
   *     err: MultiFactorError;
   *     recaptchaVerifier: RecaptchaVerifier;
   *   }} {
   *     auth,
   *     err,
   *     recaptchaVerifier,
   *   }
   * @return {Promise<InitMFAResponse>}
   * @memberof AuthService
   */
  async handleMFAErrorCode({
    auth,
    err,
    recaptchaVerifier,
  }: {
    auth: Auth;
    err: MultiFactorError;
    recaptchaVerifier: RecaptchaVerifier;
  }): Promise<InitMFAResponse> {
    this.mfa.verificationResolver = getMultiFactorResolver(auth, err);
    // TODO: find right index
    const selectedIndex = 0;
    const selectedHint = this.mfa.verificationResolver.hints[selectedIndex];
    if (selectedHint.factorId === TotpMultiFactorGenerator.FACTOR_ID) {
      this.mfa.enrollingInMFA = false;
      this.mfa.showSecure = false;
      this.mfa.showVerification = true;
      this.store.dispatch(new Navigate(['/mfa']));
      return {
        verificationId: selectedHint.uid,
        resolver: this.mfa.verificationResolver,
        factorId: TotpMultiFactorGenerator.FACTOR_ID,
      };
    } else if (selectedHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      const verificationId = await this.mfa.sendVerificationCode(
        this.mfa.verificationResolver,
        recaptchaVerifier,
        selectedIndex
      );

      this.mfa.enrollingInMFA = false;
      this.store.dispatch(new Navigate(['/mfa']));
      return {
        verificationId,
        resolver: this.mfa.verificationResolver,
        factorId: PhoneMultiFactorGenerator.FACTOR_ID,
      };
    }
  }

  /**
   * General sign in mechanism to authenticate the users with a firebase project
   * using a traditional way, via username and password
   *
   * @param {ICredentials} [credentials]
   * @returns {Promise<User>}
   * @memberof AuthService
   */
  public async signInWith(
    credentials?: ICredentials,
    recaptchaVerifier?: RecaptchaVerifier
  ): Promise<LoginResponse> {
    try {
      this.isLoading = true;
      if (this.capacitor.getPlatform() === 'ios') {
        // This sets the mfa session/resolver in the native ios code, so the session can be hooked to the verifyPhoneNumber call
        await FirebaseAuthentication.signInWithEmailAndPassword({
          email: credentials.email,
          password: credentials.password,
        });
      }
      const signInResult: UserCredential = (await signInWithEmailAndPassword(
        this.afa,
        credentials.email,
        credentials.password
      )) as UserCredential;

      const user = await this.handleSuccess(signInResult);
      return { user };
    } catch (err) {
      if (err.code === 'auth/multi-factor-auth-required') {
        const res = await this.handleMFAErrorCode({
          auth: this.afa,
          err,
          recaptchaVerifier,
        });
        if (res) {
          return res;
        }
      }
      this.handleError();
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Formats user info
   *
   * @param {UserCredential} userCredential
   * @returns {Promise<User>}
   * @memberof AuthService
   */
  async handleSuccess(userCredential: UserCredential): Promise<User> {
    const user: User = {
      id: userCredential.user.uid,
      uid: userCredential.user.uid,
      email: userCredential.user.email,
      username: userCredential.user.displayName,
      phone: userCredential.user.phoneNumber,
      active: true,
    };
    return await userCredential.user
      .getIdTokenResult()
      .then((idTokenResult) => {
        const hasuraClaims = idTokenResult.claims
          ? idTokenResult.claims['https://hasura.io/jwt/claims']
          : {};

        return {
          ...user,
          token: idTokenResult.token,
          hasuraClaims,
        };
      });
  }

  /**
   * signs user out
   *
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  public async signOut(): Promise<void> {
    try {
      return await this.afa.signOut();
    } catch (e) {
      this.notification.showError(ERROR_SIGNOUT);
    }
  }

  /**
   * get the current user id token result
   *
   * @returns {Observable<IdTokenResult>}
   * @memberof AuthService
   */
  getCurrentUserIdToken(): Observable<IdTokenResult> {
    if (this.afa.currentUser) {
      return from(this.afa.currentUser.getIdTokenResult());
    }
    return of(null);
  }

  /**
   * get all the products that the user has acccess to along with hasura link
   *
   * @returns {Observable<Products>}
   * @memberof AuthService
   */
  getProducts(): Observable<Products> {
    return this.http
      .request('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: GET_CUSTOMERS_INFO,
        }),
      })
      .pipe(map((res: any) => res.data.products));
  }

  /**
   * get all the customers that the super user has acccess to
   *
   * @returns {Observable<Customers[]>}
   * @memberof AuthService
   */
  getCustomers(): Observable<Customers[]> {
    return this.http
      .request('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: GET_ALL_CUSTOMERS,
        }),
      })
      .pipe(
        map((res: any) => res.data.customer),
        map((customers) => _orderBy(customers, ['name'], ['asc']))
      );
  }

  /**
   * get customer by id
   *
   * @param {number[]} ids
   * @returns {Observable<Customers[]>}
   * @memberof AuthService
   */
  getCustomerById(ids: number[]): Observable<Customers> {
    return this.http
      .request<{
        data: { getCustomers: Customers[] };
      }>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: GET_CUSTOMERS_BY_ID,
          variables: { options: { where: { ids } } },
        }),
      })
      .pipe(map((res) => head(res?.data?.getCustomers)));
  }

  /**
   * create customer
   *
   * @returns {Observable<Customers>}
   * @memberof AuthService
   */
  createCustomer(customer: CreateCustomers): Observable<Customers> {
    const data = {
      name: customer.name,
      domains: customer.domains ? [customer.domains] : null,
      isSSOEnabled: customer.isSSOEnabled ? true : false,
      isPilot: customer.isPilot ? true : false,
      pilotDateEnd: customer.pilotDateEnd,
    };
    return this.http
      .request<{
        data: { createCustomer: Customers };
      }>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: CREATE_CUSTOMER,
          variables: { customerData: data },
        }),
      })
      .pipe(
        catchError((error) => {
          this.notification.showError(ERROR_CREATE_CUSTOMER);
          return throwError(error);
        }),
        map((res) => res.data.createCustomer)
      );
  }

  /**
   * update customer
   *
   * @returns {Observable<Customers>}
   * @memberof AuthService
   */
  updateCustomer(customer: CreateCustomers, id: number) {
    const domain = head(customer.domains);
    const data = {
      name: customer?.name,
      domains: !isNil(domain) && domain.length > 0 ? customer.domains : null,
      isSSOEnabled: customer.isSSOEnabled ? true : false,
      isPilot: customer.isPilot ? true : false,
      pilotDateEnd: customer.pilotDateEnd,
      isCloseWorkRulesBypassEnabled: customer.isCloseWorkRulesBypassEnabled,
      isCmmsSyncEnabled: customer.isCmmsSyncEnabled,
      isDisplayRawAlarmTextEnabled: customer.isDisplayRawAlarmTextEnabled,
      isBASPriorityEnabled: customer.isBASPriorityEnabled,
      isMFAEnabled: customer.isMFAEnabled,
      defaultTimezone: customer.defaultTimezone,
      userSessionExpiryMins: customer.userSessionExpiryMins,
      maxNotificationLimit: customer.maxNotificationLimit,
    };
    return this.http
      .request<{
        data: { createCustomer: Customers };
      }>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: UPDATE_CUSTOMER_QUERY,
          variables: { id, data },
        }),
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'Update Customer',
            'Customer was successfully updated'
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_CUSTOMER);
          return throwError(error);
        })
      );
  }

  /**
   * fetch user organizations
   *
   * @param {string} nameLike
   * @returns
   * @memberof AuthService
   */
  getOrganizations(nameLike: string) {
    const contentType = { 'Content-Type': 'application/json' };
    const where = { nameLike: `%${nameLike}%` };
    const order = { field: 'NAME', direction: OrderDirection.ASC };
    const options =
      isNil(nameLike) || nameLike.length < 1
        ? { order }
        : { ...{ order }, ...{ where } };
    return this.http
      .request<{
        data: { companiesAndCount: { items: OrganizationOptions[] } };
      }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: GET_ORGANIZATIONS_QUERY,
          variables: {
            options,
          },
        }),
      })
      .pipe(
        map((data) => {
          const op = get(data, 'data.companiesAndCount.items', []);
          return op.map((r) => ({ value: r.name, label: r.name }));
        }),
        catchError((error) => {
          this.notification.showError(ERROR_GET_ORGANIZATIONS);
          return throwError(error);
        })
      );
  }

  /**
   * create new organization
   *
   * @param {string} name
   * @returns
   * @memberof AuthService
   */
  createOrganization(name: string) {
    const contentType = { 'Content-Type': 'application/json' };
    return this.http
      .request<{
        data: { createCompany: { name: string } };
      }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: CREATE_ORGANIZATION_QUERY,
          variables: {
            name,
          },
        }),
      })
      .pipe(
        catchError((error) => {
          this.notification.showError(ERROR_CREATE_ORGANIZATIONS);
          return throwError(error);
        })
      );
  }

  /**
   * delete user
   *
   * @param {string} id
   * @returns
   * @memberof AuthService
   */
  deleteUser(id: string) {
    return this.http
      .request('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: DELETE_USER_QUERY,
          variables: { id },
        }),
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'User deleted',
            'User was successfully deleted'
          );
        })
      );
  }

  /**
   * verify the user's current password
   *
   * @param {*} password
   * @memberof AuthService
   */
  public async verifyPassword(password: string) {
    const user = this.afa.currentUser;
    const cred = EmailAuthProvider.credential(user?.email, password);
    return reauthenticateWithCredential(user, cred);
  }

  /**
   * changes the user's password
   *
   * @param {*} password
   * @memberof AuthService
   */
  public async changePassword(password: string) {
    const user = this.afa.currentUser;
    await updatePassword(user, password)
      .then(() =>
        this.notification.showSuccess(
          'Change Password',
          'Your password change was successful'
        )
      )
      .catch((error) => {
        this.notification.showError(ERROR_CHANGE_PASSWORD);
        return throwError(error);
      });
    return this.notifyPasswordChanged();
  }

  /**
   * notifies users that their password was changed
   *
   * @returns
   * @memberof AuthService
   */
  public notifyPasswordChanged() {
    return this.apollo
      .mutate({
        mutation: NOTIFY_PASSWORD_CHANGED_QUERY,
      })
      .subscribe();
  }

  /**
   * change user add device registration token
   *
   * @param {string} token
   * @returns
   * @memberof AuthService
   */
  public addDeviceRegistrationToken(token: string) {
    return this.apollo
      .mutate({
        mutation: ADD_DEVICE_REGISTRATION_TOKEN,
        variables: { token },
      })
      .pipe(
        catchError((error) => {
          this.notification.showError(ERROR_ADD_DEVICE_REGISTRATION_TOKEN);
          return throwError(error);
        })
      );
  }

  /**
   * remove user device registration token
   *
   * @param {string} token
   * @returns
   * @memberof AuthService
   */
  public removeDeviceRegistrationToken(token: string) {
    return this.apollo
      .mutate({
        mutation: REMOVE_DEVICE_REGISTRATION_TOKEN,
        variables: { token },
      })
      .pipe(catchError((error) => throwError(error)));
  }

  /**
   * call endpoint to reset password
   *
   * @param {string} password
   * @param {string} token
   * @returns {Observable<boolean>}
   * @memberof AuthService
   */
  public resetPasswordByApi(
    password: string,
    token: string,
    customerId: string
  ): Observable<boolean> {
    const headers = new HttpHeaders()
      .set(CUSTOMER_ID_HEADER, customerId)
      .set('Content-Type', 'application/json');
    return this.http
      .post<boolean>(
        environment.backend,
        JSON.stringify({
          query: RESET_PASSWORD_QUERY,
          variables: { password, token },
        }),
        {
          headers: headers,
        }
      )
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'Change Password',
            'Your password change was successful'
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_CHANGE_PASSWORD);
          return throwError(error);
        })
      );
  }

  /**
   * Formats user input
   *
   * @param {UntypedFormGroup} form
   * @param {Roles[]} roles
   * @return {*}  {Partial<User>}
   * @memberof AuthService
   */
  formatUserInput({
    form,
    roles,
    isCreate = false,
  }: {
    form: UntypedFormGroup;
    roles: Roles[];
    isCreate?: boolean;
  }): Partial<Users> {
    const profile = {
      ...form.value,
      email: isCreate
        ? form.value.email
        : {
            address: form.value.email,
            forwarding: {
              isEnabled: !!form.value.forwardEnabled,
              recipient: form.value.forwardRecipient,
              until: form.value.forwardTime,
            },
          },
    };
    const authRole = profile.authRole;
    delete profile.authRole;
    const selectedRole = roles.find((role) => role.name === authRole);
    if (selectedRole) {
      profile.authRole = selectedRole.role;
      profile.authRoleId = selectedRole.customRoleId;
    }
    return profile;
  }

  /**
   * update profile on vfi backend
   *
   * @param {string} id
   * @param {Partial<Users>} user
   * @param {boolean} updatePhone
   * @returns {Observable<boolean>}
   * @memberof AuthService
   */
  public updateUser(
    id: string,
    user: Partial<Users>,
    updatePhone: boolean
  ): Observable<boolean> {
    const isPushNotificationEnabled = {
      isPushNotificationEnabled: !user.isPushNotificationEnabled,
    };
    const phone = {
      phone: {
        number: user?.phone || null,
        countryCode: '1',
        isSubscribed: true,
        isVerified: true,
      },
    };
    let data = {
      firstName: user.firstName,
      lastName: user.lastName,
      displayName: user.displayName,
      company: user.company,
      role: user.role,
      authRole: user.authRole,
      customRoleId: user.authRoleId,
      isIntegrationStatusAlertEmailsEnabled:
        user.isIntegrationStatusAlertEmailsEnabled,
      email: {
        isSubscribed: true,
        ...user.email,
      },
      landingPage: user.landingPage,
      landingPageLensId: user.landingPageLens,
      analyticsPage: user.analyticsPage,
    };
    if (updatePhone) {
      data = { ...data, ...isPushNotificationEnabled, ...phone };
    }
    return this.apollo
      .mutate<{ updateUser: boolean }>({
        mutation: UPDATE_USER_QUERY,
        variables: {
          id,
          user: data,
        },
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'Update Profile',
            'Your update profile was successful'
          );
        }),
        map((d) => d.data.updateUser),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_PROFILE);
          return throwError(error);
        })
      );
  }

  /**
   * update user landing page
   *
   * @param {(string | number)} userId
   * @param {LandingPage} landingPage
   * @returns
   * @memberof AuthService
   */
  public updateUserLandingPage(
    userId: string | number,
    landingPage: LandingPage
  ) {
    return this.apollo
      .mutate<{ updateUser: boolean }>({
        mutation: UPDATE_USER_QUERY,
        variables: {
          id: userId,
          user: { landingPage },
        },
      })
      .pipe(
        map((d) => d.data.updateUser),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_PROFILE);
          return throwError(error);
        })
      );
  }

  /**
   * update user analytics landing page
   *
   * @param {(string | number)} userId
   * @param {SavedViewsViewType} analyticsPage
   * @returns
   * @memberof AuthService
   */
  public updateUserAnalyticsLandingPage(
    userId: string | number,
    analyticsPage: SavedViewsViewType
  ) {
    return this.apollo
      .mutate<{ updateUser: boolean }>({
        mutation: UPDATE_USER_QUERY,
        variables: {
          id: userId,
          user: { analyticsPage },
        },
      })
      .pipe(
        map((d) => d.data.updateUser),
        catchError((error) => {
          this.notification.showError(ERROR_SET_DEFAULT_LENS);
          return throwError(error);
        })
      );
  }

  /**
   * refresh token when using custom token schema
   *
   * @returns {Promise<string>}
   * @memberof AuthService
   */
  public async refreshToken(): Promise<string> {
    const customToken: string = await firstValueFrom(
      this.apollo
        .mutate<{ getCustomToken: string }>({ mutation: GET_CUSTOM_TOKEN })
        .pipe(map((d) => d.data.getCustomToken))
    );

    const { user } = await signInWithCustomToken(this.afa, customToken);
    return user.getIdToken();
  }

  /**
   * add zendesk secret to JWT
   *
   * @returns
   * @memberof AuthService
   */
  public getZendeskToken(): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post<{ data: { token: string } }>(
        environment.backend,
        JSON.stringify({
          query: GET_ZEN_DESK_TOKEN,
        }),
        {
          headers: headers,
        }
      )
      .pipe(map((d) => d.data.token));
  }

  /**
   * force refresh a token
   *
   * @returns {Promise<string>}
   * @memberof AuthService
   */
  public async getRefreshedToken(): Promise<IdTokenResult> {
    return this.afa.currentUser?.getIdTokenResult(true);
  }

  /**
   * invite user
   *
   * @param {*} user
   * @param {string} orgId
   * @param {boolean} [sendInvite=true]
   * @returns
   * @memberof AuthService
   */
  public invite(user, orgId: string, sendInvite = true) {
    const phone = {
      phone: {
        number: user?.phone || null,
        countryCode: '1',
        isSubscribed: true,
        isVerified: true,
      },
    };
    return this.apollo
      .mutate({
        mutation: CREATE_USER_QUERY,
        variables: {
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            displayName: user.displayName,
            role: user.role,
            email: user.email,
            authRole: user.authRole,
            customRoleId: user.authRoleId,
            company: user.company,
            teams: user.teams,
            ...phone,
          },
          customerId: +orgId,
          sendInvite: sendInvite,
        },
      })
      .pipe(
        tap(() => {
          const header = sendInvite ? 'Invite User' : 'Save User';
          const body = sendInvite
            ? 'Your invitation was sent'
            : 'User was saved';

          this.notification.showSuccess(header, body);
        }),
        catchError((error) => {
          if (
            [ERROR_CREATE_EXISTING_USER, ERROR_CREATE_EXISTING_EMAIL].includes(
              error?.message
            )
          ) {
            this.notification.showError('Invite User', error?.message);
          } else {
            const header = sendInvite ? ERROR_INVITE : ERROR_UPDATE_PROFILE;
            this.notification.showError(header);
          }
          return throwError(() => error);
        })
      );
  }
  /**
   * resend invite user
   *
   * @param {Users} user
   * @returns
   * @memberof AuthService
   */
  public resendInvite(email: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post<boolean>(
        environment.backend,
        JSON.stringify({
          query: RESEND_INVITE_USER_QUERY,
          variables: { email },
        }),
        {
          headers: headers,
        }
      )
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'Invite User',
            'Your invitation was sent'
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_RESEND_INVITE);
          return throwError(error);
        })
      );
  }

  /**
   * validate user token
   *
   * @param {string} token
   * @param {string} customerId
   * @returns {Observable<ValidateUser>}
   * @memberof AuthService
   */
  validateToken(token: string, customerId: string): Observable<ValidateUser> {
    const headers = new HttpHeaders()
      .set(CUSTOMER_ID_HEADER, customerId)
      .set('Content-Type', 'application/json');
    return this.http
      .post(
        environment.backend,
        JSON.stringify({
          query: VALIDATE_TOKEN_QUERY,
          variables: { token },
        }),
        {
          headers: headers,
        }
      )
      .pipe(
        map(
          (res: { data: { validateActivationToken: ValidateUser } }) =>
            res.data.validateActivationToken
        )
      );
  }

  /**
   * activate new user account
   *
   * @param {number} customerId
   * @param {string} password
   * @param {string} token
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  activateUser(
    customerId: number,
    password: string,
    token: string
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(
        environment.backend,
        JSON.stringify({
          query: ACTIVATE_USER_QUERY,
          variables: { customerId, password, token },
        }),
        {
          headers: headers,
        }
      )
      .pipe(
        map((res) => res),
        tap(() => {
          this.notification.showSuccess(
            'Activate User',
            'User successfaully activated'
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_ACTIVATE_USER);
          return throwError(error);
        })
      );
  }

  /**
   * handles errors from api call
   *
   * @param {string} err
   * @memberof AuthService
   */
  handleError(err = ERROR_LOGIN_AUTH) {
    this.notification.showError(err);
  }

  /**
   * init user tracking per account.
   *
   * @param {Users} user
   * @param {string} customerId
   * @param {string} customerName
   * @returns
   * @memberof AuthService
   */
  setupTracker(user: Users, customerId: string, customerName: string) {
    if (!user || typeof pendo === 'undefined') {
      return;
    }
    pendo.initialize({
      visitor: {
        id: user.uid,
        name: user.displayName,
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email ? user.email.address : '',
        active: user.status,
        role: user.role,
        company: user.company,
        companyId: customerId,
        creationDate: user.lastInviteTime,
        authRole: user.authRole,
        isPushNotificationEnabled: user.isPushNotificationEnabled,
        isIntegrationStatusAlertEmailsEnabled:
          user.isIntegrationStatusAlertEmailsEnabled,
      },
      account: {
        id: customerId,
        customer: customerName,
        env: environment.account,
      },
    });
  }

  /**
   * update user access
   *
   * @param {string} id
   * @param {boolean} enabled
   * @param {string} customerId
   * @returns
   * @memberof AuthService
   */
  updateUserAccess(id: string, enabled: boolean) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .request<{
        data: { setUserAccess: { status: string } };
      }>('POST', environment.backend, {
        headers,
        body: JSON.stringify({
          query: SET_USER_ACCESS_QUERY,
          variables: { id, enabled },
        }),
      })
      .pipe(
        map((res) => res.data.setUserAccess.status),
        tap(() => {
          this.notification.showSuccess('Update User', 'User access updated');
        }),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_USER_ACCESS);
          return throwError(error);
        })
      );
  }

  /**
   * call Firebase and unenroll user from MFA
   *
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  public disableMFAUser(): Promise<void> {
    const multiFactorUser = multiFactor(this.afa.currentUser);
    const enrolledOptions = multiFactorUser.enrolledFactors;
    return multiFactorUser.unenroll(enrolledOptions[0]);
  }

  /**
   * call api to unenroll user from MFA
   *
   * @param {string} id
   * @returns
   * @memberof AuthService
   */
  public disableMFAAdmin(id: string) {
    return this.apollo
      .mutate({
        mutation: DISABLE_MFA_QUERY,
        variables: { id },
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'Multi-Factor Authentication',
            'Multi-Factor authentication disabled'
          );
        }),
        map((d) => d),
        catchError((error) => {
          this.notification.showError(ERROR_MFA_UPDATE);
          return throwError(error);
        })
      );
  }

  /**
   * Update related entity config
   *
   * @param {boolean} isEnabled
   * @return {Observable<boolean>}
   * @memberof AuthService
   */
  public updateRelatedEntityConfig(isEnabled: boolean): Observable<boolean> {
    return this.apollo
      .mutate<{ updateRelatedEntityConfig: { isEnabled: boolean } }>({
        mutation: UPDATE_RELATED_ENTITY_CONFIG_QUERY,
        variables: { input: { isEnabled } },
      })
      .pipe(map((d) => d.data.updateRelatedEntityConfig.isEnabled));
  }

  /**
   * Gets the related entity config
   *
   * @return {Observable<boolean>}
   * @memberof AuthService
   */
  public getRelatedEntityConfig(): Observable<boolean> {
    return this.apollo
      .query<{ relatedEntityConfig: { isEnabled: boolean } }>({
        query: RELATED_ENTITY_CONFIG_QUERY,
      })
      .pipe(map((d) => d.data.relatedEntityConfig.isEnabled));
  }

  /**
   * Activates sso user
   *
   * @return {Observable<boolean>}
   * @memberof AuthService
   */
  public activeSsoUser(): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .request<{
        data: { activateSsoUser: boolean };
      }>('POST', environment.backend, {
        headers,
        body: JSON.stringify({
          query: ACTIVATE_SSO_USER,
        }),
      })
      .pipe(
        map((d) => d.data.activateSsoUser),
        catchError(() => of(false))
      );
  }

  /**
   * fetch customer alarm config
   *
   * @returns {Observable<CustomerAlarmConfig>}
   * @memberof AuthService
   */
  public getCustomerAlarmConfig(): Observable<CustomerAlarmConfig> {
    return this.apollo
      .query<{ alarmConfig: CustomerAlarmConfig }>({
        fetchPolicy: 'no-cache',
        query: GET_CUSTOMER_ALARM_CONFIG_QUERY,
      })
      .pipe(map((d) => d?.data?.alarmConfig));
  }

  /**
   * update customer alarm config
   *
   * @param {CustomerAlarmConfig} data
   * @returns
   * @memberof AuthService
   */
  public updateCustomerAlarmConfig(data: CustomerAlarmConfig) {
    return this.apollo
      .mutate({
        mutation: UPDATE_CUSTOMER_ALARM_CONFIG_QUERY,
        variables: { input: data },
      })
      .pipe(
        catchError((error) => {
          this.notification.showError(ERROR_CUSTOMER_ALARM_CONFIG_UPDATE);
          return throwError(error);
        })
      );
  }

  /**
   * Gets the firebase user
   *
   * @return {Observable<firebaseUser>}
   * @memberof AuthService
   */
  getFirebaseUser(): Observable<firebaseUser> {
    if (this.afa.currentUser) {
      return of(this.afa.currentUser);
    }
    return new Observable<firebaseUser>((observer) => {
      const teardown = onAuthStateChanged(this.afa, (user) => {
        observer.next(user);
      });
      return teardown;
    });
  }

  /**
   * Gets the redirect result
   *
   * @return {*}  {Observable<UserCredential>}
   * @memberof AuthService
   */
  getRedirectResult(): Observable<UserCredential> {
    return from(getRedirectResult(this.afa));
  }

  /**
   * generate users status
   *
   * @private
   * @param {boolean} showDisabledUsers
   * @param {boolean} showPendingUsers
   * @returns
   * @memberof AuthService
   */
  private generateFetchUserStatus(
    showDisabledUsers: boolean,
    showPendingUsers: boolean
  ) {
    let status = ['active'];
    if (showDisabledUsers) {
      status = ['active', 'pending', 'disabled'];
    } else if (showPendingUsers) {
      status = ['active', 'pending'];
    }
    return status;
  }

  /**
   * sort users
   *
   * @private
   * @param {Users[]} users
   * @param {GlobalFilterSort} sorts
   * @returns
   * @memberof AuthService
   */
  private sortUsers(users: Users[], sorts: GlobalFilterSort) {
    if (!sorts) {
      sorts = {
        sortType: OrderDirection.ASC,
        sortDbName: DefaultUserSort.sortDbName,
      } as GlobalFilterSort;
    }
    const type = sorts.sortType.toLowerCase();
    const sort = sorts.sortDbName;
    return _orderBy(
      users,
      [
        (user) => !user.lastInviteTime && user.status === Status.pending,
        'status',
        () => sort.toLowerCase(),
      ],
      ['desc', 'desc', type]
    );
  }
}
