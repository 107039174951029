import { AuthRoles } from '../constants/roles.constants';

export interface Permissions {
  authRole: AuthRoles;
  landing: string;
  triage: PermissionTriage;
  reporting: PermissionReporting;
  settings: PermissionSettings;
  analytics: PermissionAnalytics;
  workOrder: boolean;
  alarms: boolean;
  situationalAwareness: boolean;
}

export interface PermissionAnalytics {
  base: boolean;
  counts: boolean;
  'work-resolution': boolean;
  'kpi-dashboard': boolean;
  'work-history': boolean;
  'advanced-analytics': boolean;
}

export interface PermissionReporting {
  base: boolean;
  counts: boolean;
  allAlarms: boolean;
  followUp: boolean;
  allWork: boolean;
}

export interface PermissionSettings {
  base: boolean;
  profile: boolean;
  userGroups: boolean;
  roles: boolean;
  teams: boolean;
  feedback: boolean;
  about: boolean;
  objectControl: boolean;
  automationRules: boolean;
}

export interface PermissionTriage {
  base: boolean;
  Active: boolean;
  Intake: boolean;
  Snoozed: boolean;
  Declined: boolean;
  Dispatched: boolean;
  Nuisance: boolean;
  'My Work': boolean;
  'Active Work': boolean;
  Closed: boolean;
}
