<div class="form-group w-100">
  <div
    class="input-wrapper"
    [class.requiredInput]="form.get(formName).invalid"
    [class.input-active]="value || focus"
  >
    <nz-select
      aria-label="user-select-dropdown"
      class="w-100"
      nzShowSearch
      (nzBlur)="updateForm()"
      (nzFocus)="fetchUsers()"
      [(ngModel)]="value"
      [nzCustomTemplate]="custom"
      [nzAllowClear]="'true'"
      (nzOnSearch)="fetchUsers($event)"
      [nzDisabled]="disabled"
      (ngModelChange)="updateForm(true)"
      [nzClearIcon]="customClearIcon"
      [nzRemoveIcon]="customClearIcon"
      [ngClass]="{ 'cursor-default': disabled }"
    >
      <nz-option
        nzCustomContent
        [nzLabel]="user.displayName"
        [nzValue]="user.id"
        *ngFor="let user of usersList"
      >
        <span class="d-flex align-items-center justify-content-between"
          >{{ user.displayName }}
          <nz-avatar
            [ngClass]="{ 'fas fa-user': !user?.avatarUrl }"
            [nzSrc]="user?.avatarUrl"
          ></nz-avatar>
        </span>
      </nz-option>
    </nz-select>

    <label class="w-100 d-flex align-items-center"
      >{{ labelName }}
      <nz-avatar
        [nzSrc]="null"
        class="user-placeholder fas fa-user"
        *ngIf="!disabled"
      ></nz-avatar>
    </label>
  </div>
</div>

<ng-template #customClearIcon>
  <i class="white far fa-times-circle"></i>
</ng-template>

<ng-template #custom let-selected>
  <span class="d-flex align-items-center justify-content-between mt-1"
    ><span class="selected-content"
      >{{ value ? selectedUser?.displayName : null }}
    </span>
    <nz-avatar
      *ngIf="value"
      [ngClass]="value"
      [nzSrc]="selectedUser?.avatarUrl"
      class="user-avatar"
    ></nz-avatar>
  </span>
</ng-template>
